import styles from './AddMediaComponent.styles';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Fab, Skeleton, Typography } from '@mui/material';
import { IngressButtons } from '@castify/studio/ingress-flows';

interface AddMediaComponentProps {
  showSkeleton?: boolean;
}

function AddMediaComponent({ showSkeleton }: AddMediaComponentProps) {
  const [showButtons, setShowButtons] = useState(false);

  if (showSkeleton)
    return <Skeleton variant="rectangular" height={40} width={148} />;

  return (
    <>
      {showButtons && (
        <IngressButtons afterClick={() => setShowButtons(false)} />
      )}

      {/* add media button */}
      <Fab
        css={styles.button}
        data-pendo-id="add-media-button"
        onClick={() => {
          setShowButtons(!showButtons);
        }}
        variant="extended"
        color="info"
      >
        <AddIcon css={styles.icon} />
        <Typography css={styles.font}>Add&nbsp;Media</Typography>
      </Fab>
    </>
  );
}

export default AddMediaComponent;
