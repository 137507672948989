import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  link: css`
    color: ${colors.gray[600]};
    text-decoration: none;
  `,
};
export default styles;
