import { useMst } from '@castify/studio/studio-store';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import sidebarCommon from './sidebarCommon.styles';
import { Slider } from '@mui/material';
import { observer } from 'mobx-react-lite';

/**
 * The blur tool's right sidebar
 */
function BlurSidebar() {
  const {
    toolManager: { blurToolIfOpen, closeTool },
    project: { undoManager },
  } = useMst();
  if (!blurToolIfOpen) return null;

  return (
    <div css={sidebarCommon.container}>
      <div css={sidebarCommon.titleSection}>
        <Icon css={sidebarCommon.icon}>lens_blur</Icon>
        <h2>Blur</h2>
      </div>
      <div css={sidebarCommon.centerSection}>
        <div css={sidebarCommon.sliderSection}>
          <h3>Intensity</h3>
          <div css={sidebarCommon.sliderContainer}>
            <Slider
              min={0}
              value={blurToolIfOpen.selectedBoxIntensity}
              max={1}
              step={0.01}
              onChange={(e: Event, value: number | number[]) => {
                blurToolIfOpen.changeIntensity(
                  typeof value === 'number' ? value : value[0],
                );
              }}
              onChangeCommitted={() => {
                undoManager.stopGroup();
              }}
              onPointerDown={() => {
                undoManager.startGroup(() => {});
              }}
            />
          </div>
          <div css={sidebarCommon.sliderLabels}>
            <label>Low</label>
            <label>High</label>
          </div>
        </div>
        <Button
          variant="text"
          css={sidebarCommon.centerSectionButton}
          onClick={() => {
            blurToolIfOpen.addAndSelectBlurBox();
          }}
        >
          + New Blur
        </Button>
      </div>
      <div css={sidebarCommon.bottomSection}>
        <Button
          variant="text"
          css={sidebarCommon.doneButton}
          onClick={() => closeTool()}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default observer(BlurSidebar);
