import { css } from '@emotion/react';
import { colors, st } from '@castify/studio/fe-common';

const styles = {
  container: css`
    margin-top: 0;
  `,
  cropIcon: css`
    background-color: ${colors.gray[400]};
    font-size: 16px;
    padding-top: 2px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    z-index: +1;
    position: absolute;
  `,
  selectedCropIcon: css`
    background-color: ${colors.TEMP.ZoomPurple};
  `,
  swappable: css`
    width: 100%;
    height: 100%;
  `,
  background: (backgroundColor: string, thumbnailHeight: st.px) => css`
    width: 100%;
    height: ${thumbnailHeight}px;
    background-color: ${backgroundColor};
  `,
  videoAndAudio: css`
    width: 100%;
    height: 100%;
    background-color: white;
  `,
  video: (thumbnailHeight: st.px) => css`
    height: ${thumbnailHeight}px;
  `,
  audio: css`
    height: 28px;
  `,
};
export default styles;
