import { useMst } from '@castify/studio/studio-store';
import { useQuery } from 'urql';
import {
  GetAllProjectVideosDocument,
  GetAllProjectVideosQuery,
} from '@castify/studio/database-clients/graphql';
import { observer } from 'mobx-react-lite';
import styles from '../DebugPanel.styles';
import {
  verifyProbeOutput,
  getDuration,
} from '@castify/studio/video-probe-data';

/**
 * Loads/shows a list of all of a project's videos
 */
const ProjectVideos = () => {
  const {
    project: { uuid },
    ingress: { finishIngressFlow },
  } = useMst();

  /**
   * Load videos for the project
   */
  const [{ data, error, fetching }] = useQuery<GetAllProjectVideosQuery>({
    query: GetAllProjectVideosDocument,
    variables: { projectUuid: uuid },
    requestPolicy: 'cache-and-network',
  });

  return (
    <div css={styles.addVideoMenuColumn}>
      <p>Project Videos</p>
      {fetching && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data?.video_sources
        // filter out videos that aren't ready to show
        // may need to be updated when resource generation is expanded
        .filter((row) => {
          return (
            row.generate_playback_is_done && row.generate_thumbnails_is_done
          );
        })
        // show a button
        .map((row) => {
          try {
            const probeData = verifyProbeOutput(row.stream_probe);
            return (
              <button
                key={row.uuid}
                onClick={() => {
                  finishIngressFlow({ videoSourceUuid: row.uuid, probeData });
                }}
              >
                {`${row.uuid.slice(0, 4)} ${Math.floor(
                  getDuration(probeData),
                )}sec`}
              </button>
            );
          } catch (error) {
            console.error(
              'Error verifying probe output in project videos menu',
              error,
            );
            return <p>error in parsing probe data</p>;
          }
        })}
    </div>
  );
};

export default observer(ProjectVideos);
