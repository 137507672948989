import { useGetAccessToken } from '@castify/studio/auth';
import { st } from '@castify/studio/fe-common';
import { useEffect, useState } from 'react';
import { requestDriveUploadURL, uploadFile } from './driveUploadHelpers';

export const calculateProgressFromEvent = (progressEvent: ProgressEvent) => {
  return (100 * progressEvent.loaded) / progressEvent.total;
};

export const isUploadComplete = (progress: number, error: unknown) => {
  return !error && progress === 100;
};

/**
 * Hook that wraps upload fn and keeps state for it
 */
export function useUploadFile(file: File) {
  const [progress, setProgress] = useState<st.percent>(0);
  const [error, setError] = useState<unknown>(null);
  const [driveFileId, setDriveFileId] = useState<string>('');

  /**
   *  We need the access token in order to request an md5 of the file
   */
  const getAccessToken = useGetAccessToken();

  /**
   * Start the upload
   */
  useEffect(() => {
    (async () => {
      try {
        const uploadURL = await requestDriveUploadURL(await getAccessToken());
        const driveFileId = await uploadFile(
          file,
          uploadURL,
          await getAccessToken(),
          (progressEvent) => {
            setProgress(calculateProgressFromEvent(progressEvent));
          },
        );
        setDriveFileId(driveFileId);
      } catch (error) {
        setError(error);
      }
    })();
  }, [file, getAccessToken]);

  return {
    progress,
    error,
    isComplete: isUploadComplete(progress, error),
    driveFileId,
  };
}
