import styles from './SidebarButton.styles';
import { observer } from 'mobx-react-lite';
import Icon from '@mui/material/Icon';
import { ListItemButton, Typography, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
interface SidebarButtonProps {
  goesTo: string;
  text: string;
  icon: string;
  isHere: boolean;
}

/**
 * Left side bar for when user is signed in. Assumes user is available when rendered
 */
function SidebarButton({ isHere, goesTo, text, icon }: SidebarButtonProps) {
  return (
    <ListItem disablePadding>
      <Link css={styles.link} to={goesTo}>
        <ListItemButton color="inherit" css={styles.GeneralButton}>
          <div css={[styles.selectIndicator(isHere)]} />
          <Icon>{icon}</Icon>
          <Typography>{text}</Typography>
        </ListItemButton>
      </Link>
    </ListItem>
  );
}

export default observer(SidebarButton);
