import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import RotatableBoxModel, {
  IRotatableBoxModel,
} from './rotatableBox/rotatableBox.mst';

const getDefaultBoundingBox = (): SnapshotIn<IRotatableBoxModel> => ({
  centerY: Math.random() * 0.6 + 0.2,
  centerX: Math.random() * 0.6 + 0.2,
  width: Math.random() * 0.3 + 0.1,
  height: Math.random() * 0.3 + 0.1,
});

/**
 * A text box which belongs to a text effect
 */
const TextBoxModel = types.model('TextBoxModel', {
  /**
   * Client-side uuid
   */
  uuid: types.optional(types.identifier, nanoid),
  boundingBox: types.optional(RotatableBoxModel, getDefaultBoundingBox()),
});
export default TextBoxModel;

export interface ITextBox extends Instance<typeof TextBoxModel> {}
