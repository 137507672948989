import { useMst } from '@castify/studio/studio-store';
import { Icon, Button } from '@mui/material';
import sidebarCommon from './sidebarCommon.styles';
import TextSidebarContent from './TextSidebarContent';

/**
 * The crop tool's right sidebar
 */
function TextSidebar() {
  const {
    toolManager: { textToolIfOpen, closeTool },
  } = useMst();

  if (!textToolIfOpen) return null;

  return (
    <div css={sidebarCommon.container}>
      <div css={sidebarCommon.titleSection}>
        <Icon css={sidebarCommon.icon}>title</Icon>
        <h2>Text</h2>
      </div>

      <div css={sidebarCommon.centerSection}>
        <TextSidebarContent />
      </div>

      <div css={sidebarCommon.bottomSection}>
        <Button
          variant="text"
          css={sidebarCommon.doneButton}
          onClick={() => closeTool()}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default TextSidebar;
