import { st } from '@castify/studio/fe-common';
import { Instance, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import { getSelection } from '../../selection/selection.mst';
import { getTimeline } from '../../timeline/timeline.mst';
import { getZoom } from '../../timeline/zoom/zoom.mst';
import ClipModel, { IClip } from './clip.mst';
import { getScene } from './scene.mst';
import { getUndoManager } from '../../project/project.mst';

/**
 * A audio clip that can be part of a the gapful annotations track
 * appearing alongside the main video track
 */
const MINIMUM_AUDIO_EFFECT_LENGTH = 40; // 1000 MS / 25 FRAMES

const AudioClipModel = types
  .model('AudioClipModel', {
    /**
     * Client-side uuid
     */
    uuid: types.optional(types.identifier, nanoid),

    /**
     * The audio clip is always attached to start clip
     * startClip is required
     */
    startClip: types.reference(ClipModel),

    /**
     * The point in time where the audio clip starts in relation to the startClip's duration
     * startClipSourceOffset > 0 && startClipSourceOffset < startClip.originalLength
     * startClipSourceOffset is in ms
     */
    startClipSourceOffset: types.number,

    /**
     * The audio clip is always attached to end clip
     * this is the clip which the effect ends at (could be the same as startClip)
     * endClip is required
     */
    endClip: types.reference(ClipModel),

    /**
     * The point in time where the audio clip ends in relation to the endClip's duration
     * endClipSourceOffset  > 0 && endClipSourceOffset < endClip.originalLength
     * endClipSourceOffset is in ms
     */
    endClipSourceOffset: types.number,
  })
  .views((self) => {
    return {
      /**
       * start is the start time of the effect, this will be the start time of
       * the video this effect is attached to + offset to the source video time
       * with the value of startClipSourceOffset
       */
      get start(): st.ms {
        return self.startClip.relativePointToTimeline(
          self.startClipSourceOffset,
        );
      },

      /**
       * computing end is very similar to computing start
       * because both are relative times to clip & clip offset time
       */
      get end(): st.ms {
        return self.endClip.relativePointToTimeline(self.endClipSourceOffset);
      },

      /**
       * The audio clip is not visible if its out of bound the audio clip
       * will be out of bound if start === end because of how start/end
       * they will clip to either start/end if one of the points are out
       * of bound if both points are out of bound, they will clip at the
       * same point
       */
      get isVisible(): boolean {
        return this.start !== this.end;
      },
      /**
       * The duration of the clip in MS
       */
      get duration(): st.ms {
        return this.end - this.start;
      },
      get isSelected(): boolean {
        return getSelection(self).selectedClip === self;
      },
      get leftOffsetPx(): st.px {
        const { visibleStart } = getTimeline(self);
        const { zoomFactor } = getZoom(self);
        return (this.start - visibleStart) * zoomFactor;
      },
      get widthPx(): st.px {
        return this.duration * getZoom(self).zoomFactor;
      },
    };
  })
  .actions((self) => ({
    /**
     *  Updates the startClip property for our audio clip
     */
    updateStartClip(clip: IClip) {
      self.startClip = clip;
    },
    /**
     *  Updates the endClip property for our audio clip
     */
    updateEndClip(clip: IClip) {
      self.endClip = clip;
    },
    /**
     *  Trims the right side / starting of our audio clip to equal the given start time
     */
    trimIn(newStartTime: st.ms) {
      const newStartClip =
        getScene(self).mainTrack.getMainTrackClipAtTime(newStartTime);
      if (!newStartClip)
        throw new Error('Trim has no associated Main Track Clip');
      self.startClip = newStartClip;
      self.startClipSourceOffset = newStartTime;
    },
    /**
     *  Trims the left side / ending of our audio clip to equal the given end time
     */
    trimOut(newEndTime: st.ms) {
      const newEndClip =
        getScene(self).mainTrack.getMainTrackClipAtTime(newEndTime);
      if (!newEndClip)
        throw new Error('Trim has no associated Main Track Clip');
      self.endClip = newEndClip;
      self.endClipSourceOffset = newEndTime;
    },
    trimFromStart(newStartTime: st.ms) {
      const scene = getScene(self);
      const currentClip = scene.mainTrack.getMainTrackClipAtTime(newStartTime);
      if (!currentClip) return;
      if (currentClip != self.startClip) self.startClip = currentClip;

      const { trimIn, start } = self.startClip;
      const newSourceOffset = trimIn + (newStartTime - start);
      self.startClipSourceOffset = newSourceOffset;
    },
    /**
     * Intended to be called when the pointer moves during a trim.
     *
     * TODO - deprecate use of the delta and then eliminate it entirely
     * from the signature. For more on this, see comments in useTrimHandler.ts
     * Second arg is optional to not break tests
     */
    handleTrimFromStart(delta: st.px, newStartPixel?: st.px) {
      const scene = getScene(self);

      const prevAudioClip =
        scene.detachedAudioTrack.getPreviousAudioTrackClipById(self.uuid);

      const changeInMS = delta / getZoom(self).zoomFactor;
      let newStartTime = self.start + changeInMS;

      // If we go off the timeline, reset to the start of the timeline
      if (newStartTime <= 0) newStartTime = 0;

      // If we go under out minimum length, bring us back to the minimum
      const newClipDuration = self.end - newStartTime;
      if (newClipDuration < MINIMUM_AUDIO_EFFECT_LENGTH)
        newStartTime = self.end - MINIMUM_AUDIO_EFFECT_LENGTH;

      // If we hit the edge of another audio clip, obey that minimum
      if (prevAudioClip && newStartTime <= prevAudioClip?.end)
        newStartTime = prevAudioClip.end;

      this.trimFromStart(newStartTime);
    },
    trimFromEnd(newEndTime: st.ms) {
      const scene = getScene(self);
      const currentClip = scene.mainTrack.getMainTrackClipAtTime(newEndTime);
      if (!currentClip) return;
      if (self.endClip !== currentClip) self.endClip = currentClip;

      const newEndOffset = self.endClipSourceOffset + (newEndTime - self.end);

      self.endClipSourceOffset = newEndOffset;
    },
    /**
     * Intended to be called when the pointer moves during a trim.
     *
     * TODO - deprecate use of the delta and then eliminate it entirely
     * from the signature. For more on this, see comments in useTrimHandler.ts
     * Second arg is optional to not break tests
     */
    handleTrimFromEnd(delta: st.px, newEndPixel?: st.px) {
      const scene = getScene(self);
      const changeInMS = delta / getZoom(self).zoomFactor;
      const nextAudioClip = scene.detachedAudioTrack.getNextAudioTrackClipById(
        self.uuid,
      );
      let newEndTime = self.end + changeInMS;

      // If we're past our upper bound, bring it back
      if (newEndTime >= scene.mainTrack.totalDuration)
        newEndTime = scene.mainTrack.totalDuration - 1;

      const newDuration = newEndTime - self.start;
      // If we're past our lower bounds, bring it back
      if (newDuration < MINIMUM_AUDIO_EFFECT_LENGTH)
        newEndTime = self.start + MINIMUM_AUDIO_EFFECT_LENGTH;

      // If we overlap the next audio clip, bring ourselves back
      if (nextAudioClip && newEndTime > nextAudioClip.start) {
        newEndTime = nextAudioClip.start - 1;
      }

      this.trimFromEnd(newEndTime);
    },

    /**
     * Starts a trim using either trim handle. Intended to run on pointerdown
     * events
     */
    startTrim(): void {
      getUndoManager(self).startGroup(() => {});
    },
    /**
     * Stops a trim using either trim handle. Intended to run on pointerup
     * events.
     */
    stopTrim(): void {
      getTimeline(self).setTemporaryOffset(0);
      getUndoManager(self).stopGroup();
    },
  }));

export default AudioClipModel;

export interface IAudioClip extends Instance<typeof AudioClipModel> {}
