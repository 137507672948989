import {
  CreateNewProjectDocument,
  CreateNewProjectMutation,
  CreateNewProjectMutationVariables,
} from '@castify/studio/database-clients/graphql';
import { useMutation } from 'urql';
import { Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { BrowserLogger } from '@castify/studio/observability/browser';

import styles from './AddProjectButton.styles';

/**
 * A small helper component separate from its parent mainly in order
 * to allow for a clean separation of concerns & not having three gql hooks in
 * one file. We could lift something like this back up to the parent if we
 * wanted a unified error and loading treatment.
 */
const AddProjectButton = () => {
  //Register instance of browser logger to log potential errors with project creation
  const logger = BrowserLogger.createLogger('CreateProject');

  const [{ fetching, error, data }, executeMutation] = useMutation<
    CreateNewProjectMutation,
    CreateNewProjectMutationVariables
  >(CreateNewProjectDocument);

  // Register a means to navigate with react router
  const navigate = useNavigate();
  // On a successful db insert when data changes push the user to the edit page for their new project
  useEffect(() => {
    if (data?.insert_projects_one?.uuid) {
      navigate(`/preview/${data.insert_projects_one.uuid}`);
    }
  }, [data, navigate]);

  if (error) {
    logger.error('Error occured while creating project', error);
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        executeMutation({
          title: `Untitled Video ${new Date().toDateString()}`,
        });
      }}
      data-pendo-id="new-project-button"
    >
      <AddRoundedIcon css={styles.addIcon} />

      {fetching ? 'creating...' : 'New Project'}
    </Button>
  );
};

export default AddProjectButton;
