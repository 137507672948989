import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  videoWrapper: css`
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: 90%;
  `,
  videoWrapperBorder: css`
    box-sizing: border-box;
    border-radius: 25px;
    border: 8px solid white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  `,
  overlay: css`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    opacity: 60%;
    border-radius: 25px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.75) 15%,
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.75) 85%,
      rgba(0, 0, 0, 1) 100%
    );
  `,
  overlayBorder: css`
    border-radius: 25px;
  `,
  controls: css`
    position: absolute;
    display: grid;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: opacity 0.75s; // happens in about .75 seconds

    grid-template-areas:
      'Title'
      'PlayButtonLarge'
      'BottomControls';
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
  `,
  hideControls: css`
    /* Only change opacity so the user can still interact with the buttons */
    opacity: 0%;
  `,
  titleDisplay: css`
    display: block;
    margin-top: 2%;
    margin-left: 4%;

    grid-area: Title;
  `,
  playButtonLarge: css`
    display: flex;
    align-items: center;
    justify-content: center;

    grid-area: PlayButtonLarge;
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  trimButton: css`
    align-self: flex-end;

    margin-bottom: 10px;
  `,
  playclock: css`
    color: white;
  `,
  fullscreenButtonContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    width: 15%;
  `,
  truncate: css`
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  bottomControls: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    grid-area: BottomControls;

    padding: 0 50px 0 50px;
  `,
  skeletonText: css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2em;
    color: ${colors.gray[500]};
  `,
  skeletonSquare: css`
    width: 100%;
    height: 100%;
  `,
};
export default styles;
