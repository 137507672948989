import { VideoPair } from './useVideoPlayer';
import { useMst, VideoSourceModel } from '@castify/studio/studio-store';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import { createBrowserLogger } from '@castify/studio/observability/browser';
import type { IBrowserLogger } from '@castify/studio/observability/browser';
const logger: IBrowserLogger = createBrowserLogger('UseSeek');

const RESEEK_THRESHHOLD = 0.001; // protects against rounding errors

const useSeek = (pairA: VideoPair, pairB: VideoPair) => {
  const { playback } = useMst();

  useEffect(() => {
    const disposer = reaction(
      /**
       * This determines when the effect runs
       */
      () => {
        const { seekClip, seekClipTimeSec, seekId } = playback;
        return {
          /**
           * This is a data dependency in order to ensure that repeated
           * seeks fire when playing back (as MST observables do not update
           * when passed the same value multiple times)
           */
          seekClip,
          seekClipTimeSec,
          seekId,
        };
      },
      /**
       * This is the actual effect
       */
      async () => {
        const {
          isPairAActive,
          setActivePlayerManifestLoading,
          isActivePlayerManifestLoading,
          setActivePlayerManifestUrl,
          activePlayerManifestUrl,
          seekClip,
          isPlaybackRequested,
          seekClipTimeSec,
        } = playback;

        /**
         * Select the active pair
         */
        const activePair = isPairAActive ? pairA : pairB;
        const { player } = activePair;

        /**
         * Type guards, plus early returns for cases where seek does not
         * need to run. Note that there is no need for seek behavior
         * for still clips
         */
        if (!player) return;
        if (!seekClip) return;
        if (!VideoSourceModel.is(seekClip.source)) return;

        /**
         * Switch to zero when the seekclip time is undefined;
         * (hackily) ensures the first clip is sought to zero on page load
         */
        const seekTime = seekClipTimeSec ? seekClipTimeSec : 0;

        /**
         * Syntax sweeteners for the substantive work below
         */
        const seekClipManifestUrl = seekClip.source.playbackManifest;
        const closeToLastSeek =
          Math.abs(player.currentTime() - seekTime) <= RESEEK_THRESHHOLD;
        const isManifestLoadedAlready =
          seekClipManifestUrl === activePlayerManifestUrl;
        /**
         * Have we already loaded the seek clip's manifest to the active player?
         * Are we at a different enough time from the current time?
         * ...if yes, seek in the active player
         */
        if (isManifestLoadedAlready && !closeToLastSeek) {
          logger.debug(
            `SEEKING WITHOUT LOAD IN PLAYER ${activePair.pairName}`,
            {
              seekTime,
            },
          );
          player.currentTime(seekTime);
        }

        /**
         * Have we not loaded the seek clip's manifest to the active player?
         * Is neither player in a playback requested state, such that calling
         * load would trigger an error?
         *
         * ...then load and seek.
         */
        if (
          !isManifestLoadedAlready &&
          !isActivePlayerManifestLoading &&
          !isPlaybackRequested
        ) {
          try {
            logger.debug(`LOADING MANIFEST TO PLAYER ${activePair.pairName}`, {
              seekClipTimeSec,
              seekClipManifestUrl,
            });
            setActivePlayerManifestLoading(true);
            await activePair.loadManifest(seekClipManifestUrl);
            setActivePlayerManifestUrl(seekClipManifestUrl);
            player.currentTime(seekTime);
          } catch (err) {
            if (err instanceof Error) {
              logger.error('Error fetching manifest:', { error: err });
            } else {
              logger.error(`Error fetching manifest: ${JSON.stringify(err)}`);
            }
            setActivePlayerManifestUrl('');
          } finally {
            setActivePlayerManifestLoading(false);
          }
        }
      },
      { fireImmediately: true },
    );

    return disposer;
  }, [playback, pairA, pairB]);
};

export default useSeek;
