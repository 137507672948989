import { st } from '@castify/studio/fe-common';
import {
  getVideoHeight,
  getVideoWidth,
  hasAudio,
  getDuration,
} from '@castify/studio/video-probe-data';
import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import ClipModel, { IClip } from './clip.mst';
import { MainTrackClip } from './sceneTypes';
import {
  getClipsInRange,
  enforcePointBoundriesInTrack,
} from './helpers/clipHelpers';
import { getZoom } from '../../timeline/zoom/zoom.mst';
import { getPlayback } from '../../playback/playback.mst';
import ZoomEffectModel, { IZoomEffect } from './zoomEffect.mst';
import BlurEffectModel, { IBlurEffect } from './blurEffect.mst';
import VideoSourceModel from './videoSource.mst';
import { environment } from '@castify/studio/env/browser';
import StillSourceModel from './stillSource.mst';
import { NewClipInfo } from '../../ingress/ingress.mst';

const EFFECT_DEFAULT_DURATION = 1000;

const MainTrackModel = types
  .model('MainTrackModel', {
    mainTrackClips: types.optional(types.array(ClipModel), []),
  })
  .views((self) => ({
    /**
     * Get start time of clip by id. Can be optimized in future to compute
     * start times recursively with memoization if we have performance
     * problems with timeline mutations.
     */
    getVideoClipStartById(uuid: st.uuid): st.ms {
      let start = 0;
      for (const clip of self.mainTrackClips) {
        if (clip.uuid === uuid) break;
        start += clip.duration;
      }
      return start;
    },
    /**
     * Grabs the clips visible within the range, considering 4 main cases:
     */
    getVideoClipsInRange(start: st.ms, end: st.ms): Array<MainTrackClip> {
      return getClipsInRange(self.mainTrackClips, start, end);
    },

    /**
     * Returns the main track clip at a time, if any
     */
    getMainTrackClipAtTime(time: st.ms): MainTrackClip | undefined {
      return self.mainTrackClips.find(
        (clip) => time >= clip.start && time < clip.end,
      );
    },
    /**
     * Gets zoom effect under the playhead if any
     *
     * TODO - unit test this
     */
    getZoomEffectAtTime(time: st.ms): IZoomEffect | undefined {
      const mainTrackClip = this.getMainTrackClipAtTime(time);
      if (!mainTrackClip) return;
      return mainTrackClip.zoomEffects.find((effect) => {
        return time >= effect.start && time < effect.end;
      });
    },
    /**
     * Gets blur effect under the playhead if any
     *
     * TODO - unit test this
     */
    getBlurEffectAtTime(time: st.ms): IBlurEffect | undefined {
      const mainTrackClip = this.getMainTrackClipAtTime(time);
      if (!mainTrackClip) return;
      return mainTrackClip.blurEffects.find((effect) => {
        return time >= effect.start && time < effect.end;
      });
    },
    /**
     * Gets the next clip by the current clip id
     */
    getNextMainTrackClipById(clipId: string): IClip | undefined {
      const index = self.mainTrackClips.findIndex(
        (clip) => clip.uuid === clipId,
      );
      // If there is no following clip, return early
      if (index === self.mainTrackClips.length - 1) return;
      return self.mainTrackClips[index + 1];
    },

    getPreviousMainTrackClipById(clipId: string): IClip | undefined {
      const index = self.mainTrackClips.findIndex(
        (clip) => clip.uuid === clipId,
      );
      // If there is no previous clip, return early
      if (index <= 0) return;
      return self.mainTrackClips[index - 1];
    },

    /**
     * Total duration of the scene
     */
    get totalDuration(): st.ms {
      return self.mainTrackClips.reduce((sum, clip) => {
        sum += clip.duration;
        return sum;
      }, 0);
    },
  }))
  .actions((self) => ({
    /**
     * Create and add a clip from probe data. Intended to be called
     * by the ingress flow.
     */
    addNewClipFromIngress({ videoSourceUuid, probeData }: NewClipInfo): void {
      const duration = getDuration(probeData) * 1000;
      const newClip = ClipModel.create({
        source: VideoSourceModel.create({
          videoId: videoSourceUuid,
          playbackManifest: `${environment.serviceUrl}video/${videoSourceUuid}/manifest.mpd`,
          scrubManifest: `${environment.serviceUrl}video/${videoSourceUuid}/manifest.mpd`,
          baseThumbnailUrl: `${environment.serviceUrl}video/${videoSourceUuid}/thumbnails/`,
          waveformUrl: `${environment.serviceUrl}video/${videoSourceUuid}/waveform/complete.dat`,
          height: getVideoHeight(probeData),
          width: getVideoWidth(probeData),
          hasAudio: hasAudio(probeData),
          videoLength: duration,
          trimIn: 0,
          trimOut: duration,
        }),
      });
      this.addClipToEnd(newClip);
    },
    /**
     * Add a new clip to the scene based on params passed in by the caller.
     *
     * Intended for use mainly by the debug menu.
     */
    addClipToEnd(properties: SnapshotIn<typeof ClipModel>): void {
      if (getPlayback(self).isPlaying) getPlayback(self).pause();
      const newClip = ClipModel.create(properties);
      self.mainTrackClips.push(newClip);
      getZoom(self).zoomAllTheWayOut();
    },
    /**
     * Given two clips, add the second clip provided into our timeline after the first clip.
     */
    addClipAfterOtherClip(clipInTimeline: IClip, clipToAdd: IClip): void {
      const insertIndex = self.mainTrackClips.findIndex(
        (currentClip) => currentClip.uuid === clipInTimeline.uuid,
      );
      self.mainTrackClips.splice(insertIndex, 0, clipToAdd);
    },

    /**
     * Attempts to add a blur effect at time
     */
    addBlurEffectAtTime(time: st.ms): IBlurEffect | undefined {
      // these early returns represent cases where no effect can be added
      if (self.getBlurEffectAtTime(time)) return;
      const clip = self.getMainTrackClipAtTime(time);
      if (!clip) return;
      if (StillSourceModel.is(clip.source)) return;
      const timeRelativeToClip = clip.getSourceOffsetFromTimestamp(time);
      if (!timeRelativeToClip) {
        return;
      }
      let startOffset = timeRelativeToClip;
      let endOffset = startOffset + EFFECT_DEFAULT_DURATION;

      [startOffset, endOffset] = enforcePointBoundriesInTrack(
        startOffset,
        endOffset,
        clip.trimIn,
        clip.trimOut,
        clip.blurEffects,
      );

      // create a blur effect, start time should be relative to the parent clip
      const newBlur = BlurEffectModel.create({
        startSourceOffset: startOffset,
        endSourceOffset: endOffset,
      });

      // add it & return it
      clip.unsafeAddBlurEffect(newBlur);
      return newBlur;
    },
    /**
     * Attempts to add a zoom effect at time
     */
    addZoomEffectAtTime(time: st.ms): IZoomEffect | undefined {
      // these early returns represent cases where no effect can be added
      if (self.getZoomEffectAtTime(time)) return;
      const clip = self.getMainTrackClipAtTime(time);
      if (!clip) return;
      if (StillSourceModel.is(clip.source)) return;
      const timeRelativeToClip = clip.getSourceOffsetFromTimestamp(time);
      if (!timeRelativeToClip) {
        return;
      }
      let startOffset = timeRelativeToClip;
      let endOffset = startOffset + EFFECT_DEFAULT_DURATION;

      [startOffset, endOffset] = enforcePointBoundriesInTrack(
        startOffset,
        endOffset,
        clip.trimIn,
        clip.trimOut,
        clip.zoomEffects,
      );

      // create a blur effect, start time should be relative to the parent clip
      const newZoom = ZoomEffectModel.create({
        startSourceOffset: startOffset,
        endSourceOffset: endOffset,
      });

      // add it & return it
      clip.unsafeAddZoomEffect(newZoom);
      return newZoom;
    },
    /**
     * Swap two clips by ID
     */
    swapClipsById(id1: string, id2: string): void {
      const index1 = self.mainTrackClips.findIndex((clip) => clip.uuid === id1);
      const index2 = self.mainTrackClips.findIndex((clip) => clip.uuid === id2);
      const arrayCopy = self.mainTrackClips.slice();
      arrayCopy[index1] = self.mainTrackClips[index2];
      arrayCopy[index2] = self.mainTrackClips[index1];
      self.mainTrackClips.replace(arrayCopy);
    },
  }));

export default MainTrackModel;
export interface IMainTrackModel extends Instance<typeof MainTrackModel> {}
