import { useQuery } from 'urql';
import { GetManagedLicensesDocument } from '@castify/studio/database-clients/graphql';
import { IInvitationListPropsRoles } from '../Invitations';
import Container from '@mui/material/Container';

import InvitationList from '../InvitationList/InvitationList';
import InvitationListRoleFilters from '../InvitationListRoleFilters/InvitationListRoleFilters';
import PendingInvitationFilter from '../PendingInvitationFilter/PendingInvitationFilter';

const InvitationListContainer = (props: IInvitationListPropsRoles) => {
  const {
    selectedRoles,
    setSelectedRoles,
    pendingInviteFilterValue,
    setPendingInviteFilterValue,
  } = props;

  // True gets all results
  // False will only get accepted invites.
  const seatUUIDClauseObject = pendingInviteFilterValue
    ? {}
    : { _is_null: false };

  const [{ data, error, fetching }] = useQuery({
    query: GetManagedLicensesDocument,
    variables: {
      roleType: selectedRoles,
      seat_uuid_clause: seatUUIDClauseObject,
    },
    requestPolicy: 'cache-and-network',
  });

  return (
    <Container>
      {data && (
        <InvitationListRoleFilters
          setSelectedRoles={setSelectedRoles}
          selectedRoles={selectedRoles}
          data={data}
          fetching={fetching}
          error={error}
        />
      )}
      {data && (
        <PendingInvitationFilter
          pendingInviteFilterValue={pendingInviteFilterValue}
          setPendingInviteFilterValue={setPendingInviteFilterValue}
        />
      )}
      <InvitationList data={data} fetching={fetching} error={error} />
    </Container>
  );
};

export default InvitationListContainer;
