import { st } from '@castify/studio/fe-common';
import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import { getScene } from '../project/scene/scene.mst';
import RootModel from '../root.mst';

/**
 * State, views, & actions backing preview. Note this will need to be shared
 * with a future Watch app
 */
const PlayerModel = types
  .model('PlayerModel', {
    /**
     * Subscribed to the width of the canvas' div container. Involved
     * in scaling the canvas
     */
    containerWidth: types.optional(types.number, 0),
    /**
     * Subscribed to the height of the canvas' div container. Involved
     * in scaling the canvas
     */
    containerHeight: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      /**
       * When the overlay and canvas are scaled by this value, they will
       * fill the available preview space
       */
      get scaleFactor(): st.ratio {
        const { containerWidth, containerHeight } = self;
        const { width, height } = getScene(self).canvasDimensions;
        return Math.min(containerWidth / width, containerHeight / height);
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * A setter intended to be used to pass in the dimensions of the
       * canvas' parent element when they change.
       */
      updatePlayerDimensions(dims: st.dims): void {
        self.containerWidth = dims.width;
        self.containerHeight = dims.height;
      },
    };
  });
export default PlayerModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getPreview(self: IAnyStateTreeNode): IPlayer {
  const root = getParentOfType(self, RootModel);
  return root.player;
}

export interface IPlayer extends Instance<typeof PlayerModel> {}
