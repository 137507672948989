import { Instance, types } from 'mobx-state-tree';
/**
 * State, views, & actions backing the zoom tool
 */

const ZoomToolModel = types
  .model('ZoomToolModel', {})
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
export default ZoomToolModel;

export interface IZoomTool extends Instance<typeof ZoomToolModel> {}
