/* eslint-disable @typescript-eslint/no-namespace */
export namespace st {
  /** pixels */
  export type px = number;

  /** a uuid string */
  export type uuid = string;

  /** dimensions in pixels of a rectangle */
  export type dims = {
    width: px;
    height: px;
  };

  /** A ratio, percent, or unit value */
  export type ratio = number;

  /** percent expressed as int from 1-100 */
  export type percent = number;

  /** Quadrilateral position in percent */
  export type position = {
    left: ratio; // percent (0 - 1)
    top: ratio; // percent (0 - 1)
    width: ratio; // percent (0 - 1)
    height: ratio; // percent (0 - 1)
  };

  // quadtrilateral position with rotation
  export type rotatablePosition = position & {
    // degrees
    rotation: ratio;
  };

  export type point = {
    x: st.ratio;
    y: st.ratio;
  };
  /** Quadrilateral position in pixels */
  export type pixelPosition = {
    left: px; // pixels
    top: px; // pixels
    width: px; // pixels
    height: px; // pixels
  };

  /** Time in milliseconds */
  export type ms = number;

  /** Time in seconds */
  export type sec = number;

  /** Time in hours-minutes-seconds */
  export type hms = {
    h: number; // hours
    m: number; // minutes
    s: number; // seconds
  };
}
