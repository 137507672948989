import { css } from '@emotion/react';

const styles = {
  exportLink: css`
    text-decoration: none;
  `,
  popoverHeader: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 16px;
  `,

  popoverHeaderIcon: css`
    padding-right: 5px;
    color: green;
  `,

  popoverHeaderText: css`
    padding: 0;
    font-weight: bold;
  `,
};
export default styles;
