import { getSnapshot, SnapshotIn } from 'mobx-state-tree';
import RootModel, { IRoot } from '../../../root.mst';
import BlurEffectModel, { IBlurEffect } from '../blurEffect.mst';
import ZoomEffectModel, { IZoomEffect } from '../zoomEffect.mst';
import ClipModel, { IClip } from '../clip.mst';
import StillSourceModel, { IStillSource } from '../stillSource.mst';
import AudioClipModel, { IAudioClip } from '../audioClip.mst';
import TextClipModel, { ITextClip } from '../textClip.mst';
import VideoSourceModel, { IVideoSource } from '../videoSource.mst';
import TextTrackModel, { ITextTrackModel } from '../textTrack.mst';
import DetatchedAudioTrackModel, {
  IDetatchedAudioTrackModel,
} from '../detatchedAudioTrack.mst';
import MainTrackModel, { IMainTrackModel } from '../mainTrack.mst';
import { st } from '@castify/studio/fe-common';
import { VideoPairName } from '../../../playback/playback.mst';

/**
 * This file contains helpers useful for quickly instantiating the root MST
 * store for purposes of testing.
 *
 * Because leaves of the store often make type-safe reference to other parts of
 * the store via `getParentOfType`, it is often not possible to instantiate
 * just some part of the store for testing purposes, even though many store
 * properties are optional. That is: because some leaves' types make recursive
 * reference to the inferred type of the store's root, some leaves some won't
 * compile when instantiated in isolation.
 *
 * (This can be avoided in the future by treating the return types of helpers
 * that allow navigating upwards in the tree to optionally return `undefined`,
 * but this comes with the cost that views and actions which navigate upwards
 * have a need for truthiness type guards when referencing the parent-- which
 * adds a lot of syntax to the views/actions which isn't actually needed at
 * runtime. But, note that optional returns on tree navigators *would* be needed
 * if in the future we ship a code-split store!)
 *
 * Though it might seem onerous to have to instantiate the entire store to test
 * any part of it, in practice doing so is straighforward-- which is what these
 * helpers are for.
 *
 * To get a root store instance with a video and text clip, for example:
 *
 * ```
 * const root = makeRootFromClips({
      main: [
        makeClip({
          source: makeVideoSource({}),
        }),
      ],
      text: [makeTextClip({})],
 * ```
 *
 * All properties of the snapshots passed into the factories are optional, and
 * sensible, overridable defaults for non-optional properties suitable for
 * testing are provided by the helpers.
 */

export interface IMakeRootFromClips {
  main?: Array<ReturnType<typeof makeClip>>;
  text?: Array<ReturnType<typeof makeTextClip>>;
  audio?: Array<ReturnType<typeof makeAudioClip>>;
}

/**
 * These optional named arguments are syntax sugar for initializing various
 * parts of the store to be in particular states
 */
interface RootStoreCreationOptions {
  zoomFactor?: st.ratio;
  scrollTime?: st.ms;
  timelineElementLeft?: st.px;
  timelineElementWidth?: st.px;
  scrubTime?: st.ms;
  seekTime?: st.ms;
  projectId?: string;
  userIdentifier?: string;
  displayName?: string;
  viewerList?: Array<string>;
  playbackClock?: st.ms;
  isPlaying?: boolean;
  playingPairName?: VideoPairName;
}

/**
 * Factory for instantiating the MST with clip snapshots; useful for testing.
 */
export const makeRootFromClips = (
  { main, text, audio }: IMakeRootFromClips,
  storeCreationOptions?: RootStoreCreationOptions,
): IRoot => {
  return RootModel.create({
    project: {
      uuid: storeCreationOptions?.projectId || '',
      title: 'Untitled Video',
      scene: {
        textTrack: text ? makeTextTrack(text) : makeTextTrack([]),
        mainTrack: main ? makeMainTrack(main) : makeMainTrack([]),
        detachedAudioTrack: audio
          ? makeDetachedAudioTrack(audio)
          : makeDetachedAudioTrack([]),
      },
    },
    toolManager: {},
    timeline: {
      timelineElementLeft: storeCreationOptions?.timelineElementLeft,
      timelineElementWidth: storeCreationOptions?.timelineElementWidth,
      scrollTime: storeCreationOptions?.scrollTime,
      zoom: {
        zoomFactor: storeCreationOptions?.zoomFactor,
      },
    },
    playback: {
      scrubTime: storeCreationOptions?.scrubTime,
      seekTime: storeCreationOptions?.seekTime,
      playbackClock: storeCreationOptions?.playbackClock,
      isPlaying: storeCreationOptions?.isPlaying,
      playingPairName: storeCreationOptions?.playingPairName,
    },
    viewer: {
      userIdentifier: storeCreationOptions?.userIdentifier,
      displayName: storeCreationOptions?.displayName,
    },
    analytics: {
      isAnalyticsEnabled: true,
      viewerList: storeCreationOptions?.viewerList || [],
    },
  });
};

/**
 * A test helper that allows us to easily create mock instances of videoClip
 * models without having to pass in all of the model's required params
 */
export const makeVideoSource = (
  params: Partial<SnapshotIn<IVideoSource>> = {},
) => {
  return getSnapshot(
    VideoSourceModel.create({
      playbackManifest: '',
      scrubManifest: '',
      baseThumbnailUrl: '',
      waveformUrl: '',
      height: 0,
      width: 0,
      hasAudio: true,
      trimIn: 0,
      trimOut: 10000,
      videoLength: 10000,
      ...params,
    }),
  );
};

/**
 * A test helper that allows us to easily create mock instances of stillClip
 * models without having to pass in all of the model's required params
 */
export const makeStillSource = (
  params: Partial<SnapshotIn<IStillSource>> = {},
) => {
  return getSnapshot(
    StillSourceModel.create({
      duration: 10000,
      ...params,
    }),
  );
};

/**
 * Test helper that allows us to easily create mock instances of Clip models
 * without having to pass in all of the model's required params
 */
export const makeClip = (params: Partial<SnapshotIn<IClip>>) => {
  return getSnapshot(
    ClipModel.create({
      ...params,
    } as SnapshotIn<IClip>),
  );
};

/**
 * A test helper that allows us to easily create mock instances of audioClip
 * models without having to pass in all of the model's required params
 */
export const makeAudioClip = (params: Partial<SnapshotIn<IAudioClip>>) => {
  return getSnapshot(
    AudioClipModel.create({
      boxes: [],
      startClipSourceOffset: 0,
      endClipSourceOffset: 0,
      ...params,
    } as SnapshotIn<IAudioClip>),
  );
};

/**
 * A test helper that allows us to easily create mock instances of textClip
 * models without having to pass in all of the model's required params
 */
export const makeTextClip = (params: Partial<SnapshotIn<ITextClip>>) => {
  return getSnapshot(
    TextClipModel.create({
      boxes: [],
      startClipSourceOffset: 0,
      endClipSourceOffset: 0,
      ...params,
    } as SnapshotIn<ITextClip>),
  );
};

/**
 * A test helper that allows us to easily create mock text track from array of clips
 */
export const makeTextTrack = (
  params: Array<ReturnType<typeof makeTextClip>>,
) => {
  return getSnapshot(
    TextTrackModel.create({
      textClips: params,
    } as SnapshotIn<ITextTrackModel>),
  );
};

/**
 * A test helper that allows us to easily create mock detatched audio track from array of clips
 */
export const makeDetachedAudioTrack = (
  params: Array<ReturnType<typeof makeAudioClip>>,
) => {
  return getSnapshot(
    DetatchedAudioTrackModel.create({
      audioClips: params,
    } as SnapshotIn<IDetatchedAudioTrackModel>),
  );
};

/**
 * A test helper that allows us to easily create mock main track from array of clips
 */
export const makeMainTrack = (params: Array<ReturnType<typeof makeClip>>) => {
  return getSnapshot(
    MainTrackModel.create({
      mainTrackClips: params,
    } as SnapshotIn<IMainTrackModel>),
  );
};

/**
 * A test helper that allows us to easily create mock instances of textClip
 * models without having to pass in all of the model's required params
 */
export const makeBlurEffect = (params: Partial<SnapshotIn<IBlurEffect>>) => {
  return getSnapshot(
    BlurEffectModel.create({
      ...params,
    } as SnapshotIn<IBlurEffect>),
  );
};

/**
 * A test helper that allows us to easily create mock instances of zoomEffects
 * models without having to pass in all of the model's required params
 */
export const makeZoomEffect = (params: Partial<SnapshotIn<IZoomEffect>>) => {
  return getSnapshot(
    ZoomEffectModel.create({
      ...params,
    } as SnapshotIn<IZoomEffect>),
  );
};
