import { useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import { usePrompt } from './common/usePrompt';
import DriveImportFlow from './DriveImportFlow';
import LocalUploadFlow from './LocalUploadFlow';

/**
 * This component and its children exists to render and keep state for the
 * modals that the user sees when going through the local upload and
 * Google drive ingress flows.
 *
 * By default, rendering this component does nothing; tts public API is in the
 * MST store: to kick off either flow, the developer should call the MST actions
 * which cause this component to render its children
 */
export function IngressFlows() {
  const {
    ingress: {
      driveImportInProgress,
      localUploadInProgress,
      finishIngressFlow,
      file,
      isUploadInProgress,
    },
    project: { uuid },
    debugger: { debugMode },
  } = useMst();

  usePrompt(
    "The video hasn't finished processing, leaving will discard the changes. Are you sure?",
    isUploadInProgress,
  );

  return (
    <>
      {driveImportInProgress && (
        <DriveImportFlow
          finishIngressFlow={finishIngressFlow}
          projectUuid={uuid}
          debugMode={debugMode}
        />
      )}
      {localUploadInProgress && file && (
        <LocalUploadFlow
          finishIngressFlow={finishIngressFlow}
          projectUuid={uuid}
          debugMode={debugMode}
          file={file}
        />
      )}
    </>
  );
}

export default observer(IngressFlows);
