import { SerializedStyles } from '@emotion/react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { styles } from './buttons.styles';
import DriveImportButton from './DriveImportButton';
import LocalUploadButton from './LocalUploadButton';
import {
  useAskUserForDrivePermissions,
  useTokenCheck,
} from './refreshTokenHelpers';
import {
  createBrowserLogger,
  IBrowserLogger,
} from '@castify/studio/observability/browser';
import { observer } from 'mobx-react-lite';
const logger: IBrowserLogger = createBrowserLogger('IngressButtons');

export enum RefreshTokenStates {
  /**
   * We're waitng to see if the backend has a refresh token
   */
  CHECKING_FOR_TOKEN = 'CHECKING_FOR_TOKEN',
  /**
   * The backend has a token; user has given us permission to use drive
   */
  HAS_TOKEN = 'HAS_TOKEN',
  /**
   * The backend doesn't have a token and the user hasn't given us permission;
   * we need to ask for it
   */
  NO_TOKEN = 'NO_TOKEN',
  /**
   * The user has kicked off a the permissions request flow. We're either
   * waiting for them to click things in the modal or we're waiting for
   * the backend to confirm that it has the token.
   */
  GETTING_PERMISSION = 'GETTING_PERMISSION',
}

interface IngressButtonsProps {
  /**
   * Allows overriding styles from the parent
   */
  containerStyleOverride?: SerializedStyles;
  /**
   * Allows overriding button styles from the parent. Expects the same thing the
   * emotion CSS prop expects.
   */
  buttonStyleOverride?: SerializedStyles;
  /**
   * Allows caller to run callback in the onClick handler
   */
  afterClick?: () => void;
}

/**
 * This component is responsible for showing either the ingress flow buttons,
 * a loading spinner, or a button to show the popup to ask the user for drive
 * permissions, as we have to ensure the user has granted drive permissions
 * before we can kick off any of the ingress flows.
 */
const IngressButtons = ({
  containerStyleOverride,
  buttonStyleOverride,
  afterClick,
}: IngressButtonsProps) => {
  const [state, setState] = useState<RefreshTokenStates>(
    RefreshTokenStates.CHECKING_FOR_TOKEN,
  );

  /**
   * We start off in a state that instructs this hook to check to
   * see that we already have a token in the backend
   */
  useTokenCheck({
    state,
    ifTokenFound: () => {
      logger.info('Found refresh token; showing ingress buttons');
      setState(RefreshTokenStates.HAS_TOKEN);
    },
    ifTokenAbsentOrInvalid: (error: unknown) => {
      if (!error) {
        logger.info('Refresh token missing, invalid, or expired');
      } else {
        if (error instanceof Error) {
          logger.error('Error when checking for refresh token', { error });
        } else {
          logger.error(
            `Error when checking for refresh token; UI will treat error as absence of token: ${JSON.stringify(
              error,
            )}`,
          );
        }
      }
      setState(RefreshTokenStates.NO_TOKEN);
    },
  });

  /**
   * This hook returns a callback that opens the drive permissions popup and
   * subsequent work to send a credential to the backend
   */
  const openPopup = useAskUserForDrivePermissions({
    /**
     * If the user grants permission and if the backend suceeds in getting
     * and saving a token, we're good to proceed to the drive flow
     */
    afterAccessGranted: () => {
      logger.info('Refresh token saved after user granted access');
      setState(RefreshTokenStates.HAS_TOKEN);
    },
    /**
     * If the user closes the popup or if something else goes wrong when
     * ensuring the backend has access, we go back to showing the connect
     * button (there's no real user feedback about a non-popup-closure failure)
     * but we at least log the failure to help us get a sense of how many users
     * close out of the flow without proceeding.
     */
    onAccessGrantFailure: (error) => {
      if (error instanceof Error) {
        logger.error(`Drive access grant failed`, { error });
      } else {
        logger.error(`Drive access grant failed: ${JSON.stringify(error)}`);
      }
      setState(RefreshTokenStates.NO_TOKEN);
    },
  });

  /**
   * The IIFE here allows us to use switch and get TS exhausiveness
   * checking on the switch case while also wrapping in a containing div
   */
  return (
    <div css={[styles.container, containerStyleOverride]}>
      {(() => {
        switch (state) {
          case RefreshTokenStates.CHECKING_FOR_TOKEN:
            return <CircularProgress data-testid="progress-spinner" />;
          case RefreshTokenStates.HAS_TOKEN:
            return (
              <>
                <DriveImportButton
                  styleOverride={buttonStyleOverride}
                  afterClick={afterClick}
                  data-testid="drive-import-button"
                />
                <LocalUploadButton
                  styleOverride={buttonStyleOverride}
                  afterClick={afterClick}
                  data-testid="local-upload-button"
                />
              </>
            );
          case RefreshTokenStates.NO_TOKEN:
            return (
              <Button
                variant="white"
                css={[
                  styles.button,
                  styles.connectToDriveButton,
                  buttonStyleOverride,
                ]}
                onClick={() => {
                  logger.info('Showing drive permissions popup');
                  setState(RefreshTokenStates.GETTING_PERMISSION);
                  openPopup();
                }}
                data-testid="drive-permissions-button"
              >
                <Typography>Connect to Google Drive</Typography>
              </Button>
            );
          case RefreshTokenStates.GETTING_PERMISSION:
            return <CircularProgress data-testid="progress-spinner" />;
          default:
            throw new Error('should not be possible to get here');
        }
      })()}
    </div>
  );
};

export default observer(IngressButtons);
