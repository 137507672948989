import { css } from '@emotion/react';

const styles = {
  // allows setting a uniform width for skeleton/normal elements
  buttonContainer: css`
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
export default styles;
