import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

//Initial values in all styles will be mobile first and overridden by media queries
const styles = {
  volumeButton: css`
    color: #293845;
    margin-right: 12px;
  `,
  volumeContainer: css`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    @media only screen and (min-width: 576px) {
      display: flex;
      flex-direction: flex-row;
      justify-content: center;
      align-items: center;
      width: 20%;
      min-width: 100px;
      max-width: 200px;
      background-color: white;
      border-radius: 20px;
      height: 40px;
    }
  `,
  volumeSlider: css`
    display: none;
    @media only screen and (min-width: 576px) {
      display: block;
      color: black;
      margin-right: 20px;
      .MuiSlider-rail {
        height: 2px;
      }
      .MuiSlider-track {
        height: 2px;
      }
      .MuiSlider-thumb {
        height: 13px;
        width: 13px;
      }
    }
  `,
};
export default styles;
