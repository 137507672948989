import { useEffect } from 'react';

import {
  CreateNewProjectDocument,
  CreateNewProjectMutation,
  CreateNewProjectMutationVariables,
} from '@castify/studio/database-clients/graphql';
import { useMutation } from 'urql';

import { useNavigate } from 'react-router-dom';

// This component exists solely to create a new project and then direct a user to the editor
// with the new project loaded.  It does not currently have any visual elements or other
// functionality although a loader or branded elements could be added later
const NewProject = () => {
  const [{ data }, executeMutation] = useMutation<
    CreateNewProjectMutation,
    CreateNewProjectMutationVariables
  >(CreateNewProjectDocument);
  const navigate = useNavigate();

  useEffect(() => {
    executeMutation({ title: 'Untitled Video' });
  }, [executeMutation]);

  useEffect(() => {
    if (data?.insert_projects_one?.uuid) {
      navigate(`/preview/${data.insert_projects_one.uuid}`, { replace: true });
    }
  }, [data, navigate]);
  // return an empty div because a react component that doesn't return jsx is not valid and causes an error
  return <div />;
};

export default NewProject;
