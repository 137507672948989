import React from 'react';
import ContentCutIcon from '@mui/icons-material/ContentCut';

import { Fab } from '@mui/material';
import styles from './OpenSimpleEditButton.styles';
import { ButtonTooltip } from '@castify/studio/fe-common';

interface OpenSimpleEditButtonProps {
  toggleSimpleEdit: () => void;
}

function OpenSimpleEditButton({ toggleSimpleEdit }: OpenSimpleEditButtonProps) {
  return (
    <ButtonTooltip title={'Trim'}>
      <Fab
        css={styles.button}
        variant="extended"
        size="small"
        color="primary"
        onClick={toggleSimpleEdit}
        data-pendo-id="player-open-simple-edit-button"
      >
        <ContentCutIcon />
        Trim Video
      </Fab>
    </ButtonTooltip>
  );
}

export default OpenSimpleEditButton;
