import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const commonClipStyles = {
  /**
   * This style describes the dynamic rectangle within the timeline describing a single
   * main-track clip (i.e. VideoClips, StillClips).
   */
  container: css`
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    border: 3px solid ${colors.gray[400]};
    display: flex;
    height: 100%;
    border-radius: 10px;
    margin-top: 5px;
  `,
  debugText: css`
    position: absolute;
    bottom: 0;
    color: black;
    z-index: +10;
  `,
  selected: css`
    border-color: ${colors.TEMP.ZoomPurple};
  `,
};

export default commonClipStyles;
