import axios from 'axios';
import { getGoogleAuthHeader } from '../common/driveHelpers';

const RESUMABLE_REQUEST_URL =
  'https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable';

/**
 * Request a url destination to upload to
 */
export async function requestDriveUploadURL(
  accessToken: string,
): Promise<string> {
  const response = await axios.post(RESUMABLE_REQUEST_URL, undefined, {
    headers: getGoogleAuthHeader(accessToken),
  });
  if (!response.headers || !response.headers.location)
    throw new Error(
      'no location in response from drive when requesting upload url',
    );
  // url is stored in response location
  return response.headers.location;
}

/**
 * Upload file to drive. Returns the drive ID of the uploaded file
 */
export async function uploadFile(
  file: File,
  uploadURL: string,
  accessToken: string,
  progressCallback: (progressEvent: ProgressEvent) => void,
): Promise<string> {
  const response = await axios.put(uploadURL, file, {
    headers: getGoogleAuthHeader(accessToken),
    onUploadProgress: progressCallback,
  });
  if (!response.data || !response.data.id)
    throw new Error('no drive file id in response');
  return response.data.id;
}
