import { paintFrame } from '@castify/studio/compositor';
import {
  MainTrackClip,
  StillSourceModel,
  useMst,
} from '@castify/studio/studio-store';
import { useRaf } from 'rooks';
import { VideoPair } from './useVideoPlayer';

type UseCompositorInput = {
  ctx: CanvasRenderingContext2D | null;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  scrubPair: VideoPair;
  pairA: VideoPair;
  pairB: VideoPair;
  blurCanvasRef: React.RefObject<HTMLCanvasElement>;
  blurCtx: CanvasRenderingContext2D | null;
};

/**
 * Video players are not shown to the user directly; we copy frame
 * data off of them every time the browser is ready for a new animation frame
 * and send it over to a canvas through the compositor. This hook serves as
 * an effect runner which calls out to the compositor.
 */
const useCompositor = ({
  ctx,
  canvasRef,
  scrubPair,
  pairA,
  pairB,
  blurCanvasRef,
  blurCtx,
}: UseCompositorInput) => {
  const {
    playback,
    debugger: { debugMode },
    toolManager: { isOverrideModeActive },
    project: { scene },
  } = useMst();

  useRaf(
    () => {
      const activePair = playback.isPairAActive ? pairA : pairB;

      /**
       * This early return checks to see if we are in scrub mode and if
       * a scrub manifest is still loading so as not to call the compositor
       * when transitioning between clips
       */
      if (playback.isScrubManifestLoading && playback.scrubClip) return;

      /**
       * This prevents painting a black frame in another case: when scrub
       * has loaded a new manifest but not yet enough data to show a frame
       */
      if (
        playback.scrubClip &&
        activePair.player &&
        // see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
        activePair.player.readyState() < 2
      )
        return;

      // type guards
      if (
        !ctx ||
        !canvasRef.current ||
        !scrubPair.video ||
        !activePair.video ||
        !blurCanvasRef.current ||
        !blurCtx
      )
        return;

      let activeVideo = activePair.video;
      let activeClip: MainTrackClip | undefined;

      // order matters
      // if the video is playing always want to paint that frame first
      // then paint scrub clip (if available)
      // then fallback to paint seek if both scrub/playing are not available
      if (playback.scrubClip && !playback.isPlaying) {
        activeVideo = scrubPair.video;
        activeClip = playback.scrubClip;
      } else {
        activeClip = playback.activeMainTrackClip;
      }

      const imageSource = StillSourceModel.is(activeClip?.source)
        ? null
        : activeVideo;

      /**
       * We need a timestamp to see what effects are active, this shouldn't
       * cause the preview to re-render via setting up a subscription,
       * so we do not destructure  and instead call this getter here inside our
       * animationFrame callback
       */
      const timestamp = playback.compositorTimestamp;
      const blurEffect = scene.mainTrack.getBlurEffectAtTime(timestamp);

      /**
       * Paints a frame to canvas
       */
      paintFrame({
        ctx,
        canvasElm: canvasRef.current,
        imageSource,
        activeClip,
        isOverrideModeActive,
        debugMode,
        blurCanvas: blurCanvasRef.current,
        blurCtx,
        blurEffect,
      });
    },
    /**
     * This makes the hook run continuously, meaning we need to
     * avoid doing anything in the compositor that kills perf. We may wish
     * to consider turning off the compositor when a video is not playing as
     * a future performance optimization.
     */
    true,
  );
};

export default useCompositor;
