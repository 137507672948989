import { ILicenseAutoAssignDomain, IDomain } from '../common/CommonInterfaces';
import { useQuery } from 'urql';
import { GetManagedLicenseAutoAssignDomainsDocument } from '@castify/studio/database-clients/graphql';
import { LoadingTreatmentLogger } from '@castify/studio/components/loading';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import RoleSelector from '../RoleSelector/RoleSelector';

const AutoAssignDomain = () => {
  const [{ data, error, fetching }] = useQuery({
    query: GetManagedLicenseAutoAssignDomainsDocument,
    requestPolicy: 'cache-and-network',
  });

  return (
    <>
      {fetching && <LoadingTreatmentLogger identifier="Invitation List" />}

      {error && <div>Something bad happened in AutoAssignDomain.</div>}

      {data?.licenses.map((license: ILicenseAutoAssignDomain) => {
        return (
          <Table
            sx={{ minWidth: 350 }}
            size="small"
            aria-label="Domain Table"
            data-testid="domain-table"
            id={`domain-table-${license.uuid}`}
            key={`domain-table-${license.uuid}`}
          >
            <TableHead data-testid="domain-table-header">
              {license?.auto_assign_domains?.map((domain: IDomain) => {
                return (
                  <TableRow
                    data-testid="domain-table-row"
                    key={`domain-${domain.domain}-${domain.role}`}
                  >
                    <TableCell>Your Domain:</TableCell>
                    <TableCell>
                      <TextField
                        defaultValue={domain.domain}
                        disabled={true}
                        label="auto-assign domain"
                      />
                    </TableCell>
                    <TableCell>Who is covered by domain?</TableCell>
                    <TableCell>
                      <RoleSelector
                        disabled={true}
                        role={domain.role}
                        uuid={domain.uuid}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          </Table>
        );
      })}
    </>
  );
};

export default AutoAssignDomain;
