import { css } from '@emotion/react';

const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  `,
  font: css`
    font-weight: bold;
    margin-right: 7.5px;
  `,
};
export default styles;
