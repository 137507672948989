import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import CropSidebar from './CropSidebar';
import StillClipSidebar from './StillClipSidebar';
import BlurSidebar from './BlurSidebar';
import ZoomSidebar from './ZoomSidebar';
import TextSidebar from './TextSidebar';

/**
 * Right sidebar for effect and tool settings
 *
 * This is positioned via grid, not via the styles in this library
 */
function RightSidebar() {
  const {
    toolManager: {
      textToolIfOpen,
      stillClipToolIfOpen,
      zoomToolIfOpen,
      blurToolIfOpen,
      cropToolIfOpen,
      audioToolIfOpen,
    },
  } = useMst();

  if (textToolIfOpen) return <TextSidebar />;
  else if (stillClipToolIfOpen) return <StillClipSidebar />;
  else if (zoomToolIfOpen) return <ZoomSidebar />;
  else if (blurToolIfOpen) return <BlurSidebar />;
  else if (cropToolIfOpen) return <CropSidebar />;
  else if (audioToolIfOpen) return <div>audio tool</div>;
  else return null;
}

export default observer(RightSidebar);
