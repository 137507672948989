import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import RootModel from '../root.mst';
import { GetViewerAnalyticsSubscription } from '@castify/studio/database-clients/graphql';

const AnalyticsModel = types
  .model('AnalyticsModel', {
    /**
     * isAnalyticsEnabled is used to determine whether or not view events
     * are collected.
     */
    isAnalyticsEnabled: types.optional(types.boolean, true),
    /**
     * List of userIdentifiers that have viewed the project. This list
     * can be used cross-functionally to gather other information from
     * the db.
     */
    viewerList: types.optional(types.array(types.string), []),
    /** */
  })
  .views((self) => ({
    get totalUniqueViews() {
      return self.viewerList.length;
    },
  }))
  .actions((self) => ({
    updateIsAnalyticsEnabled(isAnalyticsEnabled: boolean) {
      self.isAnalyticsEnabled = isAnalyticsEnabled;
    },
    /**
     * updateViewerList takes in an object and converts the list of
     * userIdentifiers to an array of strings.
     * It is designed to overwrite the previous list in order to avoid
     * duplicate entries and is much more efficient than appending to the
     * list after searching for existing entries.
     *
     */
    updateViewerList(data: GetViewerAnalyticsSubscription) {
      data.viewer_analytics.forEach((viewer, index) => {
        self.viewerList[index] = viewer.user_identifier;
      });
    },
  }));
export default AnalyticsModel;

export function getAnalytics(self: IAnyStateTreeNode): IAnalytics {
  const root = getParentOfType(self, RootModel);
  return root.analytics;
}

export interface IAnalytics extends Instance<typeof AnalyticsModel> {}
