import styles from './Logo.styles';
import ScreencastifyLogoWithText from './ScreencastifyLogoWithText.svg';
import ScreencastifyArrow from './ScreencastifyArrow.svg';

type LogoProps = {
  withText: boolean;
  height?: number;
};

// Assumes user is logged in
const Logo = ({ height, withText }: LogoProps) => {
  if (!height) height = 45;
  return (
    <div css={styles.unlimitedLogo(height, withText)}>
      {withText ? <ScreencastifyLogoWithText /> : <ScreencastifyArrow />}
    </div>
  );
};

export default Logo;
