import styles from './SidebarUserProfile.styles';
import { observer } from 'mobx-react-lite';
import { useUserProfile, useSignOut } from '@castify/studio/auth';
import { Avatar, ListItem, Button, Typography } from '@mui/material';

/**
 * Left side bar for when user is signed in. Assumes user is available when rendered
 */
function LeftSidebar() {
  const user = useUserProfile();
  const signOut = useSignOut();

  return (
    <>
      <ListItem css={styles.ProfileContainer}>
        <div>
          {user.photoURL ? (
            <Avatar
              sx={{ width: 48, height: 48 }}
              src={user.photoURL}
              alt="Profile"
            />
          ) : (
            <div css={styles.ProfilePicture}></div>
          )}
        </div>
        <div css={styles.ProfileInfo}>
          <Typography id="Name">
            {user.displayName || 'Name Not Available'}
          </Typography>
          <Typography id="Email">{user.email}</Typography>
        </div>
      </ListItem>
      <ListItem>
        <Button variant="outlined">
          <Typography onClick={signOut}>Sign out</Typography>
        </Button>
      </ListItem>
    </>
  );
}

export default observer(LeftSidebar);
