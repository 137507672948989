import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import AudioClip from './AudioClip/AudioClip';
import styles from './AudioTrack.styles';

function AudioTrack() {
  const {
    timeline: { visibleStart, visibleDuration },
    project: {
      scene: { detachedAudioTrack },
    },
  } = useMst();

  const audioClips = detachedAudioTrack.getVisibleAudioClipsInRange(
    visibleStart,
    visibleStart + visibleDuration,
  );

  return (
    <div css={styles.container}>
      {audioClips.map((clip) => (
        <AudioClip key={clip.uuid} clip={clip} />
      ))}
    </div>
  );
}

export default observer(AudioTrack);
