import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  timelineLink: css`
    text-decoration: none;
  `,

  text: css`
    font-weight: bold;
    margin-left: 0.2em;
  `,
};
export default styles;
