import { environment } from '@castify/studio/env/browser';
import { useMst, VideoSourceModel } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import styles from '../DebugPanel.styles';

/**
 * This type is duplicated; other def lives in dev-studio-backend
 */
export type DevVideo = {
  title: string;
  fps?: number;
  duration: number;
  width: number;
  height: number;
  bgcolor?: string;
};

export type DevVideoResponse = {
  [videoId: string]: DevVideo;
};

/**
 * Loads and displays a list of dev videos not tied to a particular project
 *
 * These are pregenerated and shared for all users in an environment
 */
const DevVideoList = () => {
  const [devVideoList, setDevVideoList] = useState<DevVideoResponse>({});

  const {
    project: { scene },
  } = useMst();

  /**
   * Load dev video list
   */
  useEffect(() => {
    (async () => {
      const res = await fetch(`${environment.serviceUrl}devVideo`);
      const json = (await res.json()) as DevVideoResponse;
      setDevVideoList(json);
    })();
  }, []);
  const devVideoListEntries = Object.entries(devVideoList);

  if (devVideoListEntries.length === 0) {
    return (
      <div css={styles.addVideoMenuColumn}>
        No dev videos found.
        {(window.location.hostname === 'localhost' ||
          window.location.hostname === '127.0.0.1') && (
          <>
            {' '}
            Please run <em>yarn nx run dev-video-cli:make-presets</em> if you
            are in a local environment
          </>
        )}
      </div>
    );
  }

  return (
    <div css={styles.addVideoMenuColumn}>
      <p>Dev Videos</p>
      {devVideoListEntries.map(([videoId, devVideo]) => {
        const manifest = `${environment.serviceUrl}devVideo/${videoId}/manifest.mpd`;
        const baseThumbnailUrl = `${environment.serviceUrl}devVideo/${videoId}/thumbnails/`;
        const waveformUrl = `${environment.serviceUrl}devVideo/${videoId}/waveform/complete.dat`;
        return (
          <button
            key={videoId}
            onClick={() =>
              scene.mainTrack.addClipToEnd({
                blurEffects: [],
                zoomEffects: [],
                source: VideoSourceModel.create({
                  videoId: nanoid(),
                  playbackManifest: manifest,
                  scrubManifest: manifest,
                  baseThumbnailUrl,
                  waveformUrl,
                  height: devVideo.height,
                  width: devVideo.width,
                  hasAudio: true,
                  videoLength: devVideo.duration * 1000,
                  trimIn: 0,
                  trimOut: devVideo.duration * 1000,
                }),
              })
            }
          >
            {devVideo.title}
          </button>
        );
      })}
    </div>
  );
};

export default observer(DevVideoList);
