import {
  createBrowserLogger,
  IBrowserLogger,
} from '@castify/studio/observability/browser';
import { NewClipInfo } from '@castify/studio/studio-store';
import { ProbeData } from '@castify/studio/video-probe-data';
import { useEffect, useState } from 'react';
import { useClient } from 'urql';
import { isDriveTokenFailureError } from './common/driveHelpers';
import ErrorDialog from './common/ErrorDialog';
import { expireRefreshToken } from './common/expireRefreshToken';
import ImportProgress from './importProgress/ImportProgress';
import RequestImport from './requestImport/RequestImport';
import UploadProgress from './uploadProgress/UploadProgress';
const logger: IBrowserLogger = createBrowserLogger('LocalUploadFlow');

interface LocalUploadFlowProps {
  /**
   * Called when the ingress flow is complete or cancelled
   */
  finishIngressFlow: (newClipInfo?: NewClipInfo) => void;
  /**
   * Project's uuid in the DB
   */
  projectUuid: string;
  /**
   * is the app-wide debug mode active?
   */
  debugMode: boolean;
  /**
   * The file to upload
   */
  file: File;
}

/**
 * What stage of the flow are we in?
 */
enum LocalFlowStage {
  /**
   * Uploading the file to drive
   */
  UPLOADING = 'UPLOADING',
  /**
   * The user has picked a file; a row is being written to the DB to kick off
   * an import
   */
  REQUESTING_IMPORT = 'REQUESTING_IMPORT',
  /**
   * A row has been written to the DB and we are watching the database
   * to observe the progress of the import tasks.
   */
  IMPORTING = 'IMPORTING',
  /**
   * We've hit an error at some point in the flow
   */
  ERRORED = 'ERRORED',
}

/**
 * Overall drive flow state
 */
interface LocalFlowState {
  /**
   * What part of the flow are we in?
   */
  stage: LocalFlowStage;
  /**
   * The drive file ID of the selected file once it is uploaded
   */
  driveFileId: undefined | string;
  /**
   * What is the uuid of the row in the video sources table?
   */
  videoSourceUuid: undefined | string;
}

/**
 * This component keeps state for the local upload flow, switching
 * between modals as the flow progresses.
 */
export function LocalUploadFlow({
  finishIngressFlow,
  projectUuid,
  debugMode,
  file,
}: LocalUploadFlowProps) {
  const client = useClient();
  /**
   * Tracks the current state of the drive import flow.
   */
  const [flowState, setFlowState] = useState<LocalFlowState>({
    stage: LocalFlowStage.UPLOADING,
    driveFileId: undefined,
    videoSourceUuid: undefined,
  });

  /**
   * On first mount, indicate that we have started the flow.
   */
  useEffect(() => {
    logger.info(`Started local upload flow; uploading...`);
  }, []);

  /**
   * Intended to be passed into any flow step, this callback is invoked when
   * we hit an error. Logging is centralized here rather than handled in each
   * child component because it should be apparent from the sequence of logs
   * firing in this file where errors originate.
   */
  const onFlowError = async (error: unknown) => {
    /**
     * If the user has revoked drive permissions, we can't talk to drive. This
     * branch handles this error. In this case, it's almost a certainty that
     * the refresh token we have for the user is no good-- so we invalidate
     * it in the DB.
     */
    if (isDriveTokenFailureError(error)) {
      try {
        logger.info('Invalid drive token encountered; marking as expired');
        await expireRefreshToken(client);
      } catch (error) {
        logger.error(
          `Could not mark bad refresh token as invalid during step ${flowState.stage}`,
        );
      }
    } else {
      /**
       * All other errors get logged
       */
      if (error instanceof Error) {
        logger.error(
          `Error in local ingress flow during step ${flowState.stage}`,
          { error },
        );
      } else {
        logger.error(
          `Error in local ingress flow during step ${
            flowState.stage
          }: ${JSON.stringify(error)}`,
        );
      }
    }

    setFlowState({
      stage: LocalFlowStage.ERRORED,
      driveFileId: flowState.driveFileId,
      videoSourceUuid: flowState.videoSourceUuid,
    });
  };

  /**
   * Intended to be passed to the error dialog, this callback is invoked
   * when the erorr dialog is closed.
   */
  const onErrorDialogClose = () => {
    logger.info('User closed the error dialog');
    finishIngressFlow();
  };

  /**
   * Intended to be passed to the upload progress component; called when
   * the upload is complete
   */
  const onUploadComplete = (driveFileId: string) => {
    logger.info(
      `Upload complete for file ${file.name}; drive id is ${driveFileId}; requesting import`,
    );
    setFlowState({
      stage: LocalFlowStage.REQUESTING_IMPORT,
      driveFileId,
      videoSourceUuid: undefined,
    });
  };

  /**
   * Intended to be passed to the import request component; called when the
   * video has been added to the DB with the uuid of the row
   */
  const onImportRequested = (videoSourceUuid: string) => {
    logger.info(
      `Import requested for driveId: ${flowState.driveFileId}, uuid: ${videoSourceUuid}; setting up progress monitoring...`,
    );
    setFlowState({
      stage: LocalFlowStage.IMPORTING,
      driveFileId: flowState.driveFileId,
      videoSourceUuid,
    });
  };

  /**
   * Intended to be passed to the progress monitoring component; called when
   * the video has finished processing and is ready to add to the scene
   */
  const onImportComplete = (probeData: ProbeData) => {
    if (!flowState.videoSourceUuid) return;
    logger.info(
      `Import complete for driveId: ${flowState.driveFileId}, uuid: ${flowState.videoSourceUuid}; adding to scene...`,
    );
    finishIngressFlow({
      probeData,
      videoSourceUuid: flowState.videoSourceUuid,
    });
  };

  /**
   * Each return value is a JSX element to render in the flow.
   */
  switch (flowState.stage) {
    case LocalFlowStage.UPLOADING:
      return (
        <UploadProgress
          file={file}
          onUploadComplete={onUploadComplete}
          onFlowError={onFlowError}
        />
      );
    case LocalFlowStage.REQUESTING_IMPORT:
      if (!flowState.driveFileId) return null;
      return (
        <RequestImport
          driveFileId={flowState.driveFileId}
          projectUuid={projectUuid}
          onImportRequested={onImportRequested}
          onFlowError={onFlowError}
        />
      );
    case LocalFlowStage.IMPORTING:
      if (!flowState.videoSourceUuid) return null;
      return (
        <ImportProgress
          videoSourceUuid={flowState.videoSourceUuid}
          onImportComplete={onImportComplete}
          onFlowError={onFlowError}
          debugMode={debugMode}
        />
      );
    case LocalFlowStage.ERRORED:
      return (
        <ErrorDialog
          onErrorDialogClose={onErrorDialogClose}
          videoSourceUuid={flowState.videoSourceUuid}
        />
      );
    /* istanbul ignore */
    default:
      throw new Error(`should not reach this default case: ${flowState.stage}`);
  }
}

export default LocalUploadFlow;
