import { IVideoSource } from '@castify/studio/studio-store';
import { st } from '@castify/studio/fe-common';

/**
 * Figure out what pixel range we should paint to in the canvas shown on screen
 * (Performs letterboxing or pillarboxing)
 */
export const calculateDestinationPosition = (
  activeClipSource: IVideoSource,
  canvasElm: HTMLCanvasElement,
  isOverrideModeActive: boolean,
): st.pixelPosition => {
  /**
   * When override mode is active it means we show the full-size clip covering
   * the entire canvas, which has been sized appropriately elsewhere in the code.
   * This means we can skip letterboxing/pillarboxing entirely.
   */
  if (isOverrideModeActive) {
    return {
      top: 0,
      left: 0,
      width: canvasElm.width,
      height: canvasElm.height,
    };
  }

  /**
   * These are the inputs to letterbox computation
   */
  const canvasWidth: st.px = canvasElm.width;
  const canvasHeight: st.px = canvasElm.height;
  const videoWidth: st.px = activeClipSource.croppedDims.width;
  const videoHeight: st.px = activeClipSource.croppedDims.height;

  // decide the axis to scale on, and how much to scale
  const scaleFactor = Math.min(
    canvasWidth / videoWidth,
    canvasHeight / videoHeight,
  );

  return {
    top: (canvasHeight - videoHeight * scaleFactor) / 2,
    left: (canvasWidth - videoWidth * scaleFactor) / 2,
    width: videoWidth * scaleFactor,
    height: videoHeight * scaleFactor,
  };
};
