import * as Types from '../generated-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateNewVideoSourceMutationVariables = Types.Exact<{
  driveFileId: Types.Scalars['String'];
  driveMd5Sum: Types.Scalars['String'];
  projectUuid: Types.Scalars['uuid'];
}>;

export type CreateNewVideoSourceMutation = {
  __typename?: 'mutation_root';
  insert_video_sources_one?:
    | { __typename?: 'video_sources'; uuid: string }
    | null
    | undefined;
};

export type WatchImportProgressSubscriptionVariables = Types.Exact<{
  videoSourceUuid: Types.Scalars['uuid'];
}>;

export type WatchImportProgressSubscription = {
  __typename?: 'subscription_root';
  video_sources_by_pk?:
    | {
        __typename?: 'video_sources';
        constrain_is_necessary?: boolean | null | undefined;
        constrain_is_done: boolean;
        constrain_percent?: any | null | undefined;
        generate_playback_completed_count?: number | null | undefined;
        generate_playback_is_done: boolean;
        generate_playback_total_count?: number | null | undefined;
        generate_scrub_completed_count?: number | null | undefined;
        generate_scrub_is_done: boolean;
        generate_scrub_total_count?: number | null | undefined;
        generate_thumbnails_completed_count?: number | null | undefined;
        generate_thumbnails_is_done: boolean;
        generate_thumbnails_total_count?: number | null | undefined;
        generate_waveform_is_done: boolean;
        ingest_failed: boolean;
        video_segments: string;
        audio_segments: string;
        stream_probe: string;
      }
    | null
    | undefined;
};

export type GetAllProjectVideosQueryVariables = Types.Exact<{
  projectUuid: Types.Scalars['uuid'];
}>;

export type GetAllProjectVideosQuery = {
  __typename?: 'query_root';
  video_sources: Array<{
    __typename?: 'video_sources';
    uuid: string;
    constrain_is_necessary?: boolean | null | undefined;
    constrain_is_done: boolean;
    constrain_percent?: any | null | undefined;
    generate_playback_completed_count?: number | null | undefined;
    generate_playback_is_done: boolean;
    generate_playback_total_count?: number | null | undefined;
    generate_scrub_completed_count?: number | null | undefined;
    generate_scrub_is_done: boolean;
    generate_scrub_total_count?: number | null | undefined;
    generate_thumbnails_completed_count?: number | null | undefined;
    generate_thumbnails_is_done: boolean;
    generate_thumbnails_total_count?: number | null | undefined;
    generate_waveform_is_done: boolean;
    stream_probe: string;
  }>;
};

export const CreateNewVideoSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewVideoSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'driveFileId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'driveMd5Sum' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_video_sources_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'drive_md5sum' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'driveMd5Sum' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'drive_file_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'driveFileId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_uuid' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectUuid' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewVideoSourceMutation,
  CreateNewVideoSourceMutationVariables
>;
export const WatchImportProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'WatchImportProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'videoSourceUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video_sources_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'videoSourceUuid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'constrain_is_necessary' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'constrain_is_done' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'constrain_percent' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_playback_completed_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_playback_is_done' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_playback_total_count',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_scrub_completed_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_scrub_is_done' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_scrub_total_count' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_thumbnails_completed_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_thumbnails_is_done' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_thumbnails_total_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_waveform_is_done' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ingest_failed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'video_segments' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audio_segments' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream_probe' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WatchImportProgressSubscription,
  WatchImportProgressSubscriptionVariables
>;
export const GetAllProjectVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllProjectVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video_sources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_uuid' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectUuid' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'constrain_is_necessary' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'constrain_is_done' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'constrain_percent' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_playback_completed_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_playback_is_done' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_playback_total_count',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_scrub_completed_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_scrub_is_done' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_scrub_total_count' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_thumbnails_completed_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_thumbnails_is_done' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'generate_thumbnails_total_count',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generate_waveform_is_done' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream_probe' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllProjectVideosQuery,
  GetAllProjectVideosQueryVariables
>;
