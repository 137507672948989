import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  headerSection: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    gap: 20px;
  `,

  projectBrowserHeader: css`
    width: 100%;
    background-color: ${colors.gray[0]};
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #e5e5e5;
  `,
  projectTitle: css`
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  `,
  projectBrowserLabel: css`
    font-family: sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 63px;
    color: #2c2c2c;
    margin-top: 0px;
    margin-bottom: 0px;
  `,
  videoLibraryIcon: css`
    font-size: 33px;
    color: #2c2c2c;
  `,
  helpIcon: css`
    font-size: 15px;
    color: #0000008a;
  `,
};
export default styles;
