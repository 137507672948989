import { IVideoSource } from '@castify/studio/studio-store';
import { st } from '@castify/studio/fe-common';

/**
 * Figure out what pixel range on the source canvas we should be copying to the
 * destination canvas, applying the crop effect
 *
 * TODO: zoom will go here
 */
export const calculateSourcePosition = (
  imageSource: HTMLVideoElement | HTMLCanvasElement,
  activeClipSource: IVideoSource,
  isOverrideModeActive: boolean,
): st.pixelPosition => {
  /**
   * Determine the source's width and height. Canvas and video elms have
   * different APIs
   *
   * (`tagName` used rathe than `instanceOf` to facilitate testing)
   */
  const sourceWidth =
    imageSource.tagName === 'VIDEO'
      ? (imageSource as HTMLVideoElement).videoWidth
      : imageSource.width;
  const sourceHeight =
    imageSource.tagName === 'VIDEO'
      ? (imageSource as HTMLVideoElement).videoHeight
      : imageSource.height;

  /**
   * When tool overrride mode is active or when the clip is not cropped,
   * we always show the full source
   */
  if (isOverrideModeActive || !activeClipSource.cropBox) {
    return {
      top: 0,
      left: 0,
      width: sourceWidth,
      height: sourceHeight,
    };
  }

  /**
   * When override mode isn't active, we apply the crop effect, only copying a
   * portion of the source
   *
   * Here the left side of the * is in pixel units and the right side in %
   */

  return {
    top: sourceHeight * activeClipSource.cropBox.top,
    left: sourceWidth * activeClipSource.cropBox.left,
    width: sourceWidth * activeClipSource.cropBox.width,
    height: sourceHeight * activeClipSource.cropBox.height,
  };
};
