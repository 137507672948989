import React, { useState, useEffect, useCallback } from 'react';
import { createCtx } from '@castify/studio/fe-common';
import {
  useGoogleAuthInstance,
  useLoggedInGoogleUser,
  useUserProfile,
} from './GoogleAuthInstanceProvider/GoogleAuthInstanceProvider';
import { accessTokenForGoogleUser } from './gapiUtils/accessTokenForGoogleUser';
import { getJwtForGoogleUser } from './gapiUtils/getJwtForGoogleUser';
import { getJwtForAnonymousVisitor } from './gapiUtils/getJwtForAnonymousVisitor';

const [useCurrentUser, Provider] = createCtx<gapi.auth2.GoogleUser>();

/**
 * Hook returning a function to retrieve an access token for a google user.
 *
 * Can ONLY be called within the context of a CurrentUserProvider.
 */
export const useGetAccessToken = () => {
  const currentUser = useCurrentUser();
  return useCallback(
    () => accessTokenForGoogleUser(currentUser),
    [currentUser],
  );
};

/**
 * Hook returning a function to retrieve a screencastify-signed jwt token for a google user.
 *
 * Can ONLY be called within the context of a CurrentUserProvider.
 */
export const useGetJwt = () => {
  const userProfile = useUserProfile();
  const googleUser = useGoogleAuthInstance().currentUser.get();
  return useCallback(() => {
    if (userProfile.isSignedIn) {
      return getJwtForGoogleUser(googleUser);
    } else {
      return getJwtForAnonymousVisitor(userProfile);
    }
  }, [googleUser, userProfile]);
};

/**
 * Hook returning a function to retrieve an offline access code.
 * This code is not enough for offline access, but in collaboration with
 * our backend + google APIs can be exchanged for a long-lived refresh token.
 *
 * Can ONLY be called within the context of a CurrentUserProvider.
 */
export const useGrantOfflineAccess = () => {
  const currentUser = useCurrentUser();
  return useCallback(
    async () => (await currentUser.grantOfflineAccess()).code,
    [currentUser],
  );
};

interface CurrentUserProviderProps {
  whenSignedIn: React.ReactNode;
  whenNotSignedIn: React.ReactNode;
}
/**
 * Provider for the current logged in google user.
 *
 * MUST be used within a GoogleAuthInstanceProvider.
 * Will NOT render props.children unless useLoggedInGoogleUser() returns
 * a value, indicating their is an available logged in user.
 */
const CurrentUserProvider = (
  props: React.PropsWithChildren<CurrentUserProviderProps>,
) => {
  const authInstance = useGoogleAuthInstance();
  const currentUser = useLoggedInGoogleUser();

  /**
   * Using a forced-update technique here. If the currentUser value changes,
   * we need to re-render this component.
   */
  const [, setForceUpdate] = useState(0);

  useEffect(() => {
    if (!authInstance) {
      return;
    }
    const authListener = authInstance.isSignedIn.listen(() =>
      setForceUpdate((c) => c++),
    );
    return () => {
      // @ts-expect-error authListener type is void | undefined, but it appears there are
      // unytped methods to remove the listener.
      authListener?.remove?.();
    };
  }, [authInstance]);

  return (
    <Provider value={currentUser}>
      {currentUser ? props.whenSignedIn : props.whenNotSignedIn}
    </Provider>
  );
};

export { CurrentUserProvider, useCurrentUser };

export { Provider as RawProvider };
