import * as Types from '../generated-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpsertViewerAnalyticsMutationVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
  userIdentifier: Types.Scalars['String'];
  displayName?: Types.Maybe<Types.Scalars['String']>;
}>;

export type UpsertViewerAnalyticsMutation = {
  __typename?: 'mutation_root';
  insert_viewer_analytics_one?:
    | { __typename?: 'viewer_analytics'; uuid: string }
    | null
    | undefined;
  update_viewer_analytics?:
    | {
        __typename?: 'viewer_analytics_mutation_response';
        affected_rows: number;
      }
    | null
    | undefined;
};

export type GetViewerAnalyticsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
}>;

export type GetViewerAnalyticsSubscription = {
  __typename?: 'subscription_root';
  viewer_analytics: Array<{
    __typename?: 'viewer_analytics';
    user_identifier: string;
  }>;
};

export const UpsertViewerAnalyticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertViewerAnalytics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userIdentifier' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_viewer_analytics_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_identifier' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userIdentifier' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'display_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value:
                          'viewer_analytics_project_id_user_identifier_key',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'display_name' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_viewer_analytics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_identifier' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userIdentifier' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_inc' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'view_count' },
                      value: { kind: 'StringValue', value: '1', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertViewerAnalyticsMutation,
  UpsertViewerAnalyticsMutationVariables
>;
export const GetViewerAnalyticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'GetViewerAnalytics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer_analytics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'project_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_identifier' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetViewerAnalyticsSubscription,
  GetViewerAnalyticsSubscriptionVariables
>;
