import { breakpoints } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  appContainer: (hasAudio: boolean, hasText: boolean) => {
    let extraTimelineHeight = 0;
    if (hasAudio) extraTimelineHeight += 45;
    if (hasText) extraTimelineHeight += 45;

    const defaultTimelineHeight = 197;
    const timelineHeight = defaultTimelineHeight + extraTimelineHeight;

    return css`
      width: 100%;
      height: 90vh;
      box-sizing: border-box;
      border: 10px solid white;

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(0, 1fr) 80px ${timelineHeight}px 50px;
      grid-template-areas:
        'preview'
        'toolbar'
        'timeline'
        'footer';
    `;
  },
  playButton: css`
    grid-area: playButton;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiButton-root {
      border-radius: 100px;
      max-width: 60px;
      min-width: 60px;
      max-height: 60px;
      min-height: 60px;
    }
  `,
  preview: css`
    grid-area: preview;
  `,
  toolbar: css`
    grid-area: toolbar;
  `,
  timeline: css`
    grid-area: timeline;
  `,
  footer: css`
    grid-area: footer;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
  `,
  rightSidebar: (isAnyToolOpen: boolean) => css`
    grid-area: rightSidebar;
    display: ${isAnyToolOpen ? `block` : `none`};
  `,
};
export default styles;
