import { css } from '@emotion/react';

const styles = {
  container: css`
    margin: 3px 0;
    height: 40px;
    position: relative;
  `,
};

export default styles;
