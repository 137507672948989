import { css } from '@emotion/react';

const styles = {
  projectGrid: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    padding: 28px 18px 0;
    height: 100%;
  `,
  bottomSection: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  legacyLink: css`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
    margin-top: 2rem;
    a {
      color: #00aaff;
    }
  `,
};
export default styles;
