import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

export interface ISubmitSnackBarState {
  open: boolean;
  message: string;
  severity: 'success' | 'warning' | 'error';
}
export interface ISnackBarProps {
  open: boolean;
  message: string;
  severity: 'success' | 'warning' | 'error';
  handleClose: () => void;
}

export const SubmitSnackBar = ({
  open,
  message,
  severity,
  handleClose,
}: ISnackBarProps) => {
  const [state] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Slide,
  });

  return (
    <Snackbar
      data-testid="snackbar-id"
      open={open}
      onClose={handleClose}
      TransitionComponent={state.Transition}
      autoHideDuration={3500}
      transitionDuration={550}
      key={state.Transition.name}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert data-testid="snackbar-alert-message-id" severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
