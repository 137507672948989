import WaveformData from 'waveform-data';

export function indexForTime(
  waveform: WaveformData,
  timeSeconds: number,
): number {
  return Math.floor(timeSeconds * waveform.pixels_per_second);
}

export function getDisplayData(
  waveform: WaveformData,
  startSeconds: number,
  endSeconds: number,
  numValues: number,
): number[] {
  const startIndex = indexForTime(waveform, startSeconds);
  const endIndex = indexForTime(waveform, endSeconds);
  const averaged = averagedChannelSlice(waveform, 0, startIndex, endIndex + 1);
  const windowSize = averaged.length / numValues;
  return windowedMax(averaged, windowSize);
}

export function averagedChannelSlice(
  waveform: WaveformData,
  channelNumber: number,
  startIndex: number,
  endIndex: number,
): number[] {
  const channel = waveform.channel(channelNumber);
  const maxVals = channel.max_array().slice(startIndex, endIndex);
  const minVals = channel.min_array().slice(startIndex, endIndex);
  const averaged = maxVals.map(
    (max: number, n: number) => (Math.abs(max) + Math.abs(minVals[n])) / 2,
  );
  return averaged;
}

export function getAverageMinMax(waveform: WaveformData): number[] {
  let min = 0;
  let max = 0;

  for (let channel = 0; channel < waveform.channels; channel++) {
    const channelData = averagedChannelSlice(
      waveform,
      channel,
      0,
      waveform.length,
    );
    for (const n of channelData) {
      min = Math.min(n, min);
      max = Math.max(n, max);
    }
  }

  return [min, max];
}

export function getWindowEndIndex(
  windowStart: number,
  windowSize: number,
  maxIndex: number,
) {
  // Ensure window end is at least 1 (so there's at least one value in the window)
  const windowEndFloat = Math.max(
    windowStart + windowSize,
    Math.round(windowStart) + 1,
  );
  // Ensure window end isn't beyond the max index available
  const limitedRoundedWindowEnd = Math.min(
    Math.round(windowEndFloat),
    maxIndex,
  );
  return limitedRoundedWindowEnd;
}

export function windowedMax(values: number[], windowSize: number): number[] {
  const result: number[] = [];
  for (
    let windowStart = 0;
    windowStart < values.length;
    windowStart += windowSize
  ) {
    const roundedWindowStart = Math.round(windowStart);
    const roundedWindowEnd = getWindowEndIndex(
      windowStart,
      windowSize,
      values.length,
    );
    const window = values.slice(roundedWindowStart, roundedWindowEnd);
    const windowMax = window.reduce(
      (a: number, b: number) => Math.max(a, b),
      0,
    );
    result.push(Math.round(windowMax));
  }
  return result;
}

/**
 * Create an array normalizer function based on the values in a reference array.
 * In the context of waveform utilities, this is meant to be used once with the
 * entire audio waveform array before windowing (or at least with a manufactured
 * array containing the max and min values from the whole waveform file). The
 * generated function can then be used to normalize windows quickly when rendering
 * the waveform graph to the screen.
 * @param reference the reference array, containing max and min values for normalization
 * @param options an object of options accepting two possible keys:
 *    rangeMax (number) - the arbitrary maximum number to normalize the values toward (default 128)
 *    bits (number) - the number of bits used to encode values in the array (default 8)
 * @returns a function that will normalize values in arrays of type number[]
 */
export function createArrayNormalizer(
  reference: number[],
  options: {
    rangeMin?: number;
    rangeMax?: number;
    bits?: number;
  } = {},
): (values: number[]) => number[] {
  // This is intended to be more than the possible max value, which could
  // be either 128 (for 8-bit encoded data) or 65536 (for 16-bit)
  let min = 65537;
  let max = -1;
  reference.forEach((n: number) => {
    min = Math.min(n, min);
    max = Math.max(n, max);
  });
  const rangeMin = options.rangeMin ? options.rangeMin : 0;
  const rangeMax = options.rangeMax
    ? options.rangeMax - rangeMin
    : !options.bits || options.bits === 8
    ? 128
    : 65536;
  const scaler = rangeMax / Math.max(max - min, 1);

  // Return a function (number[]) => number[] that will normalize windows appropriately.
  return (values) =>
    values.map((n: number) => Math.floor((n - min) * scaler + rangeMin));
}
