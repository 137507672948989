import {
  AudioProbeStream,
  ProbeData,
  StreamKeys,
  VideoProbeStream,
} from './video-probe-data-schema';

class UnrecognizedStreamKey extends Error {}

export function getVideoStream(
  probeData: ProbeData,
): VideoProbeStream | undefined {
  return probeData.streams.find(({ codec_type }) => codec_type === 'video') as
    | VideoProbeStream
    | undefined;
}
export function getAudioStream(
  probeData: ProbeData,
): AudioProbeStream | undefined {
  return probeData.streams.find(({ codec_type }) => codec_type === 'audio') as
    | AudioProbeStream
    | undefined;
}

export function getVideoHeight(probeData: ProbeData): number {
  return getVideoStream(probeData)?.height || -1;
}

export function getVideoWidth(probeData: ProbeData): number {
  return getVideoStream(probeData)?.width || -1;
}

export function hasVideo(probeData: ProbeData): boolean {
  return !!getVideoStream(probeData);
}

export function hasAudio(probeData: ProbeData): boolean {
  return !!getAudioStream(probeData);
}

export function getDuration(probeData: ProbeData): number {
  return parseFloat(probeData.format.duration);
}

export function supportsMediumEncoding(probeData: ProbeData): boolean {
  return getVideoHeight(probeData) > 480;
}
export function supportsHighEncoding(probeData: ProbeData): boolean {
  return getVideoHeight(probeData) > 720;
}

export function getAspectRatio(probeData: ProbeData): number {
  return getVideoWidth(probeData) / getVideoHeight(probeData);
}

export type VideoDimensions = {
  width: number;
  height: number;
};

export function getVideoSize(probeData: ProbeData): VideoDimensions {
  return {
    width: getVideoWidth(probeData),
    height: getVideoHeight(probeData),
  };
}

export function scaleToFitHeight(
  probeData: ProbeData,
  targetHeight: number,
): VideoDimensions {
  const sourceWidth = getVideoWidth(probeData);
  const sourceHeight = getVideoHeight(probeData);
  if (sourceHeight <= targetHeight) {
    return {
      width: sourceWidth,
      height: sourceHeight,
    };
  }
  if (sourceWidth <= 0) return { width: 0, height: 0 };
  const scaleFactor = targetHeight / sourceHeight;
  const scaledDims = {
    width: sourceWidth * scaleFactor,
    height: sourceHeight * scaleFactor,
  };
  return {
    width: Math.floor(scaledDims.width / 2) * 2,
    height: Math.floor(scaledDims.height / 2) * 2,
  };
}

export function getSizeLow(probeData: ProbeData): string {
  const { width, height } = scaleToFitHeight(probeData, 480);
  return `${width}x${height}`;
}

export function getSizeMedium(probeData: ProbeData): string {
  const { width, height } = scaleToFitHeight(probeData, 720);
  return `${width}x${height}`;
}

export function getSizeHigh(probeData: ProbeData): string {
  const { width, height } = scaleToFitHeight(probeData, 1080);
  return `${width}x${height}`;
}

export function hasStream(
  probeData: ProbeData,
  streamKey: StreamKeys,
): boolean {
  switch (streamKey) {
    case 'a':
      return hasAudio(probeData);
    case 'hi':
      return supportsHighEncoding(probeData);
    case 'md':
      return supportsMediumEncoding(probeData);
    case 'lo':
      return hasVideo(probeData);
    default:
      throw new UnrecognizedStreamKey(
        `${streamKey} is not a known stream key.`,
      );
  }
}
