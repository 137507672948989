import Select from '@mui/material/Select';
import { userRoles } from '../InvitationList/InvitationListConstants';
import MenuItem from '@mui/material/MenuItem';

interface props {
  role: string;
  uuid: string;
  disabled: boolean;
}

const RoleSelector = (props: props) => {
  /* 
      This should have a <FormControl> wrapping it
      but that adds a <tr> element and a <tr>] can't
      be the first child of a <td>. Thanks @mui!
    */

  return (
    <Select
      fullWidth
      labelId="user-role-selector"
      id={`user-role-selector-${props.uuid}`}
      defaultValue={props.role}
      name={`role-dropdown-${props.uuid}`}
      label="Role"
      disabled={props.disabled}
      data-testid="table-row-colum-role-select"
    >
      {getUserRoles(userRoles, props.uuid, props.role)}
    </Select>
  );
};

function getUserRoles(roles: string[], uuid: string, currentUserRole: string) {
  return roles.map((role: string) => {
    if (role === currentUserRole) {
      return (
        <MenuItem key={`${role}-${uuid}`} value={role} selected>
          {role.toUpperCase()}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem key={`${role}-${uuid}`} value={role}>
          {role.toUpperCase()}
        </MenuItem>
      );
    }
  });
}

export default RoleSelector;
