import { st } from '@castify/studio/fe-common';
import { IClip } from '@castify/studio/studio-store';

const MINIMUM_MOTION_THRESHOLD: st.px = 10;

/**
 * The handler exposed here is responsible for swapping clips when they are
 * dragged. It is intended to be attached to the inner div (inside the trim
 * handles) of main track clips.as a pointerDown event handler.
 *
 * This is for main track, gapless clips only; moving of effects and clips
 * in a gapful context is handled differently as it is not really an array swap.
 */
export const useSwapHandler = (
  clip: IClip,
  // ref to the outermost div of the clip within the timeline
  clipContainerRef: React.RefObject<HTMLDivElement>,
  // left offset of the timeline element on screen
  timelineElementLeft: number,
) => {
  return {
    pointerDown: (pointerDownEvent: React.PointerEvent) => {
      if (!clipContainerRef.current) return;
      const offsetX =
        pointerDownEvent.clientX -
        clipContainerRef.current.getBoundingClientRect().left;
      const initialX = pointerDownEvent.clientX;

      const pointerMove = (pointerMoveEvent: PointerEvent) => {
        // this snaps to the original position if a minimum motion threshhold
        // has not yet been passed
        const clientX =
          Math.abs(pointerMoveEvent.clientX - initialX) >
          MINIMUM_MOTION_THRESHOLD
            ? pointerMoveEvent.clientX
            : initialX;

        const hasMouseMovementExceededThreshold = clientX !== initialX;
        if (
          !clip.isBeingDragged &&
          hasMouseMovementExceededThreshold &&
          clipContainerRef.current
        ) {
          clip.startDrag(
            clipContainerRef.current.getBoundingClientRect().left -
              timelineElementLeft,
          );
        }

        const ghostClipStartPx = clientX - offsetX - timelineElementLeft;
        clip.handleSwapDrag(clientX);
        clip.handleGhostClipDrag(ghostClipStartPx);
      };

      const pointerUp = () => {
        window.removeEventListener('pointermove', pointerMove);
        window.removeEventListener('pointerup', pointerUp);
        if (clip.isBeingDragged) {
          clip.stopDrag();
        }
      };

      window.addEventListener('pointermove', pointerMove);
      window.addEventListener('pointerup', pointerUp);
    },
  };
};
