import React, { useState, useEffect } from 'react';
import styles from './Header.styles';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import ExportButton from './ExportButton/ExportButton';
import { observer } from 'mobx-react-lite';
import { LeftSideDrawer } from '@castify/studio/LeftSidebar';
import TitleUpdateField from './TitleUpdateField/TitleUpdateField';

function SimpleHeader() {
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setLeftMenuOpen(open);
    };
  return (
    <div css={styles.header}>
      <div css={styles.headerSection}>
        <IconButton
          size="large"
          aria-label="open menu drawer"
          onClick={toggleDrawer(true)}
          onKeyPress={toggleDrawer(true)}
        >
          <MenuIcon fontSize="inherit" />
        </IconButton>

        <div css={styles.titleInput}>
          <p>My Projects &gt;</p>
          <TitleUpdateField />
        </div>
      </div>

      <div css={styles.headerSection}>
        <ExportButton />
      </div>

      <LeftSideDrawer open={leftMenuOpen} onClose={toggleDrawer(false)} />
    </div>
  );
}

export default observer(SimpleHeader);
