import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import MainTrackClip from '../Clips/MainTrackClip/MainTrackClip';
import styles from './MainTrack.styles';

function MainTrack() {
  const {
    timeline: {
      visibleStart,
      visibleDuration,
      swappingClip,
      timelineElementLeft,
    },
    project: {
      scene: {
        mainTrack: { getVideoClipsInRange },
      },
    },
  } = useMst();

  return (
    <div css={styles.clipsContainer}>
      {getVideoClipsInRange(visibleStart, visibleStart + visibleDuration).map(
        (clip) => {
          return (
            <MainTrackClip
              key={clip.uuid}
              clip={clip}
              isGhost={false}
              timelineElementLeft={timelineElementLeft}
            />
          );
        },
      )}
      {swappingClip && (
        <MainTrackClip
          key={'ghost'}
          clip={swappingClip}
          isGhost={true}
          timelineElementLeft={timelineElementLeft}
        />
      )}
    </div>
  );
}

export default observer(MainTrack);
