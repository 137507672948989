import { css } from '@emotion/react';

const styles = {
  container: css`
    max-height: 40px;
    min-height: 40px;
  `,
  icon: css`
    margin-right: 10px;
  `,
  font: css`
    font-weight: bold;
  `,
};
export default styles;
