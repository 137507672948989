import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  GeneralButton: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 56px;
    width: 100%;
    padding-left: 0;
    color: ${colors.successMain};
    p {
      padding-left: 16px;
      font-weight: 700;
      font-family: Helvetica light, sans-serif;
    }
    .material-icons {
      padding: 4px;
    }
  `,
  selectIndicator: (isHere: boolean) => css`
    width: 7.5px;
    margin-right: 5px;
    height: 100%;
    background: ${colors.TEMP.ButtonBlue};
    opacity: ${isHere ? 1 : 0};
  `,
  link: css`
    text-decoration: none;
  `,
};

export default styles;
