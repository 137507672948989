import { createBrowserLogger } from '@castify/studio/observability/browser';
import { environment } from '@castify/studio/env/browser';

const logger = createBrowserLogger('gapiLoader');

const gapiInitConfig: gapi.auth2.ClientConfig = {
  client_id: environment.googleAuth.clientId,
  scope:
    'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly',
};

export const gapiLoader = {
  /**
   * This function keeps a contract with the index.html, which is supposed
   * to load the gapi script via a tag and in its onload callback, initialize
   * the script, instructing it to when complete resolve a promise on the window
   * living at __GAPI_CLIENT_PROMISE. The promise is expected to reject
   * if script load fails
   */
  async loadGapiClient() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gapiClientPromise = (window as any).__GAPI_CLIENT_PROMISE;
    if (!gapiClientPromise)
      throw new Error('no gapi client promise found on window');
    if (
      !gapiClientPromise.then ||
      !(gapiClientPromise.then instanceof Function)
    )
      throw new Error('gapi client promise is not a promise');
    await gapiClientPromise;
  },

  /**
   * Initialize gapi.auth2 with app config
   */
  initGapiAuth() {
    return new Promise<void>((resolve, reject) => {
      logger.debug('initializing gapi.auth2...');
      // not using async/await because it's not a real promise
      gapi.auth2
        .init(gapiInitConfig)
        .then(() => {
          logger.debug('gapi.auth2 initialized');
          resolve();
        })
        .catch(reject);
    });
  },

  /**
   * Typical interface to be used outside of testing/this file.
   */
  async init() {
    await gapiLoader.loadGapiClient();
    await gapiLoader.initGapiAuth();
  },
};
