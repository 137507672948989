import Icon from '@mui/material/Icon';
import { IBlurEffect, IZoomEffect, useMst } from '@castify/studio/studio-store';
import TrimHandle, { TrimHandleNames } from '../../TrimHandle/TrimHandle';
import { useShowTrimHandles } from '../../useShowTrimHandles';
import commonStyles from '../../commonClip.styles';
import styles from './EffectClip.styles';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

export enum EffectClipType {
  Blur,
  Zoom,
}

type EffectClipProps = {
  effect: IBlurEffect | IZoomEffect;
  type: EffectClipType;
};

function EffectClip({ effect, type }: EffectClipProps) {
  const clipContainerRef = useRef<HTMLDivElement>(null);

  const {
    selection: { selectClip },
    timeline: { initiateSeekFromPointerEvent },
  } = useMst();

  const { setPointerInClip, areHandlesVisible } = useShowTrimHandles(
    effect.isSelected,
  );

  /**
   * Here we stop propagation of clicks so that the underlying clip
   * model is not selected immediately after the effect within it.
   * Because this stops the event reaching the timeline, it also
   * stops seeks from firing. So we have to manually re-trigger a seek via
   * the timeline event handler.
   */
  const handleClipSelect = (e: React.PointerEvent) => {
    e.stopPropagation();
    selectClip(effect);
    initiateSeekFromPointerEvent(e);
  };

  return (
    <div
      ref={clipContainerRef}
      onPointerEnter={() => setPointerInClip(true)}
      onPointerLeave={() => setPointerInClip(false)}
      onPointerDown={handleClipSelect}
      data-selectable
      css={[
        commonStyles.container,
        styles.container,
        type === EffectClipType.Blur ? styles.blur : styles.zoom,
        effect.isSelected && commonStyles.selected,
      ]}
      style={{
        left: `${effect.leftOffsetPx}px`,
        width: `${effect.widthPx}px`,
        minWidth: `${effect.widthPx}px`,
        maxWidth: `${effect.widthPx}px`,
      }}
    >
      <TrimHandle
        trimHandleName={TrimHandleNames.left}
        isVisible={areHandlesVisible}
        clipOrEffect={effect}
        clipContainerRef={clipContainerRef}
      />
      <Icon style={{ color: 'white' }}>
        {type === EffectClipType.Blur ? 'lens_blur' : 'zoom_in'}
      </Icon>
      <TrimHandle
        trimHandleName={TrimHandleNames.right}
        isVisible={areHandlesVisible}
        clipOrEffect={effect}
        clipContainerRef={clipContainerRef}
      />
    </div>
  );
}

export default observer(EffectClip);
