import { Button } from '@mui/material';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSignIn, useUserProfile } from '@castify/studio/auth';

function Login() {
  const signIn = useSignIn();
  const userProfile = useUserProfile();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  /**
   * If we have a redirect param, use it
   */
  if (userProfile.isSignedIn) {
    return <Navigate replace to={redirect ? redirect : '/projects'} />;
  }

  return (
    <div>
      Login Page
      <div>
        <Button variant="outlined" onClick={signIn}>
          Log In
        </Button>
      </div>
      <div></div>
    </div>
  );
}

export default Login;
