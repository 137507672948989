import {
  License_Seat_Tiers_Enum,
  License_Seat_Roles_Enum,
} from '@castify/studio/database-clients/graphql';
import { useRoleList, useTierList } from '../../hooks';
import { RadioOption } from '../FormInputComponents/InputProps';
import { IAvailableSeats } from './AddUserInvitationForm.d';

// #region filter roles
export function filterRolesListBasedOnSeatMax(
  seatCaps: IAvailableSeats,
  unfilteredRoleList: RadioOption<License_Seat_Roles_Enum>[],
) {
  const rolesToInclude = getRolesToInclude(seatCaps);
  const roleList = unfilteredRoleList.filter(({ value }) =>
    rolesToInclude.has(value),
  );

  return roleList;
}
function getRolesToInclude({
  max_pro_member_seats,
  max_pro_restricted_seats,
  max_standard_member_seats,
  max_standard_restricted_seats,
}: IAvailableSeats): Set<License_Seat_Roles_Enum> {
  const tiersOnLicense = new Set<License_Seat_Roles_Enum>();
  if (max_pro_member_seats || max_standard_member_seats) {
    tiersOnLicense.add(License_Seat_Roles_Enum.Member);
    tiersOnLicense.add(License_Seat_Roles_Enum.Admin);
  }

  if (max_pro_restricted_seats || max_standard_restricted_seats)
    tiersOnLicense.add(License_Seat_Roles_Enum.Restricted);

  return tiersOnLicense;
}
// #endregion filter roles
// #region filter tiers
export function filterTierListBasedOnSeatMax(
  seatCaps: IAvailableSeats,
  unfilteredTierList: RadioOption<License_Seat_Tiers_Enum>[],
): RadioOption<License_Seat_Tiers_Enum>[] {
  const tiersToInclude = getTiersToInclude(seatCaps);
  const tierList = unfilteredTierList.filter(({ value }) =>
    tiersToInclude.has(value),
  );

  return tierList;
}

/**
 *
 * @param maxSeats {IAvailableSeats}
 * @returns
 */
function getTiersToInclude({
  max_pro_member_seats,
  max_pro_restricted_seats,
  max_standard_member_seats,
  max_standard_restricted_seats,
}: IAvailableSeats): Set<License_Seat_Tiers_Enum> {
  const tiersOnLicense = new Set<License_Seat_Tiers_Enum>();
  if (max_pro_member_seats || max_pro_restricted_seats)
    tiersOnLicense.add(License_Seat_Tiers_Enum.Pro);

  if (max_standard_member_seats || max_standard_restricted_seats)
    tiersOnLicense.add(License_Seat_Tiers_Enum.Standard);

  return tiersOnLicense;
}
// #region filter tiers
