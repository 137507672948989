import { st } from '@castify/studio/fe-common';

interface IDuration {
  start: st.ms;
  end: st.ms;
  startSourceOffset?: st.ms;
  endSourceOffset?: st.ms;
}

type DurationType = {
  start: st.ms;
  end: st.ms;
};

/**
 * considering 4 main cases:
 * Neither start or end of clip are within the visible timeline range
 * Only start of clip is within the visible timeline range
 * Only end of clip is within the visible timeline range
 * Both start and end of clip are within the visible timeline range
 */
export const getClipsInRange = <T extends IDuration>(
  clips: Array<IDuration>,
  start: st.ms,
  end: st.ms,
): Array<T> => {
  const clipsInRange = [];
  for (const clip of clips) {
    // If clip starts within range, add it
    if (clip.start >= start && clip.start < end) {
      clipsInRange.push(clip);
    } else if (clip.end > start && clip.end <= end) {
      clipsInRange.push(clip);
    }
    // If clip is range is completely inside the clip, add it
    else if (clip.end > end && clip.start <= start) {
      clipsInRange.push(clip);
    }

    // Stop iterating once we've passed the end
    if (clip.end > end) break;
  }
  return clipsInRange as Array<T>;
};

/**
 * duration for effects are saved/checked as startSourceOffset/endSourceOffset but
 * for text/audio they are saved as start/end this method returns a unified
 * interface with the different value
 */
const getDurationStartEnd = (duration: IDuration): DurationType => {
  // we need to check first for relative values, because blur/zoom also have
  // start/end
  if (
    duration.startSourceOffset !== null &&
    duration.startSourceOffset !== undefined &&
    duration.endSourceOffset !== null &&
    duration.endSourceOffset !== undefined
  ) {
    return {
      start: duration.startSourceOffset,
      end: duration.endSourceOffset,
    };
  }
  return {
    start: duration.start,
    end: duration.end,
  };
};

/**
 * When trying to insert audio/text track there is a default duration
 * But if the clip is near the edge of another track/timeline, we need
 * to move it to the left/right so we wouldn't get any overlap
 * Returns array of [start, end] after the are checked/updated for the boundries
 */
export const enforcePointBoundriesInTrack = (
  startPoint: st.ms,
  endPoint: st.ms,
  startBoundry: st.ms,
  endBoundry: st.ms,
  clips: IDuration[],
): [st.ms, st.ms] => {
  const closestClipOnRight = getClosestClipFromRight(clips, startPoint);
  const closestClipOnLeft = getClosestClipFromLeft(clips, startPoint);

  // the closest right edge would be the next clip (if exist) or the edge of the timeline
  const closestRightEdge = closestClipOnRight?.start || endBoundry;

  if (endPoint < closestRightEdge) {
    return [startPoint, endPoint];
  }

  // we remove 0.1 so the two points wouldn't overlap
  const updatedEnd = closestRightEdge - 0.1;

  const extraGap = endPoint - closestRightEdge;
  let updatedStart = startPoint - extraGap;

  // if there is no video on the left, or if there is one, but startPoint is
  // ahead of that clip end, then video doesn't overlap
  if (!closestClipOnLeft || updatedStart > closestClipOnLeft.end) {
    // If there is a video close to the start of the timeline, and the user tried to create one before that
    // then video start time could end up having -start value, so we always reset to 0 (timeline start)
    // if video start is less than 0
    if (updatedStart < startBoundry) {
      updatedStart = startBoundry;
    }
    return [updatedStart, updatedEnd];
  }

  // we add 0.1 so the two points wouldn't overlap
  updatedStart = closestClipOnLeft.end + 0.1;
  return [updatedStart, updatedEnd];
};

/**
 * helper method that accept array of clips and an end point for a clip
 * returns the closest clip from the right to the point we provided
 * This will be used when creating effects, we want to check for overlap
 */
const getClosestClipFromRight = (
  clips: IDuration[],
  point: st.ms,
): DurationType | null => {
  let closestDuration: DurationType | null = null;
  for (let index = 0; index < clips.length; index++) {
    const clip = clips[index];
    const duration = getDurationStartEnd(clip);
    const isClipAfterPoint = duration.start > point;
    const isClipBeforeClosestClip =
      !closestDuration || duration.start < closestDuration.start;
    if (isClipAfterPoint && isClipBeforeClosestClip) {
      closestDuration = duration;
    }
  }
  return closestDuration;
};

/**
 * helper method that accept array of clips and a start point for a clip
 * returns the closest clip from the left to the point we provided
 * This will be used when creating effects, we want to check for overlap
 */
const getClosestClipFromLeft = (
  clips: IDuration[],
  point: st.ms,
): DurationType | null => {
  let closestDuration: DurationType | null = null;
  for (let index = 0; index < clips.length; index++) {
    const clip = clips[index];
    const duration = getDurationStartEnd(clip);
    const isClipBeforePoint = duration.end < point;
    const isClipAfterClosestClip =
      !closestDuration || clip.end > closestDuration.end;
    if (isClipBeforePoint && isClipAfterClosestClip) {
      closestDuration = duration;
    }
  }
  return closestDuration;
};
