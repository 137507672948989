import { observer } from 'mobx-react-lite';

import { shortcutConfigs } from './shortcutsConfig';
import { useKeyboardShortcut } from './useKeyboardShortcut';

/**
 * This component exists solely to wire up keyboard shortcuts configured in
 * the shortcutsConfig file in this dir.
 */
const SimpleEditShortcuts = () => {
  useKeyboardShortcut(shortcutConfigs.PlayPause);
  useKeyboardShortcut(shortcutConfigs.Cut);
  useKeyboardShortcut(shortcutConfigs.Delete);
  useKeyboardShortcut(shortcutConfigs.Undo);
  useKeyboardShortcut(shortcutConfigs.Redo);
  useKeyboardShortcut(shortcutConfigs.FrameForward);
  useKeyboardShortcut(shortcutConfigs.FrameBack);
  useKeyboardShortcut(shortcutConfigs.TimelineZoomOut);
  useKeyboardShortcut(shortcutConfigs.TimelineZoomIn);

  return null;
};

export default observer(SimpleEditShortcuts);
