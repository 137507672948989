//@ts-strict
/*!
 * Copyright 2022 Screencastify LLC
 */
import { createBrowserLogger } from './browser-logger';
import type { IBrowserLogger } from './browser-logger';
import type { FallbackProps } from 'react-error-boundary';
import ErrorPage from '@castify/studio/components/error-page';

/**
 * The ErrorBoundary component can be directly imported from the library 'react-error-boundary' and wrap around components
 * individually to catch React rendering errors.
 * There are 2 other APIs in place of the FallbackComponent: fallbackRender (inline error fallback UI and can access elements
 * in the scope of the component that's using ErrorBoundary) and fallback (similar to React.Suspense, not recommended).
 * The FallbackComponent is required for ErrorBoundary if the other 2 are not provided.
 *
 * See observability-browser readme on how to handle errors from side effects:
 *    Event handlers
 *    Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 *    Server side rendering
 *    Errors thrown in the error boundary itself (rather than its children)
 * */

// export for unit test
export const logger: IBrowserLogger = createBrowserLogger('ReactErrorBoundary');

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps): JSX.Element => {
  return <ErrorPage errorType="refreshPage" />;
};

// handle React rendering errors and log errors to our logger
export const componentErrorHandler = (
  error: Error,
  info: { componentStack: string },
): JSX.Element => {
  // TODO: below format can probably be configured in Browser Logger
  // but the idea is if it's an Error object, always log out the message and stack
  logger.error(`An error was caught by the global Error Boundary`, {
    error,
    message: error.message,
    stack: error.stack,
    componentStack: info.componentStack,
  });
  return <ErrorPage errorType="contactSupport" />;
};
