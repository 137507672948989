import { css } from '@emotion/react';

const styles = {
  container: css`
    padding: 2rem;
    padding-top: 0;
  `,
  textInput: css`
    padding: 0;
    margin: 0 0 1rem;
  `,
};
export default styles;
