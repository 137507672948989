import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  appContainer: css`
    width: 100vw;
    height: 100vh;
    background-color: ${colors.TEMP.PreviewBackground};

    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 80px minmax(0, 1fr) 60px;
    grid-template-areas:
      'header'
      'preview'
      'footer';
  `,
  header: css`
    grid-area: header;
  `,
  preview: css`
    grid-area: preview;
    position: relative;
    padding-top: 30px;
    @media only screen and (min-width: 576px) {
      margin-right: 10%;
      margin-left: 10%;
    }
  `,
  footer: css`
    grid-area: footer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media only screen and (min-width: 576px) {
      margin-right: 10%;
      margin-left: 10%;
    }
  `,
  footerText: css`
    position: relative;
    margin: 0 0 0 60px;

    display: flex;
    align-items: center;
    height: 100%;

    font-family: Helvetica, Sans-Serif;
    font-weight: bold;
  `,
  sidebarButton: css`
    grid-area: hamburger;

    align-items: center;
    margin: 0 7.5%;
  `,
  simpleEdit: css`
    min-width: 95vw;
    min-height: 95vh;
    max-width: 95vw;
    max-height: 95vh;
  `,
};
export default styles;
