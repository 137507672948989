import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  SidebarContainer: css`
    font-family: Helvetica light, sans-serif;
    display: grid;
    height: 100vh;
    max-width: 300px;
    color: ${colors.gray[0]};
    background-color: ${colors.gray[50]};

    grid-template-areas:
      'LogoContainer'
      'TopItems'
      'BottomItems';
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;

    #LogoContainer {
      grid-area: LogoContainer;
    }
    #TopItems {
      grid-area: TopItems;
    }
    #BottomItems {
      grid-area: BottomItems;
    }
  `,
  Divider: css`
    border: 1px solid ${colors.gray[200]};
  `,
  ListNoBottom: css`
    padding-bottom: 0;
  `,
  logoContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 80px;
    width: 100%;
    gap: 10px;
    margin-bottom: 30px;
    background-color: ${colors.gray[50]};
  `,
  chip: css`
    height: 22px;
  `,
  categoryHeader: css`
    margin-left: 30px;
    font-size: 12px;
    color: ${colors.gray[600]};
  `,
};
export default styles;
