import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Scrollbar.styles';
import { useMst } from '@castify/studio/studio-store';
import { useScrollbar } from './useScrollbar';

function Scrollbar() {
  const {
    timeline: { scrollWidth, scrollLeftOffset, handleScrollbarMove },
  } = useMst();
  const scrollbarInnerRef = React.useRef<HTMLDivElement>(null);
  const { pointerDown } = useScrollbar(handleScrollbarMove, scrollbarInnerRef);

  return (
    <div css={styles.scrollbarContainer}>
      <div
        ref={scrollbarInnerRef}
        onPointerDown={pointerDown}
        css={styles.scrollbarBody}
        style={{
          left: `${scrollLeftOffset}px`,
          width: `${scrollWidth}px`,
        }}
      ></div>
    </div>
  );
}

export default observer(Scrollbar);
