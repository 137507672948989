import { observer } from 'mobx-react-lite';
import { Dialog } from '@mui/material';
import SimpleEditView from './SimpleEditView/SimpleEditView';
interface SimpleEditProps {
  isSimpleEditOpen: boolean;
  toggleSimpleEdit: () => void;
}

export function SimpleEditDialog({
  isSimpleEditOpen,
  toggleSimpleEdit,
}: SimpleEditProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          overflow: 'hidden',
          minHeight: '90vh',
          minWidth: '90vw',
          maxHeight: '90vh',
          maxWidth: '90vw',
          borderRadius: '15px',

          // Not sure why but grid is the only display that
          // allows this modal to take up the whole space described above
          display: 'grid',
        },
      }}
      onClose={toggleSimpleEdit}
      open={isSimpleEditOpen}
    >
      <SimpleEditView toggleSimpleEdit={toggleSimpleEdit} />
    </Dialog>
  );
}

export default observer(SimpleEditDialog);
