import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  ProfileContainer: css`
    height: fit-content;
    min-height: 66px;
    display: flex;
    flex-direction: row;
    padding: 5px 16px 13px;
  `,
  ProfilePicture: css`
    background-color: #00aaff;
    border-radius: 100%;
    width: 48px;
    height: 48px;
  `,
  ProfileInfo: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    color: ${colors.gray[700]};

    #Name {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 700;
    }
    #Email {
      margin-bottom: 0;
      font-size: 12.8px;
      font-weight: 400;
    }
  `,
};
export default styles;
