import { useEffect, useState } from 'react';

import { LocalUploadFlow } from '@castify/studio/ingress-flows';
import { useMutation } from 'urql';

import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { NewClipInfo, useMst } from '@castify/studio/studio-store';
import { Stack } from '@mui/material';
import {
  CreateNewProjectDocument,
  CreateNewProjectMutation,
  CreateNewProjectMutationVariables,
  UpdateSceneDocument,
  UpdateSceneMutation,
} from '@castify/studio/database-clients/graphql';

/**
 * The extension intake component does a few things:
 *
 * 1.) Creates a new studio project for the user
 * 2.) Upon recieving a successful project creation response update react state with video data and uuid
 * 3.) Upon updating state with a video data convert it to a file
 * 4.) After converting video data blob to a file object kick off the local upload flow
 * 5.) After completing the upload flow update the scene in the db manually and revoke the data url
 * 6.) Navigate the user to the preview page
 */
const ExtensionIntake = () => {
  const navigate = useNavigate();
  const [uuid, setUuid] = useState('');
  const [videoSrc, setVideoSrc] = useState<Blob>();
  const [readyForProcessing, setReadyForProcessing] = useState(false);

  const { ingress, project } = useMst();
  const [{ data }, executeMutation] = useMutation<
    CreateNewProjectMutation,
    CreateNewProjectMutationVariables
  >(CreateNewProjectDocument);

  const [updateData, updateScene] =
    useMutation<UpdateSceneMutation>(UpdateSceneDocument);

  //this use effect creates the project for the user
  useEffect(() => {
    executeMutation({ title: 'Untitled Video' });
  }, [executeMutation]);

  //this use effect runs when we get data back from the project creation mutation
  //it registers an event listener to read the data passed from the extension
  useEffect(() => {
    if (data?.insert_projects_one?.uuid) {
      setUuid(data?.insert_projects_one?.uuid);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      setVideoSrc(event.data.recordingData);
    });
  }, []);

  useEffect(() => {
    if (uuid && videoSrc) {
      setReadyForProcessing(true);
    }
  }, [videoSrc, uuid]);

  //This use effect runs once we have the data for the video
  useEffect(() => {
    const handleFile = async () => {
      const fileToUpload = new File(
        [videoSrc as unknown as BlobPart],
        'temp.webm',
        {
          type: 'video/webm',
        },
      );
      ingress.startLocalUploadFlow(fileToUpload);
    };
    if (readyForProcessing) handleFile();
  }, [readyForProcessing]);

  const finishIngress = (data: NewClipInfo | undefined) => {
    //This will update the scene within mst
    ingress.finishIngressFlow(data);
    //pull scene back out and make db update because we don't have sync scene here yet
    updateScene({ projectId: uuid, scene: JSON.stringify(project.scene) }).then(
      () => {
        navigate(`/preview/${uuid}`, { replace: true });
      },
    );
  };

  return (
    <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
      {ingress.localUploadInProgress && ingress.file && (
        <LocalUploadFlow
          finishIngressFlow={finishIngress}
          projectUuid={uuid}
          debugMode={false}
          file={ingress.file}
        />
      )}
    </Stack>
  );
};

export default observer(ExtensionIntake);
