import { IRotatableBoxModel } from '@castify/studio/studio-store';
import { st } from '@castify/studio/fe-common';
import React from 'react';

/**
 * Props for the useMovableBoxHandlers hook
 */
type UseMoveableBoxHandlersProps = {
  /**
   * The box instant being moved. Most expose API for moving box and handles
   */
  boxModel: IRotatableBoxModel;
  /**
   * A ref to the overlay container within which the box moves
   */
  overlayContainerRef: React.RefObject<HTMLDivElement>;
};

/**
 * Names of handles
 */
enum RotatePointerHandlerNames {
  Move = 'Move',
  Rotate = 'Rotate',
  Resize = 'Resize',
}

/**
 * This stateless hook exists only to encapsulate the logic for dragging the box
 * via its handles, borders, or inside, wiring these events up to the MST model.
 *
 * The division of labor between the code here and the box model is as follows:
 * the code here is responsible for the implementation details of drags (e.g.
 * offsets relative to container and element, when event listeners are active,
 * etc.). The box model can take any input expressed in a % offset of the
 * container, but clamps it to the container.
 */
export const useRotateBoxHandler = ({
  boxModel,
  overlayContainerRef,
}: UseMoveableBoxHandlersProps) => {
  /**
   * Intended to be attached as an onPointerDown handler to elements which
   * can move or resize the box.
   *
   * Tracks the offset of the pointerDown within the container to make sure
   * that we don't
   */
  const pointerDown = (
    e: React.PointerEvent,
    handlerName: RotatePointerHandlerNames,
  ) => {
    // Grab bounds of the overlay
    if (!overlayContainerRef.current) throw new Error('NO OVERLAY GIVEN'); // type guard
    const containerBounds = overlayContainerRef.current.getBoundingClientRect();
    const targetBounds = (e.target as HTMLDivElement).getBoundingClientRect();

    const offsetX = e.clientX - targetBounds.left - targetBounds.width / 2;
    const offsetY = e.clientY - targetBounds.top - targetBounds.height / 2;

    const pointerMove = (e: PointerEvent) => {
      // Mouse location relative to the container
      const mouseInCanvasX = e.clientX - containerBounds.left;
      const mouseInCanvasY = e.clientY - containerBounds.top;
      const mousePoint: st.point = { x: mouseInCanvasX, y: mouseInCanvasY };

      if (handlerName === 'Move') {
        // Get the current X and Y location
        const currYLocation = e.clientY - offsetY - containerBounds.top;
        const currXLocation = e.clientX - offsetX - containerBounds.left;
        const xPercent = currXLocation / containerBounds.width;
        const yPercent = currYLocation / containerBounds.height;
        // Move the box to that XY location
        boxModel.move(xPercent, yPercent);
      } else if (handlerName === 'Rotate') {
        boxModel.setRotationFromMouse(
          mouseInCanvasX,
          mouseInCanvasY,
          containerBounds,
        );
      } else if (handlerName === 'Resize') {
        boxModel.resize(
          mousePoint,
          containerBounds.height,
          containerBounds.width,
        );
      }
    };
    const pointerUp = () => {
      boxModel.setRealRotation();
      window.removeEventListener('pointermove', pointerMove);
      window.removeEventListener('pointerup', pointerUp);
    };

    window.addEventListener('pointermove', pointerMove);
    window.addEventListener('pointerup', pointerUp);
  };

  /**
   * Return named handlers for the border/inside and each drag handle. Prevents
   * us having to export the enum.
   */
  return {
    pointerDownMove: (e: React.PointerEvent) =>
      pointerDown(e, RotatePointerHandlerNames.Move),
    pointerDownRotate: (e: React.PointerEvent) =>
      pointerDown(e, RotatePointerHandlerNames.Rotate),
    pointerDownResize: (e: React.PointerEvent) =>
      pointerDown(e, RotatePointerHandlerNames.Resize),
  };
};
