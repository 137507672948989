import { IBoxModel, useMst } from '@castify/studio/studio-store';

/**
 * Props for the useMovableBoxHandlers hook
 */
type UseMoveableBoxHandlersProps = {
  /**
   * The box instance being moved. Most expose API for moving box and handles.
   */
  boxModel: IBoxModel;
  /**
   * A ref to the overlay container within which the box moves
   */
  overlayContainerRef: React.RefObject<HTMLDivElement>;
};

/**
 * Names of handles
 */
enum PointerHandlerNames {
  Move = 'Move',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
}

/**
 * This stateless hook exists only to encapsulate the logic for dragging the box
 * via its handles, borders, or inside, wiring these events up to the MST model.
 *
 * The division of labor between the code here and the box model is as follows:
 * the code here is responsible for the implementation details of drags (e.g.
 * offsets relative to container and element, when event listeners are active,
 * etc.). The box model can take any input expressed in a % offset of the
 * container, but clamps it to the container.
 */
export const useMoveableBoxHandlers = ({
  boxModel,
  overlayContainerRef,
}: UseMoveableBoxHandlersProps) => {
  const {
    project: { undoManager },
  } = useMst();

  /**
   * Intended to be attached as an onPointerDown handler to elements which
   * can move or resize the box.
   *
   * Tracks the offset of the pointerDown within the container to make sure
   * that we don't
   */
  const pointerDown = (
    e: React.PointerEvent,
    handlerName: PointerHandlerNames,
  ) => {
    /**
     * Start an undo group
     */
    undoManager.startGroup(() => {});

    const targetBounds = (e.target as HTMLDivElement).getBoundingClientRect();
    const offsetX = e.clientX - targetBounds.left;
    const offsetY = e.clientY - targetBounds.top;

    const pointerMove = (e: PointerEvent) => {
      if (!overlayContainerRef.current) return; // type guard

      // what coordinates, in pixels, relative to the container
      // is this a move to?
      const containerBounds =
        overlayContainerRef.current.getBoundingClientRect();
      const yPx = e.clientY - offsetY - containerBounds.top;
      const xPx = e.clientX - offsetX - containerBounds.left;

      // convert to percentages
      const xPercent = xPx / containerBounds.width;
      const yPercent = yPx / containerBounds.height;

      // delegate re-positioning the box to correct action
      if (handlerName === PointerHandlerNames.Move)
        boxModel.move(xPercent, yPercent);
      else if (handlerName === PointerHandlerNames.TopLeft)
        boxModel.resizeTopLeft(xPercent, yPercent);
      else if (handlerName === PointerHandlerNames.TopRight)
        boxModel.resizeTopRight(xPercent, yPercent);
      else if (handlerName === PointerHandlerNames.BottomLeft)
        boxModel.resizeBottomLeft(xPercent, yPercent);
      else if (handlerName === PointerHandlerNames.BottomRight)
        boxModel.resizeBottomRight(xPercent, yPercent);
    };
    const pointerUp = () => {
      window.removeEventListener('pointermove', pointerMove);
      window.removeEventListener('pointerup', pointerUp);
      /**
       * Stop the undo group
       */
      undoManager.stopGroup();
    };

    window.addEventListener('pointermove', pointerMove);
    window.addEventListener('pointerup', pointerUp);
  };

  /**
   * Return named handlers for the border/inside and each drag handle. Prevents
   * us having to export the enum.
   */
  return {
    pointerDownMove: (e: React.PointerEvent) =>
      pointerDown(e, PointerHandlerNames.Move),
    pointerDownTopLeft: (e: React.PointerEvent) =>
      pointerDown(e, PointerHandlerNames.TopLeft),
    pointerDownTopRight: (e: React.PointerEvent) =>
      pointerDown(e, PointerHandlerNames.TopRight),
    pointerDownBottomRight: (e: React.PointerEvent) =>
      pointerDown(e, PointerHandlerNames.BottomRight),
    pointerDownBottomLeft: (e: React.PointerEvent) =>
      pointerDown(e, PointerHandlerNames.BottomLeft),
  };
};
