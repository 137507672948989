import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import { ButtonTooltip, StudioParams } from '@castify/studio/fe-common';
import { useMst } from '@castify/studio/studio-store';
import styles from './PreviewButton.styles';

interface PreviewButtonProps {
  showSkeleton?: boolean;
}

function PreviewButton({ showSkeleton }: PreviewButtonProps) {
  const {
    ingress: { isUploadInProgress },
    project: {
      scene: { isTimelineEmpty },
    },
  } = useMst();

  const { projectId } = useParams<StudioParams>();

  if (showSkeleton)
    return <Skeleton variant="rectangular" width={98} height={48} />;

  return (
    <ButtonTooltip title="Go To Preview">
      <Link
        to={isTimelineEmpty || isUploadInProgress ? '#' : `/watch/${projectId}`}
        data-pendo-id="preview-button-link"
        css={styles.previewLink}
      >
        <Button
          disabled={isTimelineEmpty || isUploadInProgress}
          variant="outlined"
          color="primary"
        >
          Preview
        </Button>
      </Link>
    </ButtonTooltip>
  );
}

export default observer(PreviewButton);
