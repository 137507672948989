import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  buttonContainer: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  `,
  formContainer: css`
    padding: 1rem;
    padding-top: 0rem;
  `,
};
export default styles;
