import React from 'react';
import styles from './Header.styles';
import ExportButton from './ExportButton/ExportButton';
import { observer } from 'mobx-react-lite';
import DeleteProjectButton from './DeleteProjectButton/DeleteProjectButton';
import PreviewButton from './PreviewButton/PreviewButton';
import SidebarButton from './SidebarButton/SidebarButton';
import ProjectBreadcrumbs from './ProjectBreadcrumbs/ProjectBreadcrumbs';
import { Logo } from '@castify/studio/fe-common';

interface HeaderProps {
  showSkeleton?: boolean;
}

function Header({ showSkeleton }: HeaderProps) {
  return (
    <div css={styles.header}>
      <div css={styles.headerSection}>
        <SidebarButton showSkeleton={showSkeleton} />
        <Logo withText={false} />
        <ProjectBreadcrumbs showSkeleton={showSkeleton} />
      </div>

      <div css={styles.headerSection}>
        <DeleteProjectButton showSkeleton={showSkeleton} />
        <PreviewButton showSkeleton={showSkeleton} />
        <ExportButton showSkeleton={showSkeleton} />
      </div>
    </div>
  );
}

export default observer(Header);
