import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DriveIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g id="Finals" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="Add-Media-Options"
          transform="translate(-1431.000000, -569.000000)"
          fillRule="nonzero"
        >
          <g id="60958" transform="translate(1431.000000, 569.666667)">
            <path
              d="M15.6296303,9.1754386 L10.5184994,0.333333333 L5.48147153,0.333333333 L5.48147153,0.333333333 L10.5926024,9.1754386 L15.6296303,9.1754386 Z M6.29628487,9.9122807 L3.77777092,14.3333333 L13.481457,14.3333333 L16,9.9122807 L6.29628487,9.9122807 Z M4.81480607,1.43859649 L0,9.9122807 L2.51851395,14.3333333 L7.40739396,5.85964912 L4.81480607,1.43859649 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
