import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  playbackController: css`
    height: 100%;
    display: flex;
    align-items: flex-end;
    min-width: 137px;
    margin-bottom: 50px;
  `,
  playButton: css`
    position: relative;
    height: 60px;
    width: 60px;
  `,
  icon: css`
    font-size: 40px;
  `,
};
export default styles;
