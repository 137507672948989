import React, { useState, useEffect } from 'react';
import type {
  FeatureFlagVisibilityList,
  ActiveFeatureFlagName,
} from '@castify/studio/feature-flags/shared';
import { createCtx } from '@castify/studio/fe-common';
import { createBrowserLogger } from '@castify/studio/observability/browser';
import { environment } from '@castify/studio/env/browser';
import { useHttpClient } from '@castify/studio/auth';

const [useFeatureFlags, Provider] = createCtx<FeatureFlagVisibilityList>();
const logger = createBrowserLogger('FeatureFlagProvider');

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const [visibilityList, setVisibilityList] =
    useState<FeatureFlagVisibilityList>({} as FeatureFlagVisibilityList);

  const httpClient = useHttpClient();

  useEffect(() => {
    (async function makeRequest() {
      try {
        const url = `${environment.serviceUrl}feature-flags/`;
        const response = await httpClient.get(url);

        setVisibilityList(response.data);
      } catch (error) {
        logger.error('Could not load feature list', { error });
      }
    })();
  }, [httpClient]);

  return <Provider value={visibilityList}>{children}</Provider>;
};

type FeatureFlagConsumerProps = {
  flagName: ActiveFeatureFlagName;
  children: JSX.Element;
};

export const FeatureFlagConsumer = (
  props: FeatureFlagConsumerProps,
): JSX.Element | null => {
  const { flagName, children } = props;
  const flags = useFeatureFlags();

  return flags[flagName] ? children : null;
};
