import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import { useMutation } from 'urql';
import {
  UpdateVideoTitleDocument,
  UpdateVideoTitleMutation,
  UpdateVideoTitleMutationVariables,
} from '@castify/studio/database-clients/graphql';
import { TextField, Typography } from '@mui/material';
import { BrowserLogger } from '@castify/studio/observability/browser';
import styles from '../Header.styles';

function TitleUpdateField() {
  const logger = BrowserLogger.createLogger('UpdateProjectTitle');
  const { project } = useMst();
  const [currentActiveTimeoutId, setCurrentActiveTimeoutId] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  //Onload fetch current title from mst/db and store in temporary state to hadle cases we need to revert back
  const [currentTitle, setCurrentTitle] = useState(project.title);
  const [hasError, setHasError] = useState(false);
  const maxCharCount = 100;

  const [{ error, data }, executeMutation] = useMutation<
    UpdateVideoTitleMutation,
    UpdateVideoTitleMutationVariables
  >(UpdateVideoTitleDocument);
  const updateTitleInDb = async () => {
    await executeMutation({ projectId: project.uuid, title: project.title });
    if (data?.update_projects_by_pk?.title) {
      setCurrentTitle(data?.update_projects_by_pk?.title);
    }
    if (error) {
      logger.error('Error while updating project title', error);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    //reset error message if we have one
    setHasError(false);
    const userEnteredValue = e.target.value;
    //splice below handles scenarios users would attempt to paste in really long strings of text
    if (userEnteredValue.length > maxCharCount) {
      project.setProjectTitle(userEnteredValue.slice(0, maxCharCount));
      setHasError(true);
    } else {
      project.setProjectTitle(userEnteredValue);
    }
    /**
     * Clear timeout is a built in function whenever setTimeout is called it can produce an
     * id which can be used to stop execution if you choose,
     * we use this id in reacts state to debounce calls to the API ensuring only one call will occur
     * */
    if (userEnteredValue !== '' && userEnteredValue.length <= maxCharCount) {
      clearTimeout(currentActiveTimeoutId as unknown as NodeJS.Timeout);
      const id = setTimeout(() => {
        updateTitleInDb();
      }, 2000) as unknown as number;
      setHasError(false);
      setCurrentActiveTimeoutId(id);
    } else {
      setHasError(true);
    }
  };

  // If a user full deletes their title and then leaves the field we should force it to the previous title we had
  const handleOnBlur = () => {
    if (project.title === '') {
      project.setProjectTitle(currentTitle);
      updateTitleInDb();
      setHasError(false);
    }
    setIsEditing(false);
  };

  const handleOnFocus = () => {
    setIsEditing(true);
  };

  return (
    <div css={styles.titleInput}>
      <TextField
        value={project.title}
        label="Video Title"
        id="title-update-field"
        data-testid="title-update-field"
        data-pendo-id="title-update-field"
        size="small"
        color={!hasError ? 'primary' : 'error'}
        onChange={handleTitleChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
      />

      {isEditing && (
        <Typography
          css={[
            styles.titleInpuCharCount,
            hasError && styles.titleInpuCharCountWithError,
          ]}
        >
          {project.title.length} / {maxCharCount}
        </Typography>
      )}
    </div>
  );
}

export default observer(TitleUpdateField);
