import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

//Initial values in all styles will be mobile first and overridden by media queries
const styles = {
  fullscreenButton: css`
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    font-size: 20px;
  `,
};
export default styles;
