import { License_Seat_Roles_Enum } from '@castify/studio/database-clients/graphql';
import { IInvitationListRoleFilters } from '../Invitations.d';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

const radioValues: Record<string, License_Seat_Roles_Enum[]> = {
  all: [
    License_Seat_Roles_Enum.Admin,
    License_Seat_Roles_Enum.Member,
    License_Seat_Roles_Enum.Restricted,
  ],
  admin: [License_Seat_Roles_Enum.Admin],
  member: [License_Seat_Roles_Enum.Member],
  restricted: [License_Seat_Roles_Enum.Restricted],
};

const InvitationListRoleFilters = (props: IInvitationListRoleFilters) => {
  const { setSelectedRoles, data } = props;
  return (
    <FormControl>
      <FormLabel id="role-radio-filter">Filter</FormLabel>
      <RadioGroup
        row
        aria-labelledby="role-radio-filter"
        defaultValue="all"
        name="role-filter"
        onChange={(e, key: string) => {
          setSelectedRoles(radioValues[key]);
        }}
      >
        <FormControlLabel
          value={'all'}
          control={<Radio data-testid="show-all-radio" />}
          label="All"
        />
        <FormControlLabel
          value={`${License_Seat_Roles_Enum.Admin}`}
          control={<Radio data-testid="show-admin-radio" />}
          label="Admin"
        />
        {data.licenses[0]?.max_pro_member_seats > 0 && (
          <FormControlLabel
            value={`${License_Seat_Roles_Enum.Member}`}
            control={<Radio data-testid="show-member-radio" />}
            label="Member"
          />
        )}
        {data.licenses[0]?.max_standard_restricted_seats > 0 && (
          <FormControlLabel
            value={`${License_Seat_Roles_Enum.Restricted}`}
            control={<Radio data-testid="show-restricted-radio" />}
            label="Restricted"
          />
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default InvitationListRoleFilters;
