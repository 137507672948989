import { useEffect } from 'react';
import { useMst } from '@castify/studio/studio-store';
import styles from './WatchPageHeader.styles';
import { Logo } from '@castify/studio/fe-common';
import { useUserProfile } from '@castify/studio/auth';
import { Typography, Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';

interface WatchPageHeaderProps {
  showSkeleton?: boolean;
}

const WatchPageHeader = ({ showSkeleton }: WatchPageHeaderProps) => {
  const user = useUserProfile();
  const {
    viewer,
    project: { permissions },
  } = useMst();
  useEffect(() => {
    if (permissions === 'signed-in' && !user.isSignedIn) {
      // TODO: create prompt for user to sign in
    }
    if (permissions === 'nickname') {
      // TODO: check if user has a nickname in local storage
      // if not, prompt to set one
      // then updated viewer.displayName with nickname
    }
    /**
     * These calls update the state to the current viewer info.
     */
    viewer.updateUserIdentifier(user.id);
    viewer.updateDisplayName(user.displayName);
  }, [user.id, user.displayName, user.isSignedIn, permissions, viewer]);

  return (
    <div css={styles.header}>
      <div css={styles.logo}>
        <Logo withText={true} />
      </div>
      {showSkeleton ? (
        <Skeleton variant="rectangular" width={200} height={30} />
      ) : (
        <Typography>Viewer: {viewer.displayName}</Typography>
      )}
    </div>
  );
};

export default observer(WatchPageHeader);
