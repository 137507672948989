import { useClient } from 'urql';
import {
  GetRefreshTokenQuery,
  GetRefreshTokenQueryVariables,
  GetRefreshTokenDocument,
} from '@castify/studio/database-clients/graphql';
import { useEffect } from 'react';
import { useHttpClient, useGrantOfflineAccess } from '@castify/studio/auth';
import { environment } from '@castify/studio/env/browser';
import { RefreshTokenStates } from './IngressButtons';

/**
 * Hook for checking for a refresh token. Only runs if we're checking for
 * a token, not if we're saving a token.
 */
export const useTokenCheck = ({
  state,
  ifTokenFound,
  ifTokenAbsentOrInvalid,
}: {
  state: RefreshTokenStates;
  ifTokenFound: () => void;
  ifTokenAbsentOrInvalid: (error?: unknown) => void;
}) => {
  const client = useClient();
  useEffect(() => {
    if (state === RefreshTokenStates.CHECKING_FOR_TOKEN) {
      (async () => {
        try {
          const tokenState = await client
            .query<GetRefreshTokenQuery, GetRefreshTokenQueryVariables>(
              GetRefreshTokenDocument,
            )
            .toPromise();

          if (
            !!tokenState.data &&
            tokenState.data.refresh_tokens.length > 0 &&
            tokenState?.data?.refresh_tokens[0].expired !== true
          ) {
            ifTokenFound();
          } else {
            ifTokenAbsentOrInvalid();
          }
        } catch (error) {
          //Treats failure in talking to the DB as if the token wasn't found.
          ifTokenAbsentOrInvalid(error);
        }
      })();
    }
  }, [state, client, ifTokenFound, ifTokenAbsentOrInvalid]);
};

/**
 * Hook returning a function which allows us to ask the user for drive
 * permissions and send them to the backend.
 */
export const useAskUserForDrivePermissions = ({
  afterAccessGranted,
  onAccessGrantFailure,
}: {
  afterAccessGranted: () => void;
  onAccessGrantFailure: (error: unknown) => void;
}) => {
  const grantOfflineAccess = useGrantOfflineAccess();
  const httpClient = useHttpClient();
  return async () => {
    try {
      // this is what opens the popup
      // throws if popup is closed or if user declines to grant access
      const code = await grantOfflineAccess();

      // calls the BE and sends the auth code; the BE talks to google
      // to get the refresh token save it to the DB so the backend can use it
      // to pull in files from drive
      const res = await httpClient.post(`${environment.serviceUrl}auth/code`, {
        authorizationCode: code,
      });

      if (res.status !== 201) {
        throw new Error(
          `Failed to save refresh token; backend returned code ${res.status}`,
        );
      }
      afterAccessGranted();
    } catch (error) {
      onAccessGrantFailure(error);
    }
  };
};
