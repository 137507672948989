import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  title: css`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #2c2c2c;
    text-overflow: ellipsis;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
  `,
};
export default styles;
