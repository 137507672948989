import styles from './CutButton.styles';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { Fab, Skeleton, Typography } from '@mui/material';
import { useMst } from '@castify/studio/studio-store';
import { ButtonTooltip } from '@castify/studio/fe-common';
import { getShortcutString, shortcutConfigs } from '@castify/studio/keyboard';
import { observer } from 'mobx-react-lite';

interface CutButtonProps {
  showSkeleton?: boolean;
}

function CutButton({ showSkeleton }: CutButtonProps) {
  const {
    toolbar: { formattedPlayheadPosition, cutSelection, isCutButtonEnabled },
  } = useMst();

  if (showSkeleton)
    return <Skeleton variant="rectangular" height={40} width={205} />;

  return (
    <ButtonTooltip
      title={
        isCutButtonEnabled
          ? `Cut (${getShortcutString(shortcutConfigs.Cut)})`
          : 'Select A Clip'
      }
    >
      <Fab
        disabled={!isCutButtonEnabled}
        css={styles.container}
        variant="extended"
        data-pendo-id="cut-button"
        color="secondary"
        onPointerDown={cutSelection}
      >
        <ContentCutIcon css={styles.icon} />
        <Typography css={styles.font}>
          Split&nbsp;Clip&nbsp;At&nbsp;{formattedPlayheadPosition}
        </Typography>
      </Fab>
    </ButtonTooltip>
  );
}

export default observer(CutButton);
