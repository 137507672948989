import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import styles from './ViewCounter.styles';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useEffect } from 'react';
import { useSubscription } from 'urql';
import { useErrorHandler } from 'react-error-boundary';
import { createBrowserLogger } from '@castify/studio/observability/browser';
import type { IBrowserLogger } from '@castify/studio/observability/browser';

import {
  GetViewerAnalyticsDocument,
  GetViewerAnalyticsSubscription,
  GetViewerAnalyticsSubscriptionVariables,
} from '@castify/studio/database-clients/graphql';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

interface ViewCounterProps {
  showSkeleton?: boolean;
}

export function ViewCounter({ showSkeleton }: ViewCounterProps) {
  const logger: IBrowserLogger = createBrowserLogger('ViewCounter');
  const handleError = useErrorHandler();

  const {
    analytics,
    project: { uuid },
  } = useMst();

  /**
   * Makes a call to the viewer analytics db and returns a JSON with
   * all userIdentifiers.
   *
   * TODO: Add db testing for this subscription when infrastructure is ready.
   */
  const [{ data, error }] = useSubscription<
    GetViewerAnalyticsSubscription,
    GetViewerAnalyticsSubscription,
    GetViewerAnalyticsSubscriptionVariables
  >({
    query: GetViewerAnalyticsDocument,
    variables: { projectId: uuid },
  });
  if (error) {
    logger.error('Viewer analytics subscription error.');
    handleError(error);
  }

  useEffect(() => {
    if (!data) return;
    /**
     * The returned data is an object and needs to be converted to an
     * array of userIdentifiers each time the data changes.
     */
    analytics.updateViewerList(data);
  }, [data, analytics]);

  if (showSkeleton)
    return <Skeleton variant="rectangular" width={150} height={40} />;
  return (
    <div css={styles.container}>
      <ShowChartIcon />
      <Typography>
        <b>Viewers ({analytics.totalUniqueViews})</b>
      </Typography>
    </div>
  );
}

export default observer(ViewCounter);
