import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './PreviewPlayerFooter.styles';
import { useMst } from '@castify/studio/studio-store';
import VolumeControls from '../VolumeControls/VolumeControls';
import SmallPlayButton from '../SmallPlayButton/SmallPlayButton';
import FullscreenButton from '../FullscreenButton/FullscreenButton';
import { Typography } from '@mui/material';

interface PlayerFooterProps {
  videoWrapperRef: React.MutableRefObject<null>;
}

function PlayerFooter({ videoWrapperRef }: PlayerFooterProps) {
  const {
    toolbar: { formattedSceneDuration, formattedPlayheadPosition },
  } = useMst();

  return (
    <div css={styles.container}>
      <VolumeControls />

      <div css={styles.playbackControls}>
        <Typography css={styles.playclock} data-testid="playhead-position">
          {formattedPlayheadPosition}
        </Typography>

        <SmallPlayButton />

        <Typography css={styles.playclock} data-testid="scene-duration">
          {formattedSceneDuration}
        </Typography>
      </div>

      <div css={styles.fullscreenButtonContainer}>
        <FullscreenButton videoWrapperRef={videoWrapperRef} />
      </div>
    </div>
  );
}

export default observer(PlayerFooter);
