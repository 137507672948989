import {
  MainTrackClip,
  StillSourceModel,
  VideoSourceModel,
} from '@castify/studio/studio-store';
import { getSnapshot } from 'mobx-state-tree';
import { calculateDestinationPosition } from './helpers/destinationPosition';
import { calculateSourcePosition } from './helpers/sourcePosition';

type IPaintDebugText = {
  ctx: CanvasRenderingContext2D;
  canvasElm: HTMLCanvasElement;
  imageSource: CanvasImageSource | null;
  activeClip: MainTrackClip;
  isOverrideModeActive: boolean;
};

/**
 * Paint some debug information if debug is active
 */
export const paintDebugText = ({
  ctx,
  canvasElm,
  imageSource,
  activeClip,
  isOverrideModeActive,
}: IPaintDebugText): void => {
  const FONT_SIZE = 35;
  const FONT = `${FONT_SIZE}px  Helvetica, Arial, Sans-Serif`;
  const LEFT_MARGIN = 10;
  const RIGHT_COL_OFFSET = 300;

  /**
   * This mutable var is the line number we're printing to on the canvas. We
   * have to track this ourselves because the canvas API doesn't do any text
   * layout or text shaping for us.
   */
  let line = 1;

  const printLeftColumn = (text: string) => {
    ctx.fillText(text, LEFT_MARGIN, FONT_SIZE * line);
  };

  const printRightColumn = (text: string) => {
    ctx.fillText(text, RIGHT_COL_OFFSET, FONT_SIZE * line);
  };

  /**
   * initialize styles
   */
  ctx.font = FONT;

  /**
   * Print canvas dimensions
   */
  ctx.fillStyle = 'white';
  printLeftColumn(`canvas dims:`);
  printRightColumn(`${canvasElm.width}x${canvasElm.height}`);
  line++;

  /**
   * Print info for still clips
   */
  if (StillSourceModel.is(activeClip.source)) {
    printLeftColumn(`rendering still clip`);
    line++;
  }

  /**
   * Print info for video clips
   */
  if (VideoSourceModel.is(activeClip.source)) {
    if (!(imageSource instanceof HTMLVideoElement)) return;
    printLeftColumn(`full source dims:`);
    printRightColumn(`${imageSource.videoWidth}x${imageSource.videoHeight}`);
    line++;

    /**
     * What mode are we in
     */
    line++;
    printLeftColumn(`override mode:`);
    printRightColumn(`${isOverrideModeActive}`);
    line++;

    printLeftColumn(`crop active:`);
    printRightColumn(`${!!activeClip.source?.cropBox}`);
    line++;

    /**
     * Print out source and dest positioning
     */
    line++;
    const sourcePosition = calculateSourcePosition(
      imageSource,
      activeClip.source,
      isOverrideModeActive,
    );
    const destPosition = calculateDestinationPosition(
      activeClip.source,
      canvasElm,
      isOverrideModeActive,
    );
    printLeftColumn(`source top left: `);
    printRightColumn(
      `x:${sourcePosition.left.toFixed(0)} y:${sourcePosition.top.toFixed(0)}`,
    );
    line++;

    printLeftColumn(`source w x h:`);
    printRightColumn(
      `w:${sourcePosition.width.toFixed(0)} h:${sourcePosition.height.toFixed(
        0,
      )}`,
    );
    line++;

    printLeftColumn(`dest top left: `);
    printRightColumn(
      `x:${destPosition.left.toFixed(0)} y:${destPosition.top.toFixed(0)}`,
    );
    line++;

    printLeftColumn(`dest w x h:`);
    printRightColumn(
      `w:${destPosition.width.toFixed(0)} h:${destPosition.height.toFixed(0)}`,
    );
    line++;
  }
};
