import { useState } from 'react';
import { License_Seat_Roles_Enum } from '@castify/studio/database-clients/graphql';
import InvitationListContainer from '../InvitationListContainer/InvitationListContainer';

const InvitationContainer = () => {
  const [selectedRoles, setSelectedRoles] = useState<License_Seat_Roles_Enum[]>(
    [
      License_Seat_Roles_Enum.Admin,
      License_Seat_Roles_Enum.Member,
      License_Seat_Roles_Enum.Restricted,
    ],
  );

  // false === they have accepted invitation
  // true === they have not accepted invitation
  const [pendingInviteFilterValue, setPendingInviteFilterValue] =
    useState<boolean>(true);

  return (
    <InvitationListContainer
      setSelectedRoles={setSelectedRoles}
      selectedRoles={selectedRoles}
      pendingInviteFilterValue={pendingInviteFilterValue}
      setPendingInviteFilterValue={setPendingInviteFilterValue}
    />
  );
};

export default InvitationContainer;
