import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { IInvitationListPendingInviteFilters } from '../Invitations';

const PendingInvitationFilter = (
  props: IInvitationListPendingInviteFilters,
) => {
  const { setPendingInviteFilterValue, pendingInviteFilterValue } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={pendingInviteFilterValue}
            color="success"
            onChange={(event) => {
              setPendingInviteFilterValue(event.target.checked);
            }}
            data-testid="pending-invitation-filter"
          />
        }
        label="Show Pending Invitations"
        labelPlacement="start"
      ></FormControlLabel>
    </FormGroup>
  );
};

export default PendingInvitationFilter;
