import { StillSourceModel, useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import styles from '../DebugPanel.styles';

/**
 * Extra buttons that allow adding elements to the scene whose normal UI has
 * either not yet been added or which lives behind feature flags
 */
const FeatureButtons = () => {
  const {
    project: { scene },
    playback: { playheadPosition },
    toolbar: { addOrEditBlurEffect, addOrEditTextClip, addOrEditZoomEffect },
  } = useMst();

  return (
    <div css={styles.addVideoMenuColumn}>
      <p>Effects & Tools</p>
      <button
        onClick={() => {
          scene.mainTrack.addClipToEnd({
            source: StillSourceModel.create({
              duration: 5000,
              color: 'purple',
            }),
          });
        }}
      >
        still 5sec
      </button>
      <button
        onClick={() =>
          scene.detachedAudioTrack.addAudioClipAtTime(playheadPosition)
        }
      >
        audio
      </button>
      {scene.mainTrack.mainTrackClips.length > 0 && (
        <button onClick={addOrEditBlurEffect}>Add Blur Effect</button>
      )}
      {scene.mainTrack.mainTrackClips.length > 0 && (
        <button onClick={addOrEditTextClip}>Add Text Effect</button>
      )}
      {scene.mainTrack.mainTrackClips.length > 0 && (
        <button onClick={addOrEditZoomEffect}>Add Zoom Effect</button>
      )}
    </div>
  );
};

export default observer(FeatureButtons);
