import { createBrowserLogger } from '@castify/studio/observability/browser';
import { useEffect } from 'react';
const logger = createBrowserLogger('Loading');

interface LoadingTreatmentLoggerProps {
  /**
   * An identifier describing which loading treatment we're seeing; e.g.
   * why or by which component the loading treatment is being shown
   */
  identifier: string;
}

/**
 * This is a utility component intended to assist in logging for how long
 * the user sees loading treatments of various kinds. It renders nothing
 * and is not intended to be used to actually render loading treatments,
 * whih should be owned by the caller. As such, tests verify that that
 * this component returns null.
 */
const LoadingTreatmentLogger = ({
  identifier,
}: LoadingTreatmentLoggerProps) => {
  /**
   * Log how long we showed the loading treatment as well as which one it is
   */
  useEffect(() => {
    const start = Date.now();
    return () => {
      const end = Date.now();
      const duration = end - start;
      logger.info(
        `Showed loading treatment with identifier "${identifier}" for ${duration}ms`,
      );
    };
  }, [identifier]);

  return null;
};

export default LoadingTreatmentLogger;
