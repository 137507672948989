import { observer } from 'mobx-react-lite';

import { shortcutConfigs } from './shortcutsConfig';
import { useKeyboardShortcut } from './useKeyboardShortcut';

/**
 * This component exists solely to wire up keyboard shortcuts configured in
 * the shortcutsConfig file in this dir.

 */
const WatchShortcuts = () => {
  useKeyboardShortcut(shortcutConfigs.PlayPause);
  return null;
};

export default observer(WatchShortcuts);
