import * as React from 'react';

const ScreencastifyArrow = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{'Arrow White Center'}</title>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M119.266 128.376h129.23v143.152h-129.23z" />
      <path
        fill="#FFF"
        d="m236.621 170.335 58.677-33.568v126.997l-58.677-33.568z"
      />
      <path
        d="M25.103 128.375c-8.662 0-17.603 6.994-17.603 15.665v112.45c0 8.672 8.941 14.547 17.603 14.547h94.163V128.375H25.103ZM384.432 187.678l-88.854-51.19L141.34 47.533c-9.5-5.595-22.074 1.399-22.074 12.308v68.813h102.546c8.662 0 14.81 6.993 14.81 15.665v26.015l58.676-33.568v126.997l-58.677-33.568v26.854c0 8.672-6.147 14.546-14.809 14.546H119.266v69.093c0 10.91 12.574 17.623 22.074 12.308l154.238-88.674 88.854-51.19c9.5-6.434 9.5-20.14 0-25.455Z"
        fill="#FF8282"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default ScreencastifyArrow;
