import { VideoPair } from './useVideoPlayer';
import { useMst, VideoSourceModel } from '@castify/studio/studio-store';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import { st } from '@castify/studio/fe-common';
import { useThrottleCallback } from '@react-hook/throttle';
import {
  createBrowserLogger,
  IBrowserLogger,
} from '@castify/studio/observability/browser';
const logger: IBrowserLogger = createBrowserLogger('UseScrub');

/**
 * The max number of scrubs per second
 */
const MAX_SCRUB_FRAME_RATE = 10;

const useScrub = (scrubPair: VideoPair) => {
  const { playback } = useMst();
  const { player } = scrubPair;

  /**
   * This callback allows us to throttle scrubs, preventing the video player
   * from being overwhelmed by rapid scrub events.
   */
  const throttledScrubToTime = useThrottleCallback(
    (time: st.sec) => {
      if (!player) return;
      player.currentTime(time);
    },
    MAX_SCRUB_FRAME_RATE,
    true,
  );

  /**
   * This hook sets up an MST effect which is responsible for managing the
   * state of the scrub pair when the user interacts with the timeline or the
   * scrub state is changed via other means. The effect seeks to a new scrub
   * time when this changes, but can also load up a clip to scrub if the clip
   * changes.
   */
  useEffect(() => {
    const disposer = reaction(
      () => {
        return {
          scrubClip: playback.scrubClip,
          scrubClipTimeSec: playback.scrubClipTimeSec,
        };
      },
      async ({ scrubClip, scrubClipTimeSec }) => {
        /**
         * We destructure only once inside autorun in order to prevent
         * the Player component from subscribing to every value used here
         */
        const {
          isScrubManifestLoading,
          scrubPlayerManifestUrl,
          setIsScrubManifestLoading,
          setScrubPlayerManifestUrl,
        } = playback;

        /**
         * These prevent the effect from running prior to React setting up the
         * DOM for us, when no scrub is active, etc. (type guards, partly)
         */
        if (
          !player ||
          !scrubClip ||
          scrubClipTimeSec === undefined ||
          !VideoSourceModel.is(scrubClip.source)
        )
          return;

        const clipManifestUrl = scrubClip.source.scrubManifest;
        const rescrubThreshhold = 0.001; // protects against rounding errors

        /**
         * This path handles user-initiated scrubs after the initial clip load.
         */
        if (
          // is the video already loaded to this player?
          scrubPlayerManifestUrl === clipManifestUrl &&
          //we're not loading a manifest
          !isScrubManifestLoading &&
          // are we not already at or close to the latest scrub time?
          Math.abs(player.currentTime() - scrubClipTimeSec) > rescrubThreshhold
        ) {
          throttledScrubToTime(scrubClipTimeSec);
        }

        /**
         * Here we do the actual manifest load, but only when:
         * - we're dealing with a video clip
         * - the player is not already loading a manifest
         * - we have a time to scrub to
         * - we haven't already loaded up the manifest
         */
        if (
          VideoSourceModel.is(scrubClip.source) &&
          scrubClipTimeSec &&
          clipManifestUrl !== scrubPlayerManifestUrl &&
          !isScrubManifestLoading
        ) {
          try {
            logger.debug(`LOADING MANIFEST ${clipManifestUrl}`);

            setIsScrubManifestLoading(true);
            await scrubPair.loadManifest(clipManifestUrl);
            logger.debug(
              `MANIFEST LOAD COMPLETE; SCRUBBING TO ${scrubClipTimeSec}`,
            );
            setScrubPlayerManifestUrl(clipManifestUrl);
            throttledScrubToTime(scrubClipTimeSec);
          } catch (err) {
            if (err instanceof Error) {
              logger.error('Error fetching manifest:', { error: err });
            } else {
              logger.error(`Error fetching manifest: ${JSON.stringify(err)}`);
            }
            setScrubPlayerManifestUrl('');
          } finally {
            setIsScrubManifestLoading(false);
          }
        }
      },
    );
    return disposer;
  }, [playback, scrubPair, player, throttledScrubToTime]);
};
export default useScrub;
