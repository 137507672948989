import { ButtonTooltip } from '@castify/studio/fe-common';
import { useMst } from '@castify/studio/studio-store';
import { SerializedStyles } from '@emotion/react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { styles } from './buttons.styles';

interface LocalUploadButtonProps {
  /**
   * Allows overriding styles from the parent. Expects the same thing the
   * emotion CSS prop expects.
   */
  styleOverride?: SerializedStyles;
  /**
   * Allows caller to run callback in the onClick handler
   */
  afterClick?: () => void;
}

/**
 * A button that kicks off the local import flow. Responsible for showing the
 * local file picker and passing the selected file into MST to trigger the
 * upload flow.
 *
 * The picker is shown here as opposed to inside of the upload flow because
 * there is not a consistent cross-browser technique for detecting when the
 * <input/>-based file picker is closed without selecting a file (e.g. via the
 * cancel button). That is: the change event the input element fires does not
 * trigger on a cancel click.
 *
 * This means that if the picker is opened inside of a modal
 * it is easy to get stuck inside the modal flow forever if the picker is
 * cancelled-- as there's no callback to exit the flow on a cancel click.
 *
 * For this reason, cancellation goes unhandled (it _cannot_ be handled) and
 * does not trigger the flow, while file selection triggers the flow.
 *
 * (See here for some discussion: https://stackoverflow.com/questions/34855400/cancel-event-on-input-type-file. There are probably 50 posts on SO about this
 * going back years; none of the proposed solutions work consistently even in
 * the latest Chrome. In the future we can switch to the file system API, but
 * this API is not yet widely supported.)
 */
function LocalUploadButton({
  styleOverride,
  afterClick,
}: LocalUploadButtonProps) {
  const {
    ingress: { startLocalUploadFlow },
  } = useMst();

  /**
   * Creates/shows a file picker. Does not bother cleaning up the input
   * element in the cancel case as there is no reliable way to do so on cancel,
   * anyway.
   */
  const showFilePicker = () => {
    /**
     * Build the picker and mount to DOM
     */
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'video/*';
    inputElement.style.display = 'none';
    inputElement.setAttribute('data-testid', 'local-upload-input');
    document.body.appendChild(inputElement);

    /**
     * Handle selecting a file
     */
    const fileSelectHandler = () => {
      if (inputElement.files && inputElement.files.length) {
        startLocalUploadFlow(inputElement.files[0]);
        document.body.removeChild(inputElement);
      }
    };
    inputElement.addEventListener('change', fileSelectHandler);

    /**
     * Show the picker
     */
    inputElement.click();
  };

  return (
    <ButtonTooltip title="Upload Media From Your Device">
      <Button
        data-testid="local-upload-button"
        css={[styles.button, styleOverride]}
        startIcon={<CloudUploadIcon css={styles.icon} />}
        variant="white"
        onClick={() => {
          showFilePicker();
          if (afterClick) afterClick();
        }}
      >
        <Typography>Upload</Typography>
      </Button>
    </ButtonTooltip>
  );
}
export default observer(LocalUploadButton);
