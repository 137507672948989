import { useState } from 'react';

/**
 * Hook for tracking whether the pointer is inside a clip and deciding
 * whether the trim handles are visible. In React insteead of in MST because
 * whether the trim handles are shown is ephemeral state that does not need
 * to be serialized which no other part of the application needs to know about.
 */
export const useShowTrimHandles = (isSelected: boolean) => {
  /**
   * Tracks whether the pointer is in the clip
   */
  const [pointerInClip, setPointerInClip] = useState(false);

  /**
   * This will make handles stay visible when a clip is being edited, and also
   * enable usability of handles on mobile -- while keeping state out of MST
   * that is ephemeral view state no other part of the app depends on
   */
  const areHandlesVisible = isSelected || pointerInClip;

  return {
    setPointerInClip,
    areHandlesVisible,
  };
};
