import React from 'react';
import styles from './RedoButton.styles';
import RedoIcon from '@mui/icons-material/Redo';
import { Fab, Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import { ButtonTooltip } from '@castify/studio/fe-common';
import { getShortcutString, shortcutConfigs } from '@castify/studio/keyboard';

interface RedoButtonProps {
  showSkeleton?: boolean;
}

function RedoButton({ showSkeleton }: RedoButtonProps) {
  const {
    project: { undoManager },
  } = useMst();

  /**
   * Helper to ensure we can't call redo when
   * it is not possible to redo
   */
  const redo = () => {
    if (undoManager.canRedo) undoManager.redo();
  };
  if (showSkeleton)
    return <Skeleton variant="circular" height={40} width={40} />;

  return (
    <ButtonTooltip
      title={
        undoManager.canRedo
          ? `Redo (${getShortcutString(shortcutConfigs.Redo)})`
          : 'No Redos Available'
      }
    >
      <Fab
        css={styles.button}
        onClick={redo}
        disabled={!undoManager.canRedo}
        data-pendo-id="redo-button"
        color="info"
        aria-label="Redo Button"
      >
        <RedoIcon css={styles.icon} fontSize="inherit" />
      </Fab>
    </ButtonTooltip>
  );
}

export default observer(RedoButton);
