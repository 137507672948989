export const colors = {
  // Colors here are mapping to what MUI will eventually expect
  gray: {
    900: '#000000', // black
    800: '#262626',
    750: '#383838',
    700: '#434343',
    600: '#555555',
    500: '#7b7b7b',
    400: '#9d9d9d',
    300: '#c4c4c4',
    200: '#d9d9d9',
    100: '#e9e9e9',
    50: '#f5f5f5',
    0: '#ffffff', // white
  },
  /**
   * MUI Main Colors
   */
  primary: '#00AAFF', // Screencastify Blue
  primaryContrastText: '#ffffff',
  secondary: '#7952FF', // Screencastify Salmon
  error: '#D32F2F', // Dark Red
  warning: '#FFBC42', // Light Orange
  warningDark: '#DD7A1F', // Dark Orange
  info: '#2C2C2C',
  infoDark: '#EEEEEE',
  infoTextContrast: '#FFFFFF',
  success: '#3CCC6C', // Green
  successTextContrast: '#FFFFFF',

  /**
   * MUI Text Colors
   */
  textPrimary: '#2C2C2C',
  textSecondary: '#4F4F4F',
  actionActive: '#2C2C2C',

  successMain: '#00AAFF', // Light Blue
  ScreencastifySalmon: '#FF8282', // Screencastify Salmon
  selectedYellow: '#e4ea43',
  magenta: 'rgb(121, 82, 255)',
  magentaSeethru: 'rgba(121, 82, 255, 0.8)',
  green: 'rgb(15, 202, 78)',
  greenSeethru: 'rgba(15, 202, 78, 0.8)',
  red: 'rgb(222, 120, 120)',
  redBorder: 'rgb(255, 130, 130)',
  orangeBorder: `#e9730f`,
  blueBorder: `#7952ff`,
  pink: '#ff8282',

  // TODO: Swap these out with relevant Material UI colors once we settle on them
  TEMP: {
    HeaderBackground: 'white',
    PreviewBackground: '#FAFAFA',
    BorderGray: '#c5d2db',
    ButtonGray: '#c3cfd9',
    ScrollbarGray: '#f7f9fa',
    ZoomPurple: '#6558f5',
    ButtonBlue: '#00aaff',
    SliderYellow: '#ffb400',
  },
};
