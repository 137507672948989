import { css } from '@emotion/react';

const styles = {
  mobxDebuggerPane: css`
    position: fixed;
    width: 400px;
    height: 400px;
    top: 80px;
    right: 0px;
    overflow-y: auto;
    overflow-wrap: break-word;
    background: white;
    border: 2px solid grey;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px 0 0;
    z-index: +1500;
    overflow-x: auto;
    word-wrap: break-word;
  `,
};

export default styles;
