import Tooltip from '@mui/material/Tooltip';

type ButtonTooltipProps = {
  /**
   * As child, pass the component to decorate with a hoverable tooltip
   */
  children: React.ReactNode;
  /**
   * Message to show
   */
  title: string;
};

/**
 * A light abstraction over MUI's tooltip element.
 *
 * The MUI tooltip API is excellent and generally can be used directly
 * without a wrapper like this-- this component exists becuase of a
 * single weakness in the API: tooltips by default cannot open when
 * directly wrapping buttons that are disabled, where we often need
 * tooltips to open on disabled buttons.
 *
 * MUI's suggested workaround is to wrap the child in a <span />.
 * (Internally MUI queries the direct child's enablement state, so
 * wrapping the child breaks this mechanism and the tooltip always
 * shows. We abstract away this extra <span> here to make us less likely
 * to accidentally break this mechanism.
 *
 * {@link https://mui.com/components/tooltips/#disabled-elements}
 *
 */
const ButtonTooltip = ({ children, title }: ButtonTooltipProps) => {
  return (
    <Tooltip role="tooltip" title={title}>
      {/* This span allows tooltip to show on disabled buttons; see JSDOC for this component */}
      <span>{children}</span>
    </Tooltip>
  );
};

export default ButtonTooltip;
