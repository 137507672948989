import { useMst } from '@castify/studio/studio-store';
import { RefObject, useEffect } from 'react';
import { useOnWindowResize } from 'rooks';

/**
 * Effect which subscribes MST store to window size; useful for calculating
 * how much timeline is viewable
 * @param elRef - Ref to the container element of the Timeline
 */
export const useSyncWindowSize = (elRef: RefObject<HTMLElement>) => {
  const {
    timeline,
    project: { isLoaded },
  } = useMst();
  const setRefDims = () => {
    if (elRef.current) {
      const { x, width } = elRef.current.getBoundingClientRect();
      timeline.setElementPosition({
        left: x,
        width: width,
      });
    }
  };
  /*
   * Subscribe the store to the width of the window; intended to help
   * the timeline model determine how much time should be visible.
   */
  useOnWindowResize(setRefDims);

  /**
   * Get the window width into MST once on component mount
   */
  useEffect(setRefDims, [timeline, elRef, isLoaded]);
};
