import { observer } from 'mobx-react-lite';
import Overlays from '../Overlays/Overlays';
import { useVideoPairs } from '../PlaybackSystem/VideoPairContext';
import { useBlurCanvas } from '../playerHooks/useBlurCanvas';
import useCompositor from '../playerHooks/useCompositor';
import useScaledCanvas from '../playerHooks/useScaledCanvas';
import styles from './Player.styles';

interface PlayerProps {
  showSkeleton?: boolean;
}

const Player = ({ showSkeleton }: PlayerProps) => {
  const { pairA, pairB, scrubPair } = useVideoPairs();

  /**
   * Set up and scale a canvas displayed to the user
   */
  const { canvasRef, canvasContainerRef, ctx } = useScaledCanvas();

  /**
   * Set up an offscreen canvas for use with blur
   * Created outside the compositor so we can keep a ref across
   * callbacks
   */
  const { blurCanvasRef, blurCtx } = useBlurCanvas();

  /**
   * Set up the system which composites images and effects to the canvas
   */
  useCompositor({
    ctx,
    canvasRef,
    scrubPair,
    pairA,
    pairB,
    blurCanvasRef,
    blurCtx,
  });

  return (
    <div data-testid="player-outer" css={styles.playerOuter}>
      {/* The canvas is manually sized to fit inside this div */}
      {
        <div
          id="canvas-container"
          css={styles.canvasContainer}
          ref={canvasContainerRef}
        >
          {/* The canvas onto which we project video from the video element */}
          <canvas
            id="player-canvas"
            css={styles.canvas}
            style={{ display: showSkeleton ? 'hidden' : 'inherit' }}
            ref={canvasRef}
          ></canvas>

          {/* Overlays that appear on top of canvas when tools are open */}
          <Overlays />
        </div>
      }
    </div>
  );
};

export default observer(Player);
