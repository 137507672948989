import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  container: css`
    position: absolute;
    z-index: 2;
  `,
  containerBox: css`
    position: relative;
    height: 40px;
    justify-content: space-around;
    border-radius: 5px 5px 5px 0;
    border: 3px solid ${colors.redBorder};
    background-color: ${colors.red};
  `,
  textContainer: css`
    display: flex;
    overflow: hidden;
  `,
  text: css`
    padding: 0 7px;
    overflow: hidden;
    margin: auto;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.gray['0']};
  `,
  anchor: css`
    position: absolute;
    box-sizing: border-box;
    left: 0;
    height: 15px;
    width: 9px;
    max-width: 100%;
    margin-top: -3px;
    border: 3px solid ${colors.redBorder};
    background-color: ${colors.red};
    border-top: 0;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
  `,
};

export default styles;
