import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './ZoomController.styles';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { useMst } from '@castify/studio/studio-store';
import { ButtonTooltip } from '@castify/studio/fe-common';
import { getShortcutString, shortcutConfigs } from '@castify/studio/keyboard';
import Skeleton from '@mui/material/Skeleton';

interface ZoomControllerProps {
  showSkeleton?: boolean;
}

function ZoomController({ showSkeleton }: ZoomControllerProps) {
  const mst = useMst();
  const {
    timeline: {
      zoom: {
        scaledZoomFactor,
        canZoomOut,
        canZoomIn,
        jumpTimelineZoomBackwards,
        jumpTimelineZoomForward,
        handleZoomFactorChange,
      },
    },
  } = mst;

  if (showSkeleton)
    return <Skeleton variant="rectangular" width={250} height={35} />;

  return (
    <div css={styles.zoomSlider}>
      <ButtonTooltip
        title={
          canZoomOut
            ? `Zoom Out (${getShortcutString(shortcutConfigs.TimelineZoomOut)})`
            : "Can't Zoom Out Any Further"
        }
      >
        <Button
          variant="text"
          color="secondary"
          style={{
            opacity: canZoomOut ? 1 : 0.5,
          }}
          disabled={!canZoomOut}
          onClick={jumpTimelineZoomBackwards}
          aria-label="Zoom Out Button"
          data-pendo-id="zoom-out-button"
        >
          <Icon>remove</Icon>
        </Button>
      </ButtonTooltip>

      <Slider
        min={0}
        value={scaledZoomFactor}
        data-pendo-id="zoom-slider"
        max={1}
        step={0.001}
        onChange={handleZoomFactorChange}
        aria-label="Zoom Slider"
      />

      <ButtonTooltip
        title={
          canZoomIn
            ? `Zoom In (${getShortcutString(shortcutConfigs.TimelineZoomIn)})`
            : "Can't Zoom In Any Further"
        }
      >
        <Button
          variant="text"
          style={{
            opacity: canZoomIn ? 1 : 0.5,
          }}
          data-pendo-id="zoom-in-button"
          disabled={!canZoomIn}
          onClick={jumpTimelineZoomForward}
          aria-label="Zoom In Button"
        >
          <Icon>add</Icon>
        </Button>
      </ButtonTooltip>
    </div>
  );
}

export default observer(ZoomController);
