import React from 'react';
import { IRoot } from './root.mst';

/**
 * The slightly awakward injection setup in place here exists becase we need to
 * both:
 * 1. Put the MST provider and context hook in an NX library along with the
 * rest of the code it makes use of
 * 2. Inject environment-specific dependencies and an initial state to
 * provider/hook combination which are only available in the deployed app
 *
 * In the main.tsx or app.tsx file where the provider is first used, it is
 * necessary to do something like this:
 *
 * @example
 * injectInitialState(
 *  createInitialState({
 *     logger: environmentSpecificLogger
 *   }),
 * );
 *
 */

/**
 * Instantiates the editor's MST store as a singleton, injecting
 * a logger as a dependency.
 */
let stateTreeRoot: undefined | IRoot = undefined;

/**
 * Allows replacing this singleton with a store that has any environment-
 * specific dependencies injected into it
 */
export const injectInitialState = (state: IRoot) => {
  stateTreeRoot = state;
};

/**
 * This shared context, closed over by both the MST provider and useMst hook,
 * allows these to share the singleton store.
 */
const MstContext = React.createContext<IRoot | undefined>(stateTreeRoot);

/**
 * Allows the caller to access MST nodes in pure functional React components--
 * provided those components are also wrapped in `observer()` from
 * `mobx-react-lite`
 */
export const useMst = (): IRoot => {
  const mst = React.useContext(MstContext);
  if (!mst)
    throw new Error(
      'Called `useStore` either without a provider or without given initial state to the provider. You probably need to put an `injectInitialState` call in your `main.tsx`',
    );
  return mst;
};

/**
 * When a React component is wrapped in this, we can use the useMst hook to
 * access the store inside of pure functional components-- provided those
 * components are also wrapped in `observer()` from `mobx-react-lite`
 */
export const MstProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  return (
    <MstContext.Provider value={stateTreeRoot}>{children}</MstContext.Provider>
  );
};
