import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styles } from './FlowDialog.styles';
import { environment } from '@castify/studio/env/browser';
import { safeWindowOpen } from '@castify/safe-window-open';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import type { StudioParams } from '@castify/studio/fe-common';

/**
 * Error dialog props
 */
export interface ErrorDialogProps {
  /**
   * Invoked when the error dialog is closed
   */
  onErrorDialogClose: () => void;
  /**
   * Video ID
   */
  videoSourceUuid: string | undefined;
}

/**
 * Shared component which signals to the user that there was an error during
 * an ingress flow
 */
function ErrorDialog({
  onErrorDialogClose,
  videoSourceUuid,
}: ErrorDialogProps) {
  const { projectId } = useParams<StudioParams>();

  return (
    <Dialog
      open={true}
      onBackdropClick={onErrorDialogClose}
      // disable transition to prevent white flashes between modals
      transitionDuration={0}
    >
      <DialogTitle css={styles.title} data-testid="modal-title">
        Oops
        <IconButton onClick={onErrorDialogClose} data-testid="modal-close">
          <CloseIcon></CloseIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent css={styles.content}>
        <DialogContentText data-testid="modal-text">
          Import failed due to a system error. Please try again to see if this
          was a temporary issue; otherwise contact support.
        </DialogContentText>
        <DialogContentText>Project ID: {projectId}</DialogContentText>
        <DialogContentText>
          Video ID: {videoSourceUuid ? videoSourceUuid : 'undefined'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="modal-support-link"
          onClick={() => safeWindowOpen(environment.externalLinks.supportLink)}
        >
          Contact Support
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
