import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import TextClip from './TextClip/TextClip';
import styles from './TextTrack.styles';

function TextTrack() {
  const {
    timeline: { visibleStart, visibleDuration },
    project: {
      scene: { textTrack },
    },
  } = useMst();

  const textClips = textTrack.getVisibleTextClipsInRange(
    visibleStart,
    visibleStart + visibleDuration,
  );

  return (
    <div css={styles.container}>
      {textClips.map((clip) => (
        <TextClip key={clip.uuid} clip={clip} />
      ))}
    </div>
  );
}

export default observer(TextTrack);
