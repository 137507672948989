import AutoAssignDomain from './AutoAssignDomain/AutoAssignDomain';
import InvitationContainer from './Invitations/InvitationContainer/InvitationContainer';

const Account = () => {
  return (
    <div data-testid="account-page">
      <h2>My Account</h2>
      <AutoAssignDomain />
      <InvitationContainer />
    </div>
  );
};

export default Account;
