import { css } from '@emotion/react';

const styles = {
  appContainer: (
    hasAudio: boolean,
    hasText: boolean,
    isAnyToolOpen: boolean,
  ) => {
    let extraTimelineHeight = 0;
    if (hasAudio) extraTimelineHeight += 45;
    if (hasText) extraTimelineHeight += 45;

    const defaultTimelineHeight = 197;
    const timelineHeight = defaultTimelineHeight + extraTimelineHeight;

    return css`
      width: 100%;
      height: 100vh;

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: 80px minmax(0, 1fr) 60px ${timelineHeight}px 50px;
      grid-template-areas:
        'header header'
        'preview ${isAnyToolOpen ? 'rightSidebar' : 'preview'}'
        'toolbar toolbar'
        'timeline timeline'
        'footer footer';
    `;
  },
  header: css`
    grid-area: header;
  `,
  preview: css`
    grid-area: preview;
  `,
  rightSidebar: (isAnyToolOpen: boolean) => css`
    grid-area: rightSidebar;
    display: ${isAnyToolOpen ? `block` : `none`};
  `,
  toolbar: css`
    grid-area: toolbar;
  `,
  footer: css`
    grid-area: footer;
  `,
  playButton: css`
    grid-area: playButton;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiButton-root {
      border-radius: 100px;
      max-width: 60px;
      min-width: 60px;
      max-height: 60px;
      min-height: 60px;
    }
  `,
  timeline: css`
    grid-area: timeline;
    padding: 4px;
  `,
  tempElements: css`
    margin-top: 30px;
  `,
};
export default styles;
