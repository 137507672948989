import React from 'react';
import {
  getSnapshot,
  onSnapshot,
  SnapshotOut,
  IStateTreeNode,
} from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import styles from './MstDebuggerPane.styles';

/**
 * A helper to prettify a state snapshot
 */
const formatSnapshot = (snapshot: SnapshotOut<IStateTreeNode>): string =>
  JSON.stringify(snapshot, null, 2);

type MstDebuggerProps = {
  nodeToDebug: IStateTreeNode;
};

/**
 * This component displays a serialized representation of a node (root or
 * otherwise) of an MST store.
 */
function MstDebuggerPane({ nodeToDebug }: MstDebuggerProps) {
  /**
   * Component state to store the latest formatted snapshot of the store
   */
  const [formattedSnapshot, setFormattedSnapshot] = React.useState('');

  /**
   * On component mount, set up a listener to update the component state
   * when the MST tree changes, grabbing an initial snapshot to start
   * things off. Also dispose the snapshot listener on unmount to prevent
   * memory leaks.
   */
  React.useEffect(() => {
    const disposer = onSnapshot(nodeToDebug, (snapshot) => {
      setFormattedSnapshot(formatSnapshot(snapshot));
    });
    const initial = formatSnapshot(getSnapshot(nodeToDebug));
    setFormattedSnapshot(initial);
    return disposer;
  }, [nodeToDebug]);

  return (
    <div css={styles.mobxDebuggerPane}>
      <pre>{formattedSnapshot}</pre>
    </div>
  );
}

export default observer(MstDebuggerPane);
