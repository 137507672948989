import {
  BrowserLogger,
  addErrorHandlerMiddleware,
} from '@castify/studio/observability/browser';
import { SnapshotIn } from 'mobx-state-tree';
import RootModel, { IRoot } from './root.mst';
import makeInspectable from 'mobx-devtools-mst';

/**
 * Intended to be passed into Root() on app mount to create the root MST store.
 * Also useful for creating an empty root edit store against which to run tests.
 */
export const initialState: SnapshotIn<typeof RootModel> = {};
/**
 * leave this Env for now in case we need to inject logger later in mst,
 * right now, we are importing the browser logger singleton directly in files
 */
interface IStateEnvironment {
  browserLogger?: Console | BrowserLogger;
}

/**
 * Returns an instance of the root store deserialized from initial state
 * defined above.
 *
 * @param env - Environment-specific variables to be injected into the tree
 */
export const createInitialState = (env?: IStateEnvironment): IRoot => {
  const root = RootModel.create(initialState, env);
  makeInspectable(root);
  addErrorHandlerMiddleware(root);

  return root;
};
