import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  title: css`
    color: ${colors.gray[0]};
    font-size: 48px;
    font-weight: bold;
    user-select: none;
  `,
  smallFont: css`
    font-size: 32px;
  `,
};
export default styles;
