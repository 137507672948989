import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Playhead.styles';
import { useMst } from '@castify/studio/studio-store';

function Playhead() {
  const {
    timeline: { playheadPosition },
  } = useMst();

  return (
    <div
      id="playhead"
      css={styles.playhead}
      style={{
        // minus one as it is two pixels wide,
        // want center to be directly over current frame
        left: `${playheadPosition - 1}px`,
      }}
    ></div>
  );
}

export default observer(Playhead);
