import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './SimpleToolbar.styles';
import PlaybackController from './PlaybackController/PlaybackController';
import RedoButton from './RedoButton/RedoButton';
import UndoButton from './UndoButton/UndoButton';
import CheckIcon from '@mui/icons-material/Check';
import CutButton from './CutButton/CutButton';
import CloseSimpleEditButton from './CloseSimpleEditButton/CloseSimpleEditButton';
import { Button } from '@mui/material';
import sharedButtonStyles from './sharedButtonStyles';
import DeleteClipButton from './DeleteClipButton/DeleteClipButton';
interface SimpleToolbarProps {
  toggleSimpleEdit: () => void;
}

function SimpleToolbarComponent({ toggleSimpleEdit }: SimpleToolbarProps) {
  return (
    <div css={styles.toolbarContainer}>
      <div css={styles.toolbarLeft}>
        <PlaybackController />
      </div>
      <div css={styles.toolbarRight}>
        <DeleteClipButton />
        <CutButton />
        <UndoButton />
        <RedoButton />
        <CloseSimpleEditButton toggleSimpleEdit={toggleSimpleEdit} />
      </div>
    </div>
  );
}

export default observer(SimpleToolbarComponent);
