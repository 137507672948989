import { observer } from 'mobx-react-lite';
import styles from './SimpleEditView.styles';
import Player from '@castify/studio/Player';
import { SimpleToolbar } from '@castify/studio/Toolbar';
import Timeline from '@castify/studio/Timeline';
import { SimpleEditShortcuts } from '@castify/studio/keyboard';
import { useMst } from '@castify/studio/studio-store';
import ZoomController from 'lib/studio/components/toolbar/src/lib/ZoomController/ZoomController';
interface SimpleEditView {
  toggleSimpleEdit: () => void;
}

export function SimpleEditView({ toggleSimpleEdit }: SimpleEditView) {
  const {
    timeline: { hasText, hasAudio },
  } = useMst();

  /**
   * Render the editor itself
   */
  return (
    <>
      <SimpleEditShortcuts />
      <div
        className="App"
        data-testid="editor-app"
        css={styles.appContainer(hasAudio, hasText)}
      >
        <div role="region" aria-label="Video Player" css={styles.preview}>
          <Player />
        </div>

        <div role="region" aria-label="Editor Toolbar" css={styles.toolbar}>
          <SimpleToolbar toggleSimpleEdit={toggleSimpleEdit} />
        </div>

        <div role="region" aria-label="Editor Timeline" css={styles.timeline}>
          <Timeline />
        </div>
        <div role="region" aria-label="Editor Footer" css={styles.footer}>
          <ZoomController />
        </div>
      </div>
    </>
  );
}

export default observer(SimpleEditView);
