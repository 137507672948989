import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import RootModel from '../root.mst';

const ViewerModel = types
  .model('ViewerModel', {
    /**
     * The userIdentifier is set in the auth logic.
     */
    userIdentifier: types.optional(types.string, ''),
    /**
     * If the user is logged in, the displayName from their profile is
     * used. Otherwise, it is currently set to automatically default
     * to 'Anonymous User'. When the nickname option is implemented, this
     * will also be where that nickname is kept in state.
     */
    displayName: types.optional(types.string, 'Anonymous Viewer'),
  })
  .actions((self) => ({
    updateUserIdentifier(userIdentifier: string) {
      self.userIdentifier = userIdentifier;
    },
    updateDisplayName(displayName: string) {
      self.displayName = displayName;
    },
  }));
export default ViewerModel;

export function getViewer(self: IAnyStateTreeNode): IViewerInfo {
  const root = getParentOfType(self, RootModel);
  return root.viewer;
}

export interface IViewerInfo extends Instance<typeof ViewerModel> {}
