import {
  Icon,
  IconButton,
  FormControl,
  NativeSelect,
  Select,
  MenuItem,
  TextField,
  Button,
  Tabs,
  Tab,
  Slider,
} from '@mui/material';
import { useState } from 'react';
import styles from './TextSidebarContent.styles';
import sidebarCommon from './sidebarCommon.styles';

const COLOR_CHOICES: Array<string> = [
  '#000000',
  '#575757',
  '#B4AFAF',
  '#00D1FF',
  '#0066cc',
  '#022069',
  '#AF7302',
  '#EA6503',
  '#ff9900',
  '#ffff00',
  '#92FE00',
  '#008a00',
  '#096305',
  '#e60000',
  '#FF00D8',
  '#9933ff',
  '#440178',
  '#ffffff',
];

const noElement = () => {
  return <div></div>;
};

enum TabNames {
  none = 'none',
  text = 'text',
  border = 'border',
  background = 'background',
}

/**
 * The crop tool's right sidebar
 */
function TextSidebarContent() {
  const [curr, setCurr] = useState(10);
  const [selectedTabName, setSelectedTabName] = useState(TabNames.none);

  const handleTabClick = (tabName: TabNames) => {
    if (selectedTabName === tabName) setSelectedTabName(TabNames.none);
    else setSelectedTabName(tabName);
  };

  return (
    <div>
      <div css={styles.iconContainer}>
        <IconButton>
          <Icon style={{ color: 'white' }}>format_bold</Icon>
        </IconButton>
        <IconButton>
          <Icon style={{ color: 'white' }}>format_italic</Icon>
        </IconButton>
        <IconButton>
          <Icon style={{ color: 'white' }}>format_underline</Icon>
        </IconButton>
        <IconButton>
          <div css={styles.selectContainer}>
            <Select
              IconComponent={noElement}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={curr}
              onChange={(e) => {
                setCurr(e.target.value as number);
              }}
            >
              <MenuItem value={10}>
                <Icon>format_align_left</Icon>
              </MenuItem>
              <MenuItem value={20}>
                <Icon>format_align_center</Icon>
              </MenuItem>
              <MenuItem value={30}>
                <Icon>format_align_right</Icon>
              </MenuItem>
            </Select>
          </div>
        </IconButton>
        <IconButton>
          <div css={styles.selectContainer}>
            <Select
              IconComponent={noElement}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={curr}
              onChange={(e) => {
                setCurr(e.target.value as number);
              }}
            >
              <MenuItem value={10}>
                <Icon>vertical_align_top</Icon>
              </MenuItem>
              <MenuItem value={20}>
                <Icon>vertical_align_center</Icon>
              </MenuItem>
              <MenuItem value={30}>
                <Icon>vertical_align_bottom</Icon>
              </MenuItem>
            </Select>
          </div>
        </IconButton>
      </div>

      <div css={styles.textContainer}>
        <div css={styles.TextInputStyle}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={10}
              onChange={() => {}}
            >
              <MenuItem value={10}>Roboto</MenuItem>
              <MenuItem value={20}>Helvetica</MenuItem>
              <MenuItem value={30}>Times New Roman</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div css={styles.NumberInputStyle}>
          <TextField
            type="number"
            value={10}
            variant="outlined"
            color="primary"
            inputProps={{
              maxLength: 13,
              step: '0.1',
            }}
            onChange={(e) => {}}
          />
        </div>
      </div>

      <div css={styles.sliderContainer}>
        <p
          style={{
            fontSize: '14px',
          }}
        >
          Aa
        </p>

        <Slider
          min={0}
          value={0.5}
          max={1}
          step={0.001}
          onChange={() => {
            console.log('Update Slider');
          }}
        />

        <p
          style={{
            fontSize: '20px',
          }}
        >
          Aa
        </p>
      </div>

      <div css={styles.divider}></div>

      <div>
        <div css={styles.tabsContainer}>
          <div
            className={
              selectedTabName === TabNames.text ? 'tab selected' : 'tab'
            }
            onPointerDown={() => handleTabClick(TabNames.text)}
          >
            <div
              css={[styles.swatchIndicator]}
              style={{ background: 'red' }}
            ></div>
            <Icon>format_color_text</Icon>
          </div>

          <div
            className={
              selectedTabName === TabNames.background ? 'tab selected' : 'tab'
            }
            onPointerDown={() => handleTabClick(TabNames.background)}
          >
            <div
              css={styles.swatchIndicator}
              style={{ background: 'red' }}
            ></div>
            <Icon>format_color_fill</Icon>
          </div>

          <div
            className={
              selectedTabName === TabNames.border ? 'tab selected' : 'tab'
            }
            onPointerDown={() => handleTabClick(TabNames.border)}
          >
            <div
              css={styles.swatchIndicator}
              style={{ background: 'red' }}
            ></div>
            <Icon>border_style</Icon>
          </div>
        </div>

        {/* TODO: Add ability to select specific color and update MST here, copy selected logic from StillClipSidebar */}
        {selectedTabName !== 'none' && (
          <div css={styles.swatchContainer}>
            {COLOR_CHOICES.map((color: string) => (
              <button
                key={color}
                css={sidebarCommon.swatch}
                style={{
                  background: color,
                }}
                onClick={() => {
                  console.log('Color Clicked: ', color);
                }}
              />
            ))}
            {selectedTabName !== 'text' && (
              <button
                css={sidebarCommon.swatch}
                style={{
                  background: 'none',
                  border: 'red solid 1px',
                }}
                onClick={() => {
                  console.log('Color Clicked: None');
                }}
              />
            )}
          </div>
        )}
      </div>

      <div css={styles.divider}></div>

      <div css={styles.layoutContainer}>
        <IconButton>
          <Icon style={{ color: 'white' }}>format_underline</Icon>
          <p>Move To Front</p>
        </IconButton>
        <IconButton>
          <Icon style={{ color: 'white' }}>format_underline</Icon>
          <p>Move to Back</p>
        </IconButton>
      </div>
    </div>
  );
}

export default TextSidebarContent;
