import { Instance, types } from 'mobx-state-tree';
import TextClipModel, { ITextClip } from './textClip.mst';
import {
  enforcePointBoundriesInTrack,
  getClipsInRange,
} from './helpers/clipHelpers';
import { st } from '@castify/studio/fe-common';
import TextBoxModel from './textBox.mst';
import { getScene } from './scene.mst';
import { getPlayback } from '../../playback/playback.mst';
import { duplicateTextClip } from './modelUtils';

const EFFECT_DEFAULT_DURATION = 1000;

const TextTrackModel = types
  .model('TextTrackModel', {
    textClips: types.optional(types.array(TextClipModel), []),
  })
  .views((self) => ({
    /**
     * Grabs the text clips visible within the range
     * And filter out all the clips that are outbound
     */
    getVisibleTextClipsInRange(start: st.ms, end: st.ms): Array<ITextClip> {
      const clips = getClipsInRange(
        self.textClips,
        start,
        end,
      ) as Array<ITextClip>;

      return clips.filter((t) => t.isVisible);
    },
    /**
     * Returns the text track clip at a time, if any
     */
    getTextClipAtTime(time: st.ms): ITextClip | undefined {
      return self.textClips.find(
        (clip) => time >= clip.start && time < clip.end,
      );
    },
    /**
     * Gets the next clip by the current clip id
     */
    getNextTextTrackClipById(clipId: string): ITextClip | undefined {
      const index = self.textClips.findIndex((clip) => clip.uuid === clipId);
      // If there is no following clip, return early
      if (index === self.textClips.length - 1) return;
      return self.textClips[index + 1];
    },
    getPreviousTextTrackClipById(clipId: string): ITextClip | undefined {
      const index = self.textClips.findIndex((clip) => clip.uuid === clipId);
      // If there is no previous clip, return early
      if (index <= 0) return;
      return self.textClips[index - 1];
    },
  }))
  .actions((self) => ({
    /**
     * Add a new text clip to the scene based on params passed in by the caller.
     */
    addTextClipAtTime(time: st.ms): ITextClip {
      let startTime = time;
      let endTime = time + EFFECT_DEFAULT_DURATION;

      const scene = getScene(self);

      // recalculating start/end after checking if they overlap next/prev clips or
      // if end exceeds the timeline
      [startTime, endTime] = enforcePointBoundriesInTrack(
        startTime,
        endTime,
        0,
        scene.mainTrack.totalDuration,
        self.textClips,
      );

      const startClip = scene.mainTrack.getMainTrackClipAtTime(startTime);
      const endClip = scene.mainTrack.getMainTrackClipAtTime(endTime);

      if (!startClip || !endClip) {
        throw new Error("Couldn't find clips that text can attach to");
      }

      const startClipSourceOffset =
        startClip.getSourceOffsetFromTimestamp(startTime);
      const endClipSourceOffset = endClip.getSourceOffsetFromTimestamp(endTime);

      if (
        startClipSourceOffset === undefined ||
        endClipSourceOffset === undefined
      ) {
        throw new Error('pointInTime is outside of clip range');
      }

      const textClip = TextClipModel.create({
        startClip: startClip.uuid,
        endClip: endClip.uuid,
        startClipSourceOffset,
        endClipSourceOffset,

        // CREATE INITIAL TEXT CLIP
        boxes: [TextBoxModel.create()],
      });

      // Add to the text clips array and sort chronologically
      self.textClips.push(textClip);
      self.textClips.sort((a, b) => a.start - b.start);
      return textClip;
    },
    /**
     * Add a new clip to the scene based on params passed in by the caller.
     */
    addTextClipAfterOtherClip(
      textClipInTimeline: ITextClip,
      textClipToAdd: ITextClip,
    ): void {
      const placeToInsert = self.textClips.findIndex(
        (currClip) => currClip.uuid === textClipInTimeline.uuid,
      );
      self.textClips.splice(placeToInsert, 0, textClipToAdd);
    },
    /**
     * Cuts a given text clip into two seperate text clips, one that ends at our playhead and another that begins at our playhead
     */
    cutTextClipAtPlayhead() {
      const { playheadPosition } = getPlayback(self);
      const clip = self.getTextClipAtTime(playheadPosition);

      if (!clip) throw new Error('No text clip stored at playhead :(');

      // [..., newClip, clip, ...]
      const newClip = duplicateTextClip(clip);
      this.addTextClipAfterOtherClip(clip, newClip);

      // Shorten our first TextClip
      newClip.trimFromEnd(playheadPosition);
      // Shorten our second TextClip
      clip.trimFromStart(playheadPosition);
    },
  }));

export default TextTrackModel;
export interface ITextTrackModel extends Instance<typeof TextTrackModel> {}
