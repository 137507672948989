export { verifyProbeOutput } from './lib/video-probe-data-typeguard';

export {
  getVideoStream,
  getVideoHeight,
  getVideoWidth,
  getAudioStream,
  hasVideo,
  hasAudio,
  getDuration,
  supportsMediumEncoding,
  supportsHighEncoding,
  getAspectRatio,
  getVideoSize,
  scaleToFitHeight,
  getSizeLow,
  getSizeMedium,
  getSizeHigh,
  hasStream,
} from './lib/video-probe-data-selectors';

export type {
  ProbeData,
  StreamKeys,
  AudioStreamKeys,
  VideoStreamKeys,
  AudioProbeStream,
  VideoProbeStream,
  AnyProbeStream,
} from './lib/video-probe-data-schema';
