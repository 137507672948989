import { StudioParams } from '@castify/studio/fe-common';
import { useMst } from '@castify/studio/studio-store';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchPublishedProject } from './useFetchPublishedProject';

/**
 * This hook encapsulates data loading and redirection concerns related to
 * the watch page, specifically, as it queries against views specific to the
 * watch page.
 */
export const useWatchPageData = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const projectId = useParams<StudioParams>().projectId!;
  const { project } = useMst();
  const navigate = useNavigate();

  /**
   * On page load, we need tell MST the ID of the project we are using.
   */
  useEffect(() => {
    project.setProjectId(projectId);
  }, [projectId, project]);

  /**
   * This hook concerned with fetching the project scene from the DB
   */
  const { data, fetchingError } = useFetchPublishedProject({
    projectId,
  });

  /**
   * Redirect to a 404 page if there is no project or if the projectId
   * is an invalid param
   */
  useEffect(() => {
    if (data?.published_projects.length === 0 || fetchingError?.graphQLErrors) {
      navigate('/video-not-found', { replace: true });
    }
  }, [data, fetchingError, navigate]);
};
