import {
  MarkRefreshTokenExpiredMutation,
  MarkRefreshTokenExpiredMutationVariables,
  MarkRefreshTokenExpiredDocument,
} from '@castify/studio/database-clients/graphql';
import { Client } from 'urql';

export const expireRefreshToken = async (client: Client) => {
  return client
    .mutation<
      MarkRefreshTokenExpiredMutation,
      MarkRefreshTokenExpiredMutationVariables
    >(MarkRefreshTokenExpiredDocument)
    .toPromise();
};
