import styles from './EditFooter.styles';
import { observer } from 'mobx-react-lite';
import type { StudioParams } from '@castify/studio/fe-common';
import { Link, useParams } from 'react-router-dom';
import { Button, Skeleton, Typography } from '@mui/material';
import ZoomController from 'lib/studio/components/toolbar/src/lib/ZoomController/ZoomController';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface EditFooterProps {
  showSkeleton?: boolean;
}
export function EditFooter({ showSkeleton }: EditFooterProps) {
  const { projectId } = useParams<StudioParams>();

  return (
    <div css={styles.container}>
      <ZoomController showSkeleton={showSkeleton} />
      {showSkeleton ? (
        <Skeleton variant="rectangular" width={150} height={35} />
      ) : (
        <Link
          to={`/preview/${projectId}`}
          data-pendo-id="edit-link-to-preview"
          style={{ textDecoration: 'none' }}
        >
          <Button color="secondary" variant="text">
            <Typography css={styles.font}>Hide Timeline</Typography>
            <VisibilityOffIcon fontSize="inherit" />
          </Button>
        </Link>
      )}
    </div>
  );
}

export default observer(EditFooter);
