// Logging
export { BrowserLogger, createBrowserLogger } from './lib/browser-logger';

// DataDog Rum
export {
  DataDogRum,
  SetAllUsersRumStrategy,
  SetNoUserRumStrategy,
  getDatadogRumStrategy,
} from './lib/dd-rum';

// Error handling
export { ErrorFallback, componentErrorHandler } from './lib/error-boundary';
export { addErrorHandlerMiddleware } from './lib/mst-error-handling-middleware';
export { OnUnhandledRejection } from './lib/on-unhandled-rejection';

// types
export type { IBrowserLogger } from './lib/browser-logger';
export type { IRumStrategy } from './lib/dd-rum';
