import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IAnyStateTreeNode } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import styles from './DebugPanel.styles';

type DebugMenuProps = {
  setOpenedPane: React.Dispatch<React.SetStateAction<'mst' | null>>;
  setNodeToDebug: React.Dispatch<
    React.SetStateAction<IAnyStateTreeNode | null>
  >;
  nodeToDebug: IAnyStateTreeNode | null;
};

/**
 * This component displays a small pane of dev-facing buttons.
 */
export function DebugMenu({
  setOpenedPane,
  setNodeToDebug,
  nodeToDebug,
}: DebugMenuProps) {
  const { pathname } = useLocation();
  const mst = useMst();
  const {
    project: { scene },
    debugger: { toggleDebugMode },
  } = mst;

  const [buttonsExpanded, setButtonsExpanded] = useState(false);
  /**
   * Handler which allows switching the open debugger to a new node
   * if already open but also closing the debugger
   */
  const toggleMSTViewer = (node: IAnyStateTreeNode) => {
    // close if already open
    if (node === nodeToDebug) {
      setOpenedPane(null);
      setNodeToDebug(null);
    }
    // open the debugger
    else {
      setOpenedPane('mst');
      setNodeToDebug(node);
    }
  };

  return (
    <div
      role="region"
      aria-label="DebuggerMenu"
      onPointerLeave={() => setButtonsExpanded(false)}
    >
      <div css={styles.debuggerButtons}>
        <div
          css={styles.debuggerHover}
          onPointerEnter={() => setButtonsExpanded(true)}
        >
          debug ⬇
        </div>
        {buttonsExpanded && (
          <>
            {/* Show these only on the editor */}
            {(pathname.startsWith('/edit') ||
              pathname.startsWith('/preview')) && (
              <button onClick={() => scene.resetScene()}>Clear Scene</button>
            )}
            {/* Show these buttons everywhere */}
            <button onClick={toggleDebugMode}>Toggle Debug View</button>
            <button onClick={() => toggleMSTViewer(mst.project.undoManager)}>
              Inspect UndoManager
            </button>
            <button onClick={() => toggleMSTViewer(mst)}>
              Inspect MST Root
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default observer(DebugMenu);
