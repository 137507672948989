import { useEffect, useRef, useState } from 'react';

/**
 * Set up canvas for use by compositor
 */
export const useBlurCanvas = () => {
  const blurCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [blurCtx, setBlurCtx] = useState<CanvasRenderingContext2D | null>(null);

  /**
   * As OffscreenCanvas is behind a feature flag in Firefox, we use a
   * normal canvas but never attach to the DOM.
   */
  useEffect(() => {
    const blurCanvas = document.createElement('canvas');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ctx = blurCanvas.getContext('2d')!;
    ctx.imageSmoothingEnabled = false;
    blurCanvasRef.current = blurCanvas;
    setBlurCtx(ctx);

    // on unmount, break the refs so the canvas goes out of scope
    // and can be GCd
    return () => {
      blurCanvasRef.current = null;
      setBlurCtx(null);
    };
  }, []);

  return { blurCanvasRef, blurCtx };
};
