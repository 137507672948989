import { useMst } from '@castify/studio/studio-store';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import sidebarCommon from './sidebarCommon.styles';

/**
 * The crop tool's right sidebar
 */
function CropSidebar() {
  const {
    toolManager: { cropToolIfOpen, closeTool },
  } = useMst();

  if (!cropToolIfOpen) return null;

  return (
    <div css={sidebarCommon.container}>
      <div css={sidebarCommon.titleSection}>
        <Icon css={sidebarCommon.icon}>crop</Icon>
        <h2>Crop Clip</h2>
      </div>
      <div css={sidebarCommon.centerSection}>
        <Button
          variant="text"
          css={sidebarCommon.centerSectionButton}
          onClick={() => {
            cropToolIfOpen.removeCrop();
            closeTool();
          }}
        >
          Remove Crop
        </Button>
      </div>
      <div css={sidebarCommon.bottomSection}>
        <Button
          variant="text"
          css={sidebarCommon.doneButton}
          onClick={() => closeTool()}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default CropSidebar;
