import { z } from 'zod';

/**
 * Schema representing stream output from ffprobe JSON output.
 *
 * In practice, we only use AudioProbeStream and VideoProbeStream which extend
 * ProbeStream.
 */
const ProbeStreamBaseSchema = z.object({
  /** index of stream in container */
  index: z.number(),
  /**  type of codec; almost always "audio" or "video", could be "data" */
  codec_type: z.string(),
  /** codec tag as string */
  codec_tag_string: z.string(),
  /** codec tag, not generally human readable */
  codec_tag: z.string(),
  /** start time of stream in container */
  start_time: z.string(),
});
export type ProbeStreamBase = z.infer<typeof ProbeStreamBaseSchema>;

/**
 * `a = audio`,
 * `lo = low`,
 * `md = medium`,
 * `hi = high`
 */
const AudioStreamKeysSchema = z.literal('a');
export type AudioStreamKeys = z.infer<typeof AudioStreamKeysSchema>;
const VideoStreamKeysSchema = z.enum(['lo', 'md', 'hi']);
export type VideoStreamKeys = z.infer<typeof VideoStreamKeysSchema>;
const StreamKeysSchema = z.union([
  AudioStreamKeysSchema,
  VideoStreamKeysSchema,
]);
export type StreamKeys = z.infer<typeof StreamKeysSchema>;

/**
 * Audio probe stream in ffprobe streams array
 */
const AudioProbeStreamSchema = ProbeStreamBaseSchema.extend({
  codec_type: z.literal('audio'),
  /** short name of codec */
  codec_name: z.string(),
  /** long name of codec */
  codec_long_name: z.string(),
});
export type AudioProbeStream = z.infer<typeof AudioProbeStreamSchema>;

/**
 * Video probe stream in ffprobe streams array
 */
const VideoProbeStreamSchema = ProbeStreamBaseSchema.extend({
  codec_type: z.literal('video'),
  /** short name of codec */
  codec_name: z.string(),
  /** long name of codec */
  codec_long_name: z.string(),
  /** width of video in pixels */
  width: z.number(),
  /** height of video in pixels */
  height: z.number(),
});
export type VideoProbeStream = z.infer<typeof VideoProbeStreamSchema>;

/**
 * Data probe stream in ffprobe streams array
 */
const DataProbeStreamSchema = ProbeStreamBaseSchema.extend({
  codec_type: z.literal('data'),
});
export type DataProbeStreamSchema = z.infer<typeof DataProbeStreamSchema>;

/**
 * Convenience type for any stream
 */
const AnyStreamSchema = z.union([
  AudioProbeStreamSchema,
  VideoProbeStreamSchema,
  DataProbeStreamSchema,
]);
export type AnyProbeStream = z.infer<typeof AnyStreamSchema>;

/**
 * Schema for the top-level format key in ffprobe JSON output
 */
const ProbeFormatSchema = z.object({
  filename: z.string(),
  nb_streams: z.number(),
  nb_programs: z.number(),
  format_name: z.string(),
  format_long_name: z.string(),
  start_time: z.string(),
  duration: z.string(),
});
export type ProbeFormat = z.infer<typeof ProbeFormatSchema>;

/**
 * Schema for ffprobe JSON output
 */
export const ProbeDataSchema = z.object({
  streams: z.array(AnyStreamSchema),
  format: ProbeFormatSchema,
});
export type ProbeData = z.infer<typeof ProbeDataSchema>;
