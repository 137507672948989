import { useMst } from '@castify/studio/studio-store';
import { colors } from '@castify/studio/fe-common';
import { observer } from 'mobx-react-lite';
import MoveableBox from '../Boxes/MoveableBox';

type BlurOverlayProps = {
  overlayContainerRef: React.RefObject<HTMLDivElement>;
};

/**
 * The overlay for the blur tool. Presupposes that parent element
 * is exactly the size of the canvas
 */
const BlurOverlay = ({ overlayContainerRef }: BlurOverlayProps) => {
  const {
    toolManager: { blurToolIfOpen },
  } = useMst();

  if (!blurToolIfOpen) return null;

  return (
    <>
      {blurToolIfOpen.targetClip.boxes.map((box) => {
        return (
          <MoveableBox
            key={box.uuid}
            borderColor={colors.blueBorder}
            isSelected={blurToolIfOpen.selectedBox === box}
            boxModel={box}
            overlayContainerRef={overlayContainerRef}
            selectionCallback={() => blurToolIfOpen.selectBox(box)}
          />
        );
      })}
    </>
  );
};

export default observer(BlurOverlay);
