import { MainTrackClip, useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import commonClipStyles from './commonClip.styles';

interface ClipDebuggerProps {
  clip: MainTrackClip;
}

const ClipDebugger = ({ clip }: ClipDebuggerProps) => {
  const {
    playback: { playingClip, seekClip, nextPlayingClip },
  } = useMst();
  return (
    <div css={commonClipStyles.debugText}>
      {[
        seekClip === clip ? 'seeking' : '',
        playingClip === clip ? 'playing' : '',
        nextPlayingClip === clip ? 'next' : '',
      ]
        .filter((e) => !!e)
        .join(' + ')}
    </div>
  );
};

export default observer(ClipDebugger);
