import { st } from '@castify/studio/fe-common';
import { Instance, types, destroy } from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import { getSelection } from '../../selection/selection.mst';
import { getTimeline } from '../../timeline/timeline.mst';
import { getScene } from './scene.mst';
import { getZoom } from '../../timeline/zoom/zoom.mst';
import { getPlayback } from '../../playback/playback.mst';
import VideoSourceModel, { IVideoSource } from './videoSource.mst';
import StillSourceModel, { IStillSource } from './stillSource.mst';
import BlurEffectModel, { IBlurEffect } from './blurEffect.mst';
import ZoomEffectModel, { IZoomEffect } from './zoomEffect.mst';
import { getUndoManager } from '../../project/project.mst';
import {
  buildThumbnailDescriptors,
  ThumbnailDescriptor,
} from './helpers/thumbnailHelpers';

/**
 * These properties are volatile so that they do not get
 * serialized into the database
 */
type ClipVolatileState = {
  /**
   * Is this clip currently being dragged/swapped?
   */
  isBeingDragged: boolean;
  /**
   * The offset of the left edge of the ghost clip relative to timeline container
   *  in px while being dragged
   */
  ghostClipStartPx: st.px;
  /**
   * The ID of the last clip we swapped with, if any. Necessary to track this
   * to prevent a thrashing effect that can occur when swapping clips.
   */
  lastSwapTarget: string | null;
};

/**
 * This is the hardcoded height of the thumbnails in the timeline.
 * Width will be derived on the assumption that thumbs are fixed to 16:9
 */
const THUMBNAIL_HEIGHT = 124;

/**
 * A gapless main track clip; may have multiple sources
 */
const ClipModel = types
  .model('ClipModel', {
    /**
     * Client-side uuid
     */
    uuid: types.optional(types.identifier, nanoid),
    /**
     * Data educating what appears within preview
     */
    source: types.union(VideoSourceModel, StillSourceModel),
    /**
     * The clip's blur track
     */
    blurEffects: types.optional(types.array(BlurEffectModel), []),
    /**
     * The clip's zoom track
     */
    zoomEffects: types.optional(types.array(ZoomEffectModel), []),
  })
  .volatile((): ClipVolatileState => {
    return {
      isBeingDragged: false,
      ghostClipStartPx: 0,
      lastSwapTarget: null,
    };
  })
  .views((self) => {
    return {
      /**
       * Is this a still clip?
       */
      isStill(): boolean {
        return StillSourceModel.is(self.source);
      },
      /**
       * Is this a video clip?
       */
      isVideo(): boolean {
        return VideoSourceModel.is(self.source);
      },
      /**
       * the trimIn in ms, this will be trim in if the clip is video
       * or zero if the clip is still
       */
      get trimIn(): st.ms {
        if (VideoSourceModel.is(self.source)) {
          return self.source.trimIn;
        }
        if (StillSourceModel.is(self.source)) {
          return 0;
        }
        throw new Error('Unknown video source');
      },
      /**
       * the trimOut in ms, this will be trim out if the clip is video
       * or duration if the clip is still
       */
      get trimOut(): st.ms {
        if (VideoSourceModel.is(self.source)) {
          return self.source.trimOut;
        }
        if (StillSourceModel.is(self.source)) {
          return self.source.duration;
        }
        throw new Error('Unknown video source');
      },
      /**
       * Given the ID of a blur effect on this clip, return the prior blur
       * effect, if there is one
       */
      getPreviousBlurEffectById(blurId: string): IBlurEffect | undefined {
        const index = self.blurEffects.findIndex(
          (clip) => clip.uuid === blurId,
        );
        // If there is no previous effect, exit early
        if (index <= 0) return;
        return self.blurEffects[index - 1];
      },
      /**
       * Given the ID of a blur effect on this clip, return the next blur
       * effect, if there is one
       */
      getNextBlurEffectById(blurId: string): IBlurEffect | undefined {
        const index = self.blurEffects.findIndex(
          (clip) => clip.uuid === blurId,
        );
        // If there's no following effect, exit early
        if (index === self.blurEffects.length - 1) return;
        return self.blurEffects[index + 1];
      },
      /**
       * Given the ID of a zoom effect on this clip, return the prior zoom
       * effect, if there is one
       */
      getPreviousZoomEffectById(zoomId: string): IZoomEffect | undefined {
        const index = self.zoomEffects.findIndex(
          (clip) => clip.uuid === zoomId,
        );
        // If there is no previous effect, exit early
        if (index <= 0) return;
        return self.zoomEffects[index - 1];
      },
      /**
       * Given the ID of a zoom effect on this clip, return the next zoom
       * effect, if there is one
       */
      getNextZoomEffectById(zoomId: string): IZoomEffect | undefined {
        const index = self.zoomEffects.findIndex(
          (clip) => clip.uuid === zoomId,
        );
        // If there is no following effect, return early
        if (index === self.zoomEffects.length - 1) return;
        return self.zoomEffects[index + 1];
      },
      /**
       * This method gets called when creating a new effect
       * Accepts a point of time in the timeline and returns the same point
       * in relation to the source duration of that clip. Will return undefined
       * if that point is not in the clip
       */
      getSourceOffsetFromTimestamp(pointInTime: st.ms): st.ms | undefined {
        // if the point provided is outside of the clip range
        if (pointInTime < this.start || pointInTime >= this.end) {
          return undefined;
        }

        // this is the offset point compared to what is visible from the clip
        // if the clip has trimIn, we need to add that
        const visibleOffset = pointInTime - this.start;

        // for still clip we just return visible offset
        if (StillSourceModel.is(self.source)) {
          return visibleOffset;
        }
        if (VideoSourceModel.is(self.source)) {
          const sourceOffset = visibleOffset + this.trimIn;
          // for video clips we want to calculate the offset related to the source
          // sourceOffset is bigger than the original clip length
          if (sourceOffset > self.source.videoLength) {
            return undefined;
          }
          return sourceOffset;
        }

        throw new Error('Unknown video source');
      },
      /**
       * Duration of clip within timeline
       */
      get duration(): st.ms {
        return self.source.duration;
      },

      /**
       * Duration of the total length of the video without trimming
       */
      get sourceDuration(): st.ms {
        if (this.isStill()) {
          return this.duration;
        }
        const videoSource = self.source as IVideoSource;
        return videoSource.videoLength;
      },

      /**
       * Returns if the clip is visible in the timeline
       * a clip is visible, if its in the visible part of the timeline
       * or part of it is visible (ended or started in the visible timeline)
       * or contains the timeline (started before the timeline and ended after)
       */
      get isVisible(): boolean {
        const { visibleStart, visibleDuration } = getTimeline(self);
        const visibleEnd = visibleStart + visibleDuration;

        const isStartInViewport =
          this.start > visibleStart && this.start < visibleEnd;
        const isEndInViewport =
          this.end > visibleStart && this.end < visibleEnd;
        const doesVideoContainViewport =
          this.start < visibleStart && this.end > visibleEnd;
        return isStartInViewport || isEndInViewport || doesVideoContainViewport;
      },

      /**
       * The visible start point ot the clip
       * if the video is not visible in the view port, return null
       * it would return the video's trimIn if the start is in the view port
       * else if the video started before the viewport
       * will return the trimIn - that offset
       */
      get visibleStart(): st.ms | null {
        const { visibleStart } = getTimeline(self);

        if (!this.isVisible) {
          return null;
        }

        // if the video started before the visible timeline view
        // then visible start would be the visible start of the timeline itself
        if (this.start < visibleStart) {
          const offset = visibleStart - this.start;
          return this.trimIn - offset;
        }

        return this.trimIn;
      },

      /**
       * The visible endpoint of the clip
       * if the video is not visible in the view port, return null
       * it would return the video's trimOut if the end is in the view port
       * else if the video ended after the viewport
       * will return the trimOut - that offset
       */
      get visibleEnd(): st.ms | null {
        const { visibleStart, visibleDuration } = getTimeline(self);
        const visibleEnd = visibleStart + visibleDuration;

        if (!this.isVisible) {
          return null;
        }

        // if the video started before the visible timeline view
        // then visible start would be the visible start of the timeline itself
        if (this.end > visibleEnd) {
          const offset = this.end - visibleEnd;
          return this.trimOut - offset;
        }

        return this.trimOut;
      },

      /**
       * The visible duration of the vieo
       * if the video is not visible in the view port, return null
       * if the video ends before visible duration of the timeline then
       * return the video duration, else return timeline visible duration
       * because that means the video ended after the view port
       */
      get visibleDuration(): st.ms | null {
        if (!this.isVisible) {
          return null;
        }
        return this.visibleEnd! - this.visibleStart!;
      },

      /**
       * The visible duration width in Pixels
       */
      get visibleDurationWidth(): st.px {
        if (this.visibleDuration === null) {
          return 0;
        }
        return this.visibleDuration * getZoom(self).zoomFactor;
      },

      /**
       * Memoized start time of the clip on the timeline. Computation
       * is delegated to the scene model in order to facilitate memoization here
       */
      get start(): st.ms {
        return getScene(self).mainTrack.getVideoClipStartById(self.uuid);
      },
      /**
       * Memoized end time of the clip on the timeline. Computation
       * is delegated to the scene model in order to facilitate memoization here
       */
      get end(): st.ms {
        return this.start + this.duration;
      },
      /**
       * Left offset of clip in pixels
       */
      get leftOffset(): st.px {
        const { visibleStart, timelineElementLeft } = getTimeline(self);
        const { zoomFactor } = getZoom(self);
        return (this.start - visibleStart - timelineElementLeft) * zoomFactor;
      },
      /**
       * Gets the amount of screen space occupied by this clip in pixels
       */
      get pixelWidth(): st.px {
        return this.duration * getZoom(self).zoomFactor;
      },

      /**
       * Gets the screen space that would be occupied without any video trimming
       */
      get pixelSourceWidth(): st.px {
        return this.sourceDuration * getZoom(self).zoomFactor;
      },

      /**
       * Compares this clip to the currently selected clip to see if they're the same
       */
      get isSelected(): boolean {
        return getSelection(self).selectedClip === self;
      },

      /**
       * This method is used to get the start/end for related clips (text/audio), effects are positioned relatively
       * to the source duration, this method takes the relative point and the clip info
       * to return a point in timeline
       * With resizing, we could trim in/trim out the video to be over the effect, we need to
       * take that into account when computing the effect point
       */
      relativePointToTimeline(relativePoint: st.ms): st.ms {
        if (relativePoint > this.trimOut) {
          return this.end;
        }

        // if relativePoint < trimIn, then duration will start from start
        if (relativePoint < this.trimIn) {
          return this.start;
        }

        // for video clip, because the point is related to source duration
        // we will compute it by removing trimIn first
        const extraGap = relativePoint - this.trimIn;
        const pointInTimeline = this.start + extraGap;

        return pointInTimeline;
      },

      /**
       * This method is used to get the start/end for some effects (blur/zoom), effects are positioned relatively
       * to the source duration, this method takes the relative point and returns a point related to the clip.start
       * With resizing, we could trim in/trim out the video to be over the effect, we need to
       * take that into account when computing the effect point
       */
      relativePointToClip(relativePoint: st.ms): st.ms {
        if (relativePoint > this.trimOut) {
          return this.trimOut;
        }

        // if relativePoint < trimIn, then duration will start from start
        if (relativePoint < this.trimIn) {
          return 0;
        }

        // for video clip, because the point is related to source duration
        // we will compute it by removing trimIn
        return relativePoint - this.trimIn;
      },
      /**
       * What is the background color for clip?
       */
      get backgroundColor(): string {
        if (this.isStill()) return (self.source as IStillSource).color;
        return 'black';
      },

      /**
       * The height of a single thumbnail.
       */
      get thumbnailHeight(): st.px {
        return THUMBNAIL_HEIGHT;
      },
      /**
       * The width of a single thumbnail. A derivation of width based on a fixed
       * 16:9 aspect ratio
       */
      get thumbnailWidth(): st.px {
        return this.thumbnailHeight * (16 / 9);
      },

      /**
       * Intended to be used to offset the container for
       * thumbnails & waveform, a "filmstrip"  aligned to where the clips's source
       * would be on the timeline were it not for trimIn and trimOut
       */
      get trimInOffset(): st.px {
        return this.trimIn * getZoom(self).zoomFactor;
      },
      /**
       * Intended to be used to set the width of the container in which
       * thumbnails appear; this is just the pixel width of the uncropped
       * source.
       */
      get thumbnailContainerWidth(): st.px {
        // Only video clips have thumbnails
        if (!VideoSourceModel.is(self.source)) return 0;
        // Convert the source's duration to pixels
        return self.source.videoLength * getZoom(self).zoomFactor;
      },
      /**
       * Returns a data structure which when mapped over describes
       * the thumbnails visible within the clip
       */
      get thumbnails(): Array<ThumbnailDescriptor> {
        // Type guard; only video clips have thumbnails
        if (!VideoSourceModel.is(self.source)) return [];
        return buildThumbnailDescriptors({
          thumbnailContainerWidth: this.thumbnailContainerWidth,
          thumbnailWidth: this.thumbnailWidth,
          zoomFactor: getZoom(self).zoomFactor,
          trimIn: self.source.trimIn,
          trimOut: self.source.trimOut,
          baseThumbnailUrl: self.source.baseThumbnailUrl,
        });
      },
      get hasAudio(): boolean {
        if (this.isStill()) {
          return false;
        }
        return (self.source as IVideoSource).hasAudio;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Adds instance of blur model to the blur array. Unsafe because it
       * performs no checks-- allows multiple blurs to be stacked, etc.
       * Caller must perform checks; caller is likely in Scene model
       * (i.e. don't call this directly!)
       */
      unsafeAddBlurEffect(blurEffect: IBlurEffect): void {
        self.blurEffects.push(blurEffect);
        self.blurEffects.sort((a, b) => a.start - b.start);
      },
      /**
       * Adds instance of zoom model to the zoom array before sorting it chronologically.
       * Unsafe because it performs no checks-- allows multiple zooms to be stacked, etc.
       * Caller must perform checks; caller is likely in Scene model.
       * (i.e. don't call this directly!)
       */
      unsafeAddZoomEffect(zoomEffect: IZoomEffect): void {
        self.zoomEffects.push(zoomEffect);
        self.zoomEffects.sort((a, b) => a.start - b.start);
      },
      /**
       * Starts a trim using either trim handle. Intended to run on pointerdown
       * events
       */
      startTrim(): void {
        getUndoManager(self).startGroup(() => {});
        if (getPlayback(self).isPlaying) getPlayback(self).pause();
      },
      /**
       * Stops a trim using either trim handle. Intended to run on pointerup
       * events.
       */
      stopTrim(): void {
        this.commitTrimChanges();
        getTimeline(self).setTemporaryOffset(0);
        getUndoManager(self).stopGroup();
      },
      /**
       * Intended to be called when the pointer moves during a trim.
       *
       * TODO - deprecate use of the delta and then eliminate it entirely
       * from the signature. For more on this, see comments in useTrimHandler.ts
       * Second arg is optional to not break tests
       */
      handleTrimFromStart(delta: st.px, newStartPixel?: st.px) {
        const timeDelta = delta / getZoom(self).zoomFactor;
        const didTrimHappen = self.source.trimFromStart(timeDelta);
        const { setTemporaryOffset, temporaryOffset } = getTimeline(self);
        if (didTrimHappen) {
          setTemporaryOffset(temporaryOffset - timeDelta);
        }
      },
      /**
       * Intended to be called when the pointer moves during a trim.
       *
       * TODO - deprecate use of the delta and then eliminate it entirely
       * from the signature. For more on this, see comments in useTrimHandler.ts
       * Second arg is optional to not break tests
       */
      handleTrimFromEnd(delta: st.px, newEndPixel?: st.px) {
        self.source.trimFromEnd(delta / getZoom(self).zoomFactor);
      },

      /**
       * This action gets called after the user finishes trimming (mouseup)
       * which will commit changes to all of the related effects, text, audio
       * affected by the trim
       */
      commitTrimChanges(): void {
        self.blurEffects.forEach(this.updateEffectFromTrim);
        self.zoomEffects.forEach(this.updateEffectFromTrim);
        getScene(self).commitClipTrimChangesForTextAudio(self.uuid);
      },
      /**
       * This action is called for each effect after the user finishes trimming
       * Will ignore, delete or modify the start/end of the effects if they were
       * affected by the trimming
       */
      updateEffectFromTrim(effect: IEffectDuration): void {
        if (!effect.isVisible) {
          destroy(effect);
          return;
        }
        if (effect.startSourceOffset < self.trimIn) {
          effect.startSourceOffset = self.trimIn;
        }
        if (effect.endSourceOffset > self.trimOut) {
          effect.endSourceOffset = self.trimOut;
        }
      },
      /**
       * Called at start of a clip drag/swap
       */
      startDrag(ghostClipStartPx: st.px): void {
        getUndoManager(self).startGroup(() => {});
        self.ghostClipStartPx = ghostClipStartPx;
        self.isBeingDragged = true;
        self.lastSwapTarget = null;
      },
      /**
       * Called when swap is complete
       */
      stopDrag(): void {
        self.isBeingDragged = false;
        self.lastSwapTarget = null;
        getUndoManager(self).stopGroup();
      },
      /**
       * Figures out if we need to do any swapping and delegates it up
       * to the scene model when we do;
       */
      handleSwapDrag(mousePosition: st.px): void {
        // convert mouse position to time and account for offscreen
        // time by adding in scrolltime.
        const swapTime =
          getTimeline(self).scrollTime +
          (mousePosition - getTimeline(self).timelineElementLeft) /
            getZoom(self).zoomFactor;
        // get the clip at the time in question
        const swapTarget =
          getScene(self).mainTrack.getMainTrackClipAtTime(swapTime);
        if (!swapTarget) return;

        // this helper just makes for easier reading in what follows
        // it performs the actual swap
        const performSwap = () => {
          getScene(self).mainTrack.swapClipsById(self.uuid, swapTarget.uuid);
          self.lastSwapTarget = swapTarget.uuid;
        };

        // If we're currently reversing a swap with the last clip we swapped
        // with during this drag, we need to test to ensure the pointer has
        // moved back inside the original location of the clip the user has
        // picked up. This technique prevents repeated swapping and a thrashing
        // effect which can happen when the user's clip is shorter than the clip
        // they are swapping it with. To implement this, we gate the swap
        // behind a number of conditions.
        if (self.lastSwapTarget && swapTarget.uuid === self.lastSwapTarget) {
          // Here we test for left or right swaps, in each case preventing
          // a swap from executing until th pointer is within the area on the
          // timeline where the clip being dragged will land once the swap is
          // complete. Checking for left/right swaps is necessary because
          // the logic to the pointer is in the right range is slightly
          // different for each case.

          if (
            (self.start < swapTarget.start &&
              swapTime >= swapTarget.end - self.duration) ||
            (self.start > swapTarget.start &&
              swapTime < swapTarget.start + self.duration)
          ) {
            performSwap();
          }
        }

        // if we're not reversing a swap with the last clip we swapped with
        // during this drag, we can swap as soon as the mouse moves over
        // any clip other than the one the user picked up. This is the "normal"
        // case. We have to have performed the above check to hit the early
        // return
        else if (swapTarget !== self) {
          performSwap();
          return;
        }
      },
      //positions the ghost clip on screen
      handleGhostClipDrag(ghostClipStartPx: st.px): void {
        // pause playback if we're actually kicking off a swap;
        // this happens here instead of in startDrag because if
        // drags always kick off a swap, playback always gets paused
        // when a swap starts, which breaks seeking during ongoing
        // playback
        if (
          getPlayback(self).isPlaying &&
          self.ghostClipStartPx !== ghostClipStartPx
        )
          getPlayback(self).pause();

        // update ghost clip position
        self.ghostClipStartPx = ghostClipStartPx;
      },
    };
  });

export interface IEffectDuration {
  isVisible: boolean;
  startSourceOffset: st.ms;
  endSourceOffset: st.ms;
  relativeStart: st.ms;
  relativeEnd: st.ms;
}

export default ClipModel;

export interface IClip extends Instance<typeof ClipModel> {}
