import styles from './CloseSimpleEditButton.styles';
import AddIcon from '@mui/icons-material/Add';

import { useState } from 'react';
import { Fab, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface CloseSimpleEditButtonProps {
  toggleSimpleEdit: () => void;
}

function CloseSimpleEditButton({
  toggleSimpleEdit,
}: CloseSimpleEditButtonProps) {
  return (
    <Fab
      css={styles.button}
      data-pendo-id="close-simple-edit-button"
      onClick={toggleSimpleEdit}
      variant="extended"
      color="primary"
    >
      <CheckIcon css={styles.icon} />
      <Typography css={styles.font}>Done</Typography>
    </Fab>
  );
}

export default CloseSimpleEditButton;
