import React, { useState } from 'react';
import { createCtx } from '@castify/studio/fe-common';
import {
  useUntrackedSignIn,
  useUntrackedSignOut,
} from './GoogleAuthInstanceProvider/GoogleAuthInstanceProvider';

interface Authenticator {
  signIn: () => Promise<void>;
  signOut: () => Promise<never>;
}

const [useAuthenticator, Provider] = createCtx<Authenticator>();

/**
 * Hook returning a function that will begin signIn flow by launching a
 * google auth pop-up.
 *
 * May ONLY be used within an AuthenticatorProvider.
 */
const useSignIn = () => useAuthenticator().signIn;
/**
 * Hook returning a function that will sign the user out.
 * This will result in a full page refresh to ensure we dump any
 * ephemeral user state.
 *
 * May ONLY be used within an AuthenticatorProvider.
 */
const useSignOut = () => useAuthenticator().signOut;

/**
 * Provider for signIn/signOut.
 *
 * Will render a loading element while signIn is in progress. While signIn is in
 * progress there will be a google oauth pop-up presented to the user.
 *
 * Must be rendered inside a GoogleAuthInstanceProvider.
 */
const AuthenticatorProvider: React.FC<{ loadingElement: React.ReactElement }> =
  (props) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [authenticationError, setAuthenticationError] = useState<Error>();

    const untrackedSignIn = useUntrackedSignIn();
    const untrackedSignOut = useUntrackedSignOut();

    const authenticator: Authenticator = {
      signIn: async () => {
        try {
          setAuthenticationError(undefined);
          setIsAuthenticating(true);
          await untrackedSignIn();
        } catch (error) {
          setAuthenticationError(error as Error);
        } finally {
          setIsAuthenticating(false);
        }
      },
      signOut: async () => {
        return new Promise<never>(() => {
          untrackedSignOut()
            .then(() => {
              localStorage.clear();
              sessionStorage.clear();
              return window.location.assign('/');
            })
            .catch((error: Error) => setAuthenticationError(error));
        });
      },
    };

    if (isAuthenticating) {
      return props.loadingElement;
    }

    // Throwing to error boundary
    if (authenticationError) {
      throw authenticationError;
    }

    return <Provider value={authenticator} {...props} />;
  };

export { AuthenticatorProvider, useSignIn, useSignOut };

export { Provider as RawProvider };
