import styles from './OldScreencastifyNotification.styles';
import { observer } from 'mobx-react-lite';
import { Typography, Button, ListItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { environment } from '@castify/studio/env/browser';
import { safeWindowOpen } from '@castify/safe-window-open';
/**
 * Left side bar for when user is signed in. Assumes user is available when rendered
 */
function OldScreencastifyNotification() {
  function onMyVideoAClick() {
    safeWindowOpen(environment.externalLinks.myVideoLink);
  }
  return (
    <li>
      <div css={styles.container}>
        <Typography css={styles.contextText}>
          You are currently previewing the all NEW Screencastify, coming soon!
          Learn more here
        </Typography>
        <Button
          onClick={onMyVideoAClick}
          css={styles.button}
          variant="contained"
          color="secondary"
          data-pendo-id="old-screencastify-link"
        >
          <ArrowBackIcon />
          <Typography css={styles.buttonText}>
            <b>Back To Screencastify</b>
          </Typography>
        </Button>
      </div>
    </li>
  );
}

export default observer(OldScreencastifyNotification);
