// library interface
export { MstProvider, injectInitialState, useMst } from './lib/provider';
export { createInitialState } from './lib/initialState';

// types
export type { IRoot } from './lib/root.mst';
export type {
  SelectableClip,
  TrimmableClip,
  MainTrackClip,
} from './lib/project/scene/sceneTypes';
export type { NewClipInfo } from './lib/ingress/ingress.mst';

export type { SelectableClipNames } from './lib/project/scene/sceneTypes';
export type { IClip } from './lib/project/scene/clip.mst';
export type { ITextClip } from './lib/project/scene/textClip.mst';
export type { IAudioClip } from './lib/project/scene/audioClip.mst';
export type { IBlurEffect } from './lib/project/scene/blurEffect.mst';
export type { IZoomEffect } from './lib/project/scene/zoomEffect.mst';
export type { IVideoSource } from './lib/project/scene/videoSource.mst';
export type { IStillSource } from './lib/project/scene/stillSource.mst';
export type { IBoxModel } from './lib/project/scene/box/box.mst';
export type { IRotatableBoxModel } from './lib/project/scene/rotatableBox/rotatableBox.mst';
export type { VideoPairName, IPlayback } from './lib/playback/playback.mst';

/**
 * Exports models. These typically should not be used via `.create()`
 * outside of MST; the are exported mainly because of some static utility
 * methods they expose (for example `.is()`, which can be used to check if a
 * model instance is an instance of a given model type)
 */

import Clip from './lib/project/scene/clip.mst';
import TextClipModel from './lib/project/scene/textClip.mst';
import AudioClipModel from './lib/project/scene/audioClip.mst';
import BlurEffectModel from './lib/project/scene/blurEffect.mst';
import ZoomEffectModel from './lib/project/scene/zoomEffect.mst';
import StillSourceModel from './lib/project/scene/stillSource.mst';
import VideoSourceModel from './lib/project/scene/videoSource.mst';

export {
  Clip,
  TextClipModel,
  AudioClipModel,
  BlurEffectModel,
  ZoomEffectModel,
  StillSourceModel,
  VideoSourceModel,
};

// mainly used for testing components
export * from './test-helpers';
