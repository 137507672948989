import { observer } from 'mobx-react-lite';
import { Typography } from '@mui/material';
import styles from './EmptyPreview.styles';
import { IngressButtons } from '@castify/studio/ingress-flows';

function EmptyPreview() {
  return (
    <div css={styles.center}>
      <Typography css={styles.contentText} variant="h2">
        Ready to go?
      </Typography>
      <Typography css={styles.contentSubtext}>
        Import a video to get started
      </Typography>
      <div css={styles.ingressButtons}>
        <IngressButtons />
      </div>
    </div>
  );
}

export default observer(EmptyPreview);
