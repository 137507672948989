/**
 * This file is a place holder to get current work done.
 *
 * TODO: Architectural discussion on how we want to provide
 * enums to the UI.
 * Briefly touched on with Jeff and Aurora that the team should discuss desire for a strategy to pull them
 * but also provide the correct labelling from the database since.
 *
 */

import { License_Seat_Tiers_Enum } from 'lib/studio/database-clients/src/lib/generated-types';

export const TIER_LIST_WITH_LABELS: Array<{
  eduLabel: string;
  defaultLabel: string;
  value: License_Seat_Tiers_Enum;
}> = [
  {
    eduLabel: 'Teacher',
    defaultLabel: 'Pro',
    value: License_Seat_Tiers_Enum.Pro,
  },
  {
    eduLabel: 'Student',
    defaultLabel: 'Standard',
    value: License_Seat_Tiers_Enum.Standard,
  },
  {
    eduLabel: 'Free',
    defaultLabel: 'Free',
    value: License_Seat_Tiers_Enum.Free,
  },
];
