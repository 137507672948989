import { css } from '@emotion/react';

export const styles = {
  title: css`
    display: flex;
    justify-content: space-between;
  `,
  content: css`
    width: 400px;
  `,
  showTopNav: css`
    top: 80px;
    .MuiBackdrop-root {
      top: 80px;
    }
  `,
};
