/**
 * This file is a place holder to get current work done.
 *
 * TODO: Architectural discussion on how we want to provide
 * enums to the UI.
 * Briefly touched on with Jeff and Aurora that the team should discuss desire for a strategy to pull them
 * but also provide the correct labelling from the database since.
 *
 */

import { License_Seat_Roles_Enum } from 'lib/studio/database-clients/src/lib/generated-types';

export const ROLE_LIST_WITH_LABELS: Array<{
  eduLabel: string;
  defaultLabel: string;
  value: License_Seat_Roles_Enum;
  helperText: string;
}> = [
  {
    eduLabel: 'Admin',
    defaultLabel: 'Admin',
    value: License_Seat_Roles_Enum.Admin,
    helperText: 'Full access to product + admin access',
  },
  {
    eduLabel: 'Teacher',
    defaultLabel: 'Member',
    value: License_Seat_Roles_Enum.Member,
    helperText: 'Full access to product',
  },
  {
    eduLabel: 'Student',
    defaultLabel: 'Restrcited User',
    value: License_Seat_Roles_Enum.Restricted,
    helperText: 'Restricted access to product',
  },
];
