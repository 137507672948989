import React from 'react';
import sharedButtonStyle from '../sharedButtonStyles';
import UndoIcon from '@mui/icons-material/Undo';
import { Fab, Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import { ButtonTooltip } from '@castify/studio/fe-common';
import { getShortcutString, shortcutConfigs } from '@castify/studio/keyboard';
import styles from './UndoButton.styles';

interface UndoButtonProps {
  showSkeleton?: boolean;
}

function UndoButton({ showSkeleton }: UndoButtonProps) {
  const {
    project: { undoManager },
  } = useMst();

  /**
   * Helper to ensure we can't call undo when
   * it is not possible to undo
   */
  const undo = () => {
    if (undoManager.canUndo) undoManager.undo();
  };

  if (showSkeleton)
    return <Skeleton variant="circular" height={40} width={40} />;

  return (
    <ButtonTooltip
      title={
        undoManager.canUndo
          ? `Undo (${getShortcutString(shortcutConfigs.Undo)})`
          : 'No Undos Available'
      }
    >
      <Fab
        css={styles.button}
        onClick={undo}
        disabled={!undoManager.canUndo}
        data-pendo-id="undo-button"
        color="info"
        aria-label="Undo Button"
      >
        <UndoIcon css={styles.icon} fontSize="inherit" />
      </Fab>
    </ButtonTooltip>
  );
}

export default observer(UndoButton);
