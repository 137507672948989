import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  container: css`
    background: ${colors.gray[700]};
    padding: 20px;
  `,
  button: css`
    width: 100%;
    height: 100%;
    padding: 20px 0;
  `,
  buttonText: css`
    margin-left: 10px;
  `,
  contextText: css`
    font-size: 12px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 20px;
  `,
};
export default styles;
