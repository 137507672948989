import { st } from '@castify/studio/fe-common';

export function useScrollbar(
  handleScrollbarMove: (scrollbarLeftEdge: st.px) => void,
  scrollbarInnerRef: React.RefObject<HTMLDivElement>,
): { pointerDown: (pointerDownEvent: React.PointerEvent) => void } {
  return {
    pointerDown: (pointerDownEvent: React.PointerEvent) => {
      if (!scrollbarInnerRef.current) return;
      const offsetX =
        pointerDownEvent.clientX -
        scrollbarInnerRef.current.getBoundingClientRect().left;

      const pointerMove = (pointerMoveEvent: PointerEvent) => {
        const leftEdge = pointerMoveEvent.clientX - offsetX;
        handleScrollbarMove(leftEdge);
      };

      const pointerUp = () => {
        window.removeEventListener('pointermove', pointerMove);
        window.removeEventListener('pointerup', pointerUp);
      };

      window.addEventListener('pointermove', pointerMove);
      window.addEventListener('pointerup', pointerUp);
    },
  };
}
