//@ts-strict
/* eslint-disable no-console */
/*!
 * Copyright 2022 Screencastify LLC
 */
import {
  datadogLogs,
  Logger,
  HandlerType,
  StatusType,
} from '@datadog/browser-logs';
import { environment } from '@castify/studio/env/browser';
import { getAnonymousUserIdentifier } from '@castify/studio/fe-common';

interface IBrowserLogInfo {
  error?: Error | unknown;
  [key: string]: unknown;
}

// TODO: implement metric strict typing
export interface IBrowserLogger {
  error: (message: string, args?: IBrowserLogInfo) => void;
  info: (message: string, args?: IBrowserLogInfo) => void;
  warn: (message: string, args?: IBrowserLogInfo) => void;
  debug: (message: string, args?: IBrowserLogInfo) => void;
}

// use strategy pattern to access runtime env
interface IBrowserLoggerStrategy {
  handler: HandlerType | HandlerType[];
  context?: Record<string, unknown>;
  logger: (label: string) => Logger;
}

export class ProductionBrowserLoggerStrategy implements IBrowserLoggerStrategy {
  // send logs to DataDog in prod, same condition/setup as server logger
  handler = HandlerType.http;
  // add session user Id to all the browser logs for correlation
  context = {
    sessionUserId: getAnonymousUserIdentifier(),
  };
  logger(label: string): Logger {
    return datadogLogs.createLogger(label, {
      level: 'info',
      handler: this.handler,
      context: this.context,
    });
  }
}

export class NonProductionBrowserLoggerStrategy
  implements IBrowserLoggerStrategy
{
  // print logs on dev console in dev, same condition/setup as server logger
  handler = HandlerType.console;
  /**
   * Monkey patch logger to control logger styles ourselves in dev. Give minimum styles for readability.
   * Can be elaborated later if we want better styles.
   * DD console log levels are color coded: info-green, debug-blue, error-red, warn-yellow
   *
   * TODO: modify monkey patch to use prototype.log after remoteLogger deprecates. Currently throws using prototype
   * possibly due to conflicts with remoteLogger patching directly on Console prototype log as well
   */
  logger(label: string): Logger {
    const logger = datadogLogs.createLogger(label, {
      handler: this.handler,
      level: 'debug',
    });
    logger.log = <T>(
      message: string,
      messageContext?: T,
      status?: StatusType,
    ): void => {
      const color =
        status === 'debug'
          ? 'hsl(240, 100%, 75%)'
          : status === 'error'
          ? 'hsl(348, 78%, 53%)'
          : status === 'warn'
          ? 'hsl(60, 100%, 75%)'
          : 'hsl(120, 100%, 75%)'; // info
      const style = `color: black; background: ${color}; border: 1px solid black; border-radius: 4px; padding: 4px;`;

      if (messageContext) {
        console.log(`%c${status}: ${label}`, style, message, messageContext);
      } else {
        console.log(`%c${status}: ${label}`, style, message);
      }
    };
    return logger;
  }
}
/**
 * An abstraction to customize DataDog logger as our browser logger. Update framework logger in the class
 * versus calling framework logger directly for scalability.
 * Make all members static because their behavior remain consistent and do not require instance variables
 * for both performance and easier debugging.
 */
export class BrowserLogger {
  // made public for unit test
  public static isInit = false;

  public static init(serviceName: string) {
    if (!BrowserLogger.isInit) {
      // okay to use console log here because Browser logger is initiated before everything else during app load
      console.log('initializing DataDog Browser Logger');
      datadogLogs.init({
        clientToken: environment.dataDogClientToken,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        env: environment.environmentName,
        service: serviceName,
      });
      BrowserLogger.isInit = true;
    } else {
      throw new Error('DD Browser Logger has already been initialized.');
    }
  }
  // expose for unit test
  public static createLogger(
    label: string,
    strategy: IBrowserLoggerStrategy = new NonProductionBrowserLoggerStrategy(),
  ): Logger {
    return strategy.logger(label);
  }

  private static createStrictTypingLogger(logger: Logger): IBrowserLogger {
    return {
      error: (message, args?) => logger.error(message, args),
      warn: (message, args?) => logger.warn(message, args),
      info: (message, args?) => logger.info(message, args),
      debug: (message, args?) => logger.debug(message, args),
    };
  }

  public static getStrictTypingLogger(
    label: string,
    strategy: IBrowserLoggerStrategy = new NonProductionBrowserLoggerStrategy(),
  ): IBrowserLogger {
    const logger = BrowserLogger.createLogger(label, strategy);
    return BrowserLogger.createStrictTypingLogger(logger);
  }
}
const strategy =
  environment.environmentName === 'production'
    ? new ProductionBrowserLoggerStrategy()
    : new NonProductionBrowserLoggerStrategy();

// keep this for now to test production logs locally
// const strategy = new ProductionBrowserLoggerStrategy();

// export the logger based on env and used everywhere as a singleton
export const createBrowserLogger = (label: string) => {
  return BrowserLogger.getStrictTypingLogger(label, strategy);
};
