import { Outlet } from 'react-router-dom';
import { LeftSidebar } from '@castify/studio/LeftSidebar';
import styles from './DashLayout.styles';

function DashLayout() {
  return (
    <div css={styles.flexRow}>
      <div css={styles.sideBar}>
        <LeftSidebar />
      </div>
      <main css={styles.mainBrowser}>
        <Outlet />
      </main>
    </div>
  );
}

export default DashLayout;
