import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  playerOuter: css`
    background-color: ${colors.TEMP.PreviewBackground};
    width: 100%;
    height: 100%;
    background-color: black;
  `,
  canvasContainer: css`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${colors.gray[900]};
    justify-content: center;
    align-items: center;
  `,
  canvas: css``,
};

export default styles;
