import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useMutation } from 'urql';
import { useNavigate } from 'react-router-dom';

import { useMst } from '@castify/studio/studio-store';
import {
  DeleteProjectDocument,
  DeleteProjectMutation,
} from '@castify/studio/database-clients/graphql';

import styles from './DeleteProjectConfirmDialog.styles';

interface IDeleteConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteProjectConfirmDialog = (
  props: IDeleteConfirmDialogProps,
) => {
  const { project } = useMst();
  const navigate = useNavigate();
  const { isOpen, onClose } = props;

  const [{ data }, executeMutation] = useMutation<DeleteProjectMutation>(
    DeleteProjectDocument,
  );

  function handleDelete() {
    executeMutation({ projectUuid: project.uuid }).then((response) => {
      /*
       * re-direct the user to the project browser if deletion succeeds
       */
      if (response.data?.delete_projects_by_pk) {
        navigate('/projects');
      }
    });
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle>🗑 Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will remove
          <b css={styles.title} className="project-title">
            {project.title ?? ''}
          </b>
          from your My Videos page. Once you delete this video, it cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-pendo-id="cancel-delete-project-button" onClick={onClose}>
          Never mind
        </Button>
        <Button
          data-pendo-id="confirm-delete-project-button"
          onClick={handleDelete}
          variant="contained"
        >
          Yes, delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
