import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  scrollbarContainer: css`
    margin-top: 5px;
    height: 10px;
    background-color: ${colors.TEMP.ScrollbarGray};
  `,

  scrollbarBody: css`
    position: relative;
    background-color: ${colors.gray[300]};
    height: 100%;
    border-radius: 7px;
    width: 50%;
  `,
};
export default styles;
