import * as Types from '../generated-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetRefreshTokenQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetRefreshTokenQuery = {
  __typename?: 'query_root';
  refresh_tokens: Array<{
    __typename?: 'refresh_tokens';
    expired?: boolean | null | undefined;
  }>;
};

export type MarkRefreshTokenExpiredMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type MarkRefreshTokenExpiredMutation = {
  __typename?: 'mutation_root';
  update_refresh_tokens?:
    | {
        __typename?: 'refresh_tokens_mutation_response';
        returning: Array<{
          __typename?: 'refresh_tokens';
          expired?: boolean | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export const GetRefreshTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRefreshToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refresh_tokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRefreshTokenQuery,
  GetRefreshTokenQueryVariables
>;
export const MarkRefreshTokenExpiredDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkRefreshTokenExpired' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_refresh_tokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'ObjectValue', fields: [] },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expired' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expired' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkRefreshTokenExpiredMutation,
  MarkRefreshTokenExpiredMutationVariables
>;
