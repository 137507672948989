import styles from './ProjectBrowserHeader.styles';

import Icon from '@mui/material/Icon';
import AddProjectButton from './AddProjectButton/AddProjectButton';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@mui/material';

function ProjectBrowserHeader() {
  return (
    <div css={styles.projectBrowserHeader}>
      <div css={styles.headerSection}>
        <Icon css={styles.videoLibraryIcon}>video_library</Icon>
        <p css={styles.projectBrowserLabel}>My Videos</p>
        <Tooltip
          title="Find anything you've made in the new Screencastify here!"
          placement="right"
        >
          <Icon css={styles.helpIcon}>help_outline</Icon>
        </Tooltip>
      </div>

      <div css={styles.headerSection}>
        <AddProjectButton />
      </div>
    </div>
  );
}

export default observer(ProjectBrowserHeader);
