import { observer } from 'mobx-react-lite';
import WatchPageHeader from './WatchPageHeader/WatchPageHeader';
import WatchPageFooter from './WatchPageFooter/WatchPageFooter';
import { WatchPlayer } from '@castify/studio/components/players';
import { WatchShortcuts } from '@castify/studio/keyboard';
import { useWatchPageData } from '@castify/studio/components/project-loaders';
import { LoadingTreatmentLogger } from '@castify/studio/components/loading';
import styles from './WatchPage.styles';
import { useMst } from '@castify/studio/studio-store';

export function WatchPage() {
  const {
    project: { isLoaded },
  } = useMst();

  useWatchPageData();

  return (
    <>
      {!isLoaded ? (
        <LoadingTreatmentLogger identifier="WatchPage" />
      ) : (
        <WatchShortcuts />
      )}
      <div role="main">
        <div className="App" data-testid="editor-app" css={styles.appContainer}>
          <div css={styles.header}>
            <WatchPageHeader showSkeleton={!isLoaded} />
          </div>
          <div role="region" aria-label="Video Preview" css={styles.preview}>
            <WatchPlayer showSkeleton={!isLoaded} />
          </div>
          <div css={styles.footer}>
            <WatchPageFooter showSkeleton={!isLoaded} />
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(WatchPage);
