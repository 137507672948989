import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  zoomSlider: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    width: 250px;
    transform-origin: center;

    & .material-icons {
      padding: 10px;
    }

    .MuiButton-root {
      margin: 0;
      font: bold 12px Helvetica;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${colors.TEMP.ZoomPurple};
      p {
        margin: 0;
        margin-top: -2px;
        white-space: nowrap;
      }
    }
    & .MuiSlider-rail {
      background-color: ${colors.gray[500]};
      transform-origin: center;
    }

    & .MuiSlider-track {
      background-color: ${colors.TEMP.ZoomPurple};
      border: none;
      transform-origin: center;
    }

    & .MuiSlider-thumb {
      background-color: ${colors.TEMP.ZoomPurple};
      border: 3px solid ${colors.gray[0]};
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:focus {
        transform: translate(-50%, -50%) scale(1.5);
        box-shadow: none;
      }
      &:active {
        transform: translate(-50%, -50%) scale(1.5);
        box-shadow: none;
      }
      &:hover {
        transform: translate(-50%, -50%) scale(1.5);
        box-shadow: none;
      }
    }
  `,
};
export default styles;
