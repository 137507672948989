import { css } from '@emotion/react';

const styles = {
  clipsContainer: css`
    position: relative;
    height: 182px;
  `,
};

export default styles;
