import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  footerText: css`
    align-items: center;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-family: Helvetica, Sans-Serif;
    font-weight: bold;
  `,
  container: css`
    margin: 0 10% 0 10%;
  `,
};
export default styles;
