import { Instance, types } from 'mobx-state-tree';
/**
 * State, views, & actions backing the audio tool
 */
const AudioToolModel = types
  .model('AudioToolModel', {})
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
export default AudioToolModel;

export interface IAudioTool extends Instance<typeof AudioToolModel> {}
