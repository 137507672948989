import {
  IBlurEffect,
  MainTrackClip,
  StillSourceModel,
  VideoSourceModel,
} from '@castify/studio/studio-store';
import { st } from '@castify/studio/fe-common';
import { paintDebugText } from './paintDebugText';
import { paintStillSource } from './paintStillSource';
import { paintVideoSource } from './paintVideoSource';

type IPaintFrame = {
  /**
   * The ctx returned by `getContext('2d')` on the canvas element.
   * Passed in from above to avoid requesting a drawing context object
   * on every frame
   */
  ctx: CanvasRenderingContext2D;
  /**
   * A reference to the canvas element itself
   */
  canvasElm: HTMLCanvasElement;
  /**
   * The <video />, <img />, <canvas /> elm from which we pull image data.
   * Not present for e.g. Still clips
   */
  imageSource: CanvasImageSource | null;
  /**
   * The MST model for the clip we are rendering
   */
  activeClip: MainTrackClip | undefined;
  /**
   * Is override mode active? If so, do not apply any crop or zoom.
   */
  isOverrideModeActive: boolean;
  /**
   * Is debug mode active?
   */
  debugMode: boolean;
  /**
   * An invisible helper canvas used by blur.
   */
  blurCanvas: HTMLCanvasElement;
  /**
   * Drawing context for the canvas
   */
  blurCtx: CanvasRenderingContext2D;
  /**
   * Blur effect if one is active
   */
  blurEffect: IBlurEffect | undefined;
};

/**
 * This is the entry point to the isomorphic video compositor that backs both
 * the preview experience and the renderer. It knows how to render frames of
 * videos and apply effects to them. It takes these as input, but also takes in
 * a refernce to a canvas element and canvas drawing context; these are where
 * the output is drawn.
 */
export const paintFrame = ({
  ctx,
  canvasElm,
  imageSource,
  activeClip,
  isOverrideModeActive,
  debugMode,
  blurCanvas,
  blurCtx,
  blurEffect,
}: IPaintFrame): void => {
  /**
   * Paint still clip
   */
  if (activeClip && StillSourceModel.is(activeClip.source)) {
    paintStillSource({ ctx, canvasElm, activeClip });
  }

  /**
   * Paint video clip
   */
  if (imageSource && activeClip && VideoSourceModel.is(activeClip.source)) {
    /**
     * Do the paint
     */
    paintVideoSource({
      ctx,
      canvasElm,
      activeClipSource: activeClip.source,
      imageSource,
      isOverrideModeActive,
      blurCanvas,
      blurCtx,
      blurEffect,
    });
  }

  /**
   * Paint some debug information if debug is active. This happens on top
   * of all clip types
   */

  if (activeClip && debugMode) {
    paintDebugText({
      ctx,
      canvasElm,
      activeClip,
      imageSource,
      isOverrideModeActive,
    });
  }
};
