import { IBoxModel } from '@castify/studio/studio-store';
import { useMoveableBoxHandlers } from './useMoveableBoxHandlers';
import { observer } from 'mobx-react-lite';
import styles from './MoveableBox.styles';

type MoveableBoxProps = {
  /**
   * When selected, the border can be whatever color your heart desires
   */
  borderColor: string;
  /**
   * When not selected, the box has a hard-coded grey border and the handles
   * disappear
   */
  isSelected: boolean;
  /**
   * The MST model instance which backs the box' state
   */
  boxModel: IBoxModel;
  /**
   * A ref to the overlay container within which the box moves
   */
  overlayContainerRef: React.RefObject<HTMLDivElement>;
  /**
   * Called on pointerUp; intended to be used to select boxes
   */
  selectionCallback?: () => void;
};

/**
 * Renders borders and handles for moveable box; delegates wiring up box
 * <-> MST model to hook.
 *
 * Note that when unselected, a box must be selected beofre it can be moved.
 * Unselected boxes display no handles
 */
const MoveableBox = ({
  borderColor,
  isSelected,
  boxModel,
  overlayContainerRef,
  selectionCallback,
}: MoveableBoxProps) => {
  const color = isSelected ? borderColor : '#a9a9a9';
  const handleDisplay = isSelected ? 'inherit' : 'none';

  const {
    pointerDownMove,
    pointerDownTopLeft,
    pointerDownTopRight,
    pointerDownBottomRight,
    pointerDownBottomLeft,
  } = useMoveableBoxHandlers({ boxModel, overlayContainerRef });

  return (
    <div
      css={styles.boxContainer}
      style={{
        top: boxModel.top * 100 + '%',
        left: boxModel.left * 100 + '%',
        width: boxModel.width * 100 + '%',
        height: boxModel.height * 100 + '%',
      }}
      onPointerDown={() => {
        selectionCallback?.();
      }}
    >
      <div
        css={styles.border}
        style={{ color: color, cursor: isSelected ? 'move' : 'pointer' }}
        onPointerDown={pointerDownMove}
      ></div>
      <div
        css={styles.topLeftHandle}
        style={{ backgroundColor: color, display: handleDisplay }}
        onPointerDown={pointerDownTopLeft}
      ></div>
      <div
        css={styles.topRightHandle}
        style={{ backgroundColor: color, display: handleDisplay }}
        onPointerDown={pointerDownTopRight}
      ></div>
      <div
        css={styles.bottomRightHandle}
        style={{ backgroundColor: color, display: handleDisplay }}
        onPointerDown={pointerDownBottomRight}
      ></div>
      <div
        css={styles.bottomLeftHandle}
        style={{ backgroundColor: color, display: handleDisplay }}
        onPointerDown={pointerDownBottomLeft}
      ></div>
    </div>
  );
};

export default observer(MoveableBox);
