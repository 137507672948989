import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  contentText: css`
    font-size: 100px;
    font-weight: bold;
  `,
  contentSubtext: css`
    font-size: 40px;
  `,
  center: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-area: center;

    /* Matches border applies in PreviewPlayer styles */
    box-sizing: border-box;
    border-radius: 25px;
    border: 8px solid white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  `,
  ingressButtons: css`
    margin-top: 1em;
    display: flex;
  `,
};
export default styles;
