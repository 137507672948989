import { observer } from 'mobx-react-lite';
import { Breadcrumbs, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './ProjectBreadcrumbs.styles';
import TitleUpdateField from '../TitleUpdateField/TitleUpdateField';
import Skeleton from '@mui/material/Skeleton';

interface ProjectBreadcrumbsProps {
  showSkeleton?: boolean;
}

function ProjectBreadcrumbs({ showSkeleton }: ProjectBreadcrumbsProps) {
  return (
    <Stack spacing={2}>
      {showSkeleton ? (
        <Skeleton variant="rectangular" width={350} height={40} />
      ) : (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            css={styles.link}
            data-pendo-id="project-breadcrumbs-projects-link"
            to="/projects"
          >
            <Typography>My Projects</Typography>
          </Link>
          <TitleUpdateField />,
        </Breadcrumbs>
      )}
    </Stack>
  );
}

export default observer(ProjectBreadcrumbs);
