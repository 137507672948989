import { useMst } from '@castify/studio/studio-store';
import {
  UpdateSceneDocument,
  UpdateSceneMutation,
} from '@castify/studio/database-clients/graphql';
import { useMutation } from 'urql';
import { useEffect } from 'react';
import { createBrowserLogger } from '@castify/studio/observability/browser';
const logger = createBrowserLogger('useAutosaveScene');

/**
 * This hook is responsible for injecting a callback into MST
 * which allows saving the scene via a GQL mutation on demand
 */
export const useAutosaveScene = () => {
  const { project } = useMst();

  /**
   * This query allows saving, but we pass its mutation executor into MST
   * so that we can initiate a save from MST anywhere we like.
   */
  const [{ fetching, error }, updateScene] =
    useMutation<UpdateSceneMutation>(UpdateSceneDocument);
  project.injectScenePersistenceFunction((projectId, scene) => {
    logger.debug('saving scene');

    updateScene({ projectId, scene }).then(() => {
      logger.debug('scene saved');
    });
  });

  /**
   * This effect set up scene autosaving on page load and
   * disables it before unload
   */
  useEffect(() => {
    project.setupSceneAutosave();
    return () => {
      project.teardownSceneAutosave();
    };
  }, [project]);

  /**
   * The returns here allow the component to respond to state changes
   * as the queries update.
   */
  return {
    saving: fetching,
    savingError: error,
  };
};
