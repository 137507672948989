import { IRoot } from '@castify/studio/studio-store';

/**
 * What happens when the key is pressed?
 */
type keyboardAction = (root: IRoot, event: KeyboardEvent) => void;

/**
 *  Is the shortcut enabled? a pure function of MST root
 */
type KeyboardShortcutEnabled = (root: IRoot) => boolean;

/**
 * A programmatic description of a keyboard shortcut. Necessary because
 * a single action can have multiple shortcuts.
 */
type Shortcut = {
  key: string;
  modKeys?: Array<string>;
};

/**
 * Type of an individual keyboard shortcut config entry
 */
export type ShortcutConfig = {
  shortcuts: Array<Shortcut>;
  enabled: KeyboardShortcutEnabled;
  action: keyboardAction;
};

/**
 * Exhaustive type for all keyboard shortcuts
 */
type ShortcutConfigs = {
  [name: string]: ShortcutConfig;
};

/**
 * Platform-specific name of the modifier key
 */
const MOD = /Mac|iPod|iPhone|iPad/.test(navigator?.platform) ? 'cmd' : 'ctrl';

/**
 * Studio keyboard shortcut config
 */
export const shortcutConfigs: ShortcutConfigs = {
  Cut: {
    shortcuts: [{ key: 'b' }],
    action: (root) => {
      root.project.scene.cutMainTrackClipAtPlayhead();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.toolbar.isCutButtonEnabled;
    },
  },
  Crop: {
    shortcuts: [{ key: 'c', modKeys: [MOD, 'shift'] }],
    action: (root) => {
      root.toolbar.toggleCropTool();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.toolbar.isCropButtonEnabled;
    },
  },
  Zoom: {
    shortcuts: [{ key: 'z', modKeys: [MOD, 'shift'] }],
    action: (root) => {
      root.toolbar.addOrEditZoomEffect();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.toolbar.isZoomButtonEnabled;
    },
  },
  Blur: {
    shortcuts: [{ key: 'b', modKeys: [MOD, 'shift'] }],
    action: (root) => {
      root.toolbar.addOrEditBlurEffect();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.toolbar.isBlurButtonEnabled;
    },
  },
  Text: {
    shortcuts: [{ key: 'o', modKeys: [MOD, 'shift'] }],
    action: (root) => {
      root.toolbar.addOrEditTextClip();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.toolbar.isTextButtonEnabled;
    },
  },
  Delete: {
    shortcuts: [{ key: 'del' }, { key: 'backspace' }],
    action: (root) => {
      root.toolbar.deleteSelected();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.toolbar.isDeleteButtonEnabled;
    },
  },
  PlayPause: {
    shortcuts: [{ key: 'space' }],
    action: (root) => {
      root.playback.togglePlayback();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive;
    },
  },
  Undo: {
    shortcuts: [{ key: 'z', modKeys: [MOD] }],
    action: (root) => {
      const {
        project: { undoManager },
      } = root;
      if (undoManager.canUndo) undoManager.undo();
    },
    enabled: (root) => {
      return root.project.undoManager.canUndo;
    },
  },
  Redo: {
    shortcuts: [{ key: 'z', modKeys: [MOD, 'shift'] }],
    action: (root) => {
      const {
        project: { undoManager },
      } = root;
      if (undoManager.canRedo) undoManager.redo();
    },
    enabled: (root) => {
      return root.project.undoManager.canRedo;
    },
  },
  FrameForward: {
    shortcuts: [{ key: 'right' }],
    action: (root) => {
      root.playback.seekForward();
    },
    enabled: (root) => {
      return !root.playback.isPlaying;
    },
  },
  FrameBack: {
    shortcuts: [{ key: 'left' }],
    action: (root) => {
      root.playback.seekBackward();
    },
    enabled: (root) => {
      return !root.playback.isPlaying;
    },
  },
  TimelineZoomOut: {
    shortcuts: [{ key: '-' }],
    action: (root) => {
      root.timeline.zoom.jumpTimelineZoomBackwards();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.timeline.zoom.canZoomOut;
    },
  },
  TimelineZoomIn: {
    shortcuts: [{ key: '=' }],
    action: (root) => {
      root.timeline.zoom.jumpTimelineZoomForward();
    },
    enabled: (root) => {
      return root.keyboardShortcutsActive && root.timeline.zoom.canZoomIn;
    },
  },
};

/**
 * Helper for capitalizing first letter of word
 */
const capitalizeFirst = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * Returns a platform-specific string describing a
 * keyboard shortcut. Suitable for displaying in hover tooltips (human-readable)
 * but is also machine-readable by the `react-hotkeys-hook` library.
 */
export const getShortcutString = (config: ShortcutConfig) => {
  return config.shortcuts
    .map((shortcut) => {
      return [...(shortcut.modKeys ? shortcut.modKeys : []), shortcut.key]
        .map(capitalizeFirst)
        .join(' + ');
    })
    .join(', ');
};
