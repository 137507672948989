import { useQuery } from 'urql';
import {
  GetAllProjectsDocument,
  GetAllProjectsQuery,
} from '@castify/studio/database-clients/graphql';
import { useUserProfile } from '@castify/studio/auth';
import { ProjectBrowser } from '@castify/studio/components/project-browser';
import { ProjectBrowserHeader } from '@castify/studio/Header';
import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import { useEffect } from 'react';

/**
 * This is a placeholder for the all projects experience. Likely to be moved
 * to a lib in the future.
 *
 * Right now, this component and its children show a little demo of what it
 * looks like to use autogenerated URQL hooks to do basic CRUD operations on
 * the DB without using MST.
 */
const Projects = () => {
  const user = useUserProfile();

  const mst = useMst();

  useEffect(() => {
    mst.reset();
  }, [mst]);

  /**
   * Types for this hook are autogenerated.
   *
   * Because of URQL's caching mechanism, the hook will make this component
   * re-render when its results change (like after adding or deleting a project)
   * due to mutations originating on this client.
   *
   * If projects delete or change serverside or in another tab, however,
   * this component will not automatically refresh. For that, we'd need to use
   * a gql subscription.
   */
  const [{ data, error, fetching }] = useQuery<GetAllProjectsQuery>({
    query: GetAllProjectsDocument,
    requestPolicy: 'cache-and-network',
  });
  /**
   * These liminal states can also be handled inside the single
   * render method below, but this makes it really obvious what's
   * happening.
   */
  if (fetching) return <div>fetching all projects...</div>;
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return <div>oops, an error!</div>;
  }

  return (
    <>
      <ProjectBrowserHeader />
      <ProjectBrowser data={data} />
    </>
  );
};

export default observer(Projects);
