import { Instance, types } from 'mobx-state-tree';
import BoxModel from './box/box.mst';

const DEFAULT_BLUR_INTENSITY = 0.5;

/**
 * Helper for clamping a value between a minimum and maximum
 */
const clamp = (val: number, min: number, max: number) =>
  Math.min(Math.max(min, val), max);

/**
 * A blur box which belongs to a blur effect; can do everything a moveable box
 * can
 */
const BlurBoxModel = BoxModel.named('BlurBoxModel')
  .props({
    /**
     * The intensity of the blur effect; number from 0 to 1
     */
    intensity: types.optional(types.number, DEFAULT_BLUR_INTENSITY),
  })
  .actions((self) => {
    return {
      /**
       * Change the intensity of this blur box. Clamped from 0 to 1
       */
      setIntensity(intensity: number): void {
        self.intensity = clamp(intensity, 0, 1);
      },
    };
  });
export default BlurBoxModel;

export interface IBlurBox extends Instance<typeof BlurBoxModel> {}
