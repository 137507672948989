import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  header: css`
    grid-area: header;

    background-color: ${colors.TEMP.HeaderBackground};
    border-bottom: 2px ${colors.gray[300]} solid;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  `,
  logo: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
export default styles;
