import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './LargePlayButton.styles';
import { useMst } from '@castify/studio/studio-store';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

import { Fab } from '@mui/material';

function LargePlayButton() {
  const {
    playback: { isPlaying, pause, play, playheadAtEnd },
  } = useMst();

  const handleClick = () => {
    if (isPlaying) pause();
    else play();
  };

  return (
    <Fab
      css={styles.playButton}
      color="primary"
      data-pendo-id="play-pause-button"
      onPointerDown={handleClick}
      aria-label="LargePlayButton"
    >
      {playheadAtEnd ? (
        <ReplayRoundedIcon css={styles.icon} />
      ) : (
        <PlayArrowRoundedIcon css={styles.icon} />
      )}
    </Fab>
  );
}

export default observer(LargePlayButton);
