/**
 * @param  {string|null|undefined} seat_uuid
 * @returns string
 */

export function getUserInviteStatus(seat_uuid: string | null | undefined) {
  if (seat_uuid === '') {
    // Should ask product what they would like this to be if data goes bad for some reason.
    return 'Error Status';
  }

  return seat_uuid ? 'Active' : 'Pending';
}
