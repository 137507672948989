import styles from './LeftSidebar.styles';
import { observer } from 'mobx-react-lite';
import { Logo } from '@castify/studio/fe-common';
import { Divider, Drawer, Chip, List, Typography } from '@mui/material';
import SidebarButton from './SidebarButton/SidebarButton';
import SidebarUserProfile from './SidebarUserProfile/SidebarUserProfile';
import OldScreencastifyNotification from './OldScreencastifyNotification/OldScreencastifyNotification';
import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Left side bar for when user is signed in. Assumes user is available when rendered
 */
function LeftSidebar() {
  const location = useLocation();

  return (
    <div css={styles.SidebarContainer} data-testid="left-side-bar">
      <div css={styles.logoContainer}>
        <Logo height={35} withText={true} />
        <Chip css={styles.chip} color="primary" label="NEW" />
      </div>

      <nav aria-label="main mailbox folders" id="TopItems">
        <List>
          <SidebarButton
            isHere={location.pathname.includes('projects')}
            goesTo={'/projects'}
            icon={'video_library'}
            text={'My Videos'}
            data-pendo-id="projects-page-link"
          />
          <SidebarButton
            isHere={location.pathname.includes('account')}
            goesTo={'/account'}
            icon={'account_circle'}
            text={'My Account'}
            data-pendo-id="account-page-link"
          />
        </List>
      </nav>

      <nav id="BottomItems">
        <List css={styles.ListNoBottom}>
          <li>
            <Divider css={styles.Divider} />
          </li>
          <SidebarUserProfile />
          <OldScreencastifyNotification />
        </List>
      </nav>
    </div>
  );
}

export default observer(LeftSidebar);
