import { css } from '@emotion/react';

export const globals = css`
  body {
    margin: 0;
    // prevents accidental user selection of text during drags
    // text boxes and inputs should opt-in to user select where needed
    user-select: none;
  }
`;
