import { css } from '@emotion/react';

const styles = {
  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
  mainBrowser: css`
    width: 100%;
    margin-left: 300px;
  `,
  sideBar: css`
    position: fixed;
  `,
};
export default styles;
