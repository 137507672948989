import { environment } from '@castify/studio/env/browser';

/**
 * Mime types that we can import
 */
const videoMimeTypes = [
  'video/mp4',
  'video/webm',
  'video/mkv',
  'video/flv',
  'video/f4v',
  'video/avi',
  'video/mov',
  'video/wmv',
  'video/mpg',
  'video/mpeg',
  'video/m2v',
  'video/m4v',
  'video/3gp',
  'video/3g2',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-matrosk',
];

/**
 * Drive document type; see:
 * https://developers.google.com/picker/docs/reference#document
 */
type Document = {
  description: string;
  driveError: string;
  driveSuccess: false;
  duration: number;
  embedUrl: string;
  iconUrl: string;
  id: string;
  isShared: true;
  lastEditedUtc: number;
  mimeType: string;
  name: string;
  organizationDisplayName: string;
  serviceId: string;
  sizeBytes: number;
  type: 'video' | 'document' | 'photo';
  url: string;
};

/**
 * Emitted by the drive picker
 */
export type PickerData = {
  action: 'loaded' | 'picked' | 'cancel';
  docs?: Document[];
};

/**
 * Opens drive file picker and returns the drive file ID of the selected file
 * or null if the user canceled the picker.
 *
 * We don't have strict tyes for the picker, unforunately.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
/* istanbul ignore */
export async function showDrivePicker(
  picker: any,
  accessToken: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videoPickerRef: React.MutableRefObject<any>,
) {
  return new Promise<string | null>((resolve, reject) => {
    try {
      const view = new picker.DocsView();
      view.setMimeTypes(videoMimeTypes.join(','));
      view.setIncludeFolders(true);
      view.setOwnedByMe(true);

      const drivePicker = new picker.PickerBuilder()
        /**
         * Authenticate
         */
        .setAppId(environment.googleAuth.driveAppId)
        .setDeveloperKey(environment.firebaseConfig.apiKey)
        .setOAuthToken(accessToken)

        /**
         * Set up visual appearance
         */
        .addView(view)
        .addView(picker.ViewId.DOCS_VIDEOS)
        .hideTitleBar()
        .enableFeature(picker.Feature.NAV_HIDDEN)
        .setSize(1051, 650)
        .setTitle('Select a Video to Import')

        /**
         * Callbacks for user interaction
         */
        .setCallback((data: PickerData) => {
          if (data.action === 'picked' && data.docs) {
            resolve(data.docs[0].id);
          } else if (data.action === 'cancel') {
            resolve(null);
          }
        })
        .build();

      /**
       * Show it and store a reference to it so we can close it later
       */
      drivePicker.setVisible(true);
      videoPickerRef.current = drivePicker;

      /**
       * Force the picker to the top of the z-index stack so it appears on top
       * of the modal overlay
       */
      Array.from(document.getElementsByClassName('picker-dialog')).forEach(
        (element) => {
          (element as HTMLElement).style.zIndex = '9999';
        },
      );
    } catch (error) {
      reject(error);
    }
  });
}
