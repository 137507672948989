import ProgressDialog from '../common/ProgressDialog';
import { useMutation } from 'urql';
import {
  CreateNewVideoSourceDocument,
  CreateNewVideoSourceMutation,
  CreateNewVideoSourceMutationVariables,
} from '@castify/studio/database-clients/graphql';
import { useEffect } from 'react';
import { useGetAccessToken } from '@castify/studio/auth';
import { getMD5 } from '../common/driveHelpers';

/**
 * Import requester props
 */
export interface RequestImportProps {
  /**
   * ID of the drive file to import
   */
  driveFileId: string;
  /**
   * UUID of the project in the DB
   */
  projectUuid: string;
  /**
   * Callback to invoke when done
   */
  onImportRequested: (videoSourceUuid: string) => void;
  /**
   * Error callback
   */
  onFlowError: (error: unknown) => void;
}

/**
 * This component is responsible for requesting an import via a write to the
 * video sources table, and then showing a progress dialog with a progress bar
 * in an indeterminate state while the write is in progress.
 */
function RequestImport({
  driveFileId,
  projectUuid,
  onImportRequested,
  onFlowError,
}: RequestImportProps) {
  /**
   *  We need the access token in order to request an md5 of the file
   */
  const getAccessToken = useGetAccessToken();

  /**
   * This urql hook allows us to write to the video sources table.
   */
  const [{ error }, executeMutation] = useMutation<
    CreateNewVideoSourceMutation,
    CreateNewVideoSourceMutationVariables
  >(CreateNewVideoSourceDocument);

  /**
   * On component mount, kicks of writing to the video sources table. Declared
   * dependencies are all immutable, so this will only run once.
   */
  useEffect(() => {
    (async () => {
      try {
        if (!driveFileId) return;
        /**
         * We need the MD5 of the file to check to see if this is a repeat
         * ingress.
         */
        const driveMd5Sum = await getMD5(driveFileId, await getAccessToken());

        /**
         * The mutation returns the entire row
         */
        const response = await executeMutation({
          driveFileId: driveFileId,
          driveMd5Sum,
          projectUuid: projectUuid,
        });
        /**
         * Return just the ID to the parent via the callback
         */
        const videoSourceUuid = response.data?.insert_video_sources_one?.uuid;
        if (!videoSourceUuid) throw new Error('no video source ID in response');
        onImportRequested(videoSourceUuid);
      } catch (error) {
        onFlowError(error);
      }
    })();
    /**
     * We deliberately disable the linter rule here as we force this to
     * run exactly once on mount and then never again. (Following the linter rule
     * led to a bug because the mutation hook seemingly refreshed the callback
     * every render.)
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If we hit an error in the subscription, we'll see it via the error
   * object coming from the urql hook. This must be in a useEffect so
   * that we do not update state during the render.
   */
  useEffect(() => {
    if (error) {
      onFlowError(error);
    }
  }, [error, onFlowError]);

  /**
   * The progress dialog here shows an inteterminate bar.
   */
  return <ProgressDialog title="Starting import..." determinate={false} />;
}

export default RequestImport;
