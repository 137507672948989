import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { PropsWithChildren, useState } from 'react';
import { AddUserInvitationForm } from '../AddUserInvitationForm/AddUserInvitationForm';
import styles from './AddUserInvitationButton.styles';
interface AddUserInvitationFormProps {
  license_uuid: string;
  max_pro_member_seats: number;
  max_pro_restricted_seats: number;
  max_standard_member_seats: number;
  max_standard_restricted_seats: number;
}

export const AddUserInvitationButton = ({
  license_uuid,
  children,
  max_pro_member_seats,
  max_pro_restricted_seats,
  max_standard_member_seats,
  max_standard_restricted_seats,
}: PropsWithChildren<AddUserInvitationFormProps>) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        data-testid="open-add-user-modal-button-id"
      >
        {children}
      </Button>
      {dialogOpen && (
        <Dialog
          keepMounted={false}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          data-testid="add-user-dialog-id"
        >
          <div
            css={styles.buttonContainer}
            data-testid="close-dialog-button-id"
          >
            <Button onClick={() => setDialogOpen(false)}>X</Button>
          </div>

          <div css={styles.formContainer}>
            <AddUserInvitationForm
              license_uuid={license_uuid}
              max_pro_member_seats={max_pro_member_seats}
              max_pro_restricted_seats={max_pro_restricted_seats}
              max_standard_member_seats={max_standard_member_seats}
              max_standard_restricted_seats={max_standard_restricted_seats}
            ></AddUserInvitationForm>
          </div>
        </Dialog>
      )}
    </>
  );
};
