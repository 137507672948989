import { st } from '@castify/studio/fe-common';
import { Instance, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';

/**
 * Boxes can't be smaller than this % of parent on either
 * axis
 */
const MINIMUM_BOX_SIZE = 0.05;

type RotatableBoxVolatileState = {
  placeholderRotation: number | null;
};

/**
 * State backing the resizable, movable boxes used in the blur, crop, zoom
 * effects. As all three effets need boxes to be constrained to the bounding box
 * (the user can't drag them off the preview) and also do not enable rotation,
 * so this model is separate from the model backing text (which does not need
 * clamping and needs to enable rotation).
 *
 * The division of labor with the UI code for drags is as follows: this model
 * accepts positions the box and corners should move to, clamping values
 * to stay in bounds; implementation details of drags are left to the UI code.
 */
const RotatableBoxModel = types
  .model('RotatableBoxModel', {
    /**
     * uuid which is useful when this model is part of an array of models
     */
    uuid: types.optional(types.identifier, nanoid),
    /**
     * The center point for this box on the x axis
     */
    centerX: types.optional(types.number, 0.5),
    /**
     * The center point for this box on the y axis
     */
    centerY: types.optional(types.number, 0.5),
    /**
     * width of box expressed in % of container between 0 and 1
     */
    width: types.optional(types.number, 1),
    /**
     * height of box expressed in % of container between 0 and 1
     */
    height: types.optional(types.number, 1),
    /**
     * Degrees, not radians
     */
    rotate: types.optional(types.number, 0),
  })
  .volatile(
    (): RotatableBoxVolatileState => ({
      placeholderRotation: null,
    }),
  )
  .views((self) => {
    return {
      /**
       * How far is the top side of the box from the top bound?
       */
      get top(): st.ratio {
        return self.centerY - self.height / 2;
      },
      /**
       * How far is the left side of the box from the left bound?
       */
      get left(): st.ratio {
        return self.centerX - self.width / 2;
      },
      /**
       * How far is the bottom side of the box from the top bound?
       */
      get bottom(): st.ratio {
        return self.centerY + self.height / 2;
      },
      /**
       * How far is the right side of the box from the left bound?
       */
      get right(): st.ratio {
        return self.centerX + self.width / 2;
      },
      /**
       *  Confines a given value to be within its min and max ranges,
       */
      applyLimit: (min: number, max: number, num: number) =>
        Math.min(max, Math.max(min, num)),
      // TODO: Keep below code to explore MST Cache bug
      // convertPointToPixels(
      //   point: st.point,
      //   containerWidth: st.px,
      //   containerHeight: st.px,
      // ): st.point {
      //   return {
      //     x: point.x * containerWidth,
      //     y: point.y * containerHeight,
      //   };
      // },
      /**
       *  Rotates a given point around a given centerpoint,
       *  giving back the new points location.
       */
      rotateAroundCenter(
        pointToRotate: st.point,
        centerPoint: st.point,
        rotation: number,
      ): st.point {
        // Make angle negative to rotate clockwise
        const cosT = Math.cos(rotation);
        const sinT = Math.sin(rotation);

        // Translate point to orgin
        const originX = pointToRotate.x - centerPoint.x;
        const originY = pointToRotate.y - centerPoint.y;

        // Rotate point around origin
        const rotatedX = originX * cosT - originY * sinT;
        const rotatedY = originX * sinT + originY * cosT;

        // Translate the point back to where it was
        return {
          x: rotatedX + centerPoint.x,
          y: rotatedY + centerPoint.y,
        };
      },
      // TODO: MAKE ST FOR THIS
      get rotationInRadians(): number {
        return self.rotate * (Math.PI / 180);
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Manual setter that ignores all bounds checks
       */
      set(size: {
        left: st.ratio;
        top: st.ratio;
        width: st.ratio;
        height: st.ratio;
      }): void {
        self.centerY = size.left + size.width / 2;
        self.centerX = size.top + size.height / 2;
        self.width = size.width;
        self.height = size.height;
      },
      /**
       * Move the box; accepts upper-left corner expressed as % of container.
       * Clamped to bounds of the container
       */
      move(newX: st.ratio, newY: st.ratio): void {
        // Apply limits to the new value
        newX = Math.min(Math.max(0, newX), 1);
        newY = Math.min(Math.max(0, newY), 1);

        // TODO: This is constantly a few pixels to the right - rounding error?
        self.centerX = newX;
        self.centerY = newY;
      },
      /**
       *  Resizes the given textbox, taking into consideration a boxes rotation
       */
      resize(
        mouseLocation: st.point,
        containerHeight: number,
        containerWidth: number,
      ): void {
        const { rotationInRadians } = self;
        // TODO: Keep below code to explore MST Cache bug
        // const centerInPixels = self.convertPointToPixels(
        //   {
        //     x: self.centerX,
        //     y: self.centerY,
        //   },
        //   containerWidth,
        //   containerHeight,
        // );
        const centerInPixels = {
          x: self.centerX * containerWidth,
          y: self.centerY * containerHeight,
        };

        const unrotatedMouseLocation = self.rotateAroundCenter(
          mouseLocation,
          centerInPixels,
          -rotationInRadians,
        );

        // Grab corner opposite from the one being dragged
        const xCoord =
          unrotatedMouseLocation.x > centerInPixels.x ? self.left : self.right;
        const yCoord =
          unrotatedMouseLocation.y > centerInPixels.y ? self.top : self.bottom;
        const fixedCorner = {
          x: xCoord * containerWidth,
          y: yCoord * containerHeight,
        };
        const rotatedFixedCorner = self.rotateAroundCenter(
          fixedCorner,
          centerInPixels,
          rotationInRadians,
        );

        // Calculate the new centerpoint using the corners of our new box
        // Grab updated center point after mutation

        const newCenterPoint = {
          x: (rotatedFixedCorner.x + mouseLocation.x) / 2,
          y: (rotatedFixedCorner.y + mouseLocation.y) / 2,
        };

        // GetWidth/Height In Pixels
        // Calculate the new width and height for our box
        const newUnrotatedFixedCorner = self.rotateAroundCenter(
          rotatedFixedCorner,
          newCenterPoint,
          -rotationInRadians,
        );
        const newUnrotatedUnfixedCorner = self.rotateAroundCenter(
          mouseLocation,
          newCenterPoint,
          -rotationInRadians,
        );
        const newWidthInPixels = Math.abs(
          newUnrotatedFixedCorner.x - newUnrotatedUnfixedCorner.x,
        );
        const newHeightInPixels = Math.abs(
          newUnrotatedFixedCorner.y - newUnrotatedUnfixedCorner.y,
        );

        // Convert and save to MST
        let newCenterX = newCenterPoint.x / containerWidth;
        let newCenterY = newCenterPoint.y / containerHeight;
        let newWidth = newWidthInPixels / containerWidth;
        let newHeight = newHeightInPixels / containerHeight;

        newCenterX = self.applyLimit(0, 1, newCenterX);
        newCenterY = self.applyLimit(0, 1, newCenterY);
        newWidth = self.applyLimit(MINIMUM_BOX_SIZE, 0.9, newWidth);
        newHeight = self.applyLimit(MINIMUM_BOX_SIZE, 0.9, newHeight);

        self.height = newHeight;
        self.width = newWidth;

        if (
          newCenterX > 0 &&
          Number.parseFloat(newWidth.toFixed(2)) > MINIMUM_BOX_SIZE
        )
          self.centerX = newCenterX;
        if (
          newCenterY > 0 &&
          Number.parseFloat(newHeight.toFixed(2)) > MINIMUM_BOX_SIZE
        )
          self.centerY = newCenterY;
      },
      /**
       *  Takes in a mouse pointers location and calculates its
       *  rotation relative to the centerpoint of our textbox.
       *
       *  Updates the projected rotation of our eventual edit, doesn't actually
       *  update the boxes rotation.
       */
      setRotationFromMouse(
        mouseInCanvasX: st.ratio,
        mouseInCanvasY: st.ratio,
        containerBounds: DOMRect,
      ): void {
        const deltaX = mouseInCanvasX - self.centerX * containerBounds.width;
        const deltaY = mouseInCanvasY - self.centerY * containerBounds.height;

        // Find rotation in radians based on how far we went
        let rotation = Math.atan2(deltaY, deltaX);
        // Convert radians to degrees
        rotation *= 180 / Math.PI;
        // Add 90 to normalize with the handle position being on top instead of on the side
        rotation += 90;

        // Snap the rotation back to origin if it gets close enough
        if (rotation >= -4 && rotation <= 4) rotation = 0;

        self.placeholderRotation = rotation;
      },
      /**
       *  Updates the boxes actual rotation value using
       *  whatever the current projected rotation is.
       */
      setRealRotation(): void {
        if (self.placeholderRotation !== null) {
          self.rotate = self.placeholderRotation;
          self.placeholderRotation = null;
        }
      },
    };
  });

export default RotatableBoxModel;

export interface IRotatableBoxModel
  extends Instance<typeof RotatableBoxModel> {}
