import { v4 as uuidv4 } from 'uuid';

export const getAnonymousUserIdentifier = (): string => {
  return (
    sessionStorage.getItem('userIdentifier') || setAnonymousUserIdentifier()
  );
};

const setAnonymousUserIdentifier = (): string => {
  const userIdentifier = uuidv4();
  sessionStorage.setItem('userIdentifier', userIdentifier);
  return userIdentifier;
};
