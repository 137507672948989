import { breakpoints } from '@castify/studio/fe-common';
import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  header: css`
    width: 100%;
    height: 100%;
    /* TODO: Move the below color to a global */
    background-color: ${colors.gray[0]};
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    border-bottom: 2px ${colors.gray[300]} solid;
  `,
  headerSection: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    gap: 20px;
  `,
  titleInput: css`
    grid-area: TitleInput;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  `,
  titleInpuCharCount: css`
    margin-left: 10px;
    color: ${colors.gray[500]};
  `,
  titleInpuCharCountWithError: css`
    color: ${colors.red};
  `,
};
export default styles;
