import { MainTrackClip, StillSourceModel } from '@castify/studio/studio-store';

/**
 * Arguments are documented in paintFrame
 */
type IPaintStillSource = {
  ctx: CanvasRenderingContext2D;
  canvasElm: HTMLCanvasElement;
  activeClip: MainTrackClip;
};

/**
 * Paint a solid-color still source
 */
export const paintStillSource = ({
  ctx,
  activeClip,
  canvasElm,
}: IPaintStillSource) => {
  // type guard
  if (!StillSourceModel.is(activeClip.source)) return;

  // paint
  ctx.fillStyle = activeClip.source.color;
  ctx.fillRect(0, 0, canvasElm.width, canvasElm.height);
};
