import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  button: css`
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
  `,
  icon: css`
    font-size: 20px;
  `,
};
export default styles;
