import { css } from '@emotion/react';
import { colors, st } from '@castify/studio/fe-common';

const styles = {
  thumbnailStrip: css`
    box-sizing: content-box;
  `,
  thumbnailContainer: (thumbnailHeight: st.px) => css`
    position: absolute;
    height: ${thumbnailHeight}px;
    pointer-events: none;
    border-right: 1px solid ${colors.gray[0]};
    border-left: 1px solid ${colors.gray[0]};
    background-color: black;

    &:last-of-type {
      border-right: none;
    }
    &:first-of-type {
      border-left: none;
    }
  `,
  imageContainer: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  thumbnailImage: (thumbnailWidth: st.px) => css`
    display: block;
    max-height: 100%;
    max-width: ${thumbnailWidth}px;
    margin: 0 auto;
  `,
  debugText: (thumbnailWidth: st.px) => css`
    overflow-wrap: anywhere;
    position: absolute;
    bottom: 0;
    width: ${thumbnailWidth}px;
    text-align: center;
    color: white;
  `,
};
export default styles;
