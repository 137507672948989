import { css } from '@emotion/react';

const styles = {
  debugText: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
  `,
  manifestId: css`
    font-family: monospace;
  `,
};

export default styles;
