import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './TitleDisplay.styles';
import { useMst } from '@castify/studio/studio-store';

import { Typography, useMediaQuery } from '@mui/material';

function TitleDisplay() {
  const {
    project,
    playback: { isPlaying },
  } = useMst();

  const matchesMediaQuery = useMediaQuery('(min-width: 576px)');

  if (isPlaying) return <></>;
  return (
    <Typography
      noWrap
      css={[styles.title, !matchesMediaQuery && styles.smallFont]}
    >
      {project.title}
    </Typography>
  );
}

export default observer(TitleDisplay);
