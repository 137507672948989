import { useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import styles from './PlayerDebugText.styles';
import { AllVideoPairNames } from '../playerHooks/useVideoPlayer';

interface PlayerDebugTextProps {
  playerName: AllVideoPairNames;
}

const extractVideoIdFromManifestUrl = (url: string) => {
  const split = url.split('/');
  return split[split.length - 2]?.slice(0, 15);
};

/**
 * This component exists to display debug information about the
 * normally-invisible video players when they become visible in debug mode.
 */
const PlayerDebugText = ({ playerName }: PlayerDebugTextProps) => {
  const {
    playback: {
      isPairAActive,
      playerAManifestUrl,
      playerBManifestUrl,
      scrubPlayerManifestUrl,
      isPlayerAManifestLoading,
      isPlayerBManifestLoading,
      isScrubManifestLoading,
      isPlayerAPlaybackRequested,
      isPlayerBPlaybackRequested,
    },
  } = useMst();

  let isActive = false;
  let manifestUrl;
  let isLoading;
  let isPlaybackPending;

  if (playerName === AllVideoPairNames.A) {
    manifestUrl = extractVideoIdFromManifestUrl(playerAManifestUrl);
    isActive = isPairAActive;
    isLoading = isPlayerAManifestLoading;
    isPlaybackPending = isPlayerAPlaybackRequested;
  } else if (playerName === AllVideoPairNames.B) {
    manifestUrl = extractVideoIdFromManifestUrl(playerBManifestUrl);
    isActive = !isPairAActive;
    isLoading = isPlayerBManifestLoading;
    isPlaybackPending = isPlayerBPlaybackRequested;
  } else if (playerName === AllVideoPairNames.Scrub) {
    manifestUrl = extractVideoIdFromManifestUrl(scrubPlayerManifestUrl);
    isLoading = isScrubManifestLoading;
  }

  return (
    <div css={styles.debugText}>
      <div>
        {playerName} [
        <span>
          {isLoading ? '*' : ''}
          {isPlaybackPending ? '>' : ''}
          {isActive ? 'a' : ''}
        </span>
        ]
      </div>
      <div css={styles.manifestId}>{manifestUrl}</div>
    </div>
  );
};

export default observer(PlayerDebugText);
