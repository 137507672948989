import { ButtonTooltip } from '@castify/studio/fe-common';
import Typography from '@mui/material/Typography';
import { useMst } from '@castify/studio/studio-store';
import { SerializedStyles } from '@emotion/react';
import Button from '@mui/material/Button';
import { styles } from './buttons.styles';
import { DriveIcon } from './DriveIcon';
import { observer } from 'mobx-react-lite';

interface DriveImportButtonProps {
  /**
   * Allows overriding styles from the parent. Expects the same thing the
   * emotion CSS prop expects.
   */
  styleOverride?: SerializedStyles;
  /**
   * Allows caller to run callback in the onClick handler
   */
  afterClick?: () => void;
}

/**
 * A button that kicks off the drive import flow
 */
function DriveImportButton({
  styleOverride,
  afterClick,
}: DriveImportButtonProps) {
  const {
    ingress: { startDriveImportFlow },
  } = useMst();
  return (
    <ButtonTooltip title="Import Media From Google Drive">
      <Button
        data-testid="drive-import-button"
        css={[styles.button, styleOverride]}
        startIcon={<DriveIcon viewBox="0 -1 16 16" css={styles.icon} />}
        variant="white"
        onClick={() => {
          if (afterClick) afterClick();
          startDriveImportFlow();
        }}
      >
        <Typography>Drive</Typography>
      </Button>
    </ButtonTooltip>
  );
}
export default observer(DriveImportButton);
