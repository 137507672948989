import { useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { AllVideoPairNames } from '../playerHooks/useVideoPlayer';
import { styles } from './DebugPlayerLayout.styles';
import PlayerDebugText from './PlayerDebugText';

interface PlayerLayoutProps {
  videoA: ReactElement;
  videoB: ReactElement;
  scrubVideo: ReactElement;
}

/**
 * This layout component allows us to dynamically restyle the container
 * for the video players without the playback system needing to re-render
 *
 * The video players are usually invisible, but in debug mode become visible
 * and show some debug information.
 */
const DebugPlayerLayout = ({
  videoA,
  videoB,
  scrubVideo,
}: PlayerLayoutProps) => {
  const {
    debugger: { debugMode },
  } = useMst();

  return (
    <div
      css={[styles.videoElmsInvisible, debugMode && styles.videoElmsVisible]}
    >
      <PlayerDebugText playerName={AllVideoPairNames.A} />
      {videoA}
      <PlayerDebugText playerName={AllVideoPairNames.B} />
      {videoB}
      <PlayerDebugText playerName={AllVideoPairNames.Scrub} />
      {scrubVideo}
      <div>a = active</div>
      <div>* = loading manifest</div>
      <div>{'>'} = play request pending</div>
    </div>
  );
};

export default observer(DebugPlayerLayout);
