import { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './ExportButton.styles';
import { Button, Popover, Skeleton, Typography } from '@mui/material';
import { useMst } from '@castify/studio/studio-store';
import { useParams } from 'react-router-dom';
import { ButtonTooltip, StudioParams } from '@castify/studio/fe-common';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

interface ExportButtonProps {
  showSkeleton?: boolean;
}

function ExportButton({ showSkeleton }: ExportButtonProps) {
  const {
    project: {
      scene: { isTimelineEmpty },
    },
  } = useMst();
  const { projectId } = useParams<StudioParams>();
  const url = `${window.location.origin}/watch/${projectId}`;

  const buttonRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const copyWatchPageLink = () => {
    navigator.clipboard.writeText(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const id = open ? 'simple-popover' : undefined;

  if (showSkeleton)
    return <Skeleton variant="rectangular" width={153} height={48} />;

  return (
    <>
      <ButtonTooltip title={'Copy Share Link'}>
        <Button
          ref={buttonRef}
          disabled={isTimelineEmpty}
          variant="contained"
          color="primary"
          data-pendo-id="copy-share-link-button"
          onClick={copyWatchPageLink}
        >
          <Typography>Copy Share Link</Typography>
        </Button>
      </ButtonTooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div css={styles.popoverHeader}>
          <AssignmentTurnedInIcon css={styles.popoverHeaderIcon} />
          <Typography css={styles.popoverHeaderText} sx={{ p: 2 }}>
            Watch Page URL Copied to Clipboard
          </Typography>
        </div>
      </Popover>
    </>
  );
}

export default observer(ExportButton);
