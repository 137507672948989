import { IClip } from '@castify/studio/studio-store';
import styles from './Thumbnails.styles';

interface ThumbnailsProps {
  clip: IClip;
  debugMode: boolean;
}

const Thumbnails = ({ clip, debugMode }: ThumbnailsProps) => {
  return (
    <div
      css={styles.thumbnailStrip}
      /**
       * This "filmstrip" container is offset; at any given time, if the
       * clip is trimmed on either end, the filmstrap is larger than the
       * portion which we actually see on screen.
       */
      style={{
        transform: `translateX(-${clip.trimInOffset}px)`,
        width: `${clip.thumbnailContainerWidth}px`,
        backgroundColor: clip.backgroundColor,
      }}
    >
      {clip.thumbnails.map(({ url, startTime, thumbLeft, onscreen }, i) => (
        <div
          css={styles.thumbnailContainer(clip.thumbnailHeight)}
          style={{ left: `${thumbLeft}px`, width: `${clip.thumbnailWidth}px` }}
          /**
           * We can't key off of just the url because URLs can repeat,
           * but using just the key can cause React to render the wrong
           * DOM effects when the list changes, so we combine them.
           */
          key={`${i}-${url}`}
        >
          {/* In debug mode, the dev can see each thumbnail's timestamp within
              the clip in question */}
          {debugMode && (
            <span css={styles.debugText(clip.thumbnailWidth)}>{startTime}</span>
          )}
          {/* Thumbnails not visible on screen are not rendered, preventing
              us from hammering the backend with unnecessary thumbnail
              requests for images we will not display */}
          {onscreen && (
            <div css={styles.imageContainer}>
              <img
                css={styles.thumbnailImage(clip.thumbnailWidth)}
                src={url}
                alt={`thumbnail for ${startTime} seconds`}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Thumbnails;
