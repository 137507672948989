import { st } from '@castify/studio/fe-common';
import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import { getScene } from '../project/scene/scene.mst';
import { MainTrackClip } from '../project/scene/sceneTypes';
import RootModel from '../root.mst';
import VideoSourceModel from '../project/scene/videoSource.mst';
import { getToolManager } from '../tools/toolManager.mst';
import { getSelection } from '../selection/selection.mst';
import { IBlurEffect } from '../project/scene/blurEffect.mst';
import { getTimeline } from '../timeline/timeline.mst';
import { IClip } from '../project/scene/clip.mst';

export enum VideoPairName {
  A = 'A',
  B = 'B',
}

/**
 * Not actually one frame as video frame rates are not known to us in the browser
 * and can be variable... but close enough
 */
const ONE_FRAME = 1000 / 25;

/**
 * State, views, & actions backing playback state, which is used by Timeline,
 * Preview, and Toolbar-- and as sometimes involved in computed views living
 * in those components' MST facade models
 */
const PlaybackModel = types
  .model('PlaybackModel', {
    /**
     * Is scrub mode active, and if so, what is the scrub position?
     */
    scrubTime: types.optional(
      types.union(types.number, types.undefined),
      undefined,
    ),

    /**
     * Seek time is where the user clicks in the timeline on a video
     */
    seekTime: types.optional(types.number, 0),
    /**
     * A unique id which changes each time a seek takes place. Helps
     * us distinguish repeated seeks to same point when playing
     * in the UI (MST observables do not fire repeatedly when passed the
     * same value)
     */
    seekId: types.optional(types.string, ''),
    /**
     * Playback clock is used when the video is playing; intended to be
     * updated by the video player
     */
    playbackClock: types.optional(types.number, 0),
    /**
     * Whether or not the video is currently playing
     */
    isPlaying: types.optional(types.boolean, false),
    /**
     * Which video pair (used for playback) is currently shown
     */
    playingPairName: types.optional(
      types.enumeration<VideoPairName>(Object.values(VideoPairName)),
      VideoPairName.A,
    ),
    /**
     * What manifest if any does the scrub player have loaded?
     */
    scrubPlayerManifestUrl: types.optional(types.string, ''),
    /**
     * Is scrub player in a loading state?
     */
    isScrubManifestLoading: types.optional(types.boolean, false),
    /**
     * What manifest if any does player A have loaded?
     */
    playerAManifestUrl: types.optional(types.string, ''),
    /**
     * Is player A in a loading state?
     */
    isPlayerAManifestLoading: types.optional(types.boolean, false),
    /**
     * Is player A in a "playback requested" state? E.g., have we called play(),
     * but has the promise returned by play() not yet resolved?
     */
    isPlayerAPlaybackRequested: types.optional(types.boolean, false),
    /**
     * What manifest if any does player B have loaded?
     */
    playerBManifestUrl: types.optional(types.string, ''),
    /**
     * Is player B in a loading state?
     */
    isPlayerBManifestLoading: types.optional(types.boolean, false),
    /**
     * Is player B in a "playback requested" state? E.g., have we called play(),
     * but has the promise returned by play() not yet resolved?
     */
    isPlayerBPlaybackRequested: types.optional(types.boolean, false),
    /**
     * Volume of the player between 0 and 1
     */
    volume: types.optional(types.number, 1),
    /**
     * Is the player fullscreen?
     */
    isFullScreen: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      /**
       * The inverse of the playing pair's name
       */
      get inactivePairName(): VideoPairName {
        return self.playingPairName === VideoPairName.A
          ? VideoPairName.B
          : VideoPairName.A;
      },
      /**
       * The clip being scrubbed, if any
       */
      get scrubClip(): MainTrackClip | undefined {
        if (self.scrubTime === undefined) return undefined;
        return getScene(self).mainTrack.getMainTrackClipAtTime(self.scrubTime);
      },
      /**
       * Time within the scrubbing clip we are scrubbing to, relative
       * to its start
       */
      get scrubClipTime(): st.ms | undefined {
        if (!this.scrubClip || !self.scrubTime) return undefined;
        const trimIn = VideoSourceModel.is(this.scrubClip.source)
          ? this.scrubClip.source.trimIn
          : 0;
        return self.scrubTime - this.scrubClip.start + trimIn;
      },

      /**
       * Scrub clip time but in sec
       */
      get scrubClipTimeSec(): st.sec | undefined {
        if (!this.scrubClipTime) return undefined;
        return this.scrubClipTime / 1000;
      },

      /**
       * The clip being seeked
       */
      get seekClip(): MainTrackClip | undefined {
        return getScene(self).mainTrack.getMainTrackClipAtTime(self.seekTime);
      },
      /**
       * Time within the seek clip we are seeking to, relative
       * to its start
       */
      get seekClipTime(): st.ms | undefined {
        if (!this.seekClip) return undefined;
        const trimIn = VideoSourceModel.is(this.seekClip.source)
          ? this.seekClip.source.trimIn
          : 0;
        return self.seekTime - this.seekClip.start + trimIn;
      },
      /**
       * seekClipTime but in seconds
       */
      get seekClipTimeSec(): st.sec | undefined {
        if (!this.seekClipTime) return undefined;
        return this.seekClipTime / 1000;
      },
      /**
       * The position of the playhead
       */
      get playheadPosition(): st.ms {
        return self.isPlaying ? self.playbackClock : self.seekTime;
      },

      /**
       * The playing clip
       */
      get playingClip(): MainTrackClip | undefined {
        if (!self.isPlaying) return undefined;
        return getScene(self).mainTrack.getMainTrackClipAtTime(
          self.playbackClock,
        );
      },

      /**
       * The next clip behind playing clip
       */
      get nextPlayingClip(): MainTrackClip | undefined {
        const playingClip = this.playingClip;
        return (
          playingClip &&
          getScene(self).mainTrack.getNextMainTrackClipById(playingClip.uuid)
        );
      },

      /**
       * The in point of the next playing clip, if it is a video source. Has
       * no defined meaning for a still clip
       */
      get nextPlayingClipStartSec(): st.sec | undefined {
        if (!this.nextPlayingClip) return undefined;
        if (!VideoSourceModel.is(this.nextPlayingClip.source)) return undefined;
        return this.nextPlayingClip.source.trimIn / 1000;
      },

      get activeMainTrackClip(): IClip | undefined {
        return getScene(self).mainTrack.getMainTrackClipAtTime(
          this.playheadPosition,
        );
      },

      /**
       * A blur effect if one is active
       */
      get activeBlurEffect(): IBlurEffect | undefined {
        return getScene(self).mainTrack.getBlurEffectAtTime(self.playbackClock);
      },
      /**
       * Is the first video pair shown?
       */
      get isPairAActive(): boolean {
        return self.playingPairName === VideoPairName.A;
      },

      /**
       * Is the active player manifest loading?
       */
      get isActivePlayerManifestLoading(): boolean {
        if (self.playingPairName === VideoPairName.A)
          return self.isPlayerAManifestLoading;
        else return self.isPlayerBManifestLoading;
      },
      /**
       * Is the NON-active player's manifest loading?
       */
      get isInactivePlayerManifestLoading(): boolean {
        if (self.playingPairName === VideoPairName.A) {
          return self.isPlayerBManifestLoading;
        } else return self.isPlayerAManifestLoading;
      },

      /**
       * manifest url in the active player
       */
      get activePlayerManifestUrl(): string {
        if (self.playingPairName === VideoPairName.A)
          return self.playerAManifestUrl;
        else return self.playerBManifestUrl;
      },

      /**
       * manifest url in the inactive player
       */
      get inactivePlayerManifestUrl(): string {
        if (self.playingPairName === VideoPairName.A)
          return self.playerBManifestUrl;
        else return self.playerAManifestUrl;
      },

      /**
       * Is either player in a playback requested state, e.g. between calling
       * play() and its promise resolving?
       */
      get isPlaybackRequested(): boolean {
        return (
          self.isPlayerAPlaybackRequested || self.isPlayerBPlaybackRequested
        );
      },

      /**
       * Returns the absolute timestamp of either the playhead or scrubhead--
       * the timestamp of whatever we're seeing on screen. Has "compostior"
       * in the name because this getter is really purpose-built for the
       * compositor and should likely not be called elsewhere.
       */
      get compositorTimestamp(): st.ms {
        if (self.isPlaying) return self.playbackClock;
        if (self.scrubTime) return self.scrubTime;
        else return self.seekTime;
      },

      /**
       * Is the playhead at the end of the entire scene?
       */
      get playheadAtEnd(): boolean {
        const sceneDuration = getScene(self).mainTrack.totalDuration;
        const playheadPosition = self.playbackClock;
        return playheadPosition >= sceneDuration;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Allows preview to start playing through the edited video
       *
       * Closes any open tools as tools behave badly when playing
       */
      play() {
        getToolManager(self).closeTool();
        // restart the video if at the end
        if (self.playheadAtEnd) this.setSeekTime(0);
        self.isPlaying = true;
      },

      /**
       * Stops preview from playing through the edited video
       */
      pause() {
        this.setSeekTime(self.playbackClock);
        self.isPlaying = false;
      },

      /**
       * Toggles the playback state from play <-> paused
       */
      togglePlayback() {
        if (self.isPlaying) this.pause();
        else this.play();
      },

      /**
       * Setter for scrub position.
       *
       * Clamps scrub time to the scene duration-- but when a tool is open,
       * instead clams to the clip's start and end
       */
      setScrubTime(scrubTime: st.ms): void {
        if (self.isPlaying) return;

        // disabled when swapping
        if (getTimeline(self).swappingClip) return;

        // The scrub will be be to constrained to this range
        // it defaults to applying no constraints at all
        let maxScrub = getScene(self).mainTrack.totalDuration;
        let minScrub = 0;

        // when a tool is open, clamp to clip
        if (getToolManager(self).isAnyToolOpen) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const selectedClip = getSelection(self).selectedClip!;
          maxScrub = selectedClip.end;
          minScrub = selectedClip.start;
        }

        // clamp, but always subtract 1 ms so we see the last frame
        // (necessary due to the inclusive/exclusive way time ranges work)
        const newTime = Math.max(Math.min(scrubTime, maxScrub - 1), minScrub);
        self.scrubTime = newTime;
      },

      /**
       * Setter for seek position. Clamped between zero and timeline duration
       */
      setSeekTime(seekTime: st.ms): void {
        const updatedSeekTime = Math.max(
          0,
          Math.min(seekTime, getScene(self).mainTrack.totalDuration),
        );

        self.seekTime = updatedSeekTime;
        self.playbackClock = updatedSeekTime;
        self.seekId = nanoid();
      },

      /**
       * Allows jumping forward by ~1 frame
       */
      seekForward(): void {
        this.setSeekTime(self.seekTime + ONE_FRAME);
      },

      /**
       * Allows jumping backward by ~1 frame
       */
      seekBackward(): void {
        this.setSeekTime(self.seekTime - ONE_FRAME);
      },

      /**
       * sync the playback time with video player
       */
      setPlaybackClock(playbackClock: st.ms) {
        // If the playhead is about to leave the timeline, pull us back onto it
        getTimeline(self).ensurePlayheadInView();
        self.playbackClock = playbackClock;
      },

      /**
       * Ends a scrub
       */
      endScrub(): void {
        self.scrubTime = undefined;
      },
      /**
       * Flip the pairs
       */
      switchActiveVideoPair(): void {
        if (self.playingPairName === VideoPairName.A) {
          self.playingPairName = VideoPairName.B;
        } else self.playingPairName = VideoPairName.A;
      },

      /**
       * Reset active pair state to pair A
       */
      makePairAActive(): void {
        self.playingPairName = VideoPairName.A;
      },

      /**
       * Reset active pair state to pair B
       */
      makePairBActive(): void {
        self.playingPairName = VideoPairName.B;
      },

      /**
       * Setting for scrubPlayerManifestUrl
       */
      setScrubPlayerManifestUrl(url: string): void {
        self.scrubPlayerManifestUrl = url;
      },
      /**
       * Setter for isScrubManifestLoading
       */
      setIsScrubManifestLoading(value: boolean) {
        self.isScrubManifestLoading = value;
      },
      /**
       * Setter for active player manifest URL
       */
      setActivePlayerManifestUrl(url: string): void {
        if (self.playingPairName === VideoPairName.A) {
          self.playerAManifestUrl = url;
        } else {
          self.playerBManifestUrl = url;
        }
      },
      /**
       * Setter for manifest load state for whichever
       * player is active
       */
      setActivePlayerManifestLoading(value: boolean) {
        if (self.playingPairName === VideoPairName.A) {
          self.isPlayerAManifestLoading = value;
        } else {
          self.isPlayerBManifestLoading = value;
        }
      },
      /**
       * Set inactive player manifest url
       */
      setInactivePlayerManifestUrl(url: string): void {
        if (self.playingPairName === VideoPairName.A) {
          self.playerBManifestUrl = url;
        } else {
          self.playerAManifestUrl = url;
        }
      },
      /**
       * Setter for manifest load state for whichever
       * player is NOT active
       */
      setInactivePlayerManifestLoading(value: boolean) {
        if (self.playingPairName === VideoPairName.A) {
          self.isPlayerBManifestLoading = value;
        } else {
          self.isPlayerAManifestLoading = value;
        }
      },
      /**
       * Sets the playback requested flag for the active player
       */
      setPlayerPlaybackRequested(playerName: VideoPairName, value: boolean) {
        if (playerName === VideoPairName.A) {
          self.isPlayerAPlaybackRequested = value;
        } else {
          self.isPlayerBPlaybackRequested = value;
        }
      },

      /**
       * Setter for volume, hopefully across both players?
       */
      setVolume(value: number) {
        self.volume = value;
      },
      /**
       * Setter for if preview is full screen
       */
      setIsFullScreen(value: boolean) {
        self.isFullScreen = value;
      },
      /**
       * Resets playback state to default
       * useful when taking an action that re-renders the player component
       * TODO: maybe remove this as we should never re-render the playback
       * component
       * TODO: Maybe do not reset all of these pending product decisions
       * about what should persist when navigating
       */
      resetPlaybackState(): void {
        self.scrubTime = undefined;
        self.seekTime = 0;
        self.playbackClock = 0;
        self.isPlaying = false;
        self.playingPairName = VideoPairName.A;
        self.scrubPlayerManifestUrl = '';
        self.isScrubManifestLoading = false;
        self.playerAManifestUrl = '';
        self.isPlayerAManifestLoading = false;
        self.isPlayerAPlaybackRequested = false;
        self.playerBManifestUrl = '';
        self.isPlayerBManifestLoading = false;
        self.isPlayerBPlaybackRequested = false;
        self.volume = 1;
        self.isFullScreen = false;
      },
    };
  });
export default PlaybackModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getPlayback(self: IAnyStateTreeNode): IPlayback {
  const root = getParentOfType(self, RootModel);
  return root.playback;
}

export interface IPlayback extends Instance<typeof PlaybackModel> {}
