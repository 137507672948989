import { st } from '@castify/studio/fe-common';
import { Instance, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import BoxModel from './box/box.mst';

const MIN_VIDEO_CLIP_LENGTH = 1000 / 25;
const DEFAULT_CROP_BOX = {
  left: 0.1,
  top: 0.1,
  width: 0.8,
  height: 0.8,
};

/**
 * A video clip that is part of the main gapless video track
 */
const VideoSourceModel = types
  .model('VideoSourceModel', {
    /**
     * This is NOT the same id as the video file in any backend  storage system.
     * Two VideoClips MAY reference the same video url.
     */
    uuid: types.optional(types.identifier, nanoid),

    /*
     * the backend stored video id
     */
    videoId: types.optional(types.string, ''),

    /**
     * This URL--an absolute path--points to the DASH manifest used to play back
     * the video
     */
    playbackManifest: types.string,
    /**
     * This URL--an absolute path--points to the DASH manifest used for scrubbing
     * the video. It will be a lower resolution than the playback manifest
     *
     * TODO: right now this is the same as the playback manifest URL as the
     * backend work for scrubbing is not yet complete
     */
    scrubManifest: types.string,
    /**
     * Base URL of thumbnails
     */
    baseThumbnailUrl: types.string,

    /**
     * URL of waveforms
     */
    waveformUrl: types.string,

    /**
     * Height of the source video in px
     */
    height: types.number,
    /**
     * Width of the source video in px
     */
    width: types.number,
    /**
     * describes whether or not the current clip has audio attached to it
     */
    hasAudio: types.boolean,
    /**
     * Total duration of video
     */
    videoLength: types.number,
    /**
     * Time in MS into the video where we start playing
     */
    trimIn: types.number,
    /**
     * Time in MS from the end of the video where we stop playing
     */
    trimOut: types.number,

    /**
     * Describes crop applied to the video source. When null, it means no
     * crop has yet been applied to the video. (It is possible for a crop box
     * to be applied, but for the crop to not actually crop the video for it
     * being 100% of the width and height of the source)
     */
    cropBox: types.maybeNull(BoxModel),
  })
  .views((self) => {
    return {
      /**
       * MS of the clips time being shown in the timeline
       */
      get duration() {
        return self.trimOut - self.trimIn;
      },

      /**
       * The dimensions of the source, but cropped, if a crop is applied.
       */
      get croppedDims(): st.dims {
        const cropBox = self.cropBox
          ? self.cropBox
          : { top: 0, left: 0, width: 1, height: 1 };
        return {
          width: self.width * cropBox.width,
          height: self.height * cropBox.height,
        };
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Moves the amount of time being trimmed off the front of the clip
       *
       * The return tells us whether anything actually changed
       *
       * TODO - deprecate use of the delta and then eliminate it entirely
       * from the signature. For more on this, see comments in useTrimHandler.ts
       * Second arg is optional to not break tests
       */
      trimFromStart(delta: st.ms): boolean {
        const oldTrimIn = self.trimIn;
        const minClipLength = self.trimOut - MIN_VIDEO_CLIP_LENGTH;
        if (self.trimIn + delta < 0) self.trimIn = 0;
        else if (self.trimIn > minClipLength) self.trimIn = minClipLength;
        else self.trimIn = self.trimIn + delta;
        return oldTrimIn !== self.trimIn;
      },
      /**
       * Moves the amount of time being trimmed off the back of the clip
       *
       * The return tells us whether anything actually changed
       *
       * TODO - deprecate use of the delta and then eliminate it entirely
       * from the signature. For more on this, see comments in useTrimHandler.ts
       * Second arg is optional to not break tests
       */
      trimFromEnd(delta: st.ms): boolean {
        const oldTrimOut = self.trimOut;
        const minClipLength = self.trimIn + MIN_VIDEO_CLIP_LENGTH;
        if (self.trimOut + delta > self.videoLength)
          self.trimOut = self.videoLength;
        else if (self.trimOut < minClipLength) self.trimOut = minClipLength;
        else self.trimOut = self.trimOut + delta;
        return oldTrimOut !== self.trimOut;
      },
      /**
       * Apply the default crop
       */
      setDefaultCrop(): void {
        self.cropBox = BoxModel.create(DEFAULT_CROP_BOX);
      },
      /**
       * Null out crop
       */
      removeCrop(): void {
        self.cropBox = null;
      },
    };
  });
export default VideoSourceModel;

export interface IVideoSource extends Instance<typeof VideoSourceModel> {}
