import React, { useEffect } from 'react';

import { createBrowserLogger } from '@castify/studio/observability/browser';
import { useUserProfile } from '@castify/studio/auth';

interface IVisitor {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface IAccount {
  id: string;
  name: string;
}
interface IUserInfo {
  visitor: IVisitor;
  account: IAccount;
}

declare global {
  interface Window {
    pendo: {
      initialize: (info: IUserInfo) => void;
    };
  }
}

// Pendo uses one api key for everything..
// This key is NOT considered secret.
const apiKey = '7d729e1e-f991-4862-4839-ee60b119170e';

const logger = createBrowserLogger('Pendo');

/**
 * Pendo initializer
 *
 * Will add Pendo script and initialize Pendo with given user.
 *
 * Must be rendered inside a GoogleAuthInstanceProvider.
 *
 * Renders no UI but uses React lifecycle
 */
export const Pendo: React.FC = () => {
  const user = useUserProfile();

  // run pendo install script
  useEffect(() => {
    try {
      // Pendo set-up script copied from: https://app.pendo.io/setup
      // Smooshing this all to one line with prettier-ignore + @ts-ignore
      //     seems like the least painful way to do this.
      // The value apiKey is declared in this file
      // prettier-ignore
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line prefer-const, prefer-rest-params
      (function(p,e,n,d,o){let v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    } catch (error) {
      logger.error('something went wrong initializing pendo');
    }
  }, []);

  // initialize pendo with the signed in user
  useEffect(() => {
    if (user.isSignedIn) {
      const userDomain = user.email?.split(/([^@]+)$/)?.[1];
      const userInfo = {
        visitor: {
          id: user.id,
          name: user.displayName,
          email: user.email || '[email not available]',
          role: '[user type not available]',
        },
        account: {
          id: userDomain || '[user domain not available]',
          name: userDomain || '[user domain not available]',
        },
      };

      window.pendo.initialize(userInfo);
    }
  }, [user]);

  // this component does not have any visual elements
  return null;
};
