import { useEffect } from 'react';
import ProgressDialog from '../common/ProgressDialog';
import { useUploadFile } from './useUploadFile';

export interface UploadProgressProps {
  file: File;
  onUploadComplete: (driveFileId: string) => void;
  onFlowError: (error: unknown) => void;
}

/**
 * Responsible for kicking off an upload and showing a progress dialog.
 */
const UploadProgress = ({
  file,
  onUploadComplete,
  onFlowError,
}: UploadProgressProps) => {
  /**
   * Kick off file upload
   */
  const { progress, error, isComplete, driveFileId } = useUploadFile(file);

  /**
   * Report to parent when upload is complete
   */
  useEffect(() => {
    if (isComplete && !error && driveFileId) {
      onUploadComplete(driveFileId);
    }
  }, [isComplete, error, driveFileId, onUploadComplete]);

  /**
   * Report errors to parent
   */
  useEffect(() => {
    if (error) {
      onFlowError(error);
    }
  }, [error, onFlowError]);

  /**
   * Shows the file upload progress in an indeterminate state
   * prior to the upload starting
   */
  if (progress === 0)
    return (
      <ProgressDialog title="Uploading..." determinate={false} value={0} />
    );
  else {
    return (
      <ProgressDialog
        title="Uploading..."
        determinate={true}
        value={progress}
      />
    );
  }
};

export default UploadProgress;
