import { st } from '@castify/studio/fe-common';
import { Instance, types } from 'mobx-state-tree';

const minStillClipDuration = 1000;

/**
 * A still clip with a solid color background that can only be part of the
 * main gapless video track. Must expose a similar interface to videoClip
 * either via views or mutable state at least as regards start and duration
 */
const StillSourceModel = types
  .model('StillSourceModel', {
    /**
     * Background color
     */
    color: types.optional(types.string, '#000000'),
    /**
     * Amount of time in MS of the clip being shown within the timeline
     */
    duration: types.number,
  })
  .actions((self) => {
    return {
      /**
       * Alias for trimming to line up with other models.
       * Since still clip doesn't have a concept of start and end this is only
       * an alias for updating the duration
       *
       * Return tells us whether trim actually happened; for still clips there
       * are no constraints so all trims succeed.
       *
       * TODO - deprecate use of the delta and then eliminate it entirely
       * from the signature. For more on this, see comments in useTrimHandler.ts
       * Second arg is optional to not break tests
       */
      trimFromStart(delta: st.ms): boolean {
        const newDuration = self.duration - delta;
        if (newDuration < minStillClipDuration) return false;
        self.duration = newDuration;
        return true;
      },
      /**
       * Alias for trimming to line up with other models.
       * Since still clip doesn't have a concept of start and end this is only
       * an alias for updating the duration
       *
       * Return tells us whether trim actually happened; for still clips there
       * are no constraints so all trims succeed.
       *
       * TODO - deprecate use of the delta and then eliminate it entirely
       * from the signature. For more on this, see comments in useTrimHandler.ts
       * Second arg is optional to not break tests
       */
      trimFromEnd(delta: st.ms): boolean {
        const newDuration = self.duration + delta;
        if (newDuration < minStillClipDuration) return false;
        self.duration = newDuration;
        return true;
      },
      /**
       * Change still clip color
       */
      updateColor(newColor: string) {
        self.color = newColor;
      },
    };
  });
export default StillSourceModel;

export interface IStillSource extends Instance<typeof StillSourceModel> {}
