/*!
 * Copyright 2022 Screencastify LLC
 */
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@castify/studio/env/browser';
import { getAnonymousUserIdentifier } from '@castify/studio/fe-common';
import { createBrowserLogger } from './browser-logger';
import type { IBrowserLogger } from './browser-logger';

// use strategy pattern to access runtime env
export interface IRumStrategy {
  allowedTracingOrigins: (string | RegExp)[];
  setDatadogRumUser: () => string | null;
}

export class SetAllUsersRumStrategy implements IRumStrategy {
  constructor(public readonly allowedTracingOrigins: (string | RegExp)[]) {}
  setDatadogRumUser(): string {
    // This data does not persist - see @castify/studio/auth/README userProfile for anonymous user
    const sessionUserId: string = getAnonymousUserIdentifier();
    datadogRum.setUser({ id: sessionUserId });
    return sessionUserId;
  }
}

export class SetNoUserRumStrategy implements IRumStrategy {
  constructor(public readonly allowedTracingOrigins: (string | RegExp)[]) {}
  // to avoid undefined being possibly called in non prod env, just return early
  setDatadogRumUser() {
    return null;
  }
}

export const defaultConfigs = {
  clientToken: environment.dataDogClientToken,
  applicationId: environment.dataDogApplicationId,
  site: 'datadoghq.com',
  trackInteractions: true,
  trackSessionAcrossSubdomains: true,
};

export class DataDogRum {
  // made public for unit test
  public static isInit = false;
  static logger: IBrowserLogger = createBrowserLogger('DatadogRum');
  // this should only be called once
  public static init(serviceName: string, strategy: IRumStrategy) {
    if (!DataDogRum.isInit) {
      DataDogRum.logger.info('initializing DataDog RUM');
      datadogRum.init({
        ...defaultConfigs,
        service: serviceName,
        /**
         * The list of internal (first party) origins called by the browser application,
         * 'https://api.example.com' or regExp / https: \/\/.*\.my-api-domain\.com/,
         * enabling tracing origins allows DD to inject a trace_id on the request header and correlate requests
         */
        allowedTracingOrigins: strategy.allowedTracingOrigins,
      });
      DataDogRum.isInit = true;
    } else {
      throw new Error('DD Rum has already been initialized.');
    }
    /**
     * Whether the user is anonymous or authenticated, assign a session user Id right away on visit
     * for DataDog Real User Monitoring. The id can track user events and is sent to the backend
     * to correlate frontend and backend logs. This id is shared with GoogleAuthInstance
     * */
    strategy.setDatadogRumUser();
  }
}

export const getDatadogRumStrategy = (hostname: string) => {
  // TODO: encapsulate allowedTracingOrigin values in an external config file
  let allowedTracingOrigins: (string | RegExp)[];
  if (hostname === 'localhost') {
    allowedTracingOrigins = [/https?:\/\/localhost/g];
    return new SetNoUserRumStrategy(allowedTracingOrigins);
  } else {
    allowedTracingOrigins = [
      /https:\/\/.*\.screencastify\.com/,
      /https:\/\/.*\.staging.castifydev\.com/,
    ];
    return new SetAllUsersRumStrategy(allowedTracingOrigins);
  }
};
