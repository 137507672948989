import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

export const styles = {
  toolbarContainer: css`
    width: 100%;
    height: 100%;
    background-color: ${colors.gray[0]};
    padding: 0px 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  `,
  toolbarLeft: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  toolbarRight: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  `,
};
export default styles;
