import { License_Seat_Tiers_Enum } from 'lib/studio/database-clients/src/lib/generated-types';
import { useState } from 'react';
import { TIER_LIST_WITH_LABELS } from '../enums';
import { RadioOption } from '../lib/FormInputComponents/InputProps';

/**
 * @description returns an array of roles with the correct labels.
 * This function assumes we will replace this in some way in the future
 * a response to the datbase or more care so we always receive the appropriate labels
 * for the type of license we are working with.
 * @param isEdu
 * @returns Array<{ label: string, value: ROLES}>
 */
const createTiersArray = (isEdu = false) => {
  const tiers = TIER_LIST_WITH_LABELS.map(
    ({ eduLabel, defaultLabel, value }) => {
      return { label: isEdu ? eduLabel : defaultLabel, value };
    },
  );
  return tiers;
};

/**
   * Custom hook than hopefully can be used as the interface for whatever
   * solution we decide on for populating labels on the FE for Enums tables.
   * @param isEdu optional boolean 
   * 
  };
   */
export interface IUseTierList<T = License_Seat_Tiers_Enum> {
  tierList: Array<RadioOption<T>>;
  setCustomerType: (isEdu: boolean) => void;
}
export function useTierList<T = License_Seat_Tiers_Enum>(
  isEdu = false,
): IUseTierList {
  const [tierList, setValue] = useState(createTiersArray(isEdu));
  const setCustomerType = (isEdu: boolean) => {
    setValue(createTiersArray(isEdu));
  };
  return { tierList, setCustomerType };
}
