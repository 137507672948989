import {
  getParentOfType,
  IAnyStateTreeNode,
  Instance,
  types,
} from 'mobx-state-tree';
import { st } from '@castify/studio/fe-common';
import { getTimeline } from '../timeline.mst';
import { getScene } from '../../project/scene/scene.mst';
import { getProject } from '../../project/project.mst';
import { getPlayback } from '../../playback/playback.mst';
import RootModel from '../../root.mst';

/**
 * When the button is pressed, by what portion of the total
 * possible zoom slider range should we jump zoom forward or backward?
 */
const TIMELINE_ZOOM_JUMP_PERCENTAGE = 0.1;

// The default in the old editor was 0.01, or 100 pixels for 10 seconds.
// This is set to a higher value to make dev easier with short clips
const DEFAULT_ZOOM_FACTOR: st.ratio = 0.01;

// as far as we can get when zoomed in
const ZOOM_FACTOR_MAX: st.ratio = 0.1;

// how far from the edge is the playhead allowed to get when zooming
const ZOOM_DISTANCE_FROM_EDGE: st.px = 100;

/**
 * This is a component-coupled "facade" model that serves as an interface for
 * the zoom slider but also a boundary around concerns of the timeline related
 * to zoom.
 *
 * This model is repsonsible for scaling values from the actual allowable
 * zoomFactor values to unit values. We can change this if needed in the future
 * to scale logarithmically if this is necessary for UX purposes.
 */
const ZoomModel = types
  .model('ZoomModel', {
    /**
     * How zoomed-in the timeline is. Units are pixels per millisecond
     */
    zoomFactor: types.optional(types.number, DEFAULT_ZOOM_FACTOR),
  })
  .views((self) => {
    return {
      /**
       * Maximum value that can be applied to our zoom factor
       */
      get zoomFactorMax(): st.ratio {
        return ZOOM_FACTOR_MAX;
      },
      /**
       * The minimum value of the zoom factor; depends on scene duration.
       *
       * The default amount of time visible on the timeline in the absence
       * of lengthy clips is a function of the default zoom factor and the
       * user's window size (the latter is not represented here-- this model
       * only calculates the zoom factor itself)
       *
       * If there are lengthy clips in the scene, the zoom factor minimum
       * is set to a value such that the entire scene will be exactly 1000
       * pixels in the timeline irrespective of user window size. This is
       * borrowed from the old editor.
       *
       * This value is deliberately not extracted to a constant. We may need to
       * think through this value being dynamic in a mobile context when
       * we need to deal with window widths less than 1000px, in which case
       * the implementation here risks the scene not being visible in its
       * entirety on screen.
       */
      get zoomFactorMin(): st.ratio {
        const computedMinimum = 1000 / getScene(self).mainTrack.totalDuration;
        return Math.min(computedMinimum, DEFAULT_ZOOM_FACTOR);
      },
      /**
       * The size of the range of allowable zoom factors
       */
      get zoomFactorRange(): st.ratio {
        return this.zoomFactorMax - this.zoomFactorMin;
      },
      /**
       * Maps zoom factor to unit scale from 0 to 1;
       * intended to set value of slider on frontend
       */
      get scaledZoomFactor(): st.ratio {
        return (self.zoomFactor - this.zoomFactorMin) / this.zoomFactorRange;
      },
      /**
       * Converts a unit-scale value from 0 to 1 to a linear one going from
       * the minimum to maximum zoom value
       */
      unscaleZoomFactor(unitLogScaled: st.ratio): st.ratio {
        return this.zoomFactorMin + unitLogScaled * this.zoomFactorRange;
      },
      /**
       * Controls when we can zoom into the timeline
       */
      get canZoomIn(): boolean {
        return self.zoomFactor < this.zoomFactorMax;
      },
      /**
       * Controls when we can zoom out of the timeline
       */
      get canZoomOut(): boolean {
        return self.zoomFactor > this.zoomFactorMin;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Jumps our zoom forward by a set amount
       */
      jumpTimelineZoomForward() {
        this.changeTimelineZoom(
          self.unscaleZoomFactor(
            self.scaledZoomFactor + TIMELINE_ZOOM_JUMP_PERCENTAGE,
          ),
        );
      },
      /**
       * Jumps our zoom backward by a set amount
       */
      jumpTimelineZoomBackwards() {
        this.changeTimelineZoom(
          self.unscaleZoomFactor(
            self.scaledZoomFactor - TIMELINE_ZOOM_JUMP_PERCENTAGE,
          ),
        );
      },
      /**
       * Intended to be passed as change handler to zoom factor slider
       */
      handleZoomFactorChange(e: Event, value: number | number[]): void {
        // sometimes we get an array of values for unknown reasons from mui
        const newValue = Array.isArray(value) ? value[value.length - 1] : value;
        // delegate the change to the timeline model
        this.changeTimelineZoom(self.unscaleZoomFactor(newValue));
      },
      /**
       * Zooms all the way out
       */
      zoomAllTheWayOut() {
        this.changeTimelineZoom(self.zoomFactorMin);
      },

      /**
       * sizes the timeline to fill available space
       */
      sizeToFit() {
        // the timeline currently has 4 pixels of padding on both the
        // left and right sides which must be factored in to calculate
        // this correctly
        const timelinePadding = 8;
        const currentWidth =
          getTimeline(self).timelineElementWidth - timelinePadding;
        const duration = getScene(self).mainTrack.totalDuration;
        const newZoomFactor = currentWidth / duration;
        this.changeTimelineZoom(newZoomFactor);
      },
      /**
       * Changes the current zoom level of the timeline, manipulating the
       * scroll bar values in order to keep the playhead within the visible timeline area.
       * If the playhead approaches the edge of screen, attempts to keep it within a
       * configurable distance in pixels from the edge.
       *
       * NOTE: This is currently called timeline zoom to seperate itself from the zoom effect.
       */
      changeTimelineZoom(newZoom: number) {
        const clampedZoom = Math.min(
          Math.max(newZoom, self.zoomFactorMin),
          self.zoomFactorMax,
        );
        // TODO - all of the below needs testing!

        // Change zoom factor, noting visible duration before it's changed and after its changed to later calculate offset
        const oldVisibleDuration = getTimeline(self).visibleDuration;
        self.zoomFactor = clampedZoom;
        const newVisibleDuration = getTimeline(self).visibleDuration;
        const { setScrollbarTime } = getTimeline(self);

        // If it is called when the timeline element has not yet been rendered,
        // this action risks setting the scroll position to a value that does not
        // make sense, breaking the timeline. So, we early return to avoid this.
        if (newVisibleDuration === 0) return;

        // if visible duration is less than total (there is a scrollbar visible)
        if (newVisibleDuration < getScene(self).mainTrack.totalDuration) {
          const offset = (oldVisibleDuration - newVisibleDuration) / 2;
          const shiftedStart = getTimeline(self).visibleStart + offset; // Calculating the eventual start programatically so we don't need to call moveScrollbar twice
          const bufferSpace = ZOOM_DISTANCE_FROM_EDGE / self.zoomFactor;
          const upperLimit = shiftedStart + newVisibleDuration - bufferSpace;
          const lowerLimit = shiftedStart + bufferSpace;
          const { playheadPosition } = getPlayback(self);

          const { scrollTime } = getTimeline(self);

          // If the playhead passes the upper limit...
          if (playheadPosition > upperLimit) {
            // Shift it back to the upper limit
            setScrollbarTime(
              scrollTime + offset + (playheadPosition - upperLimit),
            );
            // If the playhead passes the lower limit
          } else if (playheadPosition < lowerLimit) {
            // Shift it back to the lower limit
            setScrollbarTime(
              scrollTime + offset + (playheadPosition - lowerLimit),
            );
          } else {
            // Otherwise keep the center of the timeline in place
            setScrollbarTime(scrollTime + offset);
          }
        } else {
          // if the user zoomed all the way out, reset scroll position to zero
          setScrollbarTime(0);
        }
      },
    };
  });

export default ZoomModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getZoom(self: IAnyStateTreeNode): IZoom {
  const root = getParentOfType(self, RootModel);
  return root.timeline.zoom;
}

export interface IZoom extends Instance<typeof ZoomModel> {}
