const errorText = {
  pageNotFound: {
    label: 'Page not found.',
    description: "It looks like the page you're looking for doesn't exist",
    helperText:
      ' You may want to reach out to the person who gave you this link.',
    errorCode: '404',
  },
  videoNotFound: {
    label: 'Video not found.',
    description: "It looks like the video you're looking for doesn't exist",
    helperText:
      ' You may want to reach out to the person who gave you this link.',
    errorCode: '404',
  },
  refreshPage: {
    label: 'Oops, something went wrong.',
    description: 'Please try refreshing the page.',
    helperText: 'If the error persists, contact our support team',
    errorCode: '500',
  },
  contactSupport: {
    label: 'Oops, something went wrong.',
    description: 'Please contact our support team',
    helperText: '',
    errorCode: '500',
  },
};

export default errorText;
