import { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Player from '@castify/studio/Player';
import { useMst } from '@castify/studio/studio-store';

import styles from './PreviewPlayer.styles';
import SeekBar from './players-common/SeekBar/SeekBar';
import OpenSimpleEditButton from './players-common/OpenSimpleEditButton/OpenSimpleEditButton';
import LargePlayButton from './players-common/LargePlayButton/LargePlayButton';
import TitleDisplay from './players-common/TitleDisplay/TitleDisplay';
import PreviewPlayerFooter from './players-common/PreviewPlayerFooter/PreviewPlayerFooter';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

interface PreviewPlayerProps {
  toggleSimpleEdit: () => void;
  showSkeleton?: boolean;
}

function PreviewPlayer({ toggleSimpleEdit, showSkeleton }: PreviewPlayerProps) {
  const {
    playback: { isPlaying, isFullScreen },
  } = useMst();

  const videoWrapperRef = useRef(null);

  //Internal state for hover and timeout id
  const [isHovered, setIsHovered] = useState(false);
  const [timeOutId, setTimeOutId] = useState(0);

  //condition used to determine if we show controls
  const isBottomControlsVisible = isHovered || !isPlaying;
  const isOverlayVisible = isHovered || !isPlaying;

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  function handleMouseMove() {
    clearTimeout(timeOutId as unknown as NodeJS.Timeout);
    if (isFullScreen) {
      setIsHovered(true);

      const timeOut = setTimeout(() => {
        setIsHovered(false);
      }, 2000) as unknown as number;
      setTimeOutId(timeOut);
    }
  }

  return (
    <div
      css={[styles.videoWrapper, !isFullScreen && styles.videoWrapperBorder]}
      ref={videoWrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      data-testid="preview-player"
      data-pendo-id="preview-player"
    >
      {showSkeleton ? (
        <>
          <Typography css={styles.skeletonText}>
            <span role="img" aria-label="sparkle">
              ✨
            </span>{' '}
            Polishing your video...
          </Typography>
          <Skeleton variant="rectangular" css={styles.skeletonSquare} />
        </>
      ) : (
        <>
          {/* Video Player */}
          <Player />

          {/* Overlay Buttons */}
          <div
            css={[
              styles.controls,
              !isBottomControlsVisible && styles.hideControls,
            ]}
            data-testid="controls"
          >
            <div css={styles.titleDisplay}>
              <TitleDisplay />
            </div>

            <div css={styles.playButtonLarge}>
              {!isPlaying && <LargePlayButton />}
            </div>

            <div css={styles.bottomControls}>
              {!isPlaying && (
                <div css={styles.trimButton}>
                  <OpenSimpleEditButton toggleSimpleEdit={toggleSimpleEdit} />
                </div>
              )}
              <SeekBar />
              <PreviewPlayerFooter videoWrapperRef={videoWrapperRef} />
            </div>
          </div>

          {isOverlayVisible && <div css={styles.overlay} />}
        </>
      )}
    </div>
  );
}

export default observer(PreviewPlayer);
