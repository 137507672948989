import DebugMenu from './DebugMenu';
import DevVideoMenu from './DevVideoMenu/DevVideoMenu';
import { useState } from 'react';
import { IAnyStateTreeNode } from 'mobx-state-tree';
import styles from './DebugPanel.styles';
import MstDebuggerPane from './MstDebuggerPane/MstDebuggerPane';
import { FeatureFlagConsumer } from '@castify/studio/components/feature-flags';

export function DebugPanel() {
  const [nodeToDebug, setNodeToDebug] = useState<null | IAnyStateTreeNode>(
    null,
  );
  const [openedPane, setOpenedPane] = useState<null | 'mst'>(null);

  return (
    <FeatureFlagConsumer flagName="DEVELOPER_TOOLING">
      <>
        {openedPane === 'mst' && nodeToDebug && (
          <MstDebuggerPane nodeToDebug={nodeToDebug} />
        )}
        <div css={styles.debuggerButtonContainer}>
          <DebugMenu
            setOpenedPane={setOpenedPane}
            setNodeToDebug={setNodeToDebug}
            nodeToDebug={nodeToDebug}
          />
          <DevVideoMenu />
        </div>
      </>
    </FeatureFlagConsumer>
  );
}
