import { IRotatableBoxModel } from '@castify/studio/studio-store';
import { useMoveableBoxHandlers } from './useMoveableBoxHandlers';
import { observer } from 'mobx-react-lite';
import styles from './RotatableBox.styles';
import { useRotateBoxHandler } from './useRotateBoxHandler';

type RotatableBoxProps = {
  /**
   * When selected, the border can be whatever color your heart desires
   */
  borderColor: string;
  /**
   * When not selected, the box has a hard-coded grey border and the handles
   * disappear
   */
  isSelected: boolean;
  /**
   * The MST model instance which backs the box' state
   */
  boxModel: IRotatableBoxModel;
  /**
   * A ref to the overlay container within which the box moves
   */
  overlayContainerRef: React.RefObject<HTMLDivElement>;
};

/**
 * Renders borders and handles for rotatable box;
 * delegates wiring up box <-> MST model to hook.
 */
const RotatableBox = ({
  borderColor,
  isSelected,
  boxModel,
  overlayContainerRef,
}: RotatableBoxProps) => {
  const color = isSelected ? borderColor : '#a9a9a9';
  const backupColor = '#a9a9a9';
  const { pointerDownMove, pointerDownRotate, pointerDownResize } =
    useRotateBoxHandler({
      boxModel,
      overlayContainerRef,
    });

  return (
    <>
      <div
        css={styles.boxContainer}
        style={{
          display: boxModel.placeholderRotation !== null ? 'block' : 'none',
          top: boxModel.top * 100 + '%',
          left: boxModel.left * 100 + '%',
          width: boxModel.width * 100 + '%',
          height: boxModel.height * 100 + '%',
          transform: `rotate(${boxModel.placeholderRotation}deg)`,
          zIndex: +4,
          transformOrigin: 'center',
        }}
      >
        <div
          css={styles.rotateHandle}
          style={{
            display: isSelected ? 'inline' : 'none',
            border: '6px solid #a9a9a9',
          }}
          onPointerDown={pointerDownRotate}
        />
        <div
          css={styles.rotateStick}
          style={{
            display: isSelected ? 'inline' : 'none',
            backgroundColor: backupColor,
          }}
        />
        <div
          css={styles.border}
          style={{ color: backupColor }}
          onPointerDown={pointerDownMove}
        ></div>
      </div>

      <div
        css={styles.boxContainer}
        style={{
          top: boxModel.top * 100 + '%',
          left: boxModel.left * 100 + '%',
          width: boxModel.width * 100 + '%',
          height: boxModel.height * 100 + '%',
          transform: `rotate(${boxModel.rotate}deg)`,
          transformOrigin: 'center',
        }}
      >
        <div
          css={styles.rotateHandle}
          style={{
            display: isSelected ? 'inline' : 'none',
            border: `6px solid ${borderColor}`,
          }}
          onPointerDown={pointerDownRotate}
        />
        <div
          css={styles.rotateStick}
          style={{
            display: isSelected ? 'inline' : 'none',
            backgroundColor: color,
          }}
        />

        <div
          css={styles.border}
          style={{ color: color }}
          onPointerDown={pointerDownMove}
        ></div>
        <div
          css={styles.topLeftHandle}
          style={{ backgroundColor: color }}
          onPointerDown={pointerDownResize}
        ></div>
        <div
          css={styles.topRightHandle}
          style={{ backgroundColor: color }}
          onPointerDown={pointerDownResize}
        ></div>
        <div
          css={styles.bottomLeftHandle}
          style={{ backgroundColor: color }}
          onPointerDown={pointerDownResize}
        ></div>
        <div
          css={styles.bottomRightHandle}
          style={{ backgroundColor: color }}
          onPointerDown={pointerDownResize}
        ></div>
      </div>
    </>
  );
};

export default observer(RotatableBox);
