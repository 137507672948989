import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  playbackController: css`
    height: 100%;
    display: flex;
    align-items: flex-end;
    min-width: 137px;
    margin-bottom: 50px;
  `,
  playButton: css`
    position: relative;
    height: 80px;
    width: 80px;
  `,
  playTime: css`
    display: block;
    margin: 5px;
    font-family: Helvetica;
    font-size: 16px;
  `,
  icon: css`
    font-size: 60px;
  `,
  // color necessary because playback button is on top of
  // the preview background. not necessary if this changes.
  playSkeleton: css`
    background-color: ${colors.gray[100]};
  `,
};
export default styles;
