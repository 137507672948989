import { Instance, types } from 'mobx-state-tree';
import { ITextClip, TextClipModel } from '../..';
import { getSelection } from '../selection/selection.mst';

/**
 * State, views, & actions backing the text tool
 */
const TextToolModel = types
  .model('TextToolModel', {})
  .views((self) => {
    return {
      /**
       * Convenience getter for the clip being edited.
       */
      get targetClip(): ITextClip {
        const clip = getSelection(self).selectedClip;
        if (!clip) throw new Error('targetClip accessed when no clip selected');
        if (!TextClipModel.is(clip))
          throw new Error('targetClip accessed with video clip not selected');
        return clip;
      },
    };
  })
  .actions(() => {
    return {};
  });
export default TextToolModel;

export interface ITextTool extends Instance<typeof TextToolModel> {}
