import {
  applySnapshot,
  getEnv,
  getParentOfType,
  getSnapshot,
  IAnyStateTreeNode,
  IDisposer,
  Instance,
  onSnapshot,
  types,
} from 'mobx-state-tree';
import { UndoManager } from 'mst-middlewares';
import RootModel from '../root.mst';
import SceneModel from './scene/scene.mst';
import { environment } from '@castify/studio/env/browser';

type PersistSceneFunc = (projectId: string, scene: string) => void;

type ProjectVolatileState = {
  persistScene: PersistSceneFunc | null;
  undoManager: Instance<typeof UndoManager>;
  dispose: IDisposer | null;
};

type Permissions = 'signed-in' | 'nickname' | 'anonymous';

/**
 * This model roughly maps to a row in the Projects table, but we
 * don't sync everything in the row to this model, as this serves mainly
 * as a container for code which deals with synchronizing the scene to
 * the database and dealing with undo. The scene node and its children
 * map 1-to-1 to the JSONB scene document in the database.
 */
const ProjectModel = types
  .model('ProjectModel', {
    /**
     * The project's uuid in postgres; initialized when edit loads.
     * Not an MST identifier because it is not known on model initialization
     * (MST identifiers are immutable)
     */
    uuid: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    /**
     * The scene displayed on the timeline and in the preview.
     */
    scene: types.optional(SceneModel, {}),
    /**
     * Permissions (when implemented fully) will be set by the
     * project owner and determine how viewers are allowed to
     * view the project on the watch page. There are more complex
     * nuances (such as restrictions) that will be handled later.
     */
    permissions: types.optional(
      types.enumeration(['signed-in', 'nickname', 'anonymous']),
      'anonymous',
    ),
    /**
     * A boolean returning true if project data has been loaded
     * by our graphql client and applied to the store.  This is necessary
     * due to cases where fetching is false but for tiny amount of time
     * the scene snapshot has not been applied, therefore causing derived
     * data to still not be ready to use.
     */
    isLoaded: types.optional(types.boolean, false),
  })
  .volatile((self): ProjectVolatileState => {
    return {
      persistScene: null,
      /**
       * Mounts the undo manager as a store with a separate root from our main
       * tree but stores a reference at `mst.project.scene.undoManager`
       *
       * If we store the history in main tree, the undo manager monitors
       * the entire tree to populate the undo stack and not just the
       * scene model. This seems to be a limitation of the UndoManager
       * and we'd need to modify it to fix this.
       *
       * Luckily it is still fully observable in React components due
       * to some cleverness on the part of `mobx-react-lite`'s `observer`
       *
       * This exposes an API which is documented here:
       * {@link https://github.com/mobxjs/mobx-state-tree/blob/master/packages/mst-middlewares/README.md#undomanager}
       *
       * Note especially the API for grouping actions into single entries
       * in the undo stack, which is the API which in practice used elsewhere
       * in MST when mutating the scene
       */
      undoManager: UndoManager.create(
        {},
        {
          targetStore: self.scene,
          maxHistoryLength: environment.maxUndoHistoryLength,
        },
      ),
      dispose: null,
    };
  })
  .actions((self) => {
    return {
      setProjectId(projectId: string): void {
        self.uuid = projectId;
      },
      setProjectTitle(projectTitle: string): void {
        self.title = projectTitle;
      },
      /**
       * Attempt to deserialize and apply a scene snapshot. Errors caught
       * for logging but rethrown to the view layer to handle.
       *
       * If jsonb scene data in the DB is corrupt or no longer matches
       * our expectations about its shape, the error will probably surface
       * here, as MST works as a runtime type validator
       *
       * We tell the undo manager to ignore snapshot application so that
       * the initial scene fetch is not recorded to the undo stack
       */
      applySceneSnapshot(serializedScene: string): void {
        self.undoManager.withoutUndo(() => {
          try {
            applySnapshot(self.scene, JSON.parse(serializedScene));
            self.isLoaded = true;
          } catch (err) {
            getEnv(self).logger.error('Deserialization error', err);
          }
        });
      },
      /**
       * Saves a scene snapshot to the DB
       */
      saveScene(): void {
        if (self.persistScene) {
          self.persistScene(self.uuid, JSON.stringify(getSnapshot(self.scene)));
        }
      },
      /**
       * This allows injecting a callback which saves the scene to the DB
       * when passed a snapshot
       */
      injectScenePersistenceFunction(persistScene: PersistSceneFunc): void {
        self.persistScene = persistScene;
      },
      /**
       * This action--which must be called after the scene is initially loaded--
       * sets up an effect that saves to the DB every time a change is made to
       * the undo manager's history.
       *
       * This ensures we save only when undo groups are complete (as opposed to
       * observing the entire scene for updates and saving every time the scene
       * changes) making it so that we save only when e.g. a drag event is
       * complete as opposed to on every mouse move that takes place as part of
       * a drag.
       */
      setupSceneAutosave(): void {
        self.dispose = onSnapshot(self.undoManager, () => {
          getProject(self).saveScene();
        });
      },
      /**
       * Destroy snapshot listener on node unmount.
       */
      teardownSceneAutosave(): void {
        if (self.dispose) self.dispose();
      },
      /**
       * Permissions control who and how the project is viewable
       * in Watch.
       */
      editPermissions(permissions: Permissions): void {
        self.permissions = permissions;
      },
    };
  });
export default ProjectModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getProject(self: IAnyStateTreeNode): IProject {
  const root = getParentOfType(self, RootModel);
  return root.project;
}

/**
 * A utility function to help navigate to the undo manager from anywhere in the
 * MST.
 */
export function getUndoManager(
  self: IAnyStateTreeNode,
): Instance<typeof UndoManager> {
  const root = getParentOfType(self, RootModel);
  return root.project.undoManager;
}

export interface IProject extends Instance<typeof ProjectModel> {}
