import { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import { useSyncWindowSize } from './useSyncWindowSize';
import Scrollbar from './Scrollbar/Scrollbar';
import TextTrack from './TextTrack/TextTrack';
import AudioTrack from './AudioTrack/AudioTrack';
import styles from './Timeline.styles';
import Playhead from './Playhead/Playhead';
import Scrubhead from './Scrubhead/Scrubhead';
import MainTrack from './MainTrack/MainTrack';
import Skeleton from '@mui/material/Skeleton';

interface TimelineProps {
  showSkeleton?: boolean;
}

function Timeline({ showSkeleton }: TimelineProps) {
  /**
   * Container element is needed to translate mouse position to video
   * milliseconds
   */
  const containerEl = useRef(null);

  /**
   * Effect which subscribes MST store to window size; useful for calculating
   * how much timeline is viewable
   */
  useSyncWindowSize(containerEl);

  const {
    project: { isLoaded },
    timeline: {
      initiateScrubOnPointerMove,
      endScrubOnPointerLeave,
      initiateSeekFromPointerEvent,
      hasText,
      hasAudio,
      zoom: { sizeToFit },
    },
    selection: { clearSelection },
  } = useMst();

  /**
   * On page load after the timeline has been fully rendered and has a
   * width, set the zoom factor to make the project fit the timeline
   * Note: this occurs in the loader and not the timeline component
   * because it should only occur once per project load.  Any zoom
   * settings applied after this should persist if the project doesn't
   *  change
   */
  useEffect(() => {
    sizeToFit();
  }, [sizeToFit, isLoaded]);
  /**
   * Not all clicks are seeks, but clicks that are only on the background
   * deselect any selected clips.
   *
   * The eventTarget for this handler is sometimes the scrubhead,
   * sometimes the playhead, and sometimes the background div (on mobile). This
   * means we can't easily tell when the click is over the background div but
   * not on any clips-- that's why `elementsFromPoint` is used here.
   *
   * But note that for this to work we'll need to ensure the `data-selectable`
   * attribute appears on all clips and effects!
   *
   * If you are creating an element where seeking should be skipped add the `data-skipseek` attribute.
   * The abscense of this attribute will cause a seek to the location the user clicks
   */
  const delegatePointerDown = (event: React.PointerEvent) => {
    const isClickOnClip = document
      .elementsFromPoint(event.clientX, event.clientY)
      .some((el) => (el as HTMLElement).dataset?.selectable);

    // We may not want to seek on certain elements by adding a data-skipseek
    // to the element this will ensure we skip the seek
    const isClickOnSeekableElement = document
      .elementsFromPoint(event.clientX, event.clientY)
      .some((el) => (el as HTMLElement).dataset?.skipseek);

    // we are on top of a clip and the click is not meant to skip seek
    if (isClickOnClip && !isClickOnSeekableElement)
      initiateSeekFromPointerEvent(event);

    if (!isClickOnClip) clearSelection();
  };

  if (showSkeleton)
    return <Skeleton variant="rectangular" css={styles.skeleton} />;

  return (
    <div css={styles.timelineContainer} ref={containerEl}>
      <div
        id="timeline-container"
        css={styles.timelineParent}
        className="timeline-container"
        onPointerMove={initiateScrubOnPointerMove}
        onPointerLeave={endScrubOnPointerLeave}
        onPointerDown={delegatePointerDown}
      >
        <Playhead />
        <Scrubhead />
        <div css={styles.timeline}>
          {hasText && <TextTrack />}
          <MainTrack />
          {hasAudio && <AudioTrack />}
        </div>
      </div>
      <Scrollbar />
    </div>
  );
}

export default observer(Timeline);
