import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './PlaybackController.styles';
import { useMst } from '@castify/studio/studio-store';
import { ButtonTooltip } from '@castify/studio/fe-common';
import { getShortcutString, shortcutConfigs } from '@castify/studio/keyboard';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/Pause';
import { Fab, Skeleton, Typography } from '@mui/material';

interface PlaybackControllerProps {
  showSkeleton?: boolean;
}

function PlaybackController({ showSkeleton }: PlaybackControllerProps) {
  const {
    toolbar: { formattedSceneDuration, formattedPlayheadPosition },
    playback: { isPlaying, pause, play },
  } = useMst();

  const handleClick = () => {
    if (isPlaying) pause();
    else play();
  };

  return (
    <div css={styles.playbackController}>
      {showSkeleton ? (
        <Skeleton
          variant="circular"
          width={80}
          height={80}
          css={styles.playSkeleton}
        />
      ) : (
        <ButtonTooltip
          title={`Play/Pause (${getShortcutString(shortcutConfigs.PlayPause)})`}
        >
          <Fab
            css={styles.playButton}
            color="secondary"
            aria-label="Play Pause Button"
            data-pendo-id="play-pause-button"
            onPointerDown={handleClick}
          >
            {!isPlaying ? (
              <PlayArrowRoundedIcon css={styles.icon} />
            ) : (
              <PauseIcon css={styles.icon} />
            )}
          </Fab>
        </ButtonTooltip>
      )}
      {showSkeleton ? (
        <Skeleton variant="rectangular" width={110} height={25} />
      ) : (
        <Typography css={styles.playTime}>
          {formattedPlayheadPosition} / {formattedSceneDuration}
        </Typography>
      )}
    </div>
  );
}

export default observer(PlaybackController);
