import { useState, useEffect } from 'react';
import {
  LoadSinglePublishedProjectQuery,
  LoadSinglePublishedProjectDocument,
} from '@castify/studio/database-clients/graphql';
import { useMst } from '@castify/studio/studio-store';
import { useQuery } from 'urql';
import { createBrowserLogger } from '@castify/studio/observability/browser';
const logger = createBrowserLogger('useFetchPublishedProject');

type IFetchScene = {
  projectId: string;
};

/**
 * This hook is responsible for fetching the scene from the database
 * on page load. It only fetches projects that are "published"
 */
export const useFetchPublishedProject = ({ projectId }: IFetchScene) => {
  const { project } = useMst();

  /**
   * If the projectId is the same as project.uuid in the store it means
   * that this projects data is already loaded.  This will occur if the owner
   * was already viewing this project on /preview or /edit and then navigated
   * to /watch
   */
  const isDataLoaded = projectId === project.uuid;
  /**
   * Acts as a latch that pauses query after it has run once
   */
  const [paused, setPaused] = useState(isDataLoaded);

  /**
   * Log on first run
   */
  useEffect(() => {
    if (!paused) {
      logger.info(`Fetching published project ${projectId}`);
    }
  }, [paused, projectId]);

  /**
   * We use a network-only policy to load the project, pausing the query
   * after it has executed once
   */
  const [{ fetching, error, data }] = useQuery<LoadSinglePublishedProjectQuery>(
    {
      query: LoadSinglePublishedProjectDocument,
      variables: { projectId },
      requestPolicy: 'network-only',
      pause: paused,
    },
  );

  /**
   * Once loaded, apply the snapshot-- but only once.
   */
  useEffect(() => {
    if (!fetching && !error && !paused && data && data.published_projects[0]) {
      logger.info(`Fetched published project ${projectId}`);
      setPaused(true);
      if (data.published_projects[0].title)
        project.setProjectTitle(data.published_projects[0].title);
      project.applySceneSnapshot(data.published_projects[0].scene);
    }
  }, [data, error, fetching, paused, project, projectId]);

  /**
   * The returns here allow the component to respond to state changes
   * as the queries update.
   */
  return {
    data,
    fetchingError: error,
  };
};
