import { Logo } from '@castify/studio/fe-common';
import styles from './ErrorPage.styles';
import ErrorImage from './ErrorImage.svg';
import errorText from './errorText';

interface IErrorPageProps {
  errorType:
    | 'videoNotFound'
    | 'pageNotFound'
    | 'refreshPage'
    | 'contactSupport';
}

const ErrorPage = (props: IErrorPageProps) => {
  const { errorType } = props;
  return (
    <main css={styles.errorPageContainer}>
      <div css={styles.logoContainer}>
        <Logo withText />
      </div>
      <div css={styles.errorContent}>
        <ErrorImage css={styles.errorImage} />
        <div>
          <p css={styles.errorLabel}>{errorText[errorType].label}</p>
          <p css={styles.errorDescription}>
            {errorText[errorType].description}
          </p>
          <p css={styles.helperText}>
            {errorText[errorType].helperText}
            <br />
            Error code: {errorText[errorType].errorCode}
          </p>
        </div>
      </div>
      <div css={styles.footer}>
        <span>©Screencastify {new Date().getFullYear()}</span>
        <a href="https://www.screencastify.com/privacy">Privacy Center</a>
        <a href="https://www.screencastify.com/legal/terms" css={styles.terms}>
          Terms of Use
        </a>
      </div>
    </main>
  );
};

export default ErrorPage;
