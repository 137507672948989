import ProgressDialog from '../common/ProgressDialog';
import { useSubscription } from 'urql';
import {
  WatchImportProgressDocument,
  WatchImportProgressSubscription,
  WatchImportProgressSubscriptionVariables,
} from '@castify/studio/database-clients/graphql';
import {
  computeConstrainProgress,
  isReadyForPlayback,
  didIngestionFail,
} from './progressHelpers';
import { DebugProgress } from './DebugProgress';
import { useEffect } from 'react';
import {
  ProbeData,
  verifyProbeOutput,
  hasAudio,
} from '@castify/studio/video-probe-data';

/**
 * Import requester props
 */
export interface ImportProgressProps {
  /**
   * What video should we monitor progress for?
   */
  videoSourceUuid: string;
  /**
   * Intended to help move to next step in flow when progress
   * has reached a certain point.
   */
  onImportComplete: (probeData: ProbeData) => void;
  /**
   * Propagate errors to parent
   */
  onFlowError: (error: unknown) => void;
  /**
   * is the app-wide debug mode active?
   */
  debugMode: boolean;
}

/**
 * This component is responsible for setting up a subscription to the video's
 * row in the video source table, monitoring the progress of the import, and
 * displaying this progress to the user.
 */
function ImportProgress({
  videoSourceUuid,
  onImportComplete,
  onFlowError,
  debugMode,
}: ImportProgressProps) {
  /**
   * Set up a subscription to the video's row in the video source table
   */
  const [{ data, error }] = useSubscription<
    WatchImportProgressSubscription,
    WatchImportProgressSubscription,
    WatchImportProgressSubscriptionVariables
  >({
    query: WatchImportProgressDocument,
    variables: { videoSourceUuid: videoSourceUuid as string },
  });

  /**
   * If we hit an error in the subscription, we'll see it via the error
   * object coming from the urql hook. This must be in a useEffect so
   * that we do not update state during the render.
   */
  useEffect(() => {
    if (error) {
      onFlowError(error);
    }
  }, [error, onFlowError]);

  /**
   * Compute the progress of the constrain step
   */
  const constrainProgress = computeConstrainProgress(data);

  /**
   * This useEffect owns figuring out what to show/do when the subscription
   * updates.
   */
  useEffect(() => {
    try {
      if (didIngestionFail(data)) {
        throw Error(`Ingestion for ${videoSourceUuid} permanently failed`);
      }

      // type guards
      if (
        !data?.video_sources_by_pk?.stream_probe ||
        Object.keys(data?.video_sources_by_pk?.stream_probe).length === 0
      )
        return;

      const probeData = verifyProbeOutput(
        data?.video_sources_by_pk?.stream_probe,
      );

      if (isReadyForPlayback(data, hasAudio(probeData))) {
        onImportComplete(probeData);
      }
    } catch (error) {
      onFlowError(error);
    }
  }, [data, onImportComplete, onFlowError, videoSourceUuid]);

  /**
   * There are two visual treatments. One covers the constrain step and the other
   * covers the import step; this is constrain
   */
  if (constrainProgress < 100) {
    return (
      <ProgressDialog
        title="Converting media..."
        determinate={true}
        value={constrainProgress}
      />
    );
  }
  /**
   * This treatment covers the various resource generation steps.
   */
  return (
    <ProgressDialog title="Processing..." determinate={false}>
      {debugMode && <DebugProgress videoSourceRow={data} />}
    </ProgressDialog>
  );
}

export default ImportProgress;
