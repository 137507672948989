import { useEffect } from 'react';
import {
  StillSourceModel,
  useMst,
  VideoSourceModel,
  IPlayback,
} from '@castify/studio/studio-store';
import { VideoPair } from './useVideoPlayer';
import { reaction } from 'mobx';
import { createBrowserLogger } from '@castify/studio/observability/browser';
import type { IBrowserLogger } from '@castify/studio/observability/browser';
import { st } from '@castify/studio/fe-common';
import { usePlaybackRaf } from './usePlaybackRaf';

const logger: IBrowserLogger = createBrowserLogger('UsePlayback');

const usePlayback = (pairA: VideoPair, pairB: VideoPair) => {
  const { playback, project } = useMst();

  /**
   * This effect updates video playback state when global play/pause state
   * changes, whether because of user action or becuase it is programatically
   * altered (as when reaching the end of a clip and transitioning to the
   * next clip).
   *
   * TODO: these two cases should probably be split out
   * IF YOU HAVE TRIED AND FAILED TO DO THIS ^^^ INCREASE THIS NUMBER: 4
   */
  useEffect(() => {
    const disposer = reaction(
      /**
       * This callback specifies the data dependncies for the reaction
       */
      () => {
        return {
          isPlaying: playback.isPlaying,
          playingClip: playback.playingClip,
        };
      },
      /**
       * This callback executes when the data dependencies change
       */
      async ({ isPlaying, playingClip }) => {
        const {
          isPairAActive,
          playingPairName,
          setPlayerPlaybackRequested,
          isPlaybackRequested,
        } = playback;
        const activePair = isPairAActive ? pairA : pairB;
        const inactivePair = isPairAActive ? pairB : pairA;

        /**
         * Play active player and pause inactive:
         * - when we're in a video / crossing a video boundary
         * - but also when we the user requests playback from a paused state
         */
        if (isPlaying && VideoSourceModel.is(playingClip?.source)) {
          logger.debug(
            `REQUESTING PLAYBACK FOR PLAYER ${activePair.pairName} AND PAUSING ${inactivePair.pairName}`,
          );
          inactivePair.player?.pause();
          setPlayerPlaybackRequested(playingPairName, true);
          await activePair.player?.play();
          logger.debug(`PLAYBACK STARTED FOR PLAYER ${playingPairName}`);
          setPlayerPlaybackRequested(playingPairName, false);
        }

        /**
         * Pause everything when in/entering a still clip
         */
        if (isPlaying && StillSourceModel.is(playingClip?.source)) {
          logger.debug(`PAUSING BOTH PLAYERS IN STILL CLIP`);
          activePair.player?.pause();
          inactivePair.player?.pause();
        }

        /**
         * Pause everything when global play/pause state changes, bu t don't
         * issue pause requests if we're in a playback requested state for
         * either player
         */
        if (!isPlaying && !isPlaybackRequested) {
          logger.debug(`PAUSING ALL PLAYBACK`);
          activePair.player?.pause();
          inactivePair.player?.pause();
        }
      },
    );
    return disposer;
  }, [pairA, pairB, playback]);

  /**
   * This effect updates volume of the video js players when the
   * volume value changes in playback state
   */
  useEffect(() => {
    const disposer = reaction(
      /**
       * This callback specifies the data dependncies for the reaction
       */
      () => {
        return {
          volume: playback.volume,
        };
      },
      /**
       * This callback executes when the data dependencies change
       */
      async ({ volume }) => {
        pairA.player?.volume(volume);
        pairB.player?.volume(volume);
      },
    );
    return disposer;
  }, [pairA, pairB, playback]);

  /**
   * This effect updates volume of the video js players when the
   * volume value changes in playback state
   */
  useEffect(() => {
    const disposer = reaction(
      /**
       * This callback specifies the data dependencies for the reaction
       */
      () => {
        return {
          nextPlayingClip: playback.nextPlayingClip,
          playheadAtEnd: playback.playheadAtEnd,
          pause: playback.pause,
          isLastClipStill: project.scene.isLastClipStill,
        };
      },
      /**
       * This callback executes when the data dependencies change
       */
      async ({ nextPlayingClip, playheadAtEnd, pause, isLastClipStill }) => {
        if (!nextPlayingClip && playheadAtEnd && isLastClipStill) {
          pause();
        }
      },
    );
    return disposer;
  }, [playback, project]);

  /**
   * This effect is responsible for prefetching the next clip manifest to
   * the offscreen player when the playing clip changes
   */
  useEffect(() => {
    const disposer = reaction(
      // run the side effect whenever this fn's return changes
      () => {
        return {
          nextPlayingClip: playback.nextPlayingClip,
          nextPlayingClipStartSec: playback.nextPlayingClipStartSec,
        };
      },
      // the side effect to run
      async ({ nextPlayingClip, nextPlayingClipStartSec }) => {
        const {
          isPairAActive,
          setInactivePlayerManifestLoading,
          isInactivePlayerManifestLoading,
          inactivePlayerManifestUrl,
          setInactivePlayerManifestUrl,
          playingClip,
          switchActiveVideoPair,
        } = playback;
        const inactivePair = isPairAActive ? pairB : pairA;
        const { player } = inactivePair;
        // if no next clip or if next clip is still... do nothing
        if (!nextPlayingClip || StillSourceModel.is(nextPlayingClip.source))
          return;

        // does the offscreen player already have this manifest?
        const nextClipManifestUrl = nextPlayingClip.source.playbackManifest;
        const isManifestLoadedAlready =
          nextClipManifestUrl === inactivePlayerManifestUrl;

        /**
         * If we already have the manifest loaded, ensure we've sought to the
         * starting point within it. This will fire if we go from clip A to
         * clip B back to clip A in the timeline when playing.
         */

        if (
          nextPlayingClipStartSec !== undefined &&
          isManifestLoadedAlready &&
          player
        ) {
          logger.debug(
            `NEXT CLIP CACHED; SEEKING TO: ${nextPlayingClip.start}`,
          );
          player.currentTime(nextPlayingClipStartSec);

          /**
           * When in still clip, switch players after next clip seek so right
           * player starts playback on transition.
           */
          if (StillSourceModel.is(playingClip?.source)) {
            logger.debug('SWITCHING PAIR IN STILL CLIP AFTER CACHED SEEK');
            switchActiveVideoPair();
          }
        }

        /**
         * If we have a video as a next clip, if it doesn't already have the
         * manifest, and if if the next clip is not already in a loading state
         * ...then load the manifest
         */
        if (
          VideoSourceModel.is(nextPlayingClip.source) &&
          !isManifestLoadedAlready &&
          !isInactivePlayerManifestLoading &&
          player &&
          nextPlayingClipStartSec !== undefined
        ) {
          try {
            logger.debug(
              `LOADING NEXT CLIP TO PLAYER ${inactivePair.pairName}`,
              { nextClipManifestUrl },
            );

            setInactivePlayerManifestLoading(true);
            await inactivePair.loadManifest(nextClipManifestUrl);
            setInactivePlayerManifestLoading(false);

            setInactivePlayerManifestUrl(nextClipManifestUrl);

            logger.debug(
              `NEXT CLIP LOAD COMPLETE; SEEKING TO: ${nextPlayingClip.start}`,
            );
            player.currentTime(nextPlayingClipStartSec);

            /**
             * When in still clip, switch players after load so right player
             * plays
             */
            if (StillSourceModel.is(playingClip?.source)) {
              logger.debug('SWITCHING PAIR IN STILL CLIP AFTER CLIP LOAD/SEEK');
              switchActiveVideoPair();
            }
          } catch (err) {
            if (err instanceof Error) {
              logger.error('Error fetching manifest:', { error: err });
            } else {
              logger.error(`Error fetching manifest: ${JSON.stringify(err)}`);
            }
            setInactivePlayerManifestLoading(false);
            setInactivePlayerManifestUrl('');
          }
        }
      },
      /**
       * Configuring this effect to run immediately ensures that the next clip
       * (if there is one) is preloaded when the application loads
       */
      { fireImmediately: true },
    );
    return disposer;
  }, [playback, pairA, pairB]);

  /**
   * Either flip the active player or pause when the playhead reaches the end
   * of a clip.
   */

  usePlaybackRaf((timePassed) => {
    handleRaf(timePassed, pairA, pairB, playback);
  }, true);
};

export async function handleRaf(
  timePassed: number,
  pairA: VideoPair,
  pairB: VideoPair,
  playback: IPlayback,
) {
  const {
    playingClip,
    playbackClock,
    isPairAActive,
    nextPlayingClip,
    switchActiveVideoPair,
    setPlaybackClock,
    pause,
    isPlaybackRequested,
  } = playback;

  const activePair = isPairAActive ? pairA : pairB;
  const inactivePair = isPairAActive ? pairB : pairA;
  if (!activePair.player) return;
  if (!playingClip) return;

  /**
   * Calculate distance to end of clip. Slightly different for
   * video vs still clips.
   */
  let distanceToEnd: st.ms;
  if (VideoSourceModel.is(playingClip.source)) {
    const clipCurrentTime: st.ms = activePair.player.currentTime() * 1000;
    distanceToEnd = playingClip.source.trimOut - clipCurrentTime;
  } else {
    distanceToEnd = playingClip.end - playbackClock;
  }

  /**
   * When we're within a certain distance of the end of a clip,
   * decide whether to pause (if at end of timeline) or flip to the next
   * clip (when there is a next clip).  Note:  This will only pause scenes
   * ending with a video clip.
   */
  if (distanceToEnd <= 0) {
    if (!nextPlayingClip) {
      setPlaybackClock(playingClip.end);
      pause();
    } else {
      /**
       * If either player is in a "requesting playback" state, meaning
       * we've called play() but the promise it returns has not yet resolved,
       * then we need to defer the flip until a future RAF tick.
       */
      if (isPlaybackRequested) {
        return;
      }

      // toggle the active pair
      logger.debug(`FLIPPING ACTIVE PAIR TO: ${inactivePair.pairName}`);
      switchActiveVideoPair();

      // set playback clock to start of new clip
      logger.debug(
        `SETTING CLOCK TO START OF NEW CLIP: ${nextPlayingClip.start}`,
      );
      setPlaybackClock(nextPlayingClip.start);
    }
    // early return so as not to send a potentially stale time update into MST
    return;
  }

  /**
   * Subscribe MST to the latest timestamp we get from sampling the video
   * element's state each frame.
   */
  if (VideoSourceModel.is(playingClip?.source)) {
    const timeInClip = activePair.player.currentTime() * 1000;

    /**
     * Sometimes the player will output a current time slightly less than
     * the trimin of the clip despite us having sought to the right point
     * in the clip. This likely has to do with the PTS of the frame being
     * shown being slightly less than the requested time. To prevent this
     * from causing chaotic behavior (e.g. when passing a timestamp prior
     * trimIn into MST, which causes playingClip to be wrong), we clamp
     * the current time to the trimIn of the clip.
     */
    let newPlaybackClockTime: st.ms;
    if (timeInClip >= playingClip.source.trimIn) {
      newPlaybackClockTime =
        playingClip.start - playingClip.source.trimIn + timeInClip;
    } else {
      newPlaybackClockTime = playingClip.start;
    }
    setPlaybackClock(newPlaybackClockTime);
  }

  /**
   * Subscribe MST to time updates from the raf hook itself when still
   * clips are playing-- so that time advances when still clips are under the
   * playhead.
   */
  if (StillSourceModel.is(playingClip?.source)) {
    const newPlaybackClockTime = playbackClock + timePassed;
    setPlaybackClock(newPlaybackClockTime);
  }
}
export default usePlayback;
