import { memo } from 'react';
import WaveformData from 'waveform-data';
import useDrawWaveformLine from './useDrawWaveform';
import { st } from '@castify/studio/fe-common';

interface WaveformCanvasProps {
  duration: st.ms;
  width: st.px;
  hasAudio: boolean;
  waveform: WaveformData | null;
}

const HEIGHT = 50;

function WaveformCanvas({
  duration,
  width,
  hasAudio,
  waveform,
}: WaveformCanvasProps) {
  const drawerRef = useDrawWaveformLine(waveform, width, duration, hasAudio);

  return (
    <canvas
      style={{
        height: '100%',
        width: `${width}px`,
      }}
      width={width}
      height={HEIGHT}
      ref={drawerRef}
    />
  );
}

export default memo(WaveformCanvas);
