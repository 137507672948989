import { observer } from 'mobx-react-lite';
import { IClip, IVideoSource, useMst } from '@castify/studio/studio-store';
import TrimHandle, { TrimHandleNames } from '../TrimHandle/TrimHandle';
import { useShowTrimHandles } from '../useShowTrimHandles';
import ClipDebugger from '../ClipDebugger';
import EffectClip, { EffectClipType } from './EffectClip/EffectClip';
import commonClipStyles from '../commonClip.styles';
import Icon from '@mui/material/Icon';
import styles from './MainTrackClip.styles';
import { useSwapHandler } from '../useSwapHandler';
import { useRef } from 'react';
import Thumbnails from './Thumbnails/Thumbnails';
import WaveformGraph from './WaveformGraph/WaveformGraph';

interface MainTrackClip {
  /**
   * The clip to render
   */
  clip: IClip;
  /**
   * Is this component being used to show a non-interactive ghost clip
   * which moves under the pointer during a drag?
   */
  isGhost: boolean;
  /**
   * Left offset of the timeline element on screen
   */
  timelineElementLeft: number;
}

function MainTrackClip({ clip, isGhost, timelineElementLeft }: MainTrackClip) {
  const clipContainerRef = useRef<HTMLDivElement>(null);

  /**
   * Should clip handles be shown?
   */
  const { setPointerInClip, areHandlesVisible } = useShowTrimHandles(
    clip.isSelected,
  );

  const {
    selection: { selectClip },
    debugger: { debugMode },
    toolbar: { toggleCropTool },
  } = useMst();

  /**
   * This handler sets up swapping clips in the timeline
   */
  const { pointerDown } = useSwapHandler(
    clip,
    clipContainerRef,
    timelineElementLeft,
  );

  /**
   * Ghost clips are always visible; the clip we've hidden in the timeline
   * because we see the ghost clip instead is always invisible.
   */
  const isClipVisible = isGhost || !clip.isBeingDragged;
  return (
    <div
      data-selectable
      ref={clipContainerRef}
      data-testid="clip-container"
      onPointerEnter={() => setPointerInClip(true)}
      onPointerLeave={() => setPointerInClip(false)}
      onPointerDown={() => selectClip(clip)}
      css={[
        commonClipStyles.container,
        clip.isSelected && commonClipStyles.selected,
        styles.container,
      ]}
      style={{
        left: `${isGhost ? clip.ghostClipStartPx : clip.leftOffset}px`,
        width: `${clip.pixelWidth}px`,
        minWidth: `${clip.pixelWidth}px`,
        maxWidth: `${clip.pixelWidth}px`,
        visibility: !isClipVisible ? 'hidden' : 'inherit',
        zIndex: isGhost ? +1 : 'inherit',
      }}
      data-pendo-id="main-track-clip"
    >
      <TrimHandle
        trimHandleName={TrimHandleNames.left}
        isVisible={areHandlesVisible}
        clipOrEffect={clip}
        clipContainerRef={clipContainerRef}
      />
      <div
        css={styles.videoAndAudio}
        onPointerDown={pointerDown}
        data-testid="inner-clip-container"
      >
        <div css={styles.video(clip.thumbnailHeight)}>
          {clip.isVideo() && (clip.source as IVideoSource).cropBox && (
            <Icon
              data-skipseek
              css={[
                styles.cropIcon,
                clip.isSelected && styles.selectedCropIcon,
              ]}
              onClick={() => {
                toggleCropTool();
              }}
            >
              crop
            </Icon>
          )}
          {debugMode && <ClipDebugger clip={clip} />}
          {clip.blurEffects
            .filter((b) => b.isVisible)
            .map((blurEffect) => (
              <EffectClip
                effect={blurEffect}
                type={EffectClipType.Blur}
                key={blurEffect.uuid}
              />
            ))}
          {clip.zoomEffects.map((zoomEffect) => (
            <EffectClip
              effect={zoomEffect}
              type={EffectClipType.Zoom}
              key={zoomEffect.uuid}
            />
          ))}
          {clip.isVideo() && <Thumbnails clip={clip} debugMode={debugMode} />}
          {clip.isStill() && (
            <div
              css={styles.background(
                clip.backgroundColor,
                clip.thumbnailHeight,
              )}
            ></div>
          )}
        </div>
        <div css={styles.audio}>
          <WaveformGraph clip={clip} />
        </div>
      </div>
      <TrimHandle
        trimHandleName={TrimHandleNames.right}
        isVisible={areHandlesVisible}
        clipOrEffect={clip}
        clipContainerRef={clipContainerRef}
      />
    </div>
  );
}

export default observer(MainTrackClip);
