import { css } from '@emotion/react';
import { TrimHandleNames } from './TrimHandle';

const styles = {
  handle: (handlesVisible: boolean, handleName: TrimHandleNames) => {
    return css`
      position: absolute;
      z-index: +1;
      background-color: rgba(49, 54, 49, 0.5);
      box-sizing: border-box;
      width: 20px;
      height: 100%;
      align-items: center;
      cursor: ew-resize;

      display: ${handlesVisible ? 'flex' : 'none'};
      ${handleName === TrimHandleNames.left ? 'left: 0' : 'right: 0'};
    `;
  },

  dragIcon: css`
    position: relative;
    left: -2px;
  `,
};
export default styles;
