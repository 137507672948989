import { Navigate, Route, Routes } from 'react-router-dom';
import { CurrentUserProvider } from '@castify/studio/auth';
import Projects from '../Projects/Projects';
import Edit from '../Edit/Edit';
import Login from '../Login/Login';
import Account from '../Account/Account';
import PreviewPage from '../PreviewPage/PreviewPage';
import WatchPage from '../WatchPage/WatchPage';
import NewProject from '../NewProject/NewProject';
import ErrorPage from 'lib/studio/components/error-page/src';
import ExtensionIntake from '../NewProject/ExtensionIntake';
import DashLayout from '../../layouts/DashLayout/DashLayout';
import { PlaybackSystem } from '@castify/studio/Player';

/**
 * Handles client-side routing.
 *
 * TODO: unit test these routes
 */
function Routing() {
  return (
    <CurrentUserProvider
      whenSignedIn={
        <Routes>
          <Route element={<DashLayout />}>
            <Route path="/projects" element={<Projects />} />
            <Route path="/account" element={<Account />} />
          </Route>
          <Route
            path="/projects/extension/intake"
            element={<ExtensionIntake />}
          />
          <Route path="/projects/new" element={<NewProject />} />
          <Route element={<PlaybackSystem />}>
            <Route path="/preview/:projectId" element={<PreviewPage />} />
            <Route path="/edit/:projectId" element={<Edit />} />
            <Route path="/watch/:projectId" element={<WatchPage />} />
          </Route>
          <Route path="/" element={<Navigate replace to="/projects" />} />
          {/* There route allows the Login component to persist for long
            enough after sign occurs to complete it's redirect handling */}
          <Route path="/login" element={<Login />} />
          <Route
            path="/video-not-found"
            element={<ErrorPage errorType="videoNotFound" />}
          />
          <Route
            path="/not-found"
            element={<ErrorPage errorType="pageNotFound" />}
          />
          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      }
      whenNotSignedIn={
        <Routes>
          <Route element={<PlaybackSystem />}>
            <Route path="/watch/:projectId" element={<WatchPage />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route
            path="/video-not-found"
            element={<ErrorPage errorType="videoNotFound" />}
          />
          <Route
            path="/not-found"
            element={<ErrorPage errorType="pageNotFound" />}
          />
          <Route
            path="/*"
            element={
              // eslint-disable-next-line no-restricted-globals
              <Navigate replace to={`/login?redirect=${location.pathname}`} />
            }
          />
        </Routes>
      }
    />
  );
}

export default Routing;
