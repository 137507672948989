import { ButtonTooltip } from '@castify/studio/fe-common';
import { useMst } from '@castify/studio/studio-store';
import { Fab, Icon } from '@mui/material';
import { useEffect } from 'react';

import styles from './FullscreenButton.styles';

interface IFullscreenButtonProps {
  videoWrapperRef:
    | React.MutableRefObject<HTMLDivElement>
    | React.MutableRefObject<null>;
}

function FullscreenButton(props: IFullscreenButtonProps) {
  const { playback } = useMst();

  function handleClick() {
    if (document.fullscreenElement) document.exitFullscreen();
    else if (props.videoWrapperRef.current) {
      props.videoWrapperRef.current.requestFullscreen();
    }
  }

  // listens to browser events to keep internal state consistent with actual browser state
  // this handles cases where the user clicks this components button as well as interactions external to this component
  // such as hitting the escape key.
  // TODO: This may need to also sub/unsub to 'mozfullscreenchange' and 'webkitfullscreenchange' events
  useEffect(() => {
    function fullscreenChanged() {
      document.fullscreenElement
        ? playback.setIsFullScreen(true)
        : playback.setIsFullScreen(false);
    }

    document.addEventListener('fullscreenchange', fullscreenChanged);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChanged);
    };
  }, [playback]);

  return (
    <ButtonTooltip title={`Full Screen`}>
      <Fab
        css={styles.fullscreenButton}
        color="info"
        onClick={handleClick}
        aria-label="FullscreenButton"
        data-testid="fullscreen-button"
        data-pendo-id="player-fullscreen-button"
      >
        <Icon>{playback.isFullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
      </Fab>
    </ButtonTooltip>
  );
}

export default FullscreenButton;
