import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import DebugPanel from '@castify/studio/DebugPanel';
import Routing from './components/Router/Router';
import axe from '@axe-core/react';
import { environment } from '@castify/studio/env/browser';
import { globals } from '@castify/studio/fe-common';

import {
  GoogleAuthInstanceProvider,
  AuthenticatorProvider,
  GraphqlClientProvider,
} from '@castify/studio/auth';
import { FeatureFlagProvider } from '@castify/studio/components/feature-flags';
import {
  MstProvider,
  createInitialState,
  injectInitialState,
} from '@castify/studio/studio-store';
import { ThemeProvider } from '@mui/material';
import { theme } from '@castify/studio/fe-common';
import {
  BrowserLogger,
  DataDogRum,
  ErrorFallback,
  componentErrorHandler,
  OnUnhandledRejection,
  getDatadogRumStrategy,
} from '@castify/studio/observability/browser';
import type { IRumStrategy } from '@castify/studio/observability/browser';
import { ErrorBoundary } from 'react-error-boundary';
import { Global } from '@emotion/react';
import { Pendo } from '@castify/studio/pendo-integration';
import { visuallyHidden } from '@mui/utils';
import { LoadingTreatmentLogger } from '@castify/studio/components/loading';

/**
 * Axe helps us debug a11y issues in local development:
 * https://www.deque.com/axe/
 */
if (!environment.production) {
  axe(React, ReactDOM, 1000);
}
/**
 * If the MST store need to have some dependencies injected, we need to initialize
 * the store here.
 */
injectInitialState(createInitialState());

/**
 * initialize both DD browser logger and rum, browser logger needs
 * to be initialized before anything else to log browser events
 */
// TODO: service name should be dynamic
BrowserLogger.init('studio-frontend');
const hostname = window.location.hostname;
const strategy: IRumStrategy = getDatadogRumStrategy(hostname);
// init also sets the session user Id shared with GoogleAuthInstance on the RUM user
DataDogRum.init('studio-frontend', strategy);

/**
 * Here, we wrap the root elements of the application in a number of context
 * providers which send singletons containing state down into the tree
 * to be consumed via useContext hooks.
 */
ReactDOM.render(
  <StrictMode>
    <Global styles={globals} />
    <BrowserRouter>
      {/** Wrap a global Error Boundary to catch any React component/child component rendering errors and
       * errors happened in component lifecycles. Can also be wrapped individually around components
       * determined by product design and user experience.
       * TODO Note: the option of resetting globally may be difficult to achieve as it most likely will need
       * the broken component's state variables. We can observe after intial release whether it's necessary
       * to have EB wrapped around individual component.
       */}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={componentErrorHandler}
      >
        <OnUnhandledRejection>
          <GoogleAuthInstanceProvider
            loadingElement={
              <LoadingTreatmentLogger identifier="GoogleAuthInstanceProvider" />
            }
          >
            <AuthenticatorProvider
              loadingElement={
                <LoadingTreatmentLogger identifier="AuthenticationProvider" />
              }
            >
              <FeatureFlagProvider>
                <MstProvider>
                  <GraphqlClientProvider>
                    <ThemeProvider theme={theme}>
                      {/* No pages in Studio had an h1 element so this
                      adds one for screen readers to make Studio more accessible*/}
                      <header>
                        <h1 style={visuallyHidden}>Screencastify</h1>
                      </header>
                      <Pendo />
                      <Routing />
                      <DebugPanel />
                    </ThemeProvider>
                  </GraphqlClientProvider>
                </MstProvider>
              </FeatureFlagProvider>
            </AuthenticatorProvider>
          </GoogleAuthInstanceProvider>
        </OnUnhandledRejection>
      </ErrorBoundary>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);
