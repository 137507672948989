import { Outlet } from 'react-router-dom';
import usePlayback from '../playerHooks/usePlayback';
import useScrub from '../playerHooks/useScrub';
import useSeek from '../playerHooks/useSeek';
import {
  AllVideoPairNames,
  useVideoPlayer,
} from '../playerHooks/useVideoPlayer';
import DebugPlayerLayout from './DebugPlayerLayout';
import { VideoPairContext } from './VideoPairContext';

/**
 * This component exists to set up paired video elements and VideoJS instances
 * so that they are available to all child views which require the use of the
 * video playback system. This permits us to avoid having to re-render
 * the video system when moving between views. Paired video element refs
 *  and instances of the player library are passed down to child components
 * via a context.
 *
 * Note that it is important that this component is not intended to be re-
 * rendered often; it is expected that effects should run on mount and establish
 * subscriptions to handle updates to the players which do not force
 * react to re-render..
 */
const PlaybackSystem = () => {
  /**
   * Set up the video players
   */
  const pairA = useVideoPlayer(AllVideoPairNames.A);
  const pairB = useVideoPlayer(AllVideoPairNames.B);
  const scrubPair = useVideoPlayer(AllVideoPairNames.Scrub);

  /**
   * Set up scrub, play, seek systems
   */
  useScrub(scrubPair);
  useSeek(pairA, pairB);
  usePlayback(pairA, pairB);

  return (
    <>
      <VideoPairContext.Provider value={{ pairA, pairB, scrubPair }}>
        <Outlet />
      </VideoPairContext.Provider>
      <DebugPlayerLayout
        videoA={
          <video
            ref={pairA.videoRef}
            data-testid="video-A"
            className="vjs-player"
          ></video>
        }
        videoB={
          <video
            ref={pairB.videoRef}
            data-testid="video-B"
            className="vjs-player"
          ></video>
        }
        scrubVideo={
          <video
            ref={scrubPair.videoRef}
            data-testid="video-scrub"
            className="vjs-player"
          ></video>
        }
      />
    </>
  );
};

/**
 * Note that we have not included an observer() call here very intentionally.
 *
 * This component is not designed to re-render.
 */
export default PlaybackSystem;
