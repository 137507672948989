import { css } from '@emotion/react';

const styles = {
  debuggerButtonContainer: css`
    width: 300px;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    z-index: 1600;
  `,
  debuggerButtons: css`
    width: 150px;
    display: flex;
    flex-direction: column;
    background-color: lightgrey;
    button {
      font-size: 1.2em;
      z-index: 1600;
    }
  `,
  debuggerHover: css`
    border: 2px solid black;
    padding: 0.5em;
    text-align: center;
    font-family: Arial, sans-serif;
  `,
  addVideoMenuContainer: css`
    position: absolute;
    margin-left: -225px;
    width: 450px;
    display: flex;
    flex-direction: row;
    background-color: lightgrey;
  `,
  addVideoMenuColumn: css`
    width: 150px;
    display: flex;
    flex-direction: column;
    button {
      font-size: 1.2em;
      z-index: 1600;
    }
    p {
      font-family: Arial, sans-serif;
      width: 100%;
      text-align: center;
    }
  `,
};

export default styles;
