import { License_Seat_Roles_Enum } from '@castify/studio/database-clients/graphql';
import { useState } from 'react';
import { ROLE_LIST_WITH_LABELS } from '../enums/roles';
import { RadioOption } from '../lib/FormInputComponents/InputProps';
/**
 * @description returns an array of roles with the correct labels.
 * This function assumes we will replace this in some way in the future
 * a response to the datbase or more care so we always receive the appropriate labels
 * for the type of license we are working with.
 * @param isEdu
 * @returns Array<{ label: string, value: ROLES}>
 */
const createRolesArray = (isEdu = false) => {
  const roles = ROLE_LIST_WITH_LABELS.map(
    ({ eduLabel, defaultLabel, value, helperText }) => {
      return { label: isEdu ? eduLabel : defaultLabel, value, helperText };
    },
  );
  return roles;
};

/**
   * Custom hook than hopefully can be used as the interface for whatever
   * solution we decide on for populating labels on the FE for Enums tables.
   * @param isEdu optional boolean 
   * 
  };
   */
export interface IUseRoleList<T = string> {
  roleList: Array<RadioOption<T>>;
  setCustomerType: (isEdu: boolean) => void;
}
export function useRoleList<T = License_Seat_Roles_Enum>(isEdu = false) {
  const [roleList, setValue] = useState(createRolesArray(isEdu));
  const setCustomerType = (isEdu: boolean) => {
    setValue(createRolesArray(isEdu));
  };
  return { roleList, setCustomerType };
}
