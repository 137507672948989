/**
 * Individual/default imports from material allow for tree shake when the component is used.
 * Please maintain this pattern since these components are intended to be
 * reusable for other forms.
 */
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInputProps } from '../InputProps';
export const FormTextInput = ({
  name,
  label,
  insetLabel,
  helperText,
  cssTextInput,
  cssTextInputLabel,
  dataTestId = 'form-text-input-label-id',
}: TextInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => {
        return (
          <>
            {label && (
              <FormLabel
                data-testid="form-text-input-label-id"
                css={cssTextInputLabel}
              >
                {label}
              </FormLabel>
            )}
            <TextField
              data-testid={dataTestId}
              css={cssTextInput}
              fullWidth //let parrent decide size and postion
              onChange={onChange}
              error={!!error}
              label={insetLabel}
              value={value}
              helperText={helperText}
            ></TextField>
          </>
        );
      }}
    ></Controller>
  );
};
