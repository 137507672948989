import { ILicense, IInvitations, IInvitationListProps } from '../Invitations.d';
import { LoadingTreatmentLogger } from '@castify/studio/components/loading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { getUserInviteStatus } from './InvitationListHelpers';
import RoleSelector from '../../RoleSelector/RoleSelector';
import { AddUserInvitationButton } from '@castify/studio/components/license-mangement';

const InvitationList = (props: IInvitationListProps) => {
  const { data, fetching, error } = props;
  return (
    <Container>
      {/* Seems to early to implement this until we get more of a spec from UX/UI
        going to use the <Loading /> debugging component for now. */}
      {fetching && <LoadingTreatmentLogger identifier="InvitationList" />}
      {/* Will need some sort of error treatment here. */}
      {error && <div>Something bad happened.</div>}

      {/* If the call returns null what will the screen look like. */}
      {/* If someone decides they want multiple licenses, everything will likely break */}

      {data?.licenses.map((table: ILicense, index: number) => {
        const {
          uuid: license_uuid,
          max_pro_member_seats,
          max_pro_restricted_seats,
          max_standard_member_seats,
          max_standard_restricted_seats,
        } = table;

        return (
          <Container
            data-testid="invitation-container"
            key={`table-${table.uuid}`}
          >
            <h2 data-testid="license-id">License {table.uuid}</h2>
            {/* <InvitationListFilters restrictedListHanlder={restrictedListHandler} /> */}
            <AddUserInvitationButton
              license_uuid={license_uuid}
              max_pro_member_seats={max_pro_member_seats}
              max_pro_restricted_seats={max_pro_restricted_seats}
              max_standard_member_seats={max_standard_member_seats}
              max_standard_restricted_seats={max_standard_restricted_seats}
            >
              Invite new user
            </AddUserInvitationButton>
            <TableContainer component={Container}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="Invitation Table"
                data-testid="invitation-table"
                id={`invitation-table-${index}`}
              >
                <TableHead data-testid="invitation-table-header">
                  <TableRow>
                    <TableCell
                      data-testid="invitation-table-header-column"
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        inputProps={{
                          'aria-label': 'Select all invitations.',
                        }}
                      />
                    </TableCell>
                    <TableCell
                      data-testid="invitation-table-header-column"
                      align="left"
                    >
                      Email
                    </TableCell>
                    <TableCell
                      data-testid="invitation-table-header-column"
                      align="left"
                    >
                      Role
                    </TableCell>
                    <TableCell
                      data-testid="invitation-table-header-column"
                      align="left"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      data-testid="invitation-table-header-column"
                      align="left"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody data-testid="invitation-table-body">
                  {table?.license_invitations.map(
                    (invitation: IInvitations) => {
                      return (
                        <TableRow
                          data-testid="invitation-table-row"
                          key={`invitation-${invitation.uuid}`}
                        >
                          <TableCell padding="checkbox">
                            {/* This should be wrapped in a form control,
                            but that uses a <tr> which isn't valid as a
                            direct descendent of a <td> Thanks @mui! */}
                            <Checkbox
                              data-testid="invitation-table-select-row"
                              color="primary"
                              inputProps={{
                                'aria-label': 'Select all invitations.',
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            data-testid="table-row-colum-email"
                          >
                            {invitation.email}
                          </TableCell>
                          <TableCell data-testid="table-row-colum-role">
                            {/*
                              This should have a <FormControl> wrapping it
                              but that adds a <tr> element and a <tr> can't
                              be the first child of a <td>. Thanks @mui!
                            */}
                            <RoleSelector
                              role={invitation.role}
                              uuid={invitation.uuid}
                              disabled={false}
                            />
                          </TableCell>
                          <TableCell data-testid="table-row-colum-status">
                            {getUserInviteStatus(invitation.seat_uuid)}
                          </TableCell>
                          <TableCell>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        );
      })}
    </Container>
  );
};

export default InvitationList;
