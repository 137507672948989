import { Drawer } from '@mui/material';

import LeftSidebar from './LeftSidebar';

/**
 * Left side bar for when user is signed in. Assumes user is available when rendered
 */
function LeftSideDrawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (event: any) => void;
}) {
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <LeftSidebar />
    </Drawer>
  );
}

export default LeftSideDrawer;
