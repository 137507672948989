import axios from 'axios';
const FILE_METADATA_URL = 'https://www.googleapis.com/drive/v3/files';

/**
 * creates an authorize request header for google api
 */
export function getGoogleAuthHeader(accessToken: string): {
  Authorization: string;
} {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

/**
 * Gets the md5 check sum of the drive file
 */
export async function getMD5(
  fileId: string,
  accessToken: string,
): Promise<string> {
  const response = await axios.get(
    `${FILE_METADATA_URL}/${fileId}?fields=md5Checksum`,
    {
      headers: getGoogleAuthHeader(accessToken),
    },
  );
  return response.data.md5Checksum;
}

export interface DriveApiError {
  type: string;
  error: string;
}

const isDriveApiError = (error: unknown): error is DriveApiError => {
  return (
    typeof error === 'object' && !!error && 'type' in error && 'error' in error
  );
};

export const isDriveTokenFailureError = (error: unknown): boolean => {
  return isDriveApiError(error) && error.type === 'tokenFailed';
};
