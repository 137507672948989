import { useRef, useEffect, useMemo } from 'react';
import WaveformData from 'waveform-data';
import {
  createArrayNormalizer,
  getAverageMinMax,
  getDisplayData,
} from './WaveformUtilities';

const containerHeight = 50;
const paddingTop = 5;
const paddingBottom = 5;
const OPTIONS = {
  containerHeight,
  paddingTop,
  paddingBottom,
  maxWave: containerHeight - (paddingTop + paddingBottom),
  minWave: 2,
  columnWidth: 6,
  columnRadius: 4,
  columnSpacing: 4,
  maxWaveValue: 128,
  columnColor: '#C4C4C4',
};

/**
 * A hook that accepts `WaveformData` and renders it on a canvas context
 * and returns it
 */
function useDrawWaveform(
  waveform: WaveformData | null,
  width: number,
  duration: number,
  hasAudio: boolean,
) {
  const columnTotalWidth = OPTIONS.columnWidth + OPTIONS.columnSpacing;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // get the min/max and create a normalizer function once
  // when the user zoom in/out we don't need to recreate it
  const normalizeValues = useMemo(() => {
    if (!waveform) {
      return () => [];
    }
    return createArrayNormalizer(getAverageMinMax(waveform), {
      rangeMin: OPTIONS.minWave,
      rangeMax: OPTIONS.maxWave,
    });
  }, [waveform]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const canvas = canvasRef.current!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ctx = canvas.getContext('2d')!;
    ctx.fillStyle = OPTIONS.columnColor;

    ctx.clearRect(0, 0, width, OPTIONS.containerHeight);

    const numberOfColumns = Math.floor(width / columnTotalWidth);

    // if video doesn't have audio draw 0 waveforms
    // for the total length of that video
    if (!hasAudio) {
      new Array(numberOfColumns).fill(0).forEach((_, index) => {
        const waveformPosition = index * columnTotalWidth;
        drawLine(ctx, waveformPosition, OPTIONS.minWave);
      });
      return;
    }

    if (!waveform) {
      return;
    }

    const waveformValues = getDisplayData(
      waveform,
      0,
      duration / 1000.0,
      numberOfColumns,
    );

    // this will normalize the result data to make it clearer on the canvas
    const normalizedValues = normalizeValues(waveformValues);

    normalizedValues.forEach((val, index) => {
      const waveformPosition = index * columnTotalWidth;
      drawLine(ctx, waveformPosition, val);
    });
  }, [waveform, width, duration, columnTotalWidth]);
  return canvasRef;
}

function drawLine(
  ctx: CanvasRenderingContext2D,
  position: number,
  height: number,
) {
  const minRadius = Math.floor(height / 2);
  const radius =
    minRadius < OPTIONS.columnRadius ? minRadius : OPTIONS.columnRadius;

  // because origin point is top left, we calculate accordingly
  // the bottom of the waveform should be the same for all waves
  const xLeft = position;
  const xRight = xLeft + OPTIONS.columnWidth;

  // vertically align to middle
  const yTop = OPTIONS.paddingTop + (OPTIONS.maxWave - height) / 2;
  const yBottom = yTop + height;

  ctx.beginPath();
  ctx.moveTo(xLeft + radius, yTop);
  ctx.lineTo(xRight - radius, yTop);
  ctx.quadraticCurveTo(xRight, yTop, xRight, yTop + radius);
  ctx.lineTo(xRight, yBottom - radius);
  ctx.quadraticCurveTo(xRight, yBottom, xRight - radius, yBottom);
  ctx.lineTo(xLeft + radius, yBottom);
  ctx.quadraticCurveTo(xLeft, yBottom, xLeft, yBottom - radius);
  ctx.lineTo(xLeft, yTop + radius);
  ctx.quadraticCurveTo(xLeft, yTop, xLeft + radius, yTop);
  ctx.fill();
  ctx.closePath();
}

export default useDrawWaveform;
