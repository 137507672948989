import { IBlurEffect } from '@castify/studio/studio-store';
import { st } from '@castify/studio/fe-common';

// how pixellated things get on the top end of the slider
const MAX_PIXELLATION = 10;

// smallest pixellation factor
const MIN_PIXELLATION = 1;

// biggest blur in px
const MAX_BLUR_RADIUS: st.px = 8;

type ApplyBlurInput = {
  blurCanvas: HTMLCanvasElement;
  blurCtx: CanvasRenderingContext2D;
  blurEffect: IBlurEffect;
  imageSource: HTMLVideoElement;
};

/**
 * This function applies the blur described by the data in a blur effect clip
 */
export const applyBlur = ({
  blurCanvas,
  blurCtx,
  blurEffect,
  imageSource,
}: ApplyBlurInput) => {
  /**
   * Resize the blur canvas to match the source size
   */
  if (blurCanvas.width !== imageSource.videoWidth)
    blurCanvas.width = imageSource.videoWidth;
  if (blurCanvas.height !== imageSource.videoHeight)
    blurCanvas.height = imageSource.videoHeight;

  /**
   * Copy the source
   */
  blurCtx.drawImage(imageSource, 0, 0);

  /**
   * This loop through the blur boxes first applies a pixellation effect
   * by downscaling the region, then blurring on the upscale.
   *
   * This ensures that the blur effect we apply is reasonably cryptographically
   * secure
   */
  blurEffect.boxes.forEach((box) => {
    /**
     * The idea here is: translate the intensity (which is a float from 0 to 1)
     * to a value by which we can downscale
     */

    const pixelFactor = MIN_PIXELLATION + box.intensity * MAX_PIXELLATION;

    /**
     * This is how "big" the radius of the blur is in pixels
     */
    const blurRadius = MAX_BLUR_RADIUS * box.intensity;

    /**
     * Blur box dims converted to pixels
     */
    const { left, top, width, height } = box;
    const sourceLeft = left * imageSource.videoWidth;
    const sourceTop = top * imageSource.videoHeight;
    const sourceWidth = width * imageSource.videoWidth;
    const sourceHeight = height * imageSource.videoHeight;

    /**
     * Scale down
     */
    blurCtx.drawImage(
      blurCanvas, // using blurCanvas as source here allows blurs to "stack"
      sourceLeft,
      sourceTop,
      sourceWidth,
      sourceHeight,
      sourceLeft,
      sourceTop,
      sourceWidth / pixelFactor,
      sourceHeight / pixelFactor,
    );

    /**
     * Scale up
     *
     * (if we blur during this draw, some of the scaled-down image may
     * "escape" upper-left side of the blur; the blur must be its own step)
     */
    blurCtx.drawImage(
      blurCanvas,
      sourceLeft,
      sourceTop,
      sourceWidth / pixelFactor,
      sourceHeight / pixelFactor,
      sourceLeft,
      sourceTop,
      sourceWidth,
      sourceHeight,
    );

    /**
     * Blur the region
     */
    blurCtx.filter = `blur(${blurRadius}px)`;
    blurCtx.drawImage(
      blurCanvas,
      sourceLeft,
      sourceTop,
      sourceWidth,
      sourceHeight,
      sourceLeft,
      sourceTop,
      sourceWidth,
      sourceHeight,
    );
    blurCtx.filter = `none`;
  });
};
