const ACCESS_TOKEN_EXPIRATION_THRESHOLD_MS = 30_000;

export const accessTokenForGoogleUser = async (
  googleUser: gapi.auth2.GoogleUser,
) => {
  /**
   * gets google access_token, reloads token if it is expired or close to expiration
   */
  let authResponse = googleUser.getAuthResponse();
  if (authResponse.expires_in < ACCESS_TOKEN_EXPIRATION_THRESHOLD_MS) {
    authResponse = await googleUser.reloadAuthResponse();
  }
  return authResponse.access_token;
};
