import { applySnapshot, Instance, types } from 'mobx-state-tree';
import ProjectModel from './project/project.mst';
import PlayerModel from './player/player.mst';
import TimelineModel from './timeline/timeline.mst';
import ToolManagerModel from './tools/toolManager.mst';
import ToolbarModel from './toolbar/toolbar.mst';
import PlaybackModel from './playback/playback.mst';
import SelectModel from './selection/selection.mst';
import DebuggerModel from './debugger/debugger.mst';
import IngressModel from './ingress/ingress.mst';
import ViewerModel from './viewer/viewer.mst';
import AnalyticsModel from './analytics/analytics.mst';

import { initialState } from './initialState';

/**
 * The root node of the mobx state tree backing the studio experience.
 */
const RootModel = types
  .model('RootModel', {
    /**
     * This is the only branch of the tree synced to the DB.
     */
    project: types.optional(ProjectModel, {}),
    /**
     * State, views, & actions backing right sidebar and tool overlays.
     */
    toolManager: types.optional(ToolManagerModel, {}),
    /**
     * State, views, & actions backing preview.
     */
    player: types.optional(PlayerModel, {}),
    /**
     * State, views, & actions backing toolbar.
     */
    toolbar: types.optional(ToolbarModel, {}),
    /**
     * State, views, & actions backing timeline.
     */
    timeline: types.optional(TimelineModel, {}),
    /**
     * Playback state; depended on by many models which are directly referenced
     * by UI code.
     */
    playback: types.optional(PlaybackModel, {}),
    /**
     * Selected clip state; depended on mainly by timeline.
     */
    selection: types.optional(SelectModel, {}),
    /**
     * Debugger State
     */
    debugger: types.optional(DebuggerModel, {}),
    /**
     * State backing ingress modals
     */
    ingress: types.optional(IngressModel, {}),
    /**
     * Are the keyboard shortcuts globally enabled?
     * Useful because we need to turn off keyboard shortcuts in certain
     * states-- modals are open, etc.
     */
    keyboardShortcutsActive: types.optional(types.boolean, true),
    /**
     * Viewer state; Will ultimately depend on permissions when implemented.
     * This model is intended only for viewer information and will be used
     * for Questions and other viewer/responder based features.
     */
    viewer: types.optional(ViewerModel, {}),
    /**
     * Analytics state. Holds the aggregated data for the project.
     */
    analytics: types.optional(AnalyticsModel, {}),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      /**
       * Globally disable keyboard shortcuts
       */
      disableKeyboardShortcuts(): void {
        self.keyboardShortcutsActive = false;
      },
      /**
       * Globally enable keyboard shortcuts
       */
      enableKeyboardShortcuts(): void {
        self.keyboardShortcutsActive = true;
      },
      reset(): void {
        applySnapshot(self, initialState);
      },
    };
  });
export default RootModel;

export interface IRoot extends Instance<typeof RootModel> {}
