import { getParentOfType, Instance, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import { st } from '@castify/studio/fe-common';
import { getSelection } from '../../selection/selection.mst';
import { getZoom } from '../../timeline/zoom/zoom.mst';
import { getTimeline } from '../../timeline/timeline.mst';
import ClipModel from './clip.mst';
import { getScene } from '../../project/scene/scene.mst';
import { getUndoManager } from '../../project/project.mst';

const MINIMUM_ZOOM_EFFECT_LENGTH = 40;
/**
 * A zoom effect clip which is part of a gapful track nested inside
 * of a video clip
 */
const ZoomEffectModel = types
  .model('ZoomEffectModel', {
    /**
     * Client-side uuid
     */
    uuid: types.optional(types.identifier, nanoid),

    /**
     * The start time related to source clip duration
     * The zoom effect start/end is related to the source duration of the clip
     * so when changing trim in/out of the parent clip, the zoom box stay in
     * position
     */
    startSourceOffset: types.number,

    /**
     * The end time related to source clip duration
     */
    endSourceOffset: types.number,
  })
  .views((self) => {
    return {
      /**
       * Is clip selected
       */
      get isSelected(): boolean {
        return getSelection(self).selectedClip === self;
      },

      /**
       * start relative to clip start
       */
      get relativeStart(): st.ms {
        const parentClip = getParentOfType(self, ClipModel);
        return parentClip.relativePointToClip(self.startSourceOffset);
      },

      /**
       * End relative to clip start
       */
      get relativeEnd(): st.ms {
        const parentClip = getParentOfType(self, ClipModel);
        return parentClip.relativePointToClip(self.endSourceOffset);
      },

      /**
       * The duration of the clip in MS
       */
      get duration(): st.ms {
        return this.relativeEnd - this.relativeStart;
      },

      /**
       * At what absolute timeline timestamp does this clip begin?
       */
      get start(): st.ms {
        const parentClip = getParentOfType(self, ClipModel);
        return this.relativeStart + parentClip.start;
      },

      /**
       * At what absolute timeline timestamp does this clip end?
       */
      get end(): st.ms {
        return this.start + this.duration;
      },

      /**
       * The logic for relative start/end is to return trim in/out if the point exceeded the edge
       * So that means if both points are at the same edge, then they should be hidden because
       * they both would have exceeded the same edge at that point
       */
      get isVisible(): boolean {
        const samePoint = this.relativeStart === this.relativeEnd;
        return !samePoint;
      },

      /**
       * How far over from the left of the clip this effect should appear
       */
      get leftOffsetPx(): st.px {
        return this.relativeStart * getZoom(self).zoomFactor;
      },

      /**
       * How wide is this clip in pixels?
       */
      get widthPx(): st.px {
        return this.duration * getZoom(self).zoomFactor;
      },
    };
  })
  .actions((self) => ({
    changeStartSourceOffset(newOffset: number) {
      self.startSourceOffset = newOffset;
    },
    changeEndSourceOffset(newOffset: number) {
      self.endSourceOffset = newOffset;
    },

    trimFromStart(newOffset: number) {
      // Set start offset
      self.startSourceOffset = newOffset;
    },
    trimFromEnd(newOffset: number) {
      self.endSourceOffset = newOffset;
    },
    /**
     * Intended to be called when the pointer moves during a trim.
     *
     * TODO - deprecate use of the delta and then eliminate it entirely
     * from the signature. For more on this, see comments in useTrimHandler.ts
     * Second arg is optional to not break tests
     */
    handleTrimFromStart(delta: st.px, newStartPixel?: st.px) {
      const changeInMS = delta / getZoom(self).zoomFactor;
      let newOffset = self.start + changeInMS;

      // Get playhead from timeline
      const playheadPosition = getTimeline(self).playheadPosition;
      //use playhead to get current clip from main track
      const currentClip =
        getScene(self).mainTrack.getMainTrackClipAtTime(playheadPosition);
      //Forcefully account for if the current clip has been trimmed
      if (currentClip) newOffset += currentClip.trimIn;
      //Determine previous blurr effects,
      const prevZoomEffect = currentClip?.getPreviousZoomEffectById(self.uuid);
      // x|start| blur 1 |end|...|start| blur2 |end|......x

      //If previous blur effect has value, check if the new offset is less than the previous blur end offset.
      if (prevZoomEffect) {
        //if the new offset is less than the previous blur end offset.
        if (newOffset <= prevZoomEffect.endSourceOffset) {
          //If it is, we set the start source offset
          newOffset = prevZoomEffect.endSourceOffset;
        }
      }

      // If we're past our lower bounds, bring it back
      if (currentClip && newOffset < currentClip.trimIn)
        newOffset = currentClip.trimIn;
      // If we're past our upper bound, bring it back

      if (
        currentClip &&
        newOffset > self.endSourceOffset - MINIMUM_ZOOM_EFFECT_LENGTH
      )
        newOffset = self.endSourceOffset - MINIMUM_ZOOM_EFFECT_LENGTH;

      this.trimFromStart(newOffset);
    },
    /**
     * Intended to be called when the pointer moves during a trim.
     */
    handleTrimFromEnd(delta: st.px, newEndPixel?: st.px) {
      const changeInMS = delta / getZoom(self).zoomFactor;
      let newOffset = self.end + changeInMS;

      const scene = getScene(self);
      // Get playhead from timeline
      const playheadPosition = getTimeline(self).playheadPosition;
      //use playhead to get current clip from main track
      const currentClip =
        scene.mainTrack.getMainTrackClipAtTime(playheadPosition);
      if (!currentClip) return;
      newOffset += currentClip.trimIn;
      //Determine next blurr effect
      const nextZoomEffect = currentClip?.getNextZoomEffectById(self.uuid);
      //if there is a next blur are we trying to move past its start point
      if (nextZoomEffect && newOffset >= nextZoomEffect.startSourceOffset - 1) {
        newOffset = nextZoomEffect.startSourceOffset - 1;
      }

      // If we're past our lower bounds, bring it back
      if (newOffset - self.startSourceOffset < MINIMUM_ZOOM_EFFECT_LENGTH)
        newOffset = self.startSourceOffset + MINIMUM_ZOOM_EFFECT_LENGTH;
      // If we're past our upper bound, bring it back
      //Adding trimIn here to account for if the clip has been trimmed
      if (newOffset >= scene.mainTrack.totalDuration + currentClip.trimIn)
        newOffset = scene.mainTrack.totalDuration + currentClip.trimIn;

      this.trimFromEnd(newOffset);
    },
    /**
     * Starts a trim using either trim handle. Intended to run on pointerdown
     * events
     */
    startTrim(): void {
      getUndoManager(self).startGroup(() => {});
    },
    /**
     * Stops a trim using either trim handle. Intended to run on pointerup
     * events.
     */
    stopTrim(): void {
      getTimeline(self).setTemporaryOffset(0);
      getUndoManager(self).stopGroup();
    },
  }));
export default ZoomEffectModel;

export interface IZoomEffect extends Instance<typeof ZoomEffectModel> {}
