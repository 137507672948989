import {
  destroy,
  getParentOfType,
  IAnyStateTreeNode,
  Instance,
  types,
} from 'mobx-state-tree';
import { st } from '@castify/studio/fe-common';
import {
  constrainToMaxResolution,
  enforceTextApsectRatio,
  ensureEvenDimensions,
  ensureNonZeroDimensions,
  findMaxClipDims,
} from './helpers/dimensions';
import { SelectableClip } from './sceneTypes';
import RootModel from '../../root.mst';
import { getSelection } from '../../selection/selection.mst';
import { getToolManager } from '../../tools/toolManager.mst';
import VideoSourceModel from './videoSource.mst';
import ClipModel, { IClip } from './clip.mst';
import { getPlayback } from '../../playback/playback.mst';
import TextTrackModel from './textTrack.mst';
import DetatchedAudioTrackModel from './detatchedAudioTrack.mst';
import MainTrackModel from './mainTrack.mst';
import { duplicateClip } from './modelUtils';

/**
 * This model is serialized and deserialized to create the scene document
 * we persist to the database. Its mutable state should map 1-to-1 to
 * the shape of the scene document.
 *
 * A note on the time values found within many of the scene model's children.
 * Often clips will have a start, duration, end, trimIn, trimOut etc. expressed
 * in milliseconds. We have adopted a convention according to which start times
 * are _inclusive_, while end times are _exclusive_. For example, if a clip
 * is 1000 ms long, this means its first frame starts being displayed exactly at
 * 0 ms, but its last frame shows only up to 999.99 repeating milliseconds-- as
 * the next clip in the timeline starts at 1000ms. So, if a model indicates that
 * a clip ends at a certain timestamp, this really means the clip ends an
 * infinitesimal duration prior to this timestamp. This comes into play
 * when thinking through computations involving clip sizes (whether to use >/< or
 * >=/<= operators when dealing with clip end points).
 */
const SceneModel = types
  .model('SceneModel', {
    textTrack: types.optional(TextTrackModel, {}),
    mainTrack: types.optional(MainTrackModel, {}),
    detachedAudioTrack: types.optional(DetatchedAudioTrackModel, {}),
  })
  .views((self) => {
    return {
      /**
       * Get the dimensions of the scene as a whole, applying various
       * constraints and checks. These are configured and fully documented in
       * `./helpers/dimensions`.
       */
      get sceneDimensions(): st.dims {
        const hasText = !!self.textTrack.textClips.length;
        const maxDims = findMaxClipDims(self.mainTrack.mainTrackClips);
        const textConstrainedDims = hasText
          ? enforceTextApsectRatio(maxDims)
          : maxDims;
        const maxConstrainedDims =
          constrainToMaxResolution(textConstrainedDims);
        const evenDims = ensureEvenDimensions(maxConstrainedDims);
        const nonZeroDims = ensureNonZeroDimensions(evenDims);
        return nonZeroDims;
      },
      /**
       * The canvas drawn to the screen is usually drawn to match the scene
       * dimensions. But, when some tools are open, they need the canvas to be
       * the size of just the selected clip.
       *
       * This getter handles this scene-dimension-override logic, getting the
       * uncropped dimensions of just the selected clip, when the crop and blur
       * tools are open.
       */
      get canvasDimensions(): st.dims {
        if (getToolManager(self).isOverrideModeActive) {
          const selectedClip = getSelection(self).selectedClipParentClip;

          // type guard
          if (!selectedClip) {
            throw new Error('no clip selected when override mode active');
          }

          // type guard
          if (!VideoSourceModel.is(selectedClip.source)) {
            throw new Error('selected clip is not a video clip');
          }

          // note that we grab the SOURCE width and height
          // not the CROPPED width and height
          const { source } = selectedClip;
          const sourceDims = { width: source.width, height: source.height };
          return ensureEvenDimensions(constrainToMaxResolution(sourceDims));
        }

        // fall back to scene dims
        return this.sceneDimensions;
      },
      /**
       * Are there any clips of any type in the timeline?
       */
      get isTimelineEmpty(): boolean {
        return (
          !self.textTrack.textClips.length &&
          !self.mainTrack.mainTrackClips.length &&
          !self.detachedAudioTrack.audioClips.length
        );
      },
      get isLastClipStill(): boolean {
        const lastClip =
          self.mainTrack.mainTrackClips[
            self.mainTrack.mainTrackClips.length - 1
          ];
        return lastClip?.isStill() ?? false;
      },
    };
  })
  .actions((self) => {
    return {
      /*
       * Deletes or moves the text/audio clips, gets called when deleting a clip
       * removes audio/text if they start & end in the deleted clip
       * moves to next clip if text/audio starts at the deleted clip
       * moves to prev clip if text/audio ends at the deleted clip
       */
      deleteOrMoveRelatedTextAudioClips(
        clipToDelete: IClip,
        textAudioTrack: ITextAudioPosition[],
      ) {
        textAudioTrack
          .filter(
            (textAudio) =>
              textAudio.startClip === clipToDelete ||
              textAudio.endClip === clipToDelete,
          )
          .forEach((textAudio) => {
            const doesStartInClip = textAudio.startClip === clipToDelete;
            const doesEndInClip = textAudio.endClip === clipToDelete;
            const isInsideClip = doesStartInClip && doesEndInClip;
            if (isInsideClip) {
              destroy(textAudio);
            } else if (doesStartInClip) {
              const nextClip = self.mainTrack.getNextMainTrackClipById(
                clipToDelete.uuid,
              );
              if (!nextClip) {
                throw new Error(
                  "Can't find next clip to attach the text/audio to",
                );
              }
              textAudio.startClip = nextClip;
              textAudio.startClipSourceOffset = nextClip.trimIn;
            } else if (doesEndInClip) {
              const prevClip = self.mainTrack.getPreviousMainTrackClipById(
                clipToDelete.uuid,
              );
              if (!prevClip) {
                throw new Error(
                  "Can't find previous clip to attach the text/audio to",
                );
              }
              textAudio.endClip = prevClip;
              textAudio.endClipSourceOffset = prevClip.trimOut;
            }
          });
      },

      /**
       * Destroys clips
       *
       * Deletion and everything that comes with this: if start in video, then
       * move start to next clip, if end in video set to the end of last clip,
       * if overlaps video reduce length by the deleted clips length
       */
      deleteClip(selectedClip: SelectableClip): void {
        // need to remove the selection so we wouldn't hold reference
        // for a deleted model
        getSelection(self).selectClip(null);

        // if the user is deleting anything but a clip (effect, text, audio) then
        // just delete it and return
        if (!ClipModel.is(selectedClip)) {
          destroy(selectedClip);
          return;
        }

        const clip = selectedClip as IClip;
        this.deleteOrMoveRelatedTextAudioClips(clip, self.textTrack.textClips);
        this.deleteOrMoveRelatedTextAudioClips(
          clip,
          self.detachedAudioTrack.audioClips,
        );

        // delete the selected clip this will impact the total duration of the scene
        destroy(selectedClip);

        //if old seek time is greater total duration update seek time to duration - 1
        if (getPlayback(self).seekTime > self.mainTrack.totalDuration) {
          //Have to subtract one to see the clip otherwise its out of bounds
          getPlayback(self).setSeekTime(
            self.mainTrack.totalDuration > 0
              ? self.mainTrack.totalDuration - 1
              : 0,
          );
        }
      },
      /**
       * Cuts the clip currently under our playhead into two seperate clips, one that ends at the playhead and one that starts at the playhead
       */
      cutMainTrackClipAtPlayhead() {
        const { playheadPosition } = getPlayback(self);

        const clip = self.mainTrack.getMainTrackClipAtTime(playheadPosition);
        if (!clip) throw new Error('No clip stored at playhead :(');

        // Grab the distance from the playhead to the start and end of the current clip
        const timeInClipBeforePlayhead = playheadPosition - clip.start;
        const timeInClipAfterPlayhead = clip.end - playheadPosition;

        // Duplicate our current clip and add it into the timeline
        const newClip = duplicateClip(clip);
        self.mainTrack.addClipAfterOtherClip(clip, newClip);

        // Adjust our first clip to end at our playhead
        newClip.source.trimFromEnd(-timeInClipAfterPlayhead);
        newClip.blurEffects.forEach(newClip.updateEffectFromTrim);
        newClip.zoomEffects.forEach(newClip.updateEffectFromTrim);

        // Adjust our second clip to start at our playhead
        clip.source.trimFromStart(timeInClipBeforePlayhead);
        clip.blurEffects.forEach(clip.updateEffectFromTrim);
        clip.zoomEffects.forEach(clip.updateEffectFromTrim);

        // Update references for any text-clips that should point to the old model
        // For each text clip
        self.textTrack.textClips.forEach((textClip) => {
          // If the start is after our new clips start and before our playhead
          if (
            textClip.startClip.uuid === clip.uuid &&
            textClip.startClipSourceOffset < playheadPosition
          ) {
            // Set start clip to be new clip
            textClip.updateStartClip(newClip);
          }

          // If the end is after our new clips start and before our playhead
          if (
            textClip.endClip.uuid === clip.uuid &&
            textClip.endClipSourceOffset < playheadPosition
          ) {
            // Set end clip to be new clip
            textClip.updateEndClip(newClip);
          }
        });

        // Update references for any text-clips that should point to the old model
        // For each detatchedaudio clip
        self.detachedAudioTrack.audioClips.forEach((audioClip) => {
          // If the start is after our new clips start and before our playhead
          if (
            audioClip.startClip.uuid === clip.uuid &&
            audioClip.startClipSourceOffset < playheadPosition
          ) {
            // Set start clip to be new clip
            audioClip.updateStartClip(newClip);
          }

          // If the end is after our new clips start and before our playhead
          if (
            audioClip.endClip.uuid === clip.uuid &&
            audioClip.endClipSourceOffset < playheadPosition
          ) {
            // Set end clip to be new clip
            audioClip.updateEndClip(newClip);
          }
        });
      },
      /**
       * Commit changes for the text or audio track
       * This method will be called by `commitClipTrimChangesForTextAudio`
       * Is responsible to figure out the changes needed for one track (text or audio)
       */
      commitTextAudioClips(
        trimmedClip: IClip,
        textAudioTrack: ITextAudioPosition[],
      ) {
        textAudioTrack.forEach((textAudio) => {
          if (!textAudio.isVisible) {
            destroy(textAudio);
            return;
          }
          if (
            textAudio.startClip === trimmedClip &&
            textAudio.startClipSourceOffset < trimmedClip.trimIn
          ) {
            textAudio.startClipSourceOffset = trimmedClip.trimIn;
          }
          if (
            textAudio.endClip === trimmedClip &&
            textAudio.endClipSourceOffset > trimmedClip.trimOut
          ) {
            textAudio.endClipSourceOffset = trimmedClip.trimOut;
          }
        });
      },

      /**
       * When the user finishes trimming a clip (mouseup), if that trim affects
       * text/audio clips that are attached to it, we wan't to modify them to match
       * the endstate
       */
      commitClipTrimChangesForTextAudio(clipId: string): void {
        const clip = self.mainTrack.mainTrackClips.find(
          (c) => c.uuid === clipId,
        );
        if (!clip) {
          throw new Error("Can't find trim clip in the timeline");
        }
        this.commitTextAudioClips(clip, self.textTrack.textClips);
        this.commitTextAudioClips(clip, self.detachedAudioTrack.audioClips);
      },
      /**
       * Remove all clips from scene, clearing any selection
       */
      resetScene(): void {
        getSelection(self).clearSelection();
        self.mainTrack.mainTrackClips.forEach((clip) => {
          destroy(clip);
        });
        self.textTrack.textClips.forEach((clip) => {
          destroy(clip);
        });
        self.detachedAudioTrack.audioClips.forEach((clip) => {
          destroy(clip);
        });
        getPlayback(self).setSeekTime(0);
      },
    };
  });
export default SceneModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getScene(self: IAnyStateTreeNode): IScene {
  const root = getParentOfType(self, RootModel);
  return root.project.scene;
}

export interface ITextAudioPosition {
  startClipSourceOffset: st.ms;
  endClipSourceOffset: st.ms;
  startClip: IClip;
  endClip: IClip;
  isVisible: boolean;
}

export interface IScene extends Instance<typeof SceneModel> {}
