import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import { getPlayback } from '../playback/playback.mst';
import { getScene } from '../project/scene/scene.mst';
import RootModel from '../root.mst';
import { formatHms, msToHms } from './timeHelpers';
import { getSelection } from '../selection/selection.mst';
import { getToolManager } from '../tools/toolManager.mst';
import { ToolNames } from '../tools/tools.types';
import BlurEffectModel from '../project/scene/blurEffect.mst';
import TextClipModel from '../project/scene/textClip.mst';
import AudioClipModel from '../project/scene/audioClip.mst';
import { getTimeline } from '../timeline/timeline.mst';

/**
 * State, views, & actions backing  the toolbar.
 */
const ToolbarModel = types
  .model('ToolbarModel', {})
  .views((self) => {
    return {
      /**
       * The position of the playhead, formatted as an `00:00:00` string
       */
      get formattedPlayheadPosition(): string {
        return formatHms(msToHms(getPlayback(self).playheadPosition));
      },
      /**
       * The total duration of the scene, formatted as an `00:00:00` string
       */
      get formattedSceneDuration(): string {
        return formatHms(msToHms(getScene(self).mainTrack.totalDuration));
      },
      /**
       * The cut button is enabled when a clip (of any type) is selected.
       */
      get isCutButtonEnabled(): boolean {
        const { mainTrack } = getScene(self);
        const { playheadPosition } = getPlayback(self);
        const currClip = mainTrack.getMainTrackClipAtTime(playheadPosition);
        const MINIMUM_EFFECT_LENGTH = 40;

        if (!currClip) return false;
        if (playheadPosition >= currClip.end - MINIMUM_EFFECT_LENGTH)
          return false;
        if (playheadPosition <= currClip.start + MINIMUM_EFFECT_LENGTH)
          return false;
        return true;
      },
      /**
       * The crop button is enabled whenever a video clip is selected.
       * Likely to change if/when we introduce still images.
       */
      get isCropButtonEnabled(): boolean {
        return getSelection(self).isVideoClipSelected;
      },
      /**
       * The zoom button is enabled whenever a video clip or effect on a video
       * clip is selected. Likely to change if/when we introduce still images.
       */
      get isZoomButtonEnabled(): boolean {
        const selectionModel = getSelection(self);
        return (
          selectionModel.isVideoClipSelected ||
          selectionModel.isBlurEffectSelected ||
          selectionModel.isZoomEffectSelected
        );
      },
      /**
       * The blur button is enabled whenever a video clip or effect on a video
       * clip is selected. Likely to change if/when we introduce still images.
       */
      get isBlurButtonEnabled(): boolean {
        const selectionModel = getSelection(self);
        return (
          selectionModel.isVideoClipSelected ||
          selectionModel.isBlurEffectSelected ||
          selectionModel.isZoomEffectSelected
        );
      },
      /**
       * The text button is enabled only when there are other clips
       * at all in the timeline; text clips are not allowed to be added
       * in isolation from other content
       */
      get isTextButtonEnabled(): boolean {
        return !getScene(self).isTimelineEmpty;
      },
      /**
       * The delete button is enabled whenever a clip is selected.
       */
      get isDeleteButtonEnabled(): boolean {
        return !!getSelection(self).selectedClip;
      },
    };
  })

  .actions((self) => {
    return {
      /**
       * Intended to be attached to the cut button but can be called
       * programatically
       */
      cutSelection(): void {
        if (getPlayback(self).isPlaying) getPlayback(self).pause();

        const { selectedClip } = getSelection(self);
        const scene = getScene(self);

        if (TextClipModel.is(selectedClip))
          scene.textTrack.cutTextClipAtPlayhead();
        else if (AudioClipModel.is(selectedClip))
          scene.detachedAudioTrack.cutAudioClipAtPlayhead();
        else scene.cutMainTrackClipAtPlayhead();
      },
      /**
       * Intended to be attached to the crop button but can be called
       * programatically
       */
      toggleCropTool(): void {
        const { cropToolIfOpen, closeTool, openTool } = getToolManager(self);
        if (cropToolIfOpen) closeTool();
        else openTool(ToolNames.CropTool);
      },
      /**
       * Intended to be attached to the zoom button but can be called
       * programatically. Either adds a new zoom effect at playhead or
       * opens the zoom effect under playhead
       */
      addOrEditZoomEffect(): void {
        const { playheadPosition } = getPlayback(self);
        const zoomEffectAtPlayhead =
          getScene(self).mainTrack.getZoomEffectAtTime(playheadPosition);

        if (zoomEffectAtPlayhead) {
          getSelection(self).selectClip(zoomEffectAtPlayhead);
        } else {
          const newZoom =
            getScene(self).mainTrack.addZoomEffectAtTime(playheadPosition);
          if (newZoom) {
            getSelection(self).selectClip(newZoom);
          }
        }
      },
      /**
       * Intended to be attached to the blur button but can be called
       * programatically. Either adds a new zoom effect at playhead or
       * opens the zoom effect under playhead
       */
      addOrEditBlurEffect(): void {
        const { playheadPosition } = getPlayback(self);
        const blurEffectAtPlayhead =
          getScene(self).mainTrack.getBlurEffectAtTime(playheadPosition);

        if (blurEffectAtPlayhead) {
          getSelection(self).selectClip(blurEffectAtPlayhead);
        } else {
          const newBlur =
            getScene(self).mainTrack.addBlurEffectAtTime(playheadPosition);
          if (newBlur) {
            getSelection(self).selectClip(newBlur);
          }
        }
      },

      /**
       * Intended to be attached to the text button but can be called
       * programatically
       */
      addOrEditTextClip(): void {
        const { playheadPosition } = getPlayback(self);
        const textClipAtPlayhead =
          getScene(self).textTrack.getTextClipAtTime(playheadPosition);

        if (textClipAtPlayhead) {
          getSelection(self).selectClip(textClipAtPlayhead);
        } else {
          const newTextClip =
            getScene(self).textTrack.addTextClipAtTime(playheadPosition);
          getSelection(self).selectClip(newTextClip);
        }
      },

      /**
       * Intended to be attached to the delete button but can be called
       * programatically
       */
      deleteSelected(): void {
        if (getPlayback(self).isPlaying) getPlayback(self).pause();

        const { selectedClip } = getSelection(self);
        const { blurToolIfOpen } = getToolManager(self);
        if (getPlayback(self).isPlaying) getPlayback(self).pause();

        // if we are in the blur editor, the delete button does not delete
        // the clip until there's only one blur left. Prior to this, it instead
        // deletes the selected blur box.
        if (blurToolIfOpen && !blurToolIfOpen.downToOneBox) {
          // type guard
          if (!BlurEffectModel.is(selectedClip)) {
            throw new Error(
              "in delete button's blur path with no blur effect clip selected ",
            );
          }

          // the blur tool does the real thinking here
          blurToolIfOpen.deleteSelectedBox();

          // early return to not delete the clip
          return;
        }

        if (selectedClip) {
          getScene(self).deleteClip(selectedClip);
        }
      },
    };
  });
export default ToolbarModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getToolbar(self: IAnyStateTreeNode): IToolbar {
  const root = getParentOfType(self, RootModel);
  return root.toolbar;
}
export interface IToolbar extends Instance<typeof ToolbarModel> {}
