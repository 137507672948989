import { useMst } from '@castify/studio/studio-store';
import { Icon, Button, TextField } from '@mui/material';
import { useState } from 'react';
import sidebarCommon from './sidebarCommon.styles';

/**
 * The crop tool's right sidebar
 */
function ZoomSidebar() {
  const {
    toolManager: { zoomToolIfOpen, closeTool },
  } = useMst();
  const [value, setValue] = useState('0.0');

  if (!zoomToolIfOpen) return null;

  return (
    <div css={sidebarCommon.container}>
      <div css={sidebarCommon.titleSection}>
        <Icon css={sidebarCommon.icon}>zoom_in</Icon>
        <h2>Zoom</h2>
      </div>

      <div css={sidebarCommon.centerSection}>
        <div css={sidebarCommon.NumberInputStyle}>
          <TextField
            type="number"
            value={value}
            variant="outlined"
            color="primary"
            inputProps={{
              maxLength: 13,
              step: '0.1',
            }}
            onChange={(e) => {
              setValue(parseFloat(e.target.value).toFixed(1));
            }}
          />

          <TextField
            type="number"
            value={value}
            variant="outlined"
            color="primary"
            inputProps={{
              maxLength: 13,
              step: '0.1',
            }}
            onChange={(e) => {
              setValue(parseFloat(e.target.value).toFixed(1));
            }}
          />
        </div>
      </div>
      <div css={sidebarCommon.bottomSection}>
        <Button
          variant="text"
          css={sidebarCommon.doneButton}
          onClick={() => closeTool()}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default ZoomSidebar;
