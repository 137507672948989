import { MemoryRouter, Route, Routes } from 'react-router-dom';
import PlaybackSystem from '../PlaybackSystem/PlaybackSystem';
import { MstProvider } from '@castify/studio/studio-store';

interface PlaybackTestWrapperProps {
  children: React.ReactNode;
}

/**
 * As the player component must have both an MST context and be wrapped by a
 * PlaybackSystem for its context provider, there's a bit of boilerplate
 * involved in meeting the conditions for a render. Thus, this test wrapper for
 * the player component and playback system parent that  centralizes and dries
 * up test setup.
 */
const PlaybackTestWrapper = ({ children }: PlaybackTestWrapperProps) => {
  return (
    <MstProvider>
      <MemoryRouter>
        <Routes>
          <Route element={<PlaybackSystem />}>
            <Route path="/" element={children} />
          </Route>
        </Routes>
      </MemoryRouter>
    </MstProvider>
  );
};

export default PlaybackTestWrapper;
