import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Toolbar.styles';
import AddMediaComponent from './AddMediaComponent/AddMediaComponent';
import PlaybackController from './PlaybackController/PlaybackController';
import RedoButton from './RedoButton/RedoButton';
import UndoButton from './UndoButton/UndoButton';
import CutButton from './CutButton/CutButton';
import DeleteClipButton from './DeleteClipButton/DeleteClipButton';

interface ToolbarProps {
  showSkeleton?: boolean;
}

function ToolbarComponent({ showSkeleton }: ToolbarProps) {
  return (
    <div css={styles.toolbarContainer}>
      <div css={styles.toolbarLeft}>
        <PlaybackController showSkeleton={showSkeleton} />
      </div>
      <div css={styles.toolbarRight}>
        <DeleteClipButton showSkeleton={showSkeleton} />
        <CutButton showSkeleton={showSkeleton} />
        <UndoButton showSkeleton={showSkeleton} />
        <RedoButton showSkeleton={showSkeleton} />
        <AddMediaComponent showSkeleton={showSkeleton} />
      </div>
    </div>
  );
}

export default observer(ToolbarComponent);
