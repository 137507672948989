import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  iconContainer: css`
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    width: 100%;
    position: relative;
    justify-content: space-between;
    color: white;

    .MuiButton-root {
      margin: 4px;
      flex: 0 1 calc(10% - 8px);
      color: white;
    }
  `,
  selectContainer: css`
    /* span {
      display: none;
    } */

    .MuiInputBase-input {
      color: white;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiSelect-select {
      padding: 4px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
  sliderContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    p {
      align-self: flex-end;
      margin: 0;
    }
  `,
  textContainer: css`
    display: flex;
    flex-direction: row;
  `,
  TextInputStyle: css`
    .MuiInput-root {
      color: white;
    }
    .MuiInput-root:before {
      border-bottom: 1.5px solid ${colors.gray[500]};
    }
    .MuiInput-root:after {
      border-bottom: 1.5px solid ${colors.gray[500]};
    }
    .MuiInput-root:hover {
      border-bottom: 1.5px solid ${colors.gray[500]};
    }

    .Mui-focused {
      border: 4px solid ${colors.gray[750]};
      border-bottom: 1.5px solid ${colors.pink};
      box-sizing: border-box;
      border-radius: 4px;
      z-index: 200;
    }
  `,
  NumberInputStyle: css`
    display: flex;
    flex-direction: row;
    margin: 0 20px;
    gap: 40px;

    .MuiSelect-select {
      color: white;
    }

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .Mui-focused input {
      border: 4px solid ${colors.gray[750]};
      border-bottom: 1px solid ${colors.pink};
      box-sizing: border-box;
      border-radius: 4px;
      z-index: 200;
    }

    & .MuiOutlinedInput-input {
      padding: 0;
      height: 40px;
      color: white;
      border-bottom: 1px solid ${colors.gray[900]};
    }
  `,
  layoutContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      font-size: 16px;
      width: 60px;
      color: white;
    }
  `,

  tabsContainer: css`
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .tab {
      padding: 5px;
    }

    .selected {
      background: ${colors.gray[500]};
      border-radius: 5px;
    }
  `,

  swatchContainer: css`
    display: grid;
    width: 100%;
    justify-content: center;
    grid-template-columns: repeat(7, 12px);
    gap: 15px 18px;
    place-items: center center;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 0;
  `,
  swatchIndicator: css`
    margin-right: 4px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: inline-block;
    border: none;
    cursor: pointer;
  `,
  divider: css`
    background: ${colors.gray[500]};
    width: 100%;
    height: 2px;
    margin: 6px 0;
  `,
};
export default styles;
