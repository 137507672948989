import { useEffect, useState } from 'react';
import { createBrowserLogger } from '@castify/studio/observability/browser';
import { gapiLoader } from '../gapiUtils/gapiLoader';

export interface GoogleAuthInstance {
  authInstance?: gapi.auth2.GoogleAuth;
  authInstanceLoading: boolean;
  authInstanceError?: Error;
}

/**
 * encapsulates loading google auth SDK
 * returns an instance/loading/error state for gapi.auth2.GoogleAuth
 */
export function useGoogleAuthLoader(): GoogleAuthInstance {
  const logger = createBrowserLogger('useGoogleAuthLoader');

  const [authInstance, setAuthInstance] = useState<
    gapi.auth2.GoogleAuth | undefined
  >();
  const [authInstanceLoading, setLoading] = useState(true);
  const [authInstanceError, setError] = useState<Error | undefined>();

  /**
   * loads sdk and initializes google auth library
   */
  useEffect(() => {
    async function init() {
      try {
        await gapiLoader.init();
        const retrievedAuthInstance = gapi.auth2.getAuthInstance();
        if (!retrievedAuthInstance) {
          throw new Error(
            'no google auth instance, make sure service has finished initiating',
          );
        }
        setAuthInstance(retrievedAuthInstance);
      } catch (error) {
        logger.warn('Error while loading/initializing googleAuth', { error });
        setError(
          error instanceof Error ? error : new Error('Unrecognized Error'),
        );
      } finally {
        setLoading(false);
      }
    }
    init();
    // Exhaustive deps are wrong here. We REALLY do not want this effect to run twice.
    // Unless we want to take on responsibility for gracefully handling failures there we
    // only want to run this effect once, and should probably rely on a full browser reload
    // to try again.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { authInstance, authInstanceLoading, authInstanceError };
}
