import { css } from '@emotion/react';

// the border size for the selection box
const borderSize = 4;

// the size of the corner handles
const handleSize = 16;
const rotateStickSize = 4;
const rotateBorderSize = 4;

// how much to offset handles;
// positions their centers directly in the middle of the border
const handleOffset = -1 * (handleSize / 2 + borderSize / 2);
const handleBase = css`
  position: absolute;
  border-radius: 50%;
  background: #ffffff;
  cursor: crosshair;
  width: ${handleSize}px;
  height: ${handleSize}px;
`;

const styles = {
  boxContainer: css`
    position: absolute;
  `,
  border: css`
    cursor: move;
    width: 100%;
    height: 100%;
    position: absolute;
    top: ${-borderSize}px;
    left: ${-borderSize}px;
    border: ${borderSize}px dashed;
  `,
  topLeftHandle: css`
    cursor: nwse-resize;
    left: ${handleOffset}px;
    top: ${handleOffset}px;
    ${handleBase}
  `,
  topRightHandle: css`
    cursor: nesw-resize;
    right: ${handleOffset}px;
    top: ${handleOffset}px;
    ${handleBase}
  `,
  bottomRightHandle: css`
    cursor: nwse-resize;
    right: ${handleOffset}px;
    bottom: ${handleOffset}px;
    ${handleBase}
  `,
  bottomLeftHandle: css`
    cursor: nesw-resize;
    left: ${handleOffset}px;
    bottom: ${handleOffset}px;
    ${handleBase}
  `,
  rotateHandle: css`
    top: ${handleOffset - 40}px;
    box-sizing: border-box;
    left: calc(50% - ${handleSize / 2}px);
    z-index: +4;
    ${handleBase};
  `,
  rotateStick: css`
    position: absolute;
    bottom: 100%;
    left: calc(50% - ${rotateStickSize / 2}px);
    width: ${rotateStickSize}px;
    height: 40px;
  `,
};
export default styles;
