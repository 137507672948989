import TextClipModel, { ITextClip } from './textClip.mst';
import { getSnapshot } from 'mobx-state-tree';
import { nanoid } from 'nanoid';
import ClipModel, { IClip } from './clip.mst';
import VideoSourceModel from './videoSource.mst';
import AudioClipModel, { IAudioClip } from './audioClip.mst';

/**
 * Creates a new clip thats a copy of this one
 *
 * TODO Semiroundpizza8: Peek at this and refactor it to use snapshots instead of manually setting up our objects
 */
export function duplicateClip(clip: IClip): IClip {
  // Create an object duplicate of our media source
  let source = {
    ...getSnapshot(clip.source),
  };
  if (VideoSourceModel.is(clip.source)) {
    source = {
      ...getSnapshot(clip.source),
      uuid: nanoid(),
    };
  }

  // Create an object duplicate of our blur effects
  const blurEffects = clip.blurEffects.map((currentBlur) => {
    const newBlurBoxes = currentBlur.boxes.map((box) => ({
      ...getSnapshot(box),
      uuid: nanoid(),
    }));

    return {
      ...getSnapshot(currentBlur),
      boxes: newBlurBoxes,
      uuid: nanoid(),
    };
  });

  // Create an object duplicate of our zoom effects
  const zoomEffects = clip.zoomEffects.map((currentZoom) => {
    return { ...getSnapshot(currentZoom), uuid: nanoid() };
  });

  // Combine everything together
  const clipSnapshot = {
    ...getSnapshot(clip),
    source,
    blurEffects,
    zoomEffects,
    uuid: nanoid(),
  };

  return ClipModel.create(clipSnapshot);
}

export function duplicateTextClip(textClip: ITextClip): ITextClip {
  const boxes = textClip.boxes.map((box) => {
    return {
      ...getSnapshot(box),
      uuid: nanoid(),
      boundingBox: {
        ...getSnapshot(box.boundingBox),
        uuid: nanoid(),
      },
    };
  });

  const newTextClipSnapshot = {
    ...getSnapshot(textClip),
    uuid: nanoid(),
    boxes,
  };

  return TextClipModel.create(newTextClipSnapshot);
}

export function duplicateAudioClip(audioClip: IAudioClip): IAudioClip {
  const newAudioClipSnapshot = {
    ...getSnapshot(audioClip),
    uuid: nanoid(),
  };

  return AudioClipModel.create(newAudioClipSnapshot);
}
