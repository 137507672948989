import { st } from '@castify/studio/fe-common';
import { Instance, types } from 'mobx-state-tree';
import { getUndoManager } from '../project/project.mst';
import ClipModel, { IClip } from '../project/scene/clip.mst';
import VideoSourceModel, {
  IVideoSource,
} from '../project/scene/videoSource.mst';
import { getSelection } from '../selection/selection.mst';

/**
 * State, views, & actions backing the crop tool
 */
const CropToolModel = types
  .model('CropToolModel', {})
  .views((self) => {
    return {
      /**
       * Convenience getter for the clip being edited.
       */
      get targetClip(): IClip {
        const clip = getSelection(self).selectedClip;
        if (!clip) throw new Error('targetClip accessed when no clip selected');
        if (!ClipModel.is(clip))
          throw new Error('targetClip accessed with video clip not selected');
        return clip;
      },
      /**
       * Convenience getter for the soure of the clip being edited.
       */
      get targetSource(): IVideoSource {
        const source = this.targetClip.source;
        if (!VideoSourceModel.is(source))
          throw new Error('targetSource accessed when video clip not selected');
        return source;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       *
       * Apply default crop if needed
       */
      afterAttach(): void {
        if (!self.targetSource.cropBox) {
          self.targetSource.setDefaultCrop();
        }
      },
      /**
       * Removes all crop, indicating via flag that clip is now uncropped
       */
      removeCrop(): void {
        self.targetSource.removeCrop();
      },
    };
  });

export default CropToolModel;

export interface ICropTool extends Instance<typeof CropToolModel> {}
