import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '@mui/material/Icon';
import { IconButton, Slider } from '@mui/material';

import { useMst } from '@castify/studio/studio-store';

import styles from './VolumeControls.styles';

function VolumeControls() {
  const {
    playback: { volume, setVolume },
  } = useMst();
  const isMuted = volume === 0;

  const [previousVolume, setPreviousVolume] = useState(volume);

  function toggleMute() {
    if (volume > 0) {
      setPreviousVolume(volume);
      setVolume(0);
    } else {
      setVolume(previousVolume);
    }
  }

  function handleSliderChange(event: Event, value: number | number[]) {
    setVolume(Array.isArray(value) ? value[value.length - 1] : value);
  }

  return (
    <div css={styles.volumeContainer}>
      <IconButton
        onClick={() => toggleMute()}
        aria-label="MuteButton"
        data-testid="mute-button"
        data-pendo-id="player-mute-button"
      >
        <Icon css={styles.volumeButton}>
          {isMuted && 'volume_off'}
          {volume >= 0.5 && 'volume_up'}
          {!isMuted && volume < 0.5 && 'volume_down'}
        </Icon>
      </IconButton>
      <Slider
        css={styles.volumeSlider}
        min={0}
        max={1}
        onChange={(event, value) => handleSliderChange(event, value)}
        step={0.01}
        value={volume}
        aria-label="VolumeSlider"
        data-testid="volume-slider"
        data-pendo-id="player-volume-slider"
      />
    </div>
  );
}
export default observer(VolumeControls);
