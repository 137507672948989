import React from 'react';
import styles from './TrimHandle.styles';
import { TrimmableClip } from '@castify/studio/studio-store';
import { useTrimHandler } from './useTrimHandler';

/**
 * Enum used to configure this component
 */
export enum TrimHandleNames {
  'left' = 'left',
  'right' = 'right',
}

/**
 * Props for TrimHandle component
 */
type TrimHandleProps = {
  trimHandleName: TrimHandleNames;
  isVisible: boolean;
  clipOrEffect: TrimmableClip;
  clipContainerRef: React.RefObject<HTMLDivElement>;
};

/**
 * A small pure helper component for displaying trim handles
 * on a clip. Useable by all clip types. Prop interface will need to be
 * expanded to allow injecting pointer event handlers
 */
function TrimHandle({
  trimHandleName,
  isVisible,
  clipOrEffect,
  clipContainerRef,
}: TrimHandleProps) {
  const { pointerDown } = useTrimHandler(
    clipOrEffect,
    trimHandleName,
    clipContainerRef,
  );

  return (
    <div
      onPointerDown={pointerDown}
      css={styles.handle(isVisible, trimHandleName)}
      className="handle"
      data-pendo-id="trim-handle"
      data-testid="trim-handle"
    ></div>
  );
}

export default TrimHandle;
