import React from 'react';
import styles from './WatchPageFooter.styles';
import { Skeleton, Typography } from '@mui/material';
import { useMst } from '@castify/studio/studio-store';

interface WatchPageFooterProps {
  showSkeleton?: boolean;
}
const WatchPageFooter = ({ showSkeleton }: WatchPageFooterProps) => {
  const { project } = useMst();
  return (
    <div css={styles.container}>
      {showSkeleton ? (
        <Skeleton variant="rectangular" height={40} width={300} />
      ) : (
        <Typography variant="h2" noWrap css={styles.footerText}>
          {project.title}
        </Typography>
      )}
    </div>
  );
};

export default WatchPageFooter;
