import { Instance, types } from 'mobx-state-tree';
import { ProbeData } from '@castify/studio/video-probe-data';

import { getPlayback } from '../playback/playback.mst';
import { getScene } from '../project/scene/scene.mst';

/**
 * This interface contains all of the data necessary to add a video to the scene
 */
export interface NewClipInfo {
  videoSourceUuid: string;
  probeData: ProbeData;
}

/**
 * Volatile state for the model
 */
interface IngressVolatileState {
  file: File | undefined;
}

/**
 * State, views, & actions backing the ingress flows.
 *
 * Almost all state is intentionally kept in react; this model
 * only describes when the modal-driven ingress experiences are shown,
 * exposing actions to kick off and finish the flows.
 *
 * The idea here is: for those parts of the application that don't profit
 * from MST, we don't need MST.
 */
const IngressModel = types
  .model('IngressModel', {
    /**
     * Is a drive import in progress?
     * We close the progress modal and add the clip without waiting
     * for all playback assets to finish.
     */
    driveImportInProgress: types.optional(types.boolean, false),
    /**
     * Is a local upload in progress?
     * We close the progress modal and add the clip without waiting
     * for all playback assets to finish.
     */
    localUploadInProgress: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get isUploadInProgress(): boolean {
        return self.localUploadInProgress || self.driveImportInProgress;
      },
    };
  })
  .volatile((self): IngressVolatileState => {
    return {
      file: undefined,
    };
  })
  .actions((self) => {
    return {
      /**
       * Start the drive import flow. Public API starting point for the
       * drive flow.
       */
      startDriveImportFlow(): void {
        getPlayback(self).pause();
        self.driveImportInProgress = true;
      },

      /**
       * Start the local upload flow. Public API starting point for the
       * local upload flow.
       */
      startLocalUploadFlow(file: File): void {
        getPlayback(self).pause();
        self.localUploadInProgress = true;
        self.file = file;
      },

      /**
       * Finish any in-progress flows. Intended to be called only internal to
       * the ingress flows. If passed data, will add the clip to the
       * scene; otherwise just wrap up the flow.
       */
      finishIngressFlow(newClipInfo?: NewClipInfo): void {
        if (newClipInfo) {
          getScene(self).mainTrack.addNewClipFromIngress(newClipInfo);
          // seek to zero
          getPlayback(self).setSeekTime(0);
        }
        self.localUploadInProgress = false;
        self.driveImportInProgress = false;
      },
    };
  });
export default IngressModel;

export interface IIngress extends Instance<typeof IngressModel> {}
