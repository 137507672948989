import { Instance, types } from 'mobx-state-tree';
import AudioClipModel, { IAudioClip } from './audioClip.mst';
import { st } from '@castify/studio/fe-common';
import {
  enforcePointBoundriesInTrack,
  getClipsInRange,
} from './helpers/clipHelpers';
import { getScene } from './scene.mst';
import { ITextClip } from './textClip.mst';
import { getPlayback } from '../../playback/playback.mst';
import { duplicateAudioClip } from './modelUtils';

const EFFECT_DEFAULT_DURATION = 1000;
const DetatchedAudioTrackModel = types
  .model('DetatchedAudioTrackModel', {
    audioClips: types.optional(types.array(AudioClipModel), []),
  })
  .views((self) => ({
    /**
     * Grabs the audio clips visible within the range
     */
    getVisibleAudioClipsInRange(start: st.ms, end: st.ms): Array<IAudioClip> {
      const clips = getClipsInRange(
        self.audioClips,
        start,
        end,
      ) as Array<IAudioClip>;
      return clips.filter((t) => t.isVisible);
    },
    /**
     * Returns the audio track clip at a time, if any
     */
    getAudioClipAtTime(time: st.ms): IAudioClip | undefined {
      return self.audioClips.find(
        (clip) => time >= clip.start && time < clip.end,
      );
    },
    /**
     * Gets the next clip by the current clip id
     */
    getNextAudioTrackClipById(clipId: string): IAudioClip | undefined {
      const index = self.audioClips.findIndex((clip) => clip.uuid === clipId);
      // If there is no following clip, return early
      if (index === self.audioClips.length - 1) return;
      return self.audioClips[index + 1];
    },
    getPreviousAudioTrackClipById(clipId: string): IAudioClip | undefined {
      const index = self.audioClips.findIndex((clip) => clip.uuid === clipId);
      // If there is no previous clip, return early
      if (index <= 0) return;
      return self.audioClips[index - 1];
    },
  }))
  .actions((self) => ({
    /**
     * Add a new audio clip to the scene based on params passed in by the caller.
     */
    addAudioClipAtTime(time: st.ms): IAudioClip {
      let startTime = time;
      let endTime = time + EFFECT_DEFAULT_DURATION;
      const scene = getScene(self);
      // recalculating start/end after checking if they overlap next/prev clips or
      // if end exceeds the timeline
      [startTime, endTime] = enforcePointBoundriesInTrack(
        startTime,
        endTime,
        0,
        scene.mainTrack.totalDuration,
        self.audioClips,
      );

      const startClip = scene.mainTrack.getMainTrackClipAtTime(startTime);
      const endClip = scene.mainTrack.getMainTrackClipAtTime(endTime);

      if (!startClip || !endClip) {
        throw new Error("Couldn't find clips that text can attach to");
      }

      const startClipSourceOffset =
        startClip.getSourceOffsetFromTimestamp(startTime);
      const endClipSourceOffset = endClip.getSourceOffsetFromTimestamp(endTime);

      if (
        startClipSourceOffset === undefined ||
        endClipSourceOffset === undefined
      ) {
        throw new Error('pointInTime is outside of clip range');
      }

      const audioClip = AudioClipModel.create({
        startClip: startClip.uuid,
        endClip: endClip.uuid,
        startClipSourceOffset,
        endClipSourceOffset,
      });
      // TODO: sort by time
      self.audioClips.push(audioClip);
      return audioClip;
    },
    /**
     * Add a new clip to the scene based on params passed in by the caller.
     */
    addAudioClipAfterOtherClip(
      audioClipInTimeline: IAudioClip,
      audioClipToAdd: IAudioClip,
    ): void {
      const placeToInsert = self.audioClips.findIndex(
        (currClip) => currClip.uuid === audioClipInTimeline.uuid,
      );
      self.audioClips.splice(placeToInsert, 0, audioClipToAdd);
    },
    /**
     * Cuts a given audio clip into two seperate audio clips, one that ends at our playhead and another that begins at our playhead
     */
    cutAudioClipAtPlayhead() {
      const { playheadPosition } = getPlayback(self);
      const clip = self.getAudioClipAtTime(playheadPosition);

      if (!clip) throw new Error('No text clip stored at playhead :(');

      // [..., newClip, clip, ...]
      const newClip = duplicateAudioClip(clip);
      this.addAudioClipAfterOtherClip(clip, newClip);

      // Shorten our first AudioClip
      newClip.trimOut(playheadPosition);
      // Shorten our second AudioClip
      clip.trimIn(playheadPosition);
    },
  }));

export default DetatchedAudioTrackModel;
export type IDetatchedAudioTrackModel = Instance<
  typeof DetatchedAudioTrackModel
>;
