import { useMst } from '@castify/studio/studio-store';
import { colors } from '@castify/studio/fe-common';
import { observer } from 'mobx-react-lite';
import MoveableBox from '../Boxes/MoveableBox';
import styles from './CropOverlay.styles';

type CropOverlayProps = {
  overlayContainerRef: React.RefObject<HTMLDivElement>;
};

/**
 * The overlay for the crop tool. Presupposes that parent element
 * is exactly the size of the canvas
 */
const CropOverlay = ({ overlayContainerRef }: CropOverlayProps) => {
  const {
    toolManager: { cropToolIfOpen },
  } = useMst();

  if (!cropToolIfOpen) return null;
  if (!cropToolIfOpen.targetSource.cropBox) return null;

  const box = cropToolIfOpen.targetSource.cropBox;

  return (
    <>
      {/**
       * This displays a darkened overlay with a hole cut out of the the middle
       * to draw the user's attention to th area being cropped.
       *
       * The technique to cut the hole out of the div is the same as the one
       * demonstrated here: https://bennettfeely.com/clippy/.
       * Choose the "frame" preset.
       * */}
      <div
        css={styles.darkOverlay}
        style={{
          clipPath: `polygon(
            0% 0%,
            0% 100%,
            ${box.left * 100}% 100%,
            ${box.left * 100}% ${box.top * 100}%,
            ${box.right * 100}% ${box.top * 100}%,
            ${box.right * 100}% ${box.bottom * 100}%,
            ${box.left * 100}% ${box.bottom * 100}%,
            ${box.left * 100}% 100%,
            100% 100%,
            100% 0%
            )`,
        }}
      ></div>
      <MoveableBox
        borderColor={colors.orangeBorder}
        isSelected={true}
        boxModel={box}
        overlayContainerRef={overlayContainerRef}
      />
    </>
  );
};

export default observer(CropOverlay);
