import { observer } from 'mobx-react-lite';
import styles from './AdvancedEditLink.styles';
import type { StudioParams } from '@castify/studio/fe-common';
import { Link, useParams } from 'react-router-dom';
import { Button, Skeleton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface AdvancedEditProps {
  showSkeleton?: boolean;
}

export function AdvancedEditLink({ showSkeleton }: AdvancedEditProps) {
  const { projectId } = useParams<StudioParams>();

  if (showSkeleton)
    return <Skeleton variant="rectangular" width={158} height={40} />;

  return (
    <Link
      to={`/edit/${projectId}`}
      data-pendo-id="advanced-edit-link"
      css={styles.timelineLink}
    >
      <Button variant="text">
        <VisibilityIcon />
        <Typography css={styles.text}>Show Timeline</Typography>
      </Button>
    </Link>
  );
}

export default observer(AdvancedEditLink);
