import { useEffect, useState } from 'react';
import {
  LoadSingleProjectQuery,
  LoadSingleProjectDocument,
} from '@castify/studio/database-clients/graphql';
import { useMst } from '@castify/studio/studio-store';
import { useQuery } from 'urql';
import { createBrowserLogger } from '@castify/studio/observability/browser';
const logger = createBrowserLogger('useFetchAnyProject');

type IFetchScene = {
  projectId: string;
};

/**
 * This hook is responsible for fetching the scene from the database
 * on page load. It fetches a project owned by a user even if that project
 * is devoid of content
 */
export const useFetchAnyProject = ({ projectId }: IFetchScene) => {
  const { project } = useMst();

  /**
   * If the projectId is the same as project.uuid in the store it means
   * that this projects data is already loaded. This will occur if the owner
   * was already viewing this project on another page
   */
  const isDataLoaded = projectId === project.uuid;

  /**
   * Acts as a latch that pauses query after it has run once
   */
  const [paused, setPaused] = useState(isDataLoaded);

  /**
   * Log on first run
   */
  useEffect(() => {
    if (!paused) {
      logger.info(`Fetching project ${projectId}`);
    }
  }, [paused, projectId]);

  /**
   * We use a network-only policy to load the project, pausing the query
   * after it has executed once
   */
  const [{ fetching, error, data }] = useQuery<LoadSingleProjectQuery>({
    query: LoadSingleProjectDocument,
    variables: { projectId },
    requestPolicy: 'network-only',
    pause: paused,
  });

  /**
   * Once loaded, apply the snapshot-- but only once.
   */
  useEffect(() => {
    if (!fetching && !error && !paused && data && data.projects_by_pk) {
      setPaused(true);
      logger.info(`Fetched project ${projectId}`);

      if (data.projects_by_pk.title)
        project.setProjectTitle(data.projects_by_pk.title);
      project.applySceneSnapshot(data.projects_by_pk.scene);
    }
  }, [data, error, fetching, paused, project, projectId]);

  /**
   * The returns here allow the component to respond to state changes
   * as the queries update.
   */
  return {
    data,
    fetchingError: error,
  };
};
