import { useEffect, useState } from 'react';
import axios from 'axios';
import WaveformData from 'waveform-data';
import Skeleton from '@mui/material/Skeleton';
import { IClip, IVideoSource } from '@castify/studio/studio-store';
import { environment } from '@castify/studio/env/browser';
import WaveformCanvas from './WaveformCanvas/WaveformCanvas';
import { createBrowserLogger } from '@castify/studio/observability/browser';
import styles from './WaveformGraph.styles';
const logger = createBrowserLogger('UseGetWaveform');

interface WaveformGraphProps {
  clip: IClip;
}

/**
 * A hook that will get the `dat` file from the BE and return it as a `WaveformData`
 */
function useGetWaveform(waveformUrl: string, hasAudio: boolean) {
  const [wave, setWave] = useState<WaveformData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrored, setHasErrored] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setHasErrored(false);
        if (!hasAudio) {
          setIsLoading(false);
          return;
        }
        const res = await axios.get(waveformUrl, {
          responseType: 'arraybuffer',
        });
        setIsLoading(false);
        const waveform = WaveformData.create(res.data);
        setWave(waveform);
      } catch (e) {
        setIsLoading(false);
        setHasErrored(true);
        logger.error(`Error happened when fetching the waveform`, {
          waveformUrl,
          error: e,
        });
      }
    })();
  }, [waveformUrl, hasAudio]);
  return {
    waveform: wave,
    isLoading,
    // TODO: add error UI
    hasErrored,
  };
}

function WaveformGraph({ clip }: WaveformGraphProps) {
  const source = clip.source as IVideoSource;
  const { waveform, isLoading } = useGetWaveform(
    source.waveformUrl,
    clip.hasAudio,
  );
  return isLoading ? (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={30}
      css={styles.skeleton}
      data-testid="loading"
    />
  ) : (
    <div
      style={{
        transform: `translateX(-${clip.trimInOffset}px)`,
      }}
      data-testid="loaded"
    >
      <WaveformCanvas
        width={clip.pixelSourceWidth}
        duration={clip.sourceDuration}
        hasAudio={clip.hasAudio}
        waveform={waveform}
      />
    </div>
  );
}

export default WaveformGraph;
