export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: string;
  numeric: any;
  timestamptz: string;
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/**
 * Maps email domain to roles for auto assignment
 *
 *
 * columns and relationships of "auto_assign_domains"
 *
 */
export type Auto_Assign_Domains = {
  __typename?: 'auto_assign_domains';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain: Scalars['String'];
  is_active: Scalars['Boolean'];
  /** An object relationship */
  license: Licenses;
  license_uuid: Scalars['uuid'];
  role: License_Seat_Roles_Enum;
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};

/** aggregated selection of "auto_assign_domains" */
export type Auto_Assign_Domains_Aggregate = {
  __typename?: 'auto_assign_domains_aggregate';
  aggregate?: Maybe<Auto_Assign_Domains_Aggregate_Fields>;
  nodes: Array<Auto_Assign_Domains>;
};

/** aggregate fields of "auto_assign_domains" */
export type Auto_Assign_Domains_Aggregate_Fields = {
  __typename?: 'auto_assign_domains_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auto_Assign_Domains_Max_Fields>;
  min?: Maybe<Auto_Assign_Domains_Min_Fields>;
};

/** aggregate fields of "auto_assign_domains" */
export type Auto_Assign_Domains_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auto_Assign_Domains_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auto_assign_domains" */
export type Auto_Assign_Domains_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auto_Assign_Domains_Max_Order_By>;
  min?: Maybe<Auto_Assign_Domains_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auto_assign_domains" */
export type Auto_Assign_Domains_Arr_Rel_Insert_Input = {
  data: Array<Auto_Assign_Domains_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Auto_Assign_Domains_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auto_assign_domains". All fields are combined with a logical 'AND'. */
export type Auto_Assign_Domains_Bool_Exp = {
  _and?: Maybe<Array<Auto_Assign_Domains_Bool_Exp>>;
  _not?: Maybe<Auto_Assign_Domains_Bool_Exp>;
  _or?: Maybe<Array<Auto_Assign_Domains_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  domain?: Maybe<String_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  license?: Maybe<Licenses_Bool_Exp>;
  license_uuid?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<License_Seat_Roles_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auto_assign_domains" */
export enum Auto_Assign_Domains_Constraint {
  /** unique or primary key constraint */
  AutoAssignDomainsDomainIsActiveDeletedAtKey = 'auto_assign_domains_domain_is_active_deleted_at_key',
  /** unique or primary key constraint */
  AutoAssignDomainsPkey = 'auto_assign_domains_pkey',
}

/** input type for inserting data into table "auto_assign_domains" */
export type Auto_Assign_Domains_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  license?: Maybe<Licenses_Obj_Rel_Insert_Input>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<License_Seat_Roles_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auto_Assign_Domains_Max_Fields = {
  __typename?: 'auto_assign_domains_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auto_assign_domains" */
export type Auto_Assign_Domains_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auto_Assign_Domains_Min_Fields = {
  __typename?: 'auto_assign_domains_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auto_assign_domains" */
export type Auto_Assign_Domains_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** response of any mutation on the table "auto_assign_domains" */
export type Auto_Assign_Domains_Mutation_Response = {
  __typename?: 'auto_assign_domains_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auto_Assign_Domains>;
};

/** on_conflict condition type for table "auto_assign_domains" */
export type Auto_Assign_Domains_On_Conflict = {
  constraint: Auto_Assign_Domains_Constraint;
  update_columns?: Array<Auto_Assign_Domains_Update_Column>;
  where?: Maybe<Auto_Assign_Domains_Bool_Exp>;
};

/** Ordering options when selecting data from "auto_assign_domains". */
export type Auto_Assign_Domains_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  license?: Maybe<Licenses_Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: auto_assign_domains */
export type Auto_Assign_Domains_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "auto_assign_domains" */
export enum Auto_Assign_Domains_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "auto_assign_domains" */
export type Auto_Assign_Domains_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<License_Seat_Roles_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auto_assign_domains" */
export enum Auto_Assign_Domains_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/**
 * Customers from the legacy table in Google Cloud Datastore
 *
 *
 * columns and relationships of "chargebee_customers"
 *
 */
export type Chargebee_Customers = {
  __typename?: 'chargebee_customers';
  chargebee_id: Scalars['String'];
  coupon_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  has_ever_subscribed: Scalars['Boolean'];
  is_education: Scalars['Boolean'];
  num_assigned_seats: Scalars['Int'];
  num_legacy_seats: Scalars['Int'];
  num_seats: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};

/** aggregated selection of "chargebee_customers" */
export type Chargebee_Customers_Aggregate = {
  __typename?: 'chargebee_customers_aggregate';
  aggregate?: Maybe<Chargebee_Customers_Aggregate_Fields>;
  nodes: Array<Chargebee_Customers>;
};

/** aggregate fields of "chargebee_customers" */
export type Chargebee_Customers_Aggregate_Fields = {
  __typename?: 'chargebee_customers_aggregate_fields';
  avg?: Maybe<Chargebee_Customers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Chargebee_Customers_Max_Fields>;
  min?: Maybe<Chargebee_Customers_Min_Fields>;
  stddev?: Maybe<Chargebee_Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Chargebee_Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Chargebee_Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Chargebee_Customers_Sum_Fields>;
  var_pop?: Maybe<Chargebee_Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Chargebee_Customers_Var_Samp_Fields>;
  variance?: Maybe<Chargebee_Customers_Variance_Fields>;
};

/** aggregate fields of "chargebee_customers" */
export type Chargebee_Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Chargebee_Customers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Chargebee_Customers_Avg_Fields = {
  __typename?: 'chargebee_customers_avg_fields';
  num_assigned_seats?: Maybe<Scalars['Float']>;
  num_legacy_seats?: Maybe<Scalars['Float']>;
  num_seats?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "chargebee_customers". All fields are combined with a logical 'AND'. */
export type Chargebee_Customers_Bool_Exp = {
  _and?: Maybe<Array<Chargebee_Customers_Bool_Exp>>;
  _not?: Maybe<Chargebee_Customers_Bool_Exp>;
  _or?: Maybe<Array<Chargebee_Customers_Bool_Exp>>;
  chargebee_id?: Maybe<String_Comparison_Exp>;
  coupon_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  has_ever_subscribed?: Maybe<Boolean_Comparison_Exp>;
  is_education?: Maybe<Boolean_Comparison_Exp>;
  num_assigned_seats?: Maybe<Int_Comparison_Exp>;
  num_legacy_seats?: Maybe<Int_Comparison_Exp>;
  num_seats?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "chargebee_customers" */
export enum Chargebee_Customers_Constraint {
  /** unique or primary key constraint */
  ChargebeeCustomersPkey = 'chargebee_customers_pkey',
}

/** input type for incrementing numeric columns in table "chargebee_customers" */
export type Chargebee_Customers_Inc_Input = {
  num_assigned_seats?: Maybe<Scalars['Int']>;
  num_legacy_seats?: Maybe<Scalars['Int']>;
  num_seats?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "chargebee_customers" */
export type Chargebee_Customers_Insert_Input = {
  chargebee_id?: Maybe<Scalars['String']>;
  coupon_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  has_ever_subscribed?: Maybe<Scalars['Boolean']>;
  is_education?: Maybe<Scalars['Boolean']>;
  num_assigned_seats?: Maybe<Scalars['Int']>;
  num_legacy_seats?: Maybe<Scalars['Int']>;
  num_seats?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Chargebee_Customers_Max_Fields = {
  __typename?: 'chargebee_customers_max_fields';
  chargebee_id?: Maybe<Scalars['String']>;
  coupon_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  num_assigned_seats?: Maybe<Scalars['Int']>;
  num_legacy_seats?: Maybe<Scalars['Int']>;
  num_seats?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Chargebee_Customers_Min_Fields = {
  __typename?: 'chargebee_customers_min_fields';
  chargebee_id?: Maybe<Scalars['String']>;
  coupon_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  num_assigned_seats?: Maybe<Scalars['Int']>;
  num_legacy_seats?: Maybe<Scalars['Int']>;
  num_seats?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "chargebee_customers" */
export type Chargebee_Customers_Mutation_Response = {
  __typename?: 'chargebee_customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chargebee_Customers>;
};

/** on_conflict condition type for table "chargebee_customers" */
export type Chargebee_Customers_On_Conflict = {
  constraint: Chargebee_Customers_Constraint;
  update_columns?: Array<Chargebee_Customers_Update_Column>;
  where?: Maybe<Chargebee_Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "chargebee_customers". */
export type Chargebee_Customers_Order_By = {
  chargebee_id?: Maybe<Order_By>;
  coupon_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  has_ever_subscribed?: Maybe<Order_By>;
  is_education?: Maybe<Order_By>;
  num_assigned_seats?: Maybe<Order_By>;
  num_legacy_seats?: Maybe<Order_By>;
  num_seats?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: chargebee_customers */
export type Chargebee_Customers_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "chargebee_customers" */
export enum Chargebee_Customers_Select_Column {
  /** column name */
  ChargebeeId = 'chargebee_id',
  /** column name */
  CouponCode = 'coupon_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Email = 'email',
  /** column name */
  HasEverSubscribed = 'has_ever_subscribed',
  /** column name */
  IsEducation = 'is_education',
  /** column name */
  NumAssignedSeats = 'num_assigned_seats',
  /** column name */
  NumLegacySeats = 'num_legacy_seats',
  /** column name */
  NumSeats = 'num_seats',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "chargebee_customers" */
export type Chargebee_Customers_Set_Input = {
  chargebee_id?: Maybe<Scalars['String']>;
  coupon_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  has_ever_subscribed?: Maybe<Scalars['Boolean']>;
  is_education?: Maybe<Scalars['Boolean']>;
  num_assigned_seats?: Maybe<Scalars['Int']>;
  num_legacy_seats?: Maybe<Scalars['Int']>;
  num_seats?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Chargebee_Customers_Stddev_Fields = {
  __typename?: 'chargebee_customers_stddev_fields';
  num_assigned_seats?: Maybe<Scalars['Float']>;
  num_legacy_seats?: Maybe<Scalars['Float']>;
  num_seats?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Chargebee_Customers_Stddev_Pop_Fields = {
  __typename?: 'chargebee_customers_stddev_pop_fields';
  num_assigned_seats?: Maybe<Scalars['Float']>;
  num_legacy_seats?: Maybe<Scalars['Float']>;
  num_seats?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Chargebee_Customers_Stddev_Samp_Fields = {
  __typename?: 'chargebee_customers_stddev_samp_fields';
  num_assigned_seats?: Maybe<Scalars['Float']>;
  num_legacy_seats?: Maybe<Scalars['Float']>;
  num_seats?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Chargebee_Customers_Sum_Fields = {
  __typename?: 'chargebee_customers_sum_fields';
  num_assigned_seats?: Maybe<Scalars['Int']>;
  num_legacy_seats?: Maybe<Scalars['Int']>;
  num_seats?: Maybe<Scalars['Int']>;
};

/** update columns of table "chargebee_customers" */
export enum Chargebee_Customers_Update_Column {
  /** column name */
  ChargebeeId = 'chargebee_id',
  /** column name */
  CouponCode = 'coupon_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Email = 'email',
  /** column name */
  HasEverSubscribed = 'has_ever_subscribed',
  /** column name */
  IsEducation = 'is_education',
  /** column name */
  NumAssignedSeats = 'num_assigned_seats',
  /** column name */
  NumLegacySeats = 'num_legacy_seats',
  /** column name */
  NumSeats = 'num_seats',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** aggregate var_pop on columns */
export type Chargebee_Customers_Var_Pop_Fields = {
  __typename?: 'chargebee_customers_var_pop_fields';
  num_assigned_seats?: Maybe<Scalars['Float']>;
  num_legacy_seats?: Maybe<Scalars['Float']>;
  num_seats?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Chargebee_Customers_Var_Samp_Fields = {
  __typename?: 'chargebee_customers_var_samp_fields';
  num_assigned_seats?: Maybe<Scalars['Float']>;
  num_legacy_seats?: Maybe<Scalars['Float']>;
  num_seats?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Chargebee_Customers_Variance_Fields = {
  __typename?: 'chargebee_customers_variance_fields';
  num_assigned_seats?: Maybe<Scalars['Float']>;
  num_legacy_seats?: Maybe<Scalars['Float']>;
  num_seats?: Maybe<Scalars['Float']>;
};

/**
 * Emails that will or do belong to users who are allowed to claim a seat from a license, but may or may not have claimed that seat yet.
 *
 *
 * columns and relationships of "invitations"
 *
 */
export type Invitations = {
  __typename?: 'invitations';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** An object relationship */
  license: Licenses;
  license_uuid: Scalars['uuid'];
  role: License_Seat_Roles_Enum;
  /** An object relationship */
  seat?: Maybe<Seats>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier: License_Seat_Tiers_Enum;
  uuid: Scalars['uuid'];
};

/** aggregated selection of "invitations" */
export type Invitations_Aggregate = {
  __typename?: 'invitations_aggregate';
  aggregate?: Maybe<Invitations_Aggregate_Fields>;
  nodes: Array<Invitations>;
};

/** aggregate fields of "invitations" */
export type Invitations_Aggregate_Fields = {
  __typename?: 'invitations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Invitations_Max_Fields>;
  min?: Maybe<Invitations_Min_Fields>;
};

/** aggregate fields of "invitations" */
export type Invitations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invitations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invitations" */
export type Invitations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Invitations_Max_Order_By>;
  min?: Maybe<Invitations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "invitations" */
export type Invitations_Arr_Rel_Insert_Input = {
  data: Array<Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Invitations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "invitations". All fields are combined with a logical 'AND'. */
export type Invitations_Bool_Exp = {
  _and?: Maybe<Array<Invitations_Bool_Exp>>;
  _not?: Maybe<Invitations_Bool_Exp>;
  _or?: Maybe<Array<Invitations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  license?: Maybe<Licenses_Bool_Exp>;
  license_uuid?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<License_Seat_Roles_Enum_Comparison_Exp>;
  seat?: Maybe<Seats_Bool_Exp>;
  seat_uuid?: Maybe<Uuid_Comparison_Exp>;
  tier?: Maybe<License_Seat_Tiers_Enum_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invitations" */
export enum Invitations_Constraint {
  /** unique or primary key constraint */
  InvitationsLicenseUuidEmailKey = 'invitations_license_uuid_email_key',
  /** unique or primary key constraint */
  InvitationsPkey = 'invitations_pkey',
  /** unique or primary key constraint */
  InvitationsSeatUuidKey = 'invitations_seat_uuid_key',
}

/** input type for inserting data into table "invitations" */
export type Invitations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license?: Maybe<Licenses_Obj_Rel_Insert_Input>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<License_Seat_Roles_Enum>;
  seat?: Maybe<Seats_Obj_Rel_Insert_Input>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<License_Seat_Tiers_Enum>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Invitations_Max_Fields = {
  __typename?: 'invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "invitations" */
export type Invitations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invitations_Min_Fields = {
  __typename?: 'invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "invitations" */
export type Invitations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** response of any mutation on the table "invitations" */
export type Invitations_Mutation_Response = {
  __typename?: 'invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitations>;
};

/** input type for inserting object relation for remote table "invitations" */
export type Invitations_Obj_Rel_Insert_Input = {
  data: Invitations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Invitations_On_Conflict>;
};

/** on_conflict condition type for table "invitations" */
export type Invitations_On_Conflict = {
  constraint: Invitations_Constraint;
  update_columns?: Array<Invitations_Update_Column>;
  where?: Maybe<Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "invitations". */
export type Invitations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  license?: Maybe<Licenses_Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat?: Maybe<Seats_Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: invitations */
export type Invitations_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "invitations" */
export enum Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  SeatUuid = 'seat_uuid',
  /** column name */
  Tier = 'tier',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "invitations" */
export type Invitations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<License_Seat_Roles_Enum>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<License_Seat_Tiers_Enum>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** update columns of table "invitations" */
export enum Invitations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  SeatUuid = 'seat_uuid',
  /** column name */
  Tier = 'tier',
  /** column name */
  Uuid = 'uuid',
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "license_invitations" */
export type License_Invitations = {
  __typename?: 'license_invitations';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** An object relationship */
  license?: Maybe<Licenses>;
  license_uuid: Scalars['uuid'];
  role: Scalars['String'];
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier: Scalars['String'];
  uuid: Scalars['uuid'];
};

/** aggregated selection of "license_invitations" */
export type License_Invitations_Aggregate = {
  __typename?: 'license_invitations_aggregate';
  aggregate?: Maybe<License_Invitations_Aggregate_Fields>;
  nodes: Array<License_Invitations>;
};

/** aggregate fields of "license_invitations" */
export type License_Invitations_Aggregate_Fields = {
  __typename?: 'license_invitations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<License_Invitations_Max_Fields>;
  min?: Maybe<License_Invitations_Min_Fields>;
};

/** aggregate fields of "license_invitations" */
export type License_Invitations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<License_Invitations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "license_invitations" */
export type License_Invitations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<License_Invitations_Max_Order_By>;
  min?: Maybe<License_Invitations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "license_invitations" */
export type License_Invitations_Arr_Rel_Insert_Input = {
  data: Array<License_Invitations_Insert_Input>;
};

/** Boolean expression to filter rows from the table "license_invitations". All fields are combined with a logical 'AND'. */
export type License_Invitations_Bool_Exp = {
  _and?: Maybe<Array<License_Invitations_Bool_Exp>>;
  _not?: Maybe<License_Invitations_Bool_Exp>;
  _or?: Maybe<Array<License_Invitations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  license?: Maybe<Licenses_Bool_Exp>;
  license_uuid?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  seat_uuid?: Maybe<Uuid_Comparison_Exp>;
  tier?: Maybe<String_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "license_invitations" */
export type License_Invitations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license?: Maybe<Licenses_Obj_Rel_Insert_Input>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type License_Invitations_Max_Fields = {
  __typename?: 'license_invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "license_invitations" */
export type License_Invitations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type License_Invitations_Min_Fields = {
  __typename?: 'license_invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "license_invitations" */
export type License_Invitations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** response of any mutation on the table "license_invitations" */
export type License_Invitations_Mutation_Response = {
  __typename?: 'license_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<License_Invitations>;
};

/** Ordering options when selecting data from "license_invitations". */
export type License_Invitations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  license?: Maybe<Licenses_Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "license_invitations" */
export enum License_Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  SeatUuid = 'seat_uuid',
  /** column name */
  Tier = 'tier',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "license_invitations" */
export type License_Invitations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/**
 * Enum table for roles in licensing
 *
 *
 * columns and relationships of "license_seat_roles"
 *
 */
export type License_Seat_Roles = {
  __typename?: 'license_seat_roles';
  comment: Scalars['String'];
  role: Scalars['String'];
};

/** aggregated selection of "license_seat_roles" */
export type License_Seat_Roles_Aggregate = {
  __typename?: 'license_seat_roles_aggregate';
  aggregate?: Maybe<License_Seat_Roles_Aggregate_Fields>;
  nodes: Array<License_Seat_Roles>;
};

/** aggregate fields of "license_seat_roles" */
export type License_Seat_Roles_Aggregate_Fields = {
  __typename?: 'license_seat_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<License_Seat_Roles_Max_Fields>;
  min?: Maybe<License_Seat_Roles_Min_Fields>;
};

/** aggregate fields of "license_seat_roles" */
export type License_Seat_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<License_Seat_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "license_seat_roles". All fields are combined with a logical 'AND'. */
export type License_Seat_Roles_Bool_Exp = {
  _and?: Maybe<Array<License_Seat_Roles_Bool_Exp>>;
  _not?: Maybe<License_Seat_Roles_Bool_Exp>;
  _or?: Maybe<Array<License_Seat_Roles_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "license_seat_roles" */
export enum License_Seat_Roles_Constraint {
  /** unique or primary key constraint */
  LicenseSeatRolesPkey = 'license_seat_roles_pkey',
}

export enum License_Seat_Roles_Enum {
  /** License administrators can add other members and change their role/tier */
  Admin = 'admin',
  /** License members are regular users with full access to screencastify */
  Member = 'member',
  /** Restricted users, such as students, have restricted access to screencastify features */
  Restricted = 'restricted',
}

/** Boolean expression to compare columns of type "license_seat_roles_enum". All fields are combined with logical 'AND'. */
export type License_Seat_Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<License_Seat_Roles_Enum>;
  _in?: Maybe<Array<License_Seat_Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<License_Seat_Roles_Enum>;
  _nin?: Maybe<Array<License_Seat_Roles_Enum>>;
};

/** input type for inserting data into table "license_seat_roles" */
export type License_Seat_Roles_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type License_Seat_Roles_Max_Fields = {
  __typename?: 'license_seat_roles_max_fields';
  comment?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type License_Seat_Roles_Min_Fields = {
  __typename?: 'license_seat_roles_min_fields';
  comment?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "license_seat_roles" */
export type License_Seat_Roles_Mutation_Response = {
  __typename?: 'license_seat_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<License_Seat_Roles>;
};

/** on_conflict condition type for table "license_seat_roles" */
export type License_Seat_Roles_On_Conflict = {
  constraint: License_Seat_Roles_Constraint;
  update_columns?: Array<License_Seat_Roles_Update_Column>;
  where?: Maybe<License_Seat_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "license_seat_roles". */
export type License_Seat_Roles_Order_By = {
  comment?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** primary key columns input for table: license_seat_roles */
export type License_Seat_Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "license_seat_roles" */
export enum License_Seat_Roles_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Role = 'role',
}

/** input type for updating data in table "license_seat_roles" */
export type License_Seat_Roles_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "license_seat_roles" */
export enum License_Seat_Roles_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Role = 'role',
}

/**
 * Payment tiers available in licensing system
 *
 *
 * columns and relationships of "license_seat_tiers"
 *
 */
export type License_Seat_Tiers = {
  __typename?: 'license_seat_tiers';
  comment: Scalars['String'];
  tier: Scalars['String'];
};

/** aggregated selection of "license_seat_tiers" */
export type License_Seat_Tiers_Aggregate = {
  __typename?: 'license_seat_tiers_aggregate';
  aggregate?: Maybe<License_Seat_Tiers_Aggregate_Fields>;
  nodes: Array<License_Seat_Tiers>;
};

/** aggregate fields of "license_seat_tiers" */
export type License_Seat_Tiers_Aggregate_Fields = {
  __typename?: 'license_seat_tiers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<License_Seat_Tiers_Max_Fields>;
  min?: Maybe<License_Seat_Tiers_Min_Fields>;
};

/** aggregate fields of "license_seat_tiers" */
export type License_Seat_Tiers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<License_Seat_Tiers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "license_seat_tiers". All fields are combined with a logical 'AND'. */
export type License_Seat_Tiers_Bool_Exp = {
  _and?: Maybe<Array<License_Seat_Tiers_Bool_Exp>>;
  _not?: Maybe<License_Seat_Tiers_Bool_Exp>;
  _or?: Maybe<Array<License_Seat_Tiers_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  tier?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "license_seat_tiers" */
export enum License_Seat_Tiers_Constraint {
  /** unique or primary key constraint */
  LicenseSeatTiersPkey = 'license_seat_tiers_pkey',
}

export enum License_Seat_Tiers_Enum {
  /** Free users have restricted quota levels and may not have all features. */
  Free = 'free',
  /** Pro licenses have the highest quota levels and access to features. */
  Pro = 'pro',
  /** Standard licenses have moderate quota levels and access to features. */
  Standard = 'standard',
}

/** Boolean expression to compare columns of type "license_seat_tiers_enum". All fields are combined with logical 'AND'. */
export type License_Seat_Tiers_Enum_Comparison_Exp = {
  _eq?: Maybe<License_Seat_Tiers_Enum>;
  _in?: Maybe<Array<License_Seat_Tiers_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<License_Seat_Tiers_Enum>;
  _nin?: Maybe<Array<License_Seat_Tiers_Enum>>;
};

/** input type for inserting data into table "license_seat_tiers" */
export type License_Seat_Tiers_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type License_Seat_Tiers_Max_Fields = {
  __typename?: 'license_seat_tiers_max_fields';
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type License_Seat_Tiers_Min_Fields = {
  __typename?: 'license_seat_tiers_min_fields';
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "license_seat_tiers" */
export type License_Seat_Tiers_Mutation_Response = {
  __typename?: 'license_seat_tiers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<License_Seat_Tiers>;
};

/** on_conflict condition type for table "license_seat_tiers" */
export type License_Seat_Tiers_On_Conflict = {
  constraint: License_Seat_Tiers_Constraint;
  update_columns?: Array<License_Seat_Tiers_Update_Column>;
  where?: Maybe<License_Seat_Tiers_Bool_Exp>;
};

/** Ordering options when selecting data from "license_seat_tiers". */
export type License_Seat_Tiers_Order_By = {
  comment?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
};

/** primary key columns input for table: license_seat_tiers */
export type License_Seat_Tiers_Pk_Columns_Input = {
  tier: Scalars['String'];
};

/** select columns of table "license_seat_tiers" */
export enum License_Seat_Tiers_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Tier = 'tier',
}

/** input type for updating data in table "license_seat_tiers" */
export type License_Seat_Tiers_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
};

/** update columns of table "license_seat_tiers" */
export enum License_Seat_Tiers_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Tier = 'tier',
}

/** columns and relationships of "license_users" */
export type License_Users = {
  __typename?: 'license_users';
  email?: Maybe<Scalars['String']>;
  /** An object relationship */
  license?: Maybe<Licenses>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "license_users" */
export type License_Users_Aggregate = {
  __typename?: 'license_users_aggregate';
  aggregate?: Maybe<License_Users_Aggregate_Fields>;
  nodes: Array<License_Users>;
};

/** aggregate fields of "license_users" */
export type License_Users_Aggregate_Fields = {
  __typename?: 'license_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<License_Users_Max_Fields>;
  min?: Maybe<License_Users_Min_Fields>;
};

/** aggregate fields of "license_users" */
export type License_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<License_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "license_users" */
export type License_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<License_Users_Max_Order_By>;
  min?: Maybe<License_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "license_users" */
export type License_Users_Arr_Rel_Insert_Input = {
  data: Array<License_Users_Insert_Input>;
};

/** Boolean expression to filter rows from the table "license_users". All fields are combined with a logical 'AND'. */
export type License_Users_Bool_Exp = {
  _and?: Maybe<Array<License_Users_Bool_Exp>>;
  _not?: Maybe<License_Users_Bool_Exp>;
  _or?: Maybe<Array<License_Users_Bool_Exp>>;
  email?: Maybe<String_Comparison_Exp>;
  license?: Maybe<Licenses_Bool_Exp>;
  license_uuid?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  seat_uuid?: Maybe<Uuid_Comparison_Exp>;
  tier?: Maybe<String_Comparison_Exp>;
  user_uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "license_users" */
export type License_Users_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  license?: Maybe<Licenses_Obj_Rel_Insert_Input>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type License_Users_Max_Fields = {
  __typename?: 'license_users_max_fields';
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "license_users" */
export type License_Users_Max_Order_By = {
  email?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  user_uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type License_Users_Min_Fields = {
  __typename?: 'license_users_min_fields';
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "license_users" */
export type License_Users_Min_Order_By = {
  email?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  user_uuid?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "license_users". */
export type License_Users_Order_By = {
  email?: Maybe<Order_By>;
  license?: Maybe<Licenses_Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  user_uuid?: Maybe<Order_By>;
};

/** select columns of table "license_users" */
export enum License_Users_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  SeatUuid = 'seat_uuid',
  /** column name */
  Tier = 'tier',
  /** column name */
  UserUuid = 'user_uuid',
}

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type Licenses = {
  __typename?: 'licenses';
  /** fetch data from the table: "auto_assign_domains" */
  auto_assign_domains: Array<Auto_Assign_Domains>;
  /** An aggregate relationship */
  auto_assign_domains_aggregate: Auto_Assign_Domains_Aggregate;
  chargebee_customer_uuid?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  /** An array relationship */
  license_invitations: Array<License_Invitations>;
  /** An aggregate relationship */
  license_invitations_aggregate: License_Invitations_Aggregate;
  /** An array relationship */
  license_users: Array<License_Users>;
  /** An aggregate relationship */
  license_users_aggregate: License_Users_Aggregate;
  max_pro_member_seats: Scalars['Int'];
  max_pro_restricted_seats: Scalars['Int'];
  max_standard_member_seats: Scalars['Int'];
  max_standard_restricted_seats: Scalars['Int'];
  organization_uuid?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  seats: Array<Seats>;
  /** An aggregate relationship */
  seats_aggregate: Seats_Aggregate;
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesAuto_Assign_DomainsArgs = {
  distinct_on?: Maybe<Array<Auto_Assign_Domains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auto_Assign_Domains_Order_By>>;
  where?: Maybe<Auto_Assign_Domains_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesAuto_Assign_Domains_AggregateArgs = {
  distinct_on?: Maybe<Array<Auto_Assign_Domains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auto_Assign_Domains_Order_By>>;
  where?: Maybe<Auto_Assign_Domains_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesInvitationsArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesInvitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesLicense_InvitationsArgs = {
  distinct_on?: Maybe<Array<License_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Invitations_Order_By>>;
  where?: Maybe<License_Invitations_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesLicense_Invitations_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Invitations_Order_By>>;
  where?: Maybe<License_Invitations_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesLicense_UsersArgs = {
  distinct_on?: Maybe<Array<License_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Users_Order_By>>;
  where?: Maybe<License_Users_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesLicense_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Users_Order_By>>;
  where?: Maybe<License_Users_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesSeatsArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

/**
 * A license has many seats, which are taken by individual users
 *
 *
 * columns and relationships of "licenses"
 *
 */
export type LicensesSeats_AggregateArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

/** aggregated selection of "licenses" */
export type Licenses_Aggregate = {
  __typename?: 'licenses_aggregate';
  aggregate?: Maybe<Licenses_Aggregate_Fields>;
  nodes: Array<Licenses>;
};

/** aggregate fields of "licenses" */
export type Licenses_Aggregate_Fields = {
  __typename?: 'licenses_aggregate_fields';
  avg?: Maybe<Licenses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Licenses_Max_Fields>;
  min?: Maybe<Licenses_Min_Fields>;
  stddev?: Maybe<Licenses_Stddev_Fields>;
  stddev_pop?: Maybe<Licenses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Licenses_Stddev_Samp_Fields>;
  sum?: Maybe<Licenses_Sum_Fields>;
  var_pop?: Maybe<Licenses_Var_Pop_Fields>;
  var_samp?: Maybe<Licenses_Var_Samp_Fields>;
  variance?: Maybe<Licenses_Variance_Fields>;
};

/** aggregate fields of "licenses" */
export type Licenses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Licenses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "licenses" */
export type Licenses_Aggregate_Order_By = {
  avg?: Maybe<Licenses_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Licenses_Max_Order_By>;
  min?: Maybe<Licenses_Min_Order_By>;
  stddev?: Maybe<Licenses_Stddev_Order_By>;
  stddev_pop?: Maybe<Licenses_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Licenses_Stddev_Samp_Order_By>;
  sum?: Maybe<Licenses_Sum_Order_By>;
  var_pop?: Maybe<Licenses_Var_Pop_Order_By>;
  var_samp?: Maybe<Licenses_Var_Samp_Order_By>;
  variance?: Maybe<Licenses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "licenses" */
export type Licenses_Arr_Rel_Insert_Input = {
  data: Array<Licenses_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Licenses_On_Conflict>;
};

/** aggregate avg on columns */
export type Licenses_Avg_Fields = {
  __typename?: 'licenses_avg_fields';
  max_pro_member_seats?: Maybe<Scalars['Float']>;
  max_pro_restricted_seats?: Maybe<Scalars['Float']>;
  max_standard_member_seats?: Maybe<Scalars['Float']>;
  max_standard_restricted_seats?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "licenses" */
export type Licenses_Avg_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "licenses". All fields are combined with a logical 'AND'. */
export type Licenses_Bool_Exp = {
  _and?: Maybe<Array<Licenses_Bool_Exp>>;
  _not?: Maybe<Licenses_Bool_Exp>;
  _or?: Maybe<Array<Licenses_Bool_Exp>>;
  auto_assign_domains?: Maybe<Auto_Assign_Domains_Bool_Exp>;
  chargebee_customer_uuid?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  invitations?: Maybe<Invitations_Bool_Exp>;
  license_invitations?: Maybe<License_Invitations_Bool_Exp>;
  license_users?: Maybe<License_Users_Bool_Exp>;
  max_pro_member_seats?: Maybe<Int_Comparison_Exp>;
  max_pro_restricted_seats?: Maybe<Int_Comparison_Exp>;
  max_standard_member_seats?: Maybe<Int_Comparison_Exp>;
  max_standard_restricted_seats?: Maybe<Int_Comparison_Exp>;
  organization_uuid?: Maybe<Uuid_Comparison_Exp>;
  seats?: Maybe<Seats_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "licenses" */
export enum Licenses_Constraint {
  /** unique or primary key constraint */
  LicensesPkey = 'licenses_pkey',
}

/** input type for incrementing numeric columns in table "licenses" */
export type Licenses_Inc_Input = {
  max_pro_member_seats?: Maybe<Scalars['Int']>;
  max_pro_restricted_seats?: Maybe<Scalars['Int']>;
  max_standard_member_seats?: Maybe<Scalars['Int']>;
  max_standard_restricted_seats?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "licenses" */
export type Licenses_Insert_Input = {
  auto_assign_domains?: Maybe<Auto_Assign_Domains_Arr_Rel_Insert_Input>;
  chargebee_customer_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invitations?: Maybe<Invitations_Arr_Rel_Insert_Input>;
  license_invitations?: Maybe<License_Invitations_Arr_Rel_Insert_Input>;
  license_users?: Maybe<License_Users_Arr_Rel_Insert_Input>;
  max_pro_member_seats?: Maybe<Scalars['Int']>;
  max_pro_restricted_seats?: Maybe<Scalars['Int']>;
  max_standard_member_seats?: Maybe<Scalars['Int']>;
  max_standard_restricted_seats?: Maybe<Scalars['Int']>;
  organization_uuid?: Maybe<Scalars['uuid']>;
  seats?: Maybe<Seats_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Licenses_Max_Fields = {
  __typename?: 'licenses_max_fields';
  chargebee_customer_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  max_pro_member_seats?: Maybe<Scalars['Int']>;
  max_pro_restricted_seats?: Maybe<Scalars['Int']>;
  max_standard_member_seats?: Maybe<Scalars['Int']>;
  max_standard_restricted_seats?: Maybe<Scalars['Int']>;
  organization_uuid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "licenses" */
export type Licenses_Max_Order_By = {
  chargebee_customer_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
  organization_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Licenses_Min_Fields = {
  __typename?: 'licenses_min_fields';
  chargebee_customer_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  max_pro_member_seats?: Maybe<Scalars['Int']>;
  max_pro_restricted_seats?: Maybe<Scalars['Int']>;
  max_standard_member_seats?: Maybe<Scalars['Int']>;
  max_standard_restricted_seats?: Maybe<Scalars['Int']>;
  organization_uuid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "licenses" */
export type Licenses_Min_Order_By = {
  chargebee_customer_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
  organization_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** response of any mutation on the table "licenses" */
export type Licenses_Mutation_Response = {
  __typename?: 'licenses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Licenses>;
};

/** input type for inserting object relation for remote table "licenses" */
export type Licenses_Obj_Rel_Insert_Input = {
  data: Licenses_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Licenses_On_Conflict>;
};

/** on_conflict condition type for table "licenses" */
export type Licenses_On_Conflict = {
  constraint: Licenses_Constraint;
  update_columns?: Array<Licenses_Update_Column>;
  where?: Maybe<Licenses_Bool_Exp>;
};

/** Ordering options when selecting data from "licenses". */
export type Licenses_Order_By = {
  auto_assign_domains_aggregate?: Maybe<Auto_Assign_Domains_Aggregate_Order_By>;
  chargebee_customer_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invitations_aggregate?: Maybe<Invitations_Aggregate_Order_By>;
  license_invitations_aggregate?: Maybe<License_Invitations_Aggregate_Order_By>;
  license_users_aggregate?: Maybe<License_Users_Aggregate_Order_By>;
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
  organization_uuid?: Maybe<Order_By>;
  seats_aggregate?: Maybe<Seats_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: licenses */
export type Licenses_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "licenses" */
export enum Licenses_Select_Column {
  /** column name */
  ChargebeeCustomerUuid = 'chargebee_customer_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MaxProMemberSeats = 'max_pro_member_seats',
  /** column name */
  MaxProRestrictedSeats = 'max_pro_restricted_seats',
  /** column name */
  MaxStandardMemberSeats = 'max_standard_member_seats',
  /** column name */
  MaxStandardRestrictedSeats = 'max_standard_restricted_seats',
  /** column name */
  OrganizationUuid = 'organization_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "licenses" */
export type Licenses_Set_Input = {
  chargebee_customer_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  max_pro_member_seats?: Maybe<Scalars['Int']>;
  max_pro_restricted_seats?: Maybe<Scalars['Int']>;
  max_standard_member_seats?: Maybe<Scalars['Int']>;
  max_standard_restricted_seats?: Maybe<Scalars['Int']>;
  organization_uuid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Licenses_Stddev_Fields = {
  __typename?: 'licenses_stddev_fields';
  max_pro_member_seats?: Maybe<Scalars['Float']>;
  max_pro_restricted_seats?: Maybe<Scalars['Float']>;
  max_standard_member_seats?: Maybe<Scalars['Float']>;
  max_standard_restricted_seats?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "licenses" */
export type Licenses_Stddev_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Licenses_Stddev_Pop_Fields = {
  __typename?: 'licenses_stddev_pop_fields';
  max_pro_member_seats?: Maybe<Scalars['Float']>;
  max_pro_restricted_seats?: Maybe<Scalars['Float']>;
  max_standard_member_seats?: Maybe<Scalars['Float']>;
  max_standard_restricted_seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "licenses" */
export type Licenses_Stddev_Pop_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Licenses_Stddev_Samp_Fields = {
  __typename?: 'licenses_stddev_samp_fields';
  max_pro_member_seats?: Maybe<Scalars['Float']>;
  max_pro_restricted_seats?: Maybe<Scalars['Float']>;
  max_standard_member_seats?: Maybe<Scalars['Float']>;
  max_standard_restricted_seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "licenses" */
export type Licenses_Stddev_Samp_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Licenses_Sum_Fields = {
  __typename?: 'licenses_sum_fields';
  max_pro_member_seats?: Maybe<Scalars['Int']>;
  max_pro_restricted_seats?: Maybe<Scalars['Int']>;
  max_standard_member_seats?: Maybe<Scalars['Int']>;
  max_standard_restricted_seats?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "licenses" */
export type Licenses_Sum_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** update columns of table "licenses" */
export enum Licenses_Update_Column {
  /** column name */
  ChargebeeCustomerUuid = 'chargebee_customer_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  MaxProMemberSeats = 'max_pro_member_seats',
  /** column name */
  MaxProRestrictedSeats = 'max_pro_restricted_seats',
  /** column name */
  MaxStandardMemberSeats = 'max_standard_member_seats',
  /** column name */
  MaxStandardRestrictedSeats = 'max_standard_restricted_seats',
  /** column name */
  OrganizationUuid = 'organization_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** aggregate var_pop on columns */
export type Licenses_Var_Pop_Fields = {
  __typename?: 'licenses_var_pop_fields';
  max_pro_member_seats?: Maybe<Scalars['Float']>;
  max_pro_restricted_seats?: Maybe<Scalars['Float']>;
  max_standard_member_seats?: Maybe<Scalars['Float']>;
  max_standard_restricted_seats?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "licenses" */
export type Licenses_Var_Pop_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Licenses_Var_Samp_Fields = {
  __typename?: 'licenses_var_samp_fields';
  max_pro_member_seats?: Maybe<Scalars['Float']>;
  max_pro_restricted_seats?: Maybe<Scalars['Float']>;
  max_standard_member_seats?: Maybe<Scalars['Float']>;
  max_standard_restricted_seats?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "licenses" */
export type Licenses_Var_Samp_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Licenses_Variance_Fields = {
  __typename?: 'licenses_variance_fields';
  max_pro_member_seats?: Maybe<Scalars['Float']>;
  max_pro_restricted_seats?: Maybe<Scalars['Float']>;
  max_standard_member_seats?: Maybe<Scalars['Float']>;
  max_standard_restricted_seats?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "licenses" */
export type Licenses_Variance_Order_By = {
  max_pro_member_seats?: Maybe<Order_By>;
  max_pro_restricted_seats?: Maybe<Order_By>;
  max_standard_member_seats?: Maybe<Order_By>;
  max_standard_restricted_seats?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "auto_assign_domains" */
  delete_auto_assign_domains?: Maybe<Auto_Assign_Domains_Mutation_Response>;
  /** delete single row from the table: "auto_assign_domains" */
  delete_auto_assign_domains_by_pk?: Maybe<Auto_Assign_Domains>;
  /** delete data from the table: "chargebee_customers" */
  delete_chargebee_customers?: Maybe<Chargebee_Customers_Mutation_Response>;
  /** delete single row from the table: "chargebee_customers" */
  delete_chargebee_customers_by_pk?: Maybe<Chargebee_Customers>;
  /** delete data from the table: "invitations" */
  delete_invitations?: Maybe<Invitations_Mutation_Response>;
  /** delete single row from the table: "invitations" */
  delete_invitations_by_pk?: Maybe<Invitations>;
  /** delete data from the table: "license_invitations" */
  delete_license_invitations?: Maybe<License_Invitations_Mutation_Response>;
  /** delete data from the table: "license_seat_roles" */
  delete_license_seat_roles?: Maybe<License_Seat_Roles_Mutation_Response>;
  /** delete single row from the table: "license_seat_roles" */
  delete_license_seat_roles_by_pk?: Maybe<License_Seat_Roles>;
  /** delete data from the table: "license_seat_tiers" */
  delete_license_seat_tiers?: Maybe<License_Seat_Tiers_Mutation_Response>;
  /** delete single row from the table: "license_seat_tiers" */
  delete_license_seat_tiers_by_pk?: Maybe<License_Seat_Tiers>;
  /** delete data from the table: "licenses" */
  delete_licenses?: Maybe<Licenses_Mutation_Response>;
  /** delete single row from the table: "licenses" */
  delete_licenses_by_pk?: Maybe<Licenses>;
  /** delete data from the table: "my_invitations" */
  delete_my_invitations?: Maybe<My_Invitations_Mutation_Response>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "published_projects" */
  delete_published_projects?: Maybe<Published_Projects_Mutation_Response>;
  /** delete data from the table: "refresh_tokens" */
  delete_refresh_tokens?: Maybe<Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "refresh_tokens" */
  delete_refresh_tokens_by_pk?: Maybe<Refresh_Tokens>;
  /** delete data from the table: "seats" */
  delete_seats?: Maybe<Seats_Mutation_Response>;
  /** delete single row from the table: "seats" */
  delete_seats_by_pk?: Maybe<Seats>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "video_sources" */
  delete_video_sources?: Maybe<Video_Sources_Mutation_Response>;
  /** delete single row from the table: "video_sources" */
  delete_video_sources_by_pk?: Maybe<Video_Sources>;
  /** delete data from the table: "viewer_analytics" */
  delete_viewer_analytics?: Maybe<Viewer_Analytics_Mutation_Response>;
  /** delete single row from the table: "viewer_analytics" */
  delete_viewer_analytics_by_pk?: Maybe<Viewer_Analytics>;
  /** insert data into the table: "auto_assign_domains" */
  insert_auto_assign_domains?: Maybe<Auto_Assign_Domains_Mutation_Response>;
  /** insert a single row into the table: "auto_assign_domains" */
  insert_auto_assign_domains_one?: Maybe<Auto_Assign_Domains>;
  /** insert data into the table: "chargebee_customers" */
  insert_chargebee_customers?: Maybe<Chargebee_Customers_Mutation_Response>;
  /** insert a single row into the table: "chargebee_customers" */
  insert_chargebee_customers_one?: Maybe<Chargebee_Customers>;
  /** insert data into the table: "invitations" */
  insert_invitations?: Maybe<Invitations_Mutation_Response>;
  /** insert a single row into the table: "invitations" */
  insert_invitations_one?: Maybe<Invitations>;
  /** insert data into the table: "license_invitations" */
  insert_license_invitations?: Maybe<License_Invitations_Mutation_Response>;
  /** insert a single row into the table: "license_invitations" */
  insert_license_invitations_one?: Maybe<License_Invitations>;
  /** insert data into the table: "license_seat_roles" */
  insert_license_seat_roles?: Maybe<License_Seat_Roles_Mutation_Response>;
  /** insert a single row into the table: "license_seat_roles" */
  insert_license_seat_roles_one?: Maybe<License_Seat_Roles>;
  /** insert data into the table: "license_seat_tiers" */
  insert_license_seat_tiers?: Maybe<License_Seat_Tiers_Mutation_Response>;
  /** insert a single row into the table: "license_seat_tiers" */
  insert_license_seat_tiers_one?: Maybe<License_Seat_Tiers>;
  /** insert data into the table: "licenses" */
  insert_licenses?: Maybe<Licenses_Mutation_Response>;
  /** insert a single row into the table: "licenses" */
  insert_licenses_one?: Maybe<Licenses>;
  /** insert data into the table: "my_invitations" */
  insert_my_invitations?: Maybe<My_Invitations_Mutation_Response>;
  /** insert a single row into the table: "my_invitations" */
  insert_my_invitations_one?: Maybe<My_Invitations>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "published_projects" */
  insert_published_projects?: Maybe<Published_Projects_Mutation_Response>;
  /** insert a single row into the table: "published_projects" */
  insert_published_projects_one?: Maybe<Published_Projects>;
  /** insert data into the table: "refresh_tokens" */
  insert_refresh_tokens?: Maybe<Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "refresh_tokens" */
  insert_refresh_tokens_one?: Maybe<Refresh_Tokens>;
  /** insert data into the table: "seats" */
  insert_seats?: Maybe<Seats_Mutation_Response>;
  /** insert a single row into the table: "seats" */
  insert_seats_one?: Maybe<Seats>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "video_sources" */
  insert_video_sources?: Maybe<Video_Sources_Mutation_Response>;
  /** insert a single row into the table: "video_sources" */
  insert_video_sources_one?: Maybe<Video_Sources>;
  /** insert data into the table: "viewer_analytics" */
  insert_viewer_analytics?: Maybe<Viewer_Analytics_Mutation_Response>;
  /** insert a single row into the table: "viewer_analytics" */
  insert_viewer_analytics_one?: Maybe<Viewer_Analytics>;
  /** update data of the table: "auto_assign_domains" */
  update_auto_assign_domains?: Maybe<Auto_Assign_Domains_Mutation_Response>;
  /** update single row of the table: "auto_assign_domains" */
  update_auto_assign_domains_by_pk?: Maybe<Auto_Assign_Domains>;
  /** update data of the table: "chargebee_customers" */
  update_chargebee_customers?: Maybe<Chargebee_Customers_Mutation_Response>;
  /** update single row of the table: "chargebee_customers" */
  update_chargebee_customers_by_pk?: Maybe<Chargebee_Customers>;
  /** update data of the table: "invitations" */
  update_invitations?: Maybe<Invitations_Mutation_Response>;
  /** update single row of the table: "invitations" */
  update_invitations_by_pk?: Maybe<Invitations>;
  /** update data of the table: "license_invitations" */
  update_license_invitations?: Maybe<License_Invitations_Mutation_Response>;
  /** update data of the table: "license_seat_roles" */
  update_license_seat_roles?: Maybe<License_Seat_Roles_Mutation_Response>;
  /** update single row of the table: "license_seat_roles" */
  update_license_seat_roles_by_pk?: Maybe<License_Seat_Roles>;
  /** update data of the table: "license_seat_tiers" */
  update_license_seat_tiers?: Maybe<License_Seat_Tiers_Mutation_Response>;
  /** update single row of the table: "license_seat_tiers" */
  update_license_seat_tiers_by_pk?: Maybe<License_Seat_Tiers>;
  /** update data of the table: "licenses" */
  update_licenses?: Maybe<Licenses_Mutation_Response>;
  /** update single row of the table: "licenses" */
  update_licenses_by_pk?: Maybe<Licenses>;
  /** update data of the table: "my_invitations" */
  update_my_invitations?: Maybe<My_Invitations_Mutation_Response>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update data of the table: "published_projects" */
  update_published_projects?: Maybe<Published_Projects_Mutation_Response>;
  /** update data of the table: "refresh_tokens" */
  update_refresh_tokens?: Maybe<Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "refresh_tokens" */
  update_refresh_tokens_by_pk?: Maybe<Refresh_Tokens>;
  /** update data of the table: "seats" */
  update_seats?: Maybe<Seats_Mutation_Response>;
  /** update single row of the table: "seats" */
  update_seats_by_pk?: Maybe<Seats>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "video_sources" */
  update_video_sources?: Maybe<Video_Sources_Mutation_Response>;
  /** update single row of the table: "video_sources" */
  update_video_sources_by_pk?: Maybe<Video_Sources>;
  /** update data of the table: "viewer_analytics" */
  update_viewer_analytics?: Maybe<Viewer_Analytics_Mutation_Response>;
  /** update single row of the table: "viewer_analytics" */
  update_viewer_analytics_by_pk?: Maybe<Viewer_Analytics>;
};

/** mutation root */
export type Mutation_RootDelete_Auto_Assign_DomainsArgs = {
  where: Auto_Assign_Domains_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auto_Assign_Domains_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chargebee_CustomersArgs = {
  where: Chargebee_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chargebee_Customers_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_InvitationsArgs = {
  where: Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invitations_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_License_InvitationsArgs = {
  where: License_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_License_Seat_RolesArgs = {
  where: License_Seat_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_License_Seat_Roles_By_PkArgs = {
  role: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_License_Seat_TiersArgs = {
  where: License_Seat_Tiers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_License_Seat_Tiers_By_PkArgs = {
  tier: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_LicensesArgs = {
  where: Licenses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Licenses_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_My_InvitationsArgs = {
  where: My_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Published_ProjectsArgs = {
  where: Published_Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Refresh_TokensArgs = {
  where: Refresh_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Refresh_Tokens_By_PkArgs = {
  user_uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SeatsArgs = {
  where: Seats_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Seats_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Video_SourcesArgs = {
  where: Video_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Sources_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Viewer_AnalyticsArgs = {
  where: Viewer_Analytics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Viewer_Analytics_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInsert_Auto_Assign_DomainsArgs = {
  objects: Array<Auto_Assign_Domains_Insert_Input>;
  on_conflict?: Maybe<Auto_Assign_Domains_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auto_Assign_Domains_OneArgs = {
  object: Auto_Assign_Domains_Insert_Input;
  on_conflict?: Maybe<Auto_Assign_Domains_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chargebee_CustomersArgs = {
  objects: Array<Chargebee_Customers_Insert_Input>;
  on_conflict?: Maybe<Chargebee_Customers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chargebee_Customers_OneArgs = {
  object: Chargebee_Customers_Insert_Input;
  on_conflict?: Maybe<Chargebee_Customers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InvitationsArgs = {
  objects: Array<Invitations_Insert_Input>;
  on_conflict?: Maybe<Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invitations_OneArgs = {
  object: Invitations_Insert_Input;
  on_conflict?: Maybe<Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_InvitationsArgs = {
  objects: Array<License_Invitations_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_License_Invitations_OneArgs = {
  object: License_Invitations_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_License_Seat_RolesArgs = {
  objects: Array<License_Seat_Roles_Insert_Input>;
  on_conflict?: Maybe<License_Seat_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_Seat_Roles_OneArgs = {
  object: License_Seat_Roles_Insert_Input;
  on_conflict?: Maybe<License_Seat_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_Seat_TiersArgs = {
  objects: Array<License_Seat_Tiers_Insert_Input>;
  on_conflict?: Maybe<License_Seat_Tiers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_Seat_Tiers_OneArgs = {
  object: License_Seat_Tiers_Insert_Input;
  on_conflict?: Maybe<License_Seat_Tiers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LicensesArgs = {
  objects: Array<Licenses_Insert_Input>;
  on_conflict?: Maybe<Licenses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Licenses_OneArgs = {
  object: Licenses_Insert_Input;
  on_conflict?: Maybe<Licenses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_My_InvitationsArgs = {
  objects: Array<My_Invitations_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_My_Invitations_OneArgs = {
  object: My_Invitations_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: Maybe<Projects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: Maybe<Projects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Published_ProjectsArgs = {
  objects: Array<Published_Projects_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Published_Projects_OneArgs = {
  object: Published_Projects_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Refresh_TokensArgs = {
  objects: Array<Refresh_Tokens_Insert_Input>;
  on_conflict?: Maybe<Refresh_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Refresh_Tokens_OneArgs = {
  object: Refresh_Tokens_Insert_Input;
  on_conflict?: Maybe<Refresh_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SeatsArgs = {
  objects: Array<Seats_Insert_Input>;
  on_conflict?: Maybe<Seats_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Seats_OneArgs = {
  object: Seats_Insert_Input;
  on_conflict?: Maybe<Seats_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_SourcesArgs = {
  objects: Array<Video_Sources_Insert_Input>;
  on_conflict?: Maybe<Video_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Sources_OneArgs = {
  object: Video_Sources_Insert_Input;
  on_conflict?: Maybe<Video_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Viewer_AnalyticsArgs = {
  objects: Array<Viewer_Analytics_Insert_Input>;
  on_conflict?: Maybe<Viewer_Analytics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Viewer_Analytics_OneArgs = {
  object: Viewer_Analytics_Insert_Input;
  on_conflict?: Maybe<Viewer_Analytics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Auto_Assign_DomainsArgs = {
  _set?: Maybe<Auto_Assign_Domains_Set_Input>;
  where: Auto_Assign_Domains_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auto_Assign_Domains_By_PkArgs = {
  _set?: Maybe<Auto_Assign_Domains_Set_Input>;
  pk_columns: Auto_Assign_Domains_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chargebee_CustomersArgs = {
  _inc?: Maybe<Chargebee_Customers_Inc_Input>;
  _set?: Maybe<Chargebee_Customers_Set_Input>;
  where: Chargebee_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chargebee_Customers_By_PkArgs = {
  _inc?: Maybe<Chargebee_Customers_Inc_Input>;
  _set?: Maybe<Chargebee_Customers_Set_Input>;
  pk_columns: Chargebee_Customers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_InvitationsArgs = {
  _set?: Maybe<Invitations_Set_Input>;
  where: Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invitations_By_PkArgs = {
  _set?: Maybe<Invitations_Set_Input>;
  pk_columns: Invitations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_License_InvitationsArgs = {
  _set?: Maybe<License_Invitations_Set_Input>;
  where: License_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_License_Seat_RolesArgs = {
  _set?: Maybe<License_Seat_Roles_Set_Input>;
  where: License_Seat_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_License_Seat_Roles_By_PkArgs = {
  _set?: Maybe<License_Seat_Roles_Set_Input>;
  pk_columns: License_Seat_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_License_Seat_TiersArgs = {
  _set?: Maybe<License_Seat_Tiers_Set_Input>;
  where: License_Seat_Tiers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_License_Seat_Tiers_By_PkArgs = {
  _set?: Maybe<License_Seat_Tiers_Set_Input>;
  pk_columns: License_Seat_Tiers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_LicensesArgs = {
  _inc?: Maybe<Licenses_Inc_Input>;
  _set?: Maybe<Licenses_Set_Input>;
  where: Licenses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Licenses_By_PkArgs = {
  _inc?: Maybe<Licenses_Inc_Input>;
  _set?: Maybe<Licenses_Set_Input>;
  pk_columns: Licenses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_My_InvitationsArgs = {
  _set?: Maybe<My_Invitations_Set_Input>;
  where: My_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _set?: Maybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _set?: Maybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _append?: Maybe<Projects_Append_Input>;
  _delete_at_path?: Maybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Projects_Delete_Elem_Input>;
  _delete_key?: Maybe<Projects_Delete_Key_Input>;
  _prepend?: Maybe<Projects_Prepend_Input>;
  _set?: Maybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _append?: Maybe<Projects_Append_Input>;
  _delete_at_path?: Maybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Projects_Delete_Elem_Input>;
  _delete_key?: Maybe<Projects_Delete_Key_Input>;
  _prepend?: Maybe<Projects_Prepend_Input>;
  _set?: Maybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Published_ProjectsArgs = {
  _append?: Maybe<Published_Projects_Append_Input>;
  _delete_at_path?: Maybe<Published_Projects_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Published_Projects_Delete_Elem_Input>;
  _delete_key?: Maybe<Published_Projects_Delete_Key_Input>;
  _prepend?: Maybe<Published_Projects_Prepend_Input>;
  _set?: Maybe<Published_Projects_Set_Input>;
  where: Published_Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Refresh_TokensArgs = {
  _set?: Maybe<Refresh_Tokens_Set_Input>;
  where: Refresh_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Refresh_Tokens_By_PkArgs = {
  _set?: Maybe<Refresh_Tokens_Set_Input>;
  pk_columns: Refresh_Tokens_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SeatsArgs = {
  _set?: Maybe<Seats_Set_Input>;
  where: Seats_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Seats_By_PkArgs = {
  _set?: Maybe<Seats_Set_Input>;
  pk_columns: Seats_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_SourcesArgs = {
  _append?: Maybe<Video_Sources_Append_Input>;
  _delete_at_path?: Maybe<Video_Sources_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Video_Sources_Delete_Elem_Input>;
  _delete_key?: Maybe<Video_Sources_Delete_Key_Input>;
  _inc?: Maybe<Video_Sources_Inc_Input>;
  _prepend?: Maybe<Video_Sources_Prepend_Input>;
  _set?: Maybe<Video_Sources_Set_Input>;
  where: Video_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Sources_By_PkArgs = {
  _append?: Maybe<Video_Sources_Append_Input>;
  _delete_at_path?: Maybe<Video_Sources_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Video_Sources_Delete_Elem_Input>;
  _delete_key?: Maybe<Video_Sources_Delete_Key_Input>;
  _inc?: Maybe<Video_Sources_Inc_Input>;
  _prepend?: Maybe<Video_Sources_Prepend_Input>;
  _set?: Maybe<Video_Sources_Set_Input>;
  pk_columns: Video_Sources_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Viewer_AnalyticsArgs = {
  _inc?: Maybe<Viewer_Analytics_Inc_Input>;
  _set?: Maybe<Viewer_Analytics_Set_Input>;
  where: Viewer_Analytics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Viewer_Analytics_By_PkArgs = {
  _inc?: Maybe<Viewer_Analytics_Inc_Input>;
  _set?: Maybe<Viewer_Analytics_Set_Input>;
  pk_columns: Viewer_Analytics_Pk_Columns_Input;
};

/** columns and relationships of "my_invitations" */
export type My_Invitations = {
  __typename?: 'my_invitations';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "my_invitations" */
export type My_Invitations_Aggregate = {
  __typename?: 'my_invitations_aggregate';
  aggregate?: Maybe<My_Invitations_Aggregate_Fields>;
  nodes: Array<My_Invitations>;
};

/** aggregate fields of "my_invitations" */
export type My_Invitations_Aggregate_Fields = {
  __typename?: 'my_invitations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<My_Invitations_Max_Fields>;
  min?: Maybe<My_Invitations_Min_Fields>;
};

/** aggregate fields of "my_invitations" */
export type My_Invitations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<My_Invitations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "my_invitations". All fields are combined with a logical 'AND'. */
export type My_Invitations_Bool_Exp = {
  _and?: Maybe<Array<My_Invitations_Bool_Exp>>;
  _not?: Maybe<My_Invitations_Bool_Exp>;
  _or?: Maybe<Array<My_Invitations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  license_uuid?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  seat_uuid?: Maybe<Uuid_Comparison_Exp>;
  tier?: Maybe<String_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "my_invitations" */
export type My_Invitations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type My_Invitations_Max_Fields = {
  __typename?: 'my_invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type My_Invitations_Min_Fields = {
  __typename?: 'my_invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "my_invitations" */
export type My_Invitations_Mutation_Response = {
  __typename?: 'my_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<My_Invitations>;
};

/** Ordering options when selecting data from "my_invitations". */
export type My_Invitations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  seat_uuid?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** select columns of table "my_invitations" */
export enum My_Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  SeatUuid = 'seat_uuid',
  /** column name */
  Tier = 'tier',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "my_invitations" */
export type My_Invitations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  seat_uuid?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/**
 * organizations that manage licenses and managed users
 *
 *
 * columns and relationships of "organizations"
 *
 */
export type Organizations = {
  __typename?: 'organizations';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** fetch data from the table: "licenses" */
  licenses: Array<Licenses>;
  /** fetch aggregated fields from the table: "licenses" */
  licenses_aggregate: Licenses_Aggregate;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};

/**
 * organizations that manage licenses and managed users
 *
 *
 * columns and relationships of "organizations"
 *
 */
export type OrganizationsLicensesArgs = {
  distinct_on?: Maybe<Array<Licenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Licenses_Order_By>>;
  where?: Maybe<Licenses_Bool_Exp>;
};

/**
 * organizations that manage licenses and managed users
 *
 *
 * columns and relationships of "organizations"
 *
 */
export type OrganizationsLicenses_AggregateArgs = {
  distinct_on?: Maybe<Array<Licenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Licenses_Order_By>>;
  where?: Maybe<Licenses_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organizations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: Maybe<Array<Organizations_Bool_Exp>>;
  _not?: Maybe<Organizations_Bool_Exp>;
  _or?: Maybe<Array<Organizations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  licenses?: Maybe<Licenses_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint */
  OrganizationsPkey = 'organizations_pkey',
}

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  licenses?: Maybe<Licenses_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** on_conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns?: Array<Organizations_Update_Column>;
  where?: Maybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  licenses_aggregate?: Maybe<Licenses_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  created_at: Scalars['timestamptz'];
  owner_uuid: Scalars['uuid'];
  scene: Scalars['jsonb'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};

/** columns and relationships of "projects" */
export type ProjectsSceneArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Projects_Append_Input = {
  scene?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: Maybe<Array<Projects_Bool_Exp>>;
  _not?: Maybe<Projects_Bool_Exp>;
  _or?: Maybe<Array<Projects_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  owner_uuid?: Maybe<Uuid_Comparison_Exp>;
  scene?: Maybe<Jsonb_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint */
  ProjectsPkey = 'projects_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Projects_Delete_At_Path_Input = {
  scene?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Projects_Delete_Elem_Input = {
  scene?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Projects_Delete_Key_Input = {
  scene?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  scene?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  data: Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns?: Array<Projects_Update_Column>;
  where?: Maybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  created_at?: Maybe<Order_By>;
  owner_uuid?: Maybe<Order_By>;
  scene?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Projects_Prepend_Input = {
  scene?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OwnerUuid = 'owner_uuid',
  /** column name */
  Scene = 'scene',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  scene?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OwnerUuid = 'owner_uuid',
  /** column name */
  Scene = 'scene',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** columns and relationships of "published_projects" */
export type Published_Projects = {
  __typename?: 'published_projects';
  created_at: Scalars['timestamptz'];
  owner_uuid: Scalars['uuid'];
  scene: Scalars['jsonb'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};

/** columns and relationships of "published_projects" */
export type Published_ProjectsSceneArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "published_projects" */
export type Published_Projects_Aggregate = {
  __typename?: 'published_projects_aggregate';
  aggregate?: Maybe<Published_Projects_Aggregate_Fields>;
  nodes: Array<Published_Projects>;
};

/** aggregate fields of "published_projects" */
export type Published_Projects_Aggregate_Fields = {
  __typename?: 'published_projects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Published_Projects_Max_Fields>;
  min?: Maybe<Published_Projects_Min_Fields>;
};

/** aggregate fields of "published_projects" */
export type Published_Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Published_Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Published_Projects_Append_Input = {
  scene?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "published_projects". All fields are combined with a logical 'AND'. */
export type Published_Projects_Bool_Exp = {
  _and?: Maybe<Array<Published_Projects_Bool_Exp>>;
  _not?: Maybe<Published_Projects_Bool_Exp>;
  _or?: Maybe<Array<Published_Projects_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  owner_uuid?: Maybe<Uuid_Comparison_Exp>;
  scene?: Maybe<Jsonb_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Published_Projects_Delete_At_Path_Input = {
  scene?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Published_Projects_Delete_Elem_Input = {
  scene?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Published_Projects_Delete_Key_Input = {
  scene?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "published_projects" */
export type Published_Projects_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  scene?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Published_Projects_Max_Fields = {
  __typename?: 'published_projects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Published_Projects_Min_Fields = {
  __typename?: 'published_projects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "published_projects" */
export type Published_Projects_Mutation_Response = {
  __typename?: 'published_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Published_Projects>;
};

/** Ordering options when selecting data from "published_projects". */
export type Published_Projects_Order_By = {
  created_at?: Maybe<Order_By>;
  owner_uuid?: Maybe<Order_By>;
  scene?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Published_Projects_Prepend_Input = {
  scene?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "published_projects" */
export enum Published_Projects_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OwnerUuid = 'owner_uuid',
  /** column name */
  Scene = 'scene',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "published_projects" */
export type Published_Projects_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  scene?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "auto_assign_domains" */
  auto_assign_domains: Array<Auto_Assign_Domains>;
  /** An aggregate relationship */
  auto_assign_domains_aggregate: Auto_Assign_Domains_Aggregate;
  /** fetch data from the table: "auto_assign_domains" using primary key columns */
  auto_assign_domains_by_pk?: Maybe<Auto_Assign_Domains>;
  /** fetch data from the table: "chargebee_customers" */
  chargebee_customers: Array<Chargebee_Customers>;
  /** fetch aggregated fields from the table: "chargebee_customers" */
  chargebee_customers_aggregate: Chargebee_Customers_Aggregate;
  /** fetch data from the table: "chargebee_customers" using primary key columns */
  chargebee_customers_by_pk?: Maybe<Chargebee_Customers>;
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** An array relationship */
  license_invitations: Array<License_Invitations>;
  /** An aggregate relationship */
  license_invitations_aggregate: License_Invitations_Aggregate;
  /** fetch data from the table: "license_seat_roles" */
  license_seat_roles: Array<License_Seat_Roles>;
  /** fetch aggregated fields from the table: "license_seat_roles" */
  license_seat_roles_aggregate: License_Seat_Roles_Aggregate;
  /** fetch data from the table: "license_seat_roles" using primary key columns */
  license_seat_roles_by_pk?: Maybe<License_Seat_Roles>;
  /** fetch data from the table: "license_seat_tiers" */
  license_seat_tiers: Array<License_Seat_Tiers>;
  /** fetch aggregated fields from the table: "license_seat_tiers" */
  license_seat_tiers_aggregate: License_Seat_Tiers_Aggregate;
  /** fetch data from the table: "license_seat_tiers" using primary key columns */
  license_seat_tiers_by_pk?: Maybe<License_Seat_Tiers>;
  /** An array relationship */
  license_users: Array<License_Users>;
  /** An aggregate relationship */
  license_users_aggregate: License_Users_Aggregate;
  /** fetch data from the table: "licenses" */
  licenses: Array<Licenses>;
  /** fetch aggregated fields from the table: "licenses" */
  licenses_aggregate: Licenses_Aggregate;
  /** fetch data from the table: "licenses" using primary key columns */
  licenses_by_pk?: Maybe<Licenses>;
  /** fetch data from the table: "my_invitations" */
  my_invitations: Array<My_Invitations>;
  /** fetch aggregated fields from the table: "my_invitations" */
  my_invitations_aggregate: My_Invitations_Aggregate;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "published_projects" */
  published_projects: Array<Published_Projects>;
  /** fetch aggregated fields from the table: "published_projects" */
  published_projects_aggregate: Published_Projects_Aggregate;
  /** fetch data from the table: "refresh_tokens" */
  refresh_tokens: Array<Refresh_Tokens>;
  /** fetch aggregated fields from the table: "refresh_tokens" */
  refresh_tokens_aggregate: Refresh_Tokens_Aggregate;
  /** fetch data from the table: "refresh_tokens" using primary key columns */
  refresh_tokens_by_pk?: Maybe<Refresh_Tokens>;
  /** An array relationship */
  seats: Array<Seats>;
  /** An aggregate relationship */
  seats_aggregate: Seats_Aggregate;
  /** fetch data from the table: "seats" using primary key columns */
  seats_by_pk?: Maybe<Seats>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "video_sources" */
  video_sources: Array<Video_Sources>;
  /** fetch aggregated fields from the table: "video_sources" */
  video_sources_aggregate: Video_Sources_Aggregate;
  /** fetch data from the table: "video_sources" using primary key columns */
  video_sources_by_pk?: Maybe<Video_Sources>;
  /** fetch data from the table: "viewer_analytics" */
  viewer_analytics: Array<Viewer_Analytics>;
  /** fetch aggregated fields from the table: "viewer_analytics" */
  viewer_analytics_aggregate: Viewer_Analytics_Aggregate;
  /** fetch data from the table: "viewer_analytics" using primary key columns */
  viewer_analytics_by_pk?: Maybe<Viewer_Analytics>;
};

export type Query_RootAuto_Assign_DomainsArgs = {
  distinct_on?: Maybe<Array<Auto_Assign_Domains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auto_Assign_Domains_Order_By>>;
  where?: Maybe<Auto_Assign_Domains_Bool_Exp>;
};

export type Query_RootAuto_Assign_Domains_AggregateArgs = {
  distinct_on?: Maybe<Array<Auto_Assign_Domains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auto_Assign_Domains_Order_By>>;
  where?: Maybe<Auto_Assign_Domains_Bool_Exp>;
};

export type Query_RootAuto_Assign_Domains_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootChargebee_CustomersArgs = {
  distinct_on?: Maybe<Array<Chargebee_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chargebee_Customers_Order_By>>;
  where?: Maybe<Chargebee_Customers_Bool_Exp>;
};

export type Query_RootChargebee_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Chargebee_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chargebee_Customers_Order_By>>;
  where?: Maybe<Chargebee_Customers_Bool_Exp>;
};

export type Query_RootChargebee_Customers_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootInvitationsArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};

export type Query_RootInvitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};

export type Query_RootInvitations_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootLicense_InvitationsArgs = {
  distinct_on?: Maybe<Array<License_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Invitations_Order_By>>;
  where?: Maybe<License_Invitations_Bool_Exp>;
};

export type Query_RootLicense_Invitations_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Invitations_Order_By>>;
  where?: Maybe<License_Invitations_Bool_Exp>;
};

export type Query_RootLicense_Seat_RolesArgs = {
  distinct_on?: Maybe<Array<License_Seat_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Roles_Order_By>>;
  where?: Maybe<License_Seat_Roles_Bool_Exp>;
};

export type Query_RootLicense_Seat_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Seat_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Roles_Order_By>>;
  where?: Maybe<License_Seat_Roles_Bool_Exp>;
};

export type Query_RootLicense_Seat_Roles_By_PkArgs = {
  role: Scalars['String'];
};

export type Query_RootLicense_Seat_TiersArgs = {
  distinct_on?: Maybe<Array<License_Seat_Tiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Tiers_Order_By>>;
  where?: Maybe<License_Seat_Tiers_Bool_Exp>;
};

export type Query_RootLicense_Seat_Tiers_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Seat_Tiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Tiers_Order_By>>;
  where?: Maybe<License_Seat_Tiers_Bool_Exp>;
};

export type Query_RootLicense_Seat_Tiers_By_PkArgs = {
  tier: Scalars['String'];
};

export type Query_RootLicense_UsersArgs = {
  distinct_on?: Maybe<Array<License_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Users_Order_By>>;
  where?: Maybe<License_Users_Bool_Exp>;
};

export type Query_RootLicense_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Users_Order_By>>;
  where?: Maybe<License_Users_Bool_Exp>;
};

export type Query_RootLicensesArgs = {
  distinct_on?: Maybe<Array<Licenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Licenses_Order_By>>;
  where?: Maybe<Licenses_Bool_Exp>;
};

export type Query_RootLicenses_AggregateArgs = {
  distinct_on?: Maybe<Array<Licenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Licenses_Order_By>>;
  where?: Maybe<Licenses_Bool_Exp>;
};

export type Query_RootLicenses_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootMy_InvitationsArgs = {
  distinct_on?: Maybe<Array<My_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Invitations_Order_By>>;
  where?: Maybe<My_Invitations_Bool_Exp>;
};

export type Query_RootMy_Invitations_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Invitations_Order_By>>;
  where?: Maybe<My_Invitations_Bool_Exp>;
};

export type Query_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};

export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};

export type Query_RootOrganizations_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

export type Query_RootProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

export type Query_RootProjects_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootPublished_ProjectsArgs = {
  distinct_on?: Maybe<Array<Published_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Published_Projects_Order_By>>;
  where?: Maybe<Published_Projects_Bool_Exp>;
};

export type Query_RootPublished_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Published_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Published_Projects_Order_By>>;
  where?: Maybe<Published_Projects_Bool_Exp>;
};

export type Query_RootRefresh_TokensArgs = {
  distinct_on?: Maybe<Array<Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refresh_Tokens_Order_By>>;
  where?: Maybe<Refresh_Tokens_Bool_Exp>;
};

export type Query_RootRefresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refresh_Tokens_Order_By>>;
  where?: Maybe<Refresh_Tokens_Bool_Exp>;
};

export type Query_RootRefresh_Tokens_By_PkArgs = {
  user_uuid: Scalars['uuid'];
};

export type Query_RootSeatsArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

export type Query_RootSeats_AggregateArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

export type Query_RootSeats_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootVideo_SourcesArgs = {
  distinct_on?: Maybe<Array<Video_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Sources_Order_By>>;
  where?: Maybe<Video_Sources_Bool_Exp>;
};

export type Query_RootVideo_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Sources_Order_By>>;
  where?: Maybe<Video_Sources_Bool_Exp>;
};

export type Query_RootVideo_Sources_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootViewer_AnalyticsArgs = {
  distinct_on?: Maybe<Array<Viewer_Analytics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Viewer_Analytics_Order_By>>;
  where?: Maybe<Viewer_Analytics_Bool_Exp>;
};

export type Query_RootViewer_Analytics_AggregateArgs = {
  distinct_on?: Maybe<Array<Viewer_Analytics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Viewer_Analytics_Order_By>>;
  where?: Maybe<Viewer_Analytics_Bool_Exp>;
};

export type Query_RootViewer_Analytics_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/**
 * refresh tokens comes from google Oauth. we assume that refresh tokens are unique
 *
 *
 * columns and relationships of "refresh_tokens"
 *
 */
export type Refresh_Tokens = {
  __typename?: 'refresh_tokens';
  created_at: Scalars['timestamptz'];
  expired?: Maybe<Scalars['Boolean']>;
  refresh_token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_uuid: Scalars['uuid'];
};

/** aggregated selection of "refresh_tokens" */
export type Refresh_Tokens_Aggregate = {
  __typename?: 'refresh_tokens_aggregate';
  aggregate?: Maybe<Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Refresh_Tokens>;
};

/** aggregate fields of "refresh_tokens" */
export type Refresh_Tokens_Aggregate_Fields = {
  __typename?: 'refresh_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Refresh_Tokens_Max_Fields>;
  min?: Maybe<Refresh_Tokens_Min_Fields>;
};

/** aggregate fields of "refresh_tokens" */
export type Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Refresh_Tokens_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "refresh_tokens". All fields are combined with a logical 'AND'. */
export type Refresh_Tokens_Bool_Exp = {
  _and?: Maybe<Array<Refresh_Tokens_Bool_Exp>>;
  _not?: Maybe<Refresh_Tokens_Bool_Exp>;
  _or?: Maybe<Array<Refresh_Tokens_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expired?: Maybe<Boolean_Comparison_Exp>;
  refresh_token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "refresh_tokens" */
export enum Refresh_Tokens_Constraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey',
}

/** input type for inserting data into table "refresh_tokens" */
export type Refresh_Tokens_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expired?: Maybe<Scalars['Boolean']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Refresh_Tokens_Max_Fields = {
  __typename?: 'refresh_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Refresh_Tokens_Min_Fields = {
  __typename?: 'refresh_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "refresh_tokens" */
export type Refresh_Tokens_Mutation_Response = {
  __typename?: 'refresh_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Refresh_Tokens>;
};

/** on_conflict condition type for table "refresh_tokens" */
export type Refresh_Tokens_On_Conflict = {
  constraint: Refresh_Tokens_Constraint;
  update_columns?: Array<Refresh_Tokens_Update_Column>;
  where?: Maybe<Refresh_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "refresh_tokens". */
export type Refresh_Tokens_Order_By = {
  created_at?: Maybe<Order_By>;
  expired?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: refresh_tokens */
export type Refresh_Tokens_Pk_Columns_Input = {
  user_uuid: Scalars['uuid'];
};

/** select columns of table "refresh_tokens" */
export enum Refresh_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expired = 'expired',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserUuid = 'user_uuid',
}

/** input type for updating data in table "refresh_tokens" */
export type Refresh_Tokens_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expired?: Maybe<Scalars['Boolean']>;
  refresh_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_uuid?: Maybe<Scalars['uuid']>;
};

/** update columns of table "refresh_tokens" */
export enum Refresh_Tokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expired = 'expired',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserUuid = 'user_uuid',
}

/**
 * Join between licenses and users
 *
 *
 * columns and relationships of "seats"
 *
 */
export type Seats = {
  __typename?: 'seats';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  invitation?: Maybe<Invitations>;
  /** An object relationship */
  license: Licenses;
  license_uuid: Scalars['uuid'];
  role: License_Seat_Roles_Enum;
  tier: License_Seat_Tiers_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_uuid: Scalars['uuid'];
  uuid: Scalars['uuid'];
};

/** aggregated selection of "seats" */
export type Seats_Aggregate = {
  __typename?: 'seats_aggregate';
  aggregate?: Maybe<Seats_Aggregate_Fields>;
  nodes: Array<Seats>;
};

/** aggregate fields of "seats" */
export type Seats_Aggregate_Fields = {
  __typename?: 'seats_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Seats_Max_Fields>;
  min?: Maybe<Seats_Min_Fields>;
};

/** aggregate fields of "seats" */
export type Seats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Seats_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seats" */
export type Seats_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Seats_Max_Order_By>;
  min?: Maybe<Seats_Min_Order_By>;
};

/** input type for inserting array relation for remote table "seats" */
export type Seats_Arr_Rel_Insert_Input = {
  data: Array<Seats_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Seats_On_Conflict>;
};

/** Boolean expression to filter rows from the table "seats". All fields are combined with a logical 'AND'. */
export type Seats_Bool_Exp = {
  _and?: Maybe<Array<Seats_Bool_Exp>>;
  _not?: Maybe<Seats_Bool_Exp>;
  _or?: Maybe<Array<Seats_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  invitation?: Maybe<Invitations_Bool_Exp>;
  license?: Maybe<Licenses_Bool_Exp>;
  license_uuid?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<License_Seat_Roles_Enum_Comparison_Exp>;
  tier?: Maybe<License_Seat_Tiers_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_uuid?: Maybe<Uuid_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "seats" */
export enum Seats_Constraint {
  /** unique or primary key constraint */
  SeatsLicenseUuidUserUuidKey = 'seats_license_uuid_user_uuid_key',
  /** unique or primary key constraint */
  SeatsPkey = 'seats_pkey',
}

/** input type for inserting data into table "seats" */
export type Seats_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  invitation?: Maybe<Invitations_Obj_Rel_Insert_Input>;
  license?: Maybe<Licenses_Obj_Rel_Insert_Input>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<License_Seat_Roles_Enum>;
  tier?: Maybe<License_Seat_Tiers_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_uuid?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Seats_Max_Fields = {
  __typename?: 'seats_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_uuid?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "seats" */
export type Seats_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_uuid?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Seats_Min_Fields = {
  __typename?: 'seats_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_uuid?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "seats" */
export type Seats_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  license_uuid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_uuid?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** response of any mutation on the table "seats" */
export type Seats_Mutation_Response = {
  __typename?: 'seats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Seats>;
};

/** input type for inserting object relation for remote table "seats" */
export type Seats_Obj_Rel_Insert_Input = {
  data: Seats_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Seats_On_Conflict>;
};

/** on_conflict condition type for table "seats" */
export type Seats_On_Conflict = {
  constraint: Seats_Constraint;
  update_columns?: Array<Seats_Update_Column>;
  where?: Maybe<Seats_Bool_Exp>;
};

/** Ordering options when selecting data from "seats". */
export type Seats_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  invitation?: Maybe<Invitations_Order_By>;
  license?: Maybe<Licenses_Order_By>;
  license_uuid?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  tier?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_uuid?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: seats */
export type Seats_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "seats" */
export enum Seats_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserUuid = 'user_uuid',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "seats" */
export type Seats_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  license_uuid?: Maybe<Scalars['uuid']>;
  role?: Maybe<License_Seat_Roles_Enum>;
  tier?: Maybe<License_Seat_Tiers_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_uuid?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** update columns of table "seats" */
export enum Seats_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  LicenseUuid = 'license_uuid',
  /** column name */
  Role = 'role',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserUuid = 'user_uuid',
  /** column name */
  Uuid = 'uuid',
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "auto_assign_domains" */
  auto_assign_domains: Array<Auto_Assign_Domains>;
  /** An aggregate relationship */
  auto_assign_domains_aggregate: Auto_Assign_Domains_Aggregate;
  /** fetch data from the table: "auto_assign_domains" using primary key columns */
  auto_assign_domains_by_pk?: Maybe<Auto_Assign_Domains>;
  /** fetch data from the table: "chargebee_customers" */
  chargebee_customers: Array<Chargebee_Customers>;
  /** fetch aggregated fields from the table: "chargebee_customers" */
  chargebee_customers_aggregate: Chargebee_Customers_Aggregate;
  /** fetch data from the table: "chargebee_customers" using primary key columns */
  chargebee_customers_by_pk?: Maybe<Chargebee_Customers>;
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** An array relationship */
  license_invitations: Array<License_Invitations>;
  /** An aggregate relationship */
  license_invitations_aggregate: License_Invitations_Aggregate;
  /** fetch data from the table: "license_seat_roles" */
  license_seat_roles: Array<License_Seat_Roles>;
  /** fetch aggregated fields from the table: "license_seat_roles" */
  license_seat_roles_aggregate: License_Seat_Roles_Aggregate;
  /** fetch data from the table: "license_seat_roles" using primary key columns */
  license_seat_roles_by_pk?: Maybe<License_Seat_Roles>;
  /** fetch data from the table: "license_seat_tiers" */
  license_seat_tiers: Array<License_Seat_Tiers>;
  /** fetch aggregated fields from the table: "license_seat_tiers" */
  license_seat_tiers_aggregate: License_Seat_Tiers_Aggregate;
  /** fetch data from the table: "license_seat_tiers" using primary key columns */
  license_seat_tiers_by_pk?: Maybe<License_Seat_Tiers>;
  /** An array relationship */
  license_users: Array<License_Users>;
  /** An aggregate relationship */
  license_users_aggregate: License_Users_Aggregate;
  /** fetch data from the table: "licenses" */
  licenses: Array<Licenses>;
  /** fetch aggregated fields from the table: "licenses" */
  licenses_aggregate: Licenses_Aggregate;
  /** fetch data from the table: "licenses" using primary key columns */
  licenses_by_pk?: Maybe<Licenses>;
  /** fetch data from the table: "my_invitations" */
  my_invitations: Array<My_Invitations>;
  /** fetch aggregated fields from the table: "my_invitations" */
  my_invitations_aggregate: My_Invitations_Aggregate;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "published_projects" */
  published_projects: Array<Published_Projects>;
  /** fetch aggregated fields from the table: "published_projects" */
  published_projects_aggregate: Published_Projects_Aggregate;
  /** fetch data from the table: "refresh_tokens" */
  refresh_tokens: Array<Refresh_Tokens>;
  /** fetch aggregated fields from the table: "refresh_tokens" */
  refresh_tokens_aggregate: Refresh_Tokens_Aggregate;
  /** fetch data from the table: "refresh_tokens" using primary key columns */
  refresh_tokens_by_pk?: Maybe<Refresh_Tokens>;
  /** An array relationship */
  seats: Array<Seats>;
  /** An aggregate relationship */
  seats_aggregate: Seats_Aggregate;
  /** fetch data from the table: "seats" using primary key columns */
  seats_by_pk?: Maybe<Seats>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "video_sources" */
  video_sources: Array<Video_Sources>;
  /** fetch aggregated fields from the table: "video_sources" */
  video_sources_aggregate: Video_Sources_Aggregate;
  /** fetch data from the table: "video_sources" using primary key columns */
  video_sources_by_pk?: Maybe<Video_Sources>;
  /** fetch data from the table: "viewer_analytics" */
  viewer_analytics: Array<Viewer_Analytics>;
  /** fetch aggregated fields from the table: "viewer_analytics" */
  viewer_analytics_aggregate: Viewer_Analytics_Aggregate;
  /** fetch data from the table: "viewer_analytics" using primary key columns */
  viewer_analytics_by_pk?: Maybe<Viewer_Analytics>;
};

export type Subscription_RootAuto_Assign_DomainsArgs = {
  distinct_on?: Maybe<Array<Auto_Assign_Domains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auto_Assign_Domains_Order_By>>;
  where?: Maybe<Auto_Assign_Domains_Bool_Exp>;
};

export type Subscription_RootAuto_Assign_Domains_AggregateArgs = {
  distinct_on?: Maybe<Array<Auto_Assign_Domains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auto_Assign_Domains_Order_By>>;
  where?: Maybe<Auto_Assign_Domains_Bool_Exp>;
};

export type Subscription_RootAuto_Assign_Domains_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootChargebee_CustomersArgs = {
  distinct_on?: Maybe<Array<Chargebee_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chargebee_Customers_Order_By>>;
  where?: Maybe<Chargebee_Customers_Bool_Exp>;
};

export type Subscription_RootChargebee_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Chargebee_Customers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chargebee_Customers_Order_By>>;
  where?: Maybe<Chargebee_Customers_Bool_Exp>;
};

export type Subscription_RootChargebee_Customers_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootInvitationsArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};

export type Subscription_RootInvitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitations_Order_By>>;
  where?: Maybe<Invitations_Bool_Exp>;
};

export type Subscription_RootInvitations_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootLicense_InvitationsArgs = {
  distinct_on?: Maybe<Array<License_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Invitations_Order_By>>;
  where?: Maybe<License_Invitations_Bool_Exp>;
};

export type Subscription_RootLicense_Invitations_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Invitations_Order_By>>;
  where?: Maybe<License_Invitations_Bool_Exp>;
};

export type Subscription_RootLicense_Seat_RolesArgs = {
  distinct_on?: Maybe<Array<License_Seat_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Roles_Order_By>>;
  where?: Maybe<License_Seat_Roles_Bool_Exp>;
};

export type Subscription_RootLicense_Seat_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Seat_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Roles_Order_By>>;
  where?: Maybe<License_Seat_Roles_Bool_Exp>;
};

export type Subscription_RootLicense_Seat_Roles_By_PkArgs = {
  role: Scalars['String'];
};

export type Subscription_RootLicense_Seat_TiersArgs = {
  distinct_on?: Maybe<Array<License_Seat_Tiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Tiers_Order_By>>;
  where?: Maybe<License_Seat_Tiers_Bool_Exp>;
};

export type Subscription_RootLicense_Seat_Tiers_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Seat_Tiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Seat_Tiers_Order_By>>;
  where?: Maybe<License_Seat_Tiers_Bool_Exp>;
};

export type Subscription_RootLicense_Seat_Tiers_By_PkArgs = {
  tier: Scalars['String'];
};

export type Subscription_RootLicense_UsersArgs = {
  distinct_on?: Maybe<Array<License_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Users_Order_By>>;
  where?: Maybe<License_Users_Bool_Exp>;
};

export type Subscription_RootLicense_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<License_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<License_Users_Order_By>>;
  where?: Maybe<License_Users_Bool_Exp>;
};

export type Subscription_RootLicensesArgs = {
  distinct_on?: Maybe<Array<Licenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Licenses_Order_By>>;
  where?: Maybe<Licenses_Bool_Exp>;
};

export type Subscription_RootLicenses_AggregateArgs = {
  distinct_on?: Maybe<Array<Licenses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Licenses_Order_By>>;
  where?: Maybe<Licenses_Bool_Exp>;
};

export type Subscription_RootLicenses_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootMy_InvitationsArgs = {
  distinct_on?: Maybe<Array<My_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Invitations_Order_By>>;
  where?: Maybe<My_Invitations_Bool_Exp>;
};

export type Subscription_RootMy_Invitations_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Invitations_Order_By>>;
  where?: Maybe<My_Invitations_Bool_Exp>;
};

export type Subscription_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};

export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};

export type Subscription_RootOrganizations_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};

export type Subscription_RootProjects_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootPublished_ProjectsArgs = {
  distinct_on?: Maybe<Array<Published_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Published_Projects_Order_By>>;
  where?: Maybe<Published_Projects_Bool_Exp>;
};

export type Subscription_RootPublished_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Published_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Published_Projects_Order_By>>;
  where?: Maybe<Published_Projects_Bool_Exp>;
};

export type Subscription_RootRefresh_TokensArgs = {
  distinct_on?: Maybe<Array<Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refresh_Tokens_Order_By>>;
  where?: Maybe<Refresh_Tokens_Bool_Exp>;
};

export type Subscription_RootRefresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Refresh_Tokens_Order_By>>;
  where?: Maybe<Refresh_Tokens_Bool_Exp>;
};

export type Subscription_RootRefresh_Tokens_By_PkArgs = {
  user_uuid: Scalars['uuid'];
};

export type Subscription_RootSeatsArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

export type Subscription_RootSeats_AggregateArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

export type Subscription_RootSeats_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootVideo_SourcesArgs = {
  distinct_on?: Maybe<Array<Video_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Sources_Order_By>>;
  where?: Maybe<Video_Sources_Bool_Exp>;
};

export type Subscription_RootVideo_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Sources_Order_By>>;
  where?: Maybe<Video_Sources_Bool_Exp>;
};

export type Subscription_RootVideo_Sources_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootViewer_AnalyticsArgs = {
  distinct_on?: Maybe<Array<Viewer_Analytics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Viewer_Analytics_Order_By>>;
  where?: Maybe<Viewer_Analytics_Bool_Exp>;
};

export type Subscription_RootViewer_Analytics_AggregateArgs = {
  distinct_on?: Maybe<Array<Viewer_Analytics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Viewer_Analytics_Order_By>>;
  where?: Maybe<Viewer_Analytics_Bool_Exp>;
};

export type Subscription_RootViewer_Analytics_By_PkArgs = {
  uuid: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  google_uid: Scalars['String'];
  /** An array relationship */
  seats: Array<Seats>;
  /** An aggregate relationship */
  seats_aggregate: Seats_Aggregate;
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
};

/** columns and relationships of "users" */
export type UsersSeatsArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersSeats_AggregateArgs = {
  distinct_on?: Maybe<Array<Seats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Seats_Order_By>>;
  where?: Maybe<Seats_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  google_uid?: Maybe<String_Comparison_Exp>;
  seats?: Maybe<Seats_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersGoogleUidKey = 'users_google_uid_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey',
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  google_uid?: Maybe<Scalars['String']>;
  seats?: Maybe<Seats_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  google_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  google_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  google_uid?: Maybe<Order_By>;
  seats_aggregate?: Maybe<Seats_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleUid = 'google_uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  google_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleUid = 'google_uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "video_sources" */
export type Video_Sources = {
  __typename?: 'video_sources';
  audio_segments: Scalars['jsonb'];
  constrain_is_done: Scalars['Boolean'];
  constrain_is_necessary?: Maybe<Scalars['Boolean']>;
  constrain_percent?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  drive_file_id: Scalars['String'];
  drive_md5sum: Scalars['String'];
  generate_playback_completed_count?: Maybe<Scalars['Int']>;
  generate_playback_is_done: Scalars['Boolean'];
  generate_playback_total_count?: Maybe<Scalars['Int']>;
  generate_scrub_completed_count?: Maybe<Scalars['Int']>;
  generate_scrub_is_done: Scalars['Boolean'];
  generate_scrub_total_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_is_done: Scalars['Boolean'];
  generate_thumbnails_total_count?: Maybe<Scalars['Int']>;
  generate_waveform_is_done: Scalars['Boolean'];
  ingest_failed: Scalars['Boolean'];
  owner_uuid: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_uuid: Scalars['uuid'];
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  stream_probe: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['uuid'];
  video_segments: Scalars['jsonb'];
};

/** columns and relationships of "video_sources" */
export type Video_SourcesAudio_SegmentsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "video_sources" */
export type Video_SourcesStream_ProbeArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "video_sources" */
export type Video_SourcesVideo_SegmentsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "video_sources" */
export type Video_Sources_Aggregate = {
  __typename?: 'video_sources_aggregate';
  aggregate?: Maybe<Video_Sources_Aggregate_Fields>;
  nodes: Array<Video_Sources>;
};

/** aggregate fields of "video_sources" */
export type Video_Sources_Aggregate_Fields = {
  __typename?: 'video_sources_aggregate_fields';
  avg?: Maybe<Video_Sources_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Video_Sources_Max_Fields>;
  min?: Maybe<Video_Sources_Min_Fields>;
  stddev?: Maybe<Video_Sources_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Sources_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Sources_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Sources_Sum_Fields>;
  var_pop?: Maybe<Video_Sources_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Sources_Var_Samp_Fields>;
  variance?: Maybe<Video_Sources_Variance_Fields>;
};

/** aggregate fields of "video_sources" */
export type Video_Sources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Video_Sources_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Video_Sources_Append_Input = {
  audio_segments?: Maybe<Scalars['jsonb']>;
  stream_probe?: Maybe<Scalars['jsonb']>;
  video_segments?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Video_Sources_Avg_Fields = {
  __typename?: 'video_sources_avg_fields';
  constrain_percent?: Maybe<Scalars['Float']>;
  generate_playback_completed_count?: Maybe<Scalars['Float']>;
  generate_playback_total_count?: Maybe<Scalars['Float']>;
  generate_scrub_completed_count?: Maybe<Scalars['Float']>;
  generate_scrub_total_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "video_sources". All fields are combined with a logical 'AND'. */
export type Video_Sources_Bool_Exp = {
  _and?: Maybe<Array<Video_Sources_Bool_Exp>>;
  _not?: Maybe<Video_Sources_Bool_Exp>;
  _or?: Maybe<Array<Video_Sources_Bool_Exp>>;
  audio_segments?: Maybe<Jsonb_Comparison_Exp>;
  constrain_is_done?: Maybe<Boolean_Comparison_Exp>;
  constrain_is_necessary?: Maybe<Boolean_Comparison_Exp>;
  constrain_percent?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  drive_file_id?: Maybe<String_Comparison_Exp>;
  drive_md5sum?: Maybe<String_Comparison_Exp>;
  generate_playback_completed_count?: Maybe<Int_Comparison_Exp>;
  generate_playback_is_done?: Maybe<Boolean_Comparison_Exp>;
  generate_playback_total_count?: Maybe<Int_Comparison_Exp>;
  generate_scrub_completed_count?: Maybe<Int_Comparison_Exp>;
  generate_scrub_is_done?: Maybe<Boolean_Comparison_Exp>;
  generate_scrub_total_count?: Maybe<Int_Comparison_Exp>;
  generate_thumbnails_completed_count?: Maybe<Int_Comparison_Exp>;
  generate_thumbnails_is_done?: Maybe<Boolean_Comparison_Exp>;
  generate_thumbnails_total_count?: Maybe<Int_Comparison_Exp>;
  generate_waveform_is_done?: Maybe<Boolean_Comparison_Exp>;
  ingest_failed?: Maybe<Boolean_Comparison_Exp>;
  owner_uuid?: Maybe<Uuid_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_uuid?: Maybe<Uuid_Comparison_Exp>;
  scheduled_at?: Maybe<Timestamptz_Comparison_Exp>;
  stream_probe?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
  video_segments?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_sources" */
export enum Video_Sources_Constraint {
  /** unique or primary key constraint */
  VideoSourcesPkey = 'video_sources_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Video_Sources_Delete_At_Path_Input = {
  audio_segments?: Maybe<Array<Scalars['String']>>;
  stream_probe?: Maybe<Array<Scalars['String']>>;
  video_segments?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Video_Sources_Delete_Elem_Input = {
  audio_segments?: Maybe<Scalars['Int']>;
  stream_probe?: Maybe<Scalars['Int']>;
  video_segments?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Video_Sources_Delete_Key_Input = {
  audio_segments?: Maybe<Scalars['String']>;
  stream_probe?: Maybe<Scalars['String']>;
  video_segments?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "video_sources" */
export type Video_Sources_Inc_Input = {
  constrain_percent?: Maybe<Scalars['numeric']>;
  generate_playback_completed_count?: Maybe<Scalars['Int']>;
  generate_playback_total_count?: Maybe<Scalars['Int']>;
  generate_scrub_completed_count?: Maybe<Scalars['Int']>;
  generate_scrub_total_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "video_sources" */
export type Video_Sources_Insert_Input = {
  audio_segments?: Maybe<Scalars['jsonb']>;
  constrain_is_done?: Maybe<Scalars['Boolean']>;
  constrain_is_necessary?: Maybe<Scalars['Boolean']>;
  constrain_percent?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  drive_file_id?: Maybe<Scalars['String']>;
  drive_md5sum?: Maybe<Scalars['String']>;
  generate_playback_completed_count?: Maybe<Scalars['Int']>;
  generate_playback_is_done?: Maybe<Scalars['Boolean']>;
  generate_playback_total_count?: Maybe<Scalars['Int']>;
  generate_scrub_completed_count?: Maybe<Scalars['Int']>;
  generate_scrub_is_done?: Maybe<Scalars['Boolean']>;
  generate_scrub_total_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_is_done?: Maybe<Scalars['Boolean']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Int']>;
  generate_waveform_is_done?: Maybe<Scalars['Boolean']>;
  ingest_failed?: Maybe<Scalars['Boolean']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_uuid?: Maybe<Scalars['uuid']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  stream_probe?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_segments?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Video_Sources_Max_Fields = {
  __typename?: 'video_sources_max_fields';
  constrain_percent?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  drive_file_id?: Maybe<Scalars['String']>;
  drive_md5sum?: Maybe<Scalars['String']>;
  generate_playback_completed_count?: Maybe<Scalars['Int']>;
  generate_playback_total_count?: Maybe<Scalars['Int']>;
  generate_scrub_completed_count?: Maybe<Scalars['Int']>;
  generate_scrub_total_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Int']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  project_uuid?: Maybe<Scalars['uuid']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Video_Sources_Min_Fields = {
  __typename?: 'video_sources_min_fields';
  constrain_percent?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  drive_file_id?: Maybe<Scalars['String']>;
  drive_md5sum?: Maybe<Scalars['String']>;
  generate_playback_completed_count?: Maybe<Scalars['Int']>;
  generate_playback_total_count?: Maybe<Scalars['Int']>;
  generate_scrub_completed_count?: Maybe<Scalars['Int']>;
  generate_scrub_total_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Int']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  project_uuid?: Maybe<Scalars['uuid']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "video_sources" */
export type Video_Sources_Mutation_Response = {
  __typename?: 'video_sources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Sources>;
};

/** on_conflict condition type for table "video_sources" */
export type Video_Sources_On_Conflict = {
  constraint: Video_Sources_Constraint;
  update_columns?: Array<Video_Sources_Update_Column>;
  where?: Maybe<Video_Sources_Bool_Exp>;
};

/** Ordering options when selecting data from "video_sources". */
export type Video_Sources_Order_By = {
  audio_segments?: Maybe<Order_By>;
  constrain_is_done?: Maybe<Order_By>;
  constrain_is_necessary?: Maybe<Order_By>;
  constrain_percent?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  drive_file_id?: Maybe<Order_By>;
  drive_md5sum?: Maybe<Order_By>;
  generate_playback_completed_count?: Maybe<Order_By>;
  generate_playback_is_done?: Maybe<Order_By>;
  generate_playback_total_count?: Maybe<Order_By>;
  generate_scrub_completed_count?: Maybe<Order_By>;
  generate_scrub_is_done?: Maybe<Order_By>;
  generate_scrub_total_count?: Maybe<Order_By>;
  generate_thumbnails_completed_count?: Maybe<Order_By>;
  generate_thumbnails_is_done?: Maybe<Order_By>;
  generate_thumbnails_total_count?: Maybe<Order_By>;
  generate_waveform_is_done?: Maybe<Order_By>;
  ingest_failed?: Maybe<Order_By>;
  owner_uuid?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_uuid?: Maybe<Order_By>;
  scheduled_at?: Maybe<Order_By>;
  stream_probe?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  video_segments?: Maybe<Order_By>;
};

/** primary key columns input for table: video_sources */
export type Video_Sources_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Video_Sources_Prepend_Input = {
  audio_segments?: Maybe<Scalars['jsonb']>;
  stream_probe?: Maybe<Scalars['jsonb']>;
  video_segments?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "video_sources" */
export enum Video_Sources_Select_Column {
  /** column name */
  AudioSegments = 'audio_segments',
  /** column name */
  ConstrainIsDone = 'constrain_is_done',
  /** column name */
  ConstrainIsNecessary = 'constrain_is_necessary',
  /** column name */
  ConstrainPercent = 'constrain_percent',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DriveFileId = 'drive_file_id',
  /** column name */
  DriveMd5sum = 'drive_md5sum',
  /** column name */
  GeneratePlaybackCompletedCount = 'generate_playback_completed_count',
  /** column name */
  GeneratePlaybackIsDone = 'generate_playback_is_done',
  /** column name */
  GeneratePlaybackTotalCount = 'generate_playback_total_count',
  /** column name */
  GenerateScrubCompletedCount = 'generate_scrub_completed_count',
  /** column name */
  GenerateScrubIsDone = 'generate_scrub_is_done',
  /** column name */
  GenerateScrubTotalCount = 'generate_scrub_total_count',
  /** column name */
  GenerateThumbnailsCompletedCount = 'generate_thumbnails_completed_count',
  /** column name */
  GenerateThumbnailsIsDone = 'generate_thumbnails_is_done',
  /** column name */
  GenerateThumbnailsTotalCount = 'generate_thumbnails_total_count',
  /** column name */
  GenerateWaveformIsDone = 'generate_waveform_is_done',
  /** column name */
  IngestFailed = 'ingest_failed',
  /** column name */
  OwnerUuid = 'owner_uuid',
  /** column name */
  ProjectUuid = 'project_uuid',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  StreamProbe = 'stream_probe',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VideoSegments = 'video_segments',
}

/** input type for updating data in table "video_sources" */
export type Video_Sources_Set_Input = {
  audio_segments?: Maybe<Scalars['jsonb']>;
  constrain_is_done?: Maybe<Scalars['Boolean']>;
  constrain_is_necessary?: Maybe<Scalars['Boolean']>;
  constrain_percent?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  drive_file_id?: Maybe<Scalars['String']>;
  drive_md5sum?: Maybe<Scalars['String']>;
  generate_playback_completed_count?: Maybe<Scalars['Int']>;
  generate_playback_is_done?: Maybe<Scalars['Boolean']>;
  generate_playback_total_count?: Maybe<Scalars['Int']>;
  generate_scrub_completed_count?: Maybe<Scalars['Int']>;
  generate_scrub_is_done?: Maybe<Scalars['Boolean']>;
  generate_scrub_total_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_is_done?: Maybe<Scalars['Boolean']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Int']>;
  generate_waveform_is_done?: Maybe<Scalars['Boolean']>;
  ingest_failed?: Maybe<Scalars['Boolean']>;
  owner_uuid?: Maybe<Scalars['uuid']>;
  project_uuid?: Maybe<Scalars['uuid']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  stream_probe?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_segments?: Maybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Video_Sources_Stddev_Fields = {
  __typename?: 'video_sources_stddev_fields';
  constrain_percent?: Maybe<Scalars['Float']>;
  generate_playback_completed_count?: Maybe<Scalars['Float']>;
  generate_playback_total_count?: Maybe<Scalars['Float']>;
  generate_scrub_completed_count?: Maybe<Scalars['Float']>;
  generate_scrub_total_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Video_Sources_Stddev_Pop_Fields = {
  __typename?: 'video_sources_stddev_pop_fields';
  constrain_percent?: Maybe<Scalars['Float']>;
  generate_playback_completed_count?: Maybe<Scalars['Float']>;
  generate_playback_total_count?: Maybe<Scalars['Float']>;
  generate_scrub_completed_count?: Maybe<Scalars['Float']>;
  generate_scrub_total_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Video_Sources_Stddev_Samp_Fields = {
  __typename?: 'video_sources_stddev_samp_fields';
  constrain_percent?: Maybe<Scalars['Float']>;
  generate_playback_completed_count?: Maybe<Scalars['Float']>;
  generate_playback_total_count?: Maybe<Scalars['Float']>;
  generate_scrub_completed_count?: Maybe<Scalars['Float']>;
  generate_scrub_total_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Video_Sources_Sum_Fields = {
  __typename?: 'video_sources_sum_fields';
  constrain_percent?: Maybe<Scalars['numeric']>;
  generate_playback_completed_count?: Maybe<Scalars['Int']>;
  generate_playback_total_count?: Maybe<Scalars['Int']>;
  generate_scrub_completed_count?: Maybe<Scalars['Int']>;
  generate_scrub_total_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Int']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "video_sources" */
export enum Video_Sources_Update_Column {
  /** column name */
  AudioSegments = 'audio_segments',
  /** column name */
  ConstrainIsDone = 'constrain_is_done',
  /** column name */
  ConstrainIsNecessary = 'constrain_is_necessary',
  /** column name */
  ConstrainPercent = 'constrain_percent',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DriveFileId = 'drive_file_id',
  /** column name */
  DriveMd5sum = 'drive_md5sum',
  /** column name */
  GeneratePlaybackCompletedCount = 'generate_playback_completed_count',
  /** column name */
  GeneratePlaybackIsDone = 'generate_playback_is_done',
  /** column name */
  GeneratePlaybackTotalCount = 'generate_playback_total_count',
  /** column name */
  GenerateScrubCompletedCount = 'generate_scrub_completed_count',
  /** column name */
  GenerateScrubIsDone = 'generate_scrub_is_done',
  /** column name */
  GenerateScrubTotalCount = 'generate_scrub_total_count',
  /** column name */
  GenerateThumbnailsCompletedCount = 'generate_thumbnails_completed_count',
  /** column name */
  GenerateThumbnailsIsDone = 'generate_thumbnails_is_done',
  /** column name */
  GenerateThumbnailsTotalCount = 'generate_thumbnails_total_count',
  /** column name */
  GenerateWaveformIsDone = 'generate_waveform_is_done',
  /** column name */
  IngestFailed = 'ingest_failed',
  /** column name */
  OwnerUuid = 'owner_uuid',
  /** column name */
  ProjectUuid = 'project_uuid',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  StreamProbe = 'stream_probe',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VideoSegments = 'video_segments',
}

/** aggregate var_pop on columns */
export type Video_Sources_Var_Pop_Fields = {
  __typename?: 'video_sources_var_pop_fields';
  constrain_percent?: Maybe<Scalars['Float']>;
  generate_playback_completed_count?: Maybe<Scalars['Float']>;
  generate_playback_total_count?: Maybe<Scalars['Float']>;
  generate_scrub_completed_count?: Maybe<Scalars['Float']>;
  generate_scrub_total_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Video_Sources_Var_Samp_Fields = {
  __typename?: 'video_sources_var_samp_fields';
  constrain_percent?: Maybe<Scalars['Float']>;
  generate_playback_completed_count?: Maybe<Scalars['Float']>;
  generate_playback_total_count?: Maybe<Scalars['Float']>;
  generate_scrub_completed_count?: Maybe<Scalars['Float']>;
  generate_scrub_total_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Video_Sources_Variance_Fields = {
  __typename?: 'video_sources_variance_fields';
  constrain_percent?: Maybe<Scalars['Float']>;
  generate_playback_completed_count?: Maybe<Scalars['Float']>;
  generate_playback_total_count?: Maybe<Scalars['Float']>;
  generate_scrub_completed_count?: Maybe<Scalars['Float']>;
  generate_scrub_total_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_completed_count?: Maybe<Scalars['Float']>;
  generate_thumbnails_total_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "viewer_analytics" */
export type Viewer_Analytics = {
  __typename?: 'viewer_analytics';
  created_at: Scalars['timestamptz'];
  display_name: Scalars['String'];
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_identifier: Scalars['String'];
  uuid: Scalars['uuid'];
  view_count: Scalars['numeric'];
};

/** aggregated selection of "viewer_analytics" */
export type Viewer_Analytics_Aggregate = {
  __typename?: 'viewer_analytics_aggregate';
  aggregate?: Maybe<Viewer_Analytics_Aggregate_Fields>;
  nodes: Array<Viewer_Analytics>;
};

/** aggregate fields of "viewer_analytics" */
export type Viewer_Analytics_Aggregate_Fields = {
  __typename?: 'viewer_analytics_aggregate_fields';
  avg?: Maybe<Viewer_Analytics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Viewer_Analytics_Max_Fields>;
  min?: Maybe<Viewer_Analytics_Min_Fields>;
  stddev?: Maybe<Viewer_Analytics_Stddev_Fields>;
  stddev_pop?: Maybe<Viewer_Analytics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Viewer_Analytics_Stddev_Samp_Fields>;
  sum?: Maybe<Viewer_Analytics_Sum_Fields>;
  var_pop?: Maybe<Viewer_Analytics_Var_Pop_Fields>;
  var_samp?: Maybe<Viewer_Analytics_Var_Samp_Fields>;
  variance?: Maybe<Viewer_Analytics_Variance_Fields>;
};

/** aggregate fields of "viewer_analytics" */
export type Viewer_Analytics_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Viewer_Analytics_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Viewer_Analytics_Avg_Fields = {
  __typename?: 'viewer_analytics_avg_fields';
  view_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "viewer_analytics". All fields are combined with a logical 'AND'. */
export type Viewer_Analytics_Bool_Exp = {
  _and?: Maybe<Array<Viewer_Analytics_Bool_Exp>>;
  _not?: Maybe<Viewer_Analytics_Bool_Exp>;
  _or?: Maybe<Array<Viewer_Analytics_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_identifier?: Maybe<String_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
  view_count?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "viewer_analytics" */
export enum Viewer_Analytics_Constraint {
  /** unique or primary key constraint */
  ViewerAnalyticsPkey = 'viewer_analytics_pkey',
  /** unique or primary key constraint */
  ViewerAnalyticsProjectIdUserIdentifierKey = 'viewer_analytics_project_id_user_identifier_key',
}

/** input type for incrementing numeric columns in table "viewer_analytics" */
export type Viewer_Analytics_Inc_Input = {
  view_count?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "viewer_analytics" */
export type Viewer_Analytics_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_identifier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  view_count?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Viewer_Analytics_Max_Fields = {
  __typename?: 'viewer_analytics_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_identifier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  view_count?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Viewer_Analytics_Min_Fields = {
  __typename?: 'viewer_analytics_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_identifier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  view_count?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "viewer_analytics" */
export type Viewer_Analytics_Mutation_Response = {
  __typename?: 'viewer_analytics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Viewer_Analytics>;
};

/** on_conflict condition type for table "viewer_analytics" */
export type Viewer_Analytics_On_Conflict = {
  constraint: Viewer_Analytics_Constraint;
  update_columns?: Array<Viewer_Analytics_Update_Column>;
  where?: Maybe<Viewer_Analytics_Bool_Exp>;
};

/** Ordering options when selecting data from "viewer_analytics". */
export type Viewer_Analytics_Order_By = {
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_identifier?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  view_count?: Maybe<Order_By>;
};

/** primary key columns input for table: viewer_analytics */
export type Viewer_Analytics_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "viewer_analytics" */
export enum Viewer_Analytics_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserIdentifier = 'user_identifier',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  ViewCount = 'view_count',
}

/** input type for updating data in table "viewer_analytics" */
export type Viewer_Analytics_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_identifier?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  view_count?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Viewer_Analytics_Stddev_Fields = {
  __typename?: 'viewer_analytics_stddev_fields';
  view_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Viewer_Analytics_Stddev_Pop_Fields = {
  __typename?: 'viewer_analytics_stddev_pop_fields';
  view_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Viewer_Analytics_Stddev_Samp_Fields = {
  __typename?: 'viewer_analytics_stddev_samp_fields';
  view_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Viewer_Analytics_Sum_Fields = {
  __typename?: 'viewer_analytics_sum_fields';
  view_count?: Maybe<Scalars['numeric']>;
};

/** update columns of table "viewer_analytics" */
export enum Viewer_Analytics_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserIdentifier = 'user_identifier',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  ViewCount = 'view_count',
}

/** aggregate var_pop on columns */
export type Viewer_Analytics_Var_Pop_Fields = {
  __typename?: 'viewer_analytics_var_pop_fields';
  view_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Viewer_Analytics_Var_Samp_Fields = {
  __typename?: 'viewer_analytics_var_samp_fields';
  view_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Viewer_Analytics_Variance_Fields = {
  __typename?: 'viewer_analytics_variance_fields';
  view_count?: Maybe<Scalars['Float']>;
};
