import { Instance, types } from 'mobx-state-tree';
import { IClip, IStillSource, StillSourceModel } from '../..';
import ClipModel from '../project/scene/clip.mst';
import { getSelection } from '../selection/selection.mst';

/**
 * State, views, & actions backing the still clip tool
 */

const StillClipToolModel = types
  .model('StillClipTool', {})
  .views((self) => {
    return {
      /**
       * Convenience getter for the clip being edited.
       */
      get targetClip(): IClip {
        const clip = getSelection(self).selectedClip;
        if (!clip) throw new Error('targetClip accessed when no clip selected');
        if (!ClipModel.is(clip))
          throw new Error('targetClip accessed with video clip not selected');
        return clip;
      },
      /**
       * Convenience getter for the soure of the clip being edited.
       */
      get targetSource(): IStillSource {
        const source = this.targetClip.source;
        if (!StillSourceModel.is(source))
          throw new Error('targetSource accessed when video clip not selected');
        return source;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Wrapping around the base models update color function to
       * allow for singular updates to the update stack via above life cycle functions
       */
      updateBackgroundColor(newColor: string): void {
        self.targetSource.updateColor(newColor);
      },
    };
  });
export default StillClipToolModel;

export interface IStillClipTool extends Instance<typeof StillClipToolModel> {}
