import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import RootModel from '../root.mst';

/**
 * State, views, & actions backing  the Debugger.
 */
const DebuggerModel = types
  .model('DebuggerModel', {
    debugMode: types.optional(types.boolean, false),
  })
  .actions((self) => {
    return {
      toggleDebugMode: () => {
        self.debugMode = !self.debugMode;
      },
    };
  });
export default DebuggerModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getDebugger(self: IAnyStateTreeNode): IDebugger {
  const root = getParentOfType(self, RootModel);
  return root.debugger;
}
export interface IDebugger extends Instance<typeof DebuggerModel> {}
