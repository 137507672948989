import axios, { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { environment } from '@castify/studio/env/browser';
import { useGetJwt } from './CurrentUserProvider';
import { getAnonymousUserIdentifier } from '@castify/studio/fe-common';

/**
 * Hook that returns an instance of axios.
 *
 * If there is a current logged in user, requests will be intercepted and
 * a JWT for the user will be set as their Authorization bearer token.
 *
 * Otherwise, the axios client will make unauthorized requests.
 *
 * If you absolutely do not want to make authorized requests, use
 * axios directly (import axios from 'axios')
 */
export const useHttpClient = () => {
  const [httpClient] = useState<AxiosInstance>(() => axios.create());
  const getJwt = useGetJwt();

  useEffect(() => {
    const authInterceptor = httpClient.interceptors.request.use(
      async (config) => {
        if (config.url?.startsWith(environment.serviceUrl)) {
          config.headers ||= {};
          const jwt = await getJwt();
          config.headers.Authorization ||= `Bearer ${jwt}`;
          config.headers.userIdentifier = getAnonymousUserIdentifier();
        }
        return config;
      },
    );
    return () => {
      httpClient.interceptors.request.eject(authInterceptor);
    };
  }, [getJwt, httpClient]);

  return httpClient;
};
