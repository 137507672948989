import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  container: css`
    height: 30px;
    justify-content: space-around;
  `,
  zoom: css`
    z-index: 3;
    margin-top: 35px;
    border: 3px solid ${colors.green};
    background-color: ${colors.greenSeethru};
  `,
  blur: css`
    z-index: 2;
    border: 3px solid ${colors.magenta};
    background-color: ${colors.magentaSeethru};
  `,
};

export default styles;
