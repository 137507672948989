import { Slider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useMst } from '@castify/studio/studio-store';

import styles from './SeekBar.styles';

function SeekBar() {
  const {
    project: {
      scene: { mainTrack },
    },
    playback: {
      isPlaying,
      pause,
      playheadPosition,
      setPlaybackClock,
      setSeekTime,
    },
  } = useMst();

  const duration = mainTrack.totalDuration;

  const handleChange = (e: Event, value: number | number[]) => {
    // TODO: ongoing playback during seek is temporarily disabled
    // due to a bug in the change from shaka to vjs
    if (isPlaying) pause();
    const newValue = Array.isArray(value) ? value[value.length - 1] : value;
    setPlaybackClock(newValue);
    setSeekTime(newValue);
  };

  return (
    <Slider
      css={styles.seekBar}
      min={0}
      max={duration}
      value={playheadPosition}
      step={0.1}
      onChange={handleChange}
      aria-label="SeekBar"
      data-testid="seekbar"
      data-pendo-id="player-seekbar"
    />
  );
}

export default observer(SeekBar);
