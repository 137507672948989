import { baseEnvironment } from './environment.base';
import type { Environment } from './environment';
import { overwriteEnvironment } from './environment.overwrite';

export const environment: Environment = {
  ...baseEnvironment,
  production: true,
  environmentName: 'staging',
  serviceUrl:
    'https://studio-backend.staging.castifydev.com/api/studioService/',
  httpHasuraUrl: 'https://studio-graphql.staging.castifydev.com/v1/graphql',
  websocketHasuraUrl: 'wss://studio-graphql.staging.castifydev.com/v1/graphql',
  dataDogClientToken: 'pub4005fa5b9b4b6e7a869453c241fc771e',
  dataDogApplicationId: 'd45a5e8f-84fb-4faa-b295-fcae05acc096',
  googleAuth: {
    scopes: ['https://www.googleapis.com/auth/drive.file'],
    clientId:
      '741471751866-vlfbvujeo7de30i720k40lu5gqcpkmj8.apps.googleusercontent.com',
    driveAppId: '741471751866', // staging
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDuHz-96qS5J-_TkIO4Ge81IwmtsXnVToI',
    authDomain: 'castify-storage-staging.firebaseapp.com',
    databaseURL: 'https://castify-storage-staging.firebaseio.com',
    projectId: 'castify-storage-staging',
    storageBucket: 'castify-storage-staging.appspot.com',
    messagingSenderId: '220257450312',
    appId: '1:220257450312:web:72cf306162ddc5f30a9b7d',
    measurementId: 'G-GJF4ED1M8N',
  },
  externalLinks: {
    accountLink: 'https://app.staging.screencastify.com/account',
    myVideoLink: 'https://account.screencastify.com/user/launch/',
    supportLink:
      'https://learn.screencastify.com/hc/en-us/categories/360003891334-Edit',
  },

  // overwrite url endpoints
  ...overwriteEnvironment,
};
