import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

//Initial values in all styles will be mobile first and overridden by media queries
const styles = {
  seekBar: css`
    width: 100%;
    color: ${colors.TEMP.SliderYellow};
    margin-bottom: 2%;
    .MuiSlider-rail {
      color: ${colors.gray[50]};
    }
    .MuiSlider-track,
    .MuiSlider-rail {
      height: 10px;
    }
    .MuiSlider-track,
    .MuiSlider-rail,
    .MuiSlider-thumb {
      transition-duration: 0s;
    }
  `,
};
export default styles;
