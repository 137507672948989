import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  container: css`
    background-color: ${colors.gray[750]};
    color: ${colors.gray[0]};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  titleSection: css`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid grey;
    margin: 0px 10px 0px 10px;
    padding: 12px 0px 14px 0px;
    h2 {
      font-family: Helvetica;
      font-size: 2em;
      margin: 2px 0px 0px 0px;
    }
  `,
  icon: css`
    font-size: 2em;
    margin: 0px 8px 0px 5px;
  `,
  iconBorder: css`
    border: solid white 2px;
    border-radius: 4px;
  `,
  centerSection: css`
    box-sizing: border-box;
    padding: 1em;
    height: 100%;
    width: 100%;
    h4 {
      font-family: Helvetica;
      margin: 2px 0px 0px 0px;
      padding-bottom: 10px;
    }
  `,
  centerSectionButton: css`
    color: ${colors.gray[0]};
    background: ${colors.gray[900]};
    border: 1px solid ${colors.gray[0]};
    border-radius: 25px;
    margin-bottom: 15px;
    width: 100%;
    height: 50px;
    font-size: medium;
    font-weight: 700;
  `,
  sliderSection: css`
    margin: 0 5px 20px 5px;
    h3 {
      font-family: Helvetica;
      font-size: 1em;
      margin: 0px 0px 10px 0px;
    }
  `,
  sliderContainer: css`
    margin: 0 10px 0 10px;
  `,
  sliderLabels: css`
    font-family: Helvetica;
    display: flex;
    width: 100%;
    justify-content: space-between;
  `,
  bottomSection: css``,
  doneButton: css`
    width: 70%;
    margin: 15%;
    padding: 5px;
    background-color: ${colors.gray[0]};
    border: 2px solid ${colors.primary};
    color: ${colors.primary};
    font-size: 1.1em;
    font-weight: bold;

    &:hover {
      background-color: ${colors.gray[200]};
    }
  `,
  swatchContainer: css`
    display: grid;
    width: 100%;
    justify-content: center;
    grid-template-columns: repeat(6, 12px);
    gap: 15px 18px;
    place-items: center center;
    margin-left: auto;
    margin-right: auto;
  `,
  swatch: css`
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: inline-block;
    border: none;
    cursor: pointer;

    &:hover {
      border: 2px solid grey;
    }

    &:focus {
      border: 2px solid grey;
      outline: none;
    }

    &.selected {
      // Takes precedence over the hover border
      border: 2px solid white;

      // this is a sepcial case: the white swatch
      // requires a drop shadow for its selection
      // ring to be visible
      &.white {
        box-shadow: inset 1px 1px 3px #333;
      }
    }
  `,
  NumberInputStyle: css`
    display: flex;
    flex-direction: row;
    margin: 0 20px;
    gap: 40px;
    & .Mui-focused input {
      border: 4px solid ${colors.pink};
      box-sizing: border-box;
      border-radius: 4px;
      z-index: 200;
    }

    & .MuiOutlinedInput-root {
      background: white;
    }

    & .MuiOutlinedInput-input {
      padding: 0;
      height: 40px;
    }
  `,
};
export default styles;
