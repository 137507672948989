import React, { Fragment } from 'react';
/**
 * Individual/default  imports from material allow for tree shake when the component is used.
 * Please maintain this pattern since these components are intended to be
 * reusable for other forms.
 */
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { InputRadioProps, RadioOption } from '../InputProps';
import { SerializedStyles } from '@emotion/react';

const createRadioOption = (
  options: Array<RadioOption>,
  value: RadioOption['value'],
  cssRadio?: SerializedStyles,
  cssRadioHelperText?: SerializedStyles,
) => {
  return options.map((radioOption) => {
    return (
      <Fragment key={radioOption.value}>
        <FormControlLabel
          checked={value === radioOption.value}
          value={radioOption.value}
          label={radioOption.label}
          control={<Radio css={cssRadio} />}
        />
        {radioOption.helperText && (
          <FormHelperText
            key={radioOption.value + 'help-text'}
            css={cssRadioHelperText}
          >
            {radioOption.helperText}
          </FormHelperText>
        )}
      </Fragment>
    );
  });
};

export const FormRadioInput = ({
  name,
  label,
  options,
  cssRadioGroup,
  cssRadio,
  cssRadioHelperText,
}: InputRadioProps) => {
  const { control } = useFormContext();

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <RadioGroup css={cssRadioGroup} value={value} onChange={onChange}>
              {createRadioOption(options, value, cssRadio, cssRadioHelperText)}
            </RadioGroup>
          );
        }}
      />
    </FormControl>
  );
};
