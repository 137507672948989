import { observer } from 'mobx-react-lite';
import { Clip, StillSourceModel, useMst } from '@castify/studio/studio-store';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import sidebarCommon from './sidebarCommon.styles';

/**
 * Right sidebar for effect and tool settings
 */
const COLOR_CHOICES: Array<string> = [
  '#000000',
  '#575757',
  '#B4AFAF',
  '#00D1FF',
  '#0066cc',
  '#022069',
  '#AF7302',
  '#EA6503',
  '#ff9900',
  '#ffff00',
  '#92FE00',
  '#008a00',
  '#096305',
  '#e60000',
  '#FF00D8',
  '#9933ff',
  '#440178',
  '#ffffff',
];
function StillClipTool() {
  const {
    toolManager: { closeTool, stillClipToolIfOpen },
  } = useMst();

  return (
    <div css={sidebarCommon.container}>
      <div css={sidebarCommon.titleSection}>
        <Icon css={[sidebarCommon.icon, sidebarCommon.iconBorder]}>
          title-clips-icon-white
        </Icon>
        <h2>Title</h2>
      </div>
      <div css={sidebarCommon.centerSection}>
        <h4>Background</h4>
        <div css={sidebarCommon.swatchContainer}>
          {COLOR_CHOICES.map((color: string) => {
            return (
              <button
                className={
                  stillClipToolIfOpen?.targetSource.color === color
                    ? 'selected'
                    : ''
                }
                key={color}
                css={sidebarCommon.swatch}
                style={{ background: color }}
                onClick={() => {
                  stillClipToolIfOpen?.updateBackgroundColor(color);
                }}
              />
            );
          })}
        </div>
      </div>
      <div css={sidebarCommon.bottomSection}>
        <Button
          variant="text"
          css={sidebarCommon.doneButton}
          onClick={() => closeTool()}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default observer(StillClipTool);
