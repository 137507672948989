import { st } from '@castify/studio/fe-common';
import { useRaf } from 'rooks';

/**
 * This wrapper over useRaf exists just to facilitate import mocking in a test
 * context
 */
export const usePlaybackRaf = (
  callback: (timePassed: st.ms) => void,
  enabled: boolean,
) => {
  useRaf(callback, enabled);
};
