import Dialog from '@mui/material/Dialog';
import { useEffect } from 'react';
import { useDrivePicker } from './useDrivePicker';

/**
 * Driver picker component props
 */
export interface DrivePickerProps {
  onPickerCancel: () => void;
  onFilePicked: (driveFileId: string) => void;
  onFlowError: (error: unknown) => void;
}

/**
 * This component shows the drive picker on top of an empty modal overlay so
 * that the screen behind the picker darkens
 */
function DrivePicker({
  onPickerCancel,
  onFilePicked,
  onFlowError,
}: DrivePickerProps) {
  /**
   * Show drive picker
   */
  const { videoPickerRef, error, driveFileId, pickerClosed } = useDrivePicker();

  /**
   * Pass errors to parent
   */
  useEffect(() => {
    if (error) {
      onFlowError(error);
    }
  }, [error, onFlowError]);

  /**
   * Handle file being picked
   */
  useEffect(() => {
    if (driveFileId) {
      onFilePicked(driveFileId);
    }
  }, [driveFileId, onFilePicked]);

  /**
   * When picker is closed via its close button, pass the event to parent
   * to close the modal
   */
  useEffect(() => {
    if (pickerClosed) {
      onPickerCancel();
    }
  }, [pickerClosed, onPickerCancel]);

  /**
   * Close the picker on overlay clicks
   */
  const onBackdropClick = () => {
    if (videoPickerRef.current) {
      videoPickerRef.current.dispose();
      onPickerCancel();
    }
  };

  /**
   * Render an empty modal overlay behind the picker
   */
  return (
    <Dialog
      open={true}
      onBackdropClick={onBackdropClick}
      // disable transition to prevent white flashes between modals
      transitionDuration={0}
    ></Dialog>
  );
}

export default DrivePicker;
