import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
  icon: css`
    margin-left: 0;
  `,
  button: css`
    display: flex;
    justify-content: center;
    height: 40px;
    margin: 0 0.5em;
    width: 100px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
  `,
  connectToDriveButton: css`
    width: 220px;
  `,
};
