import { Instance, types } from 'mobx-state-tree';
import { IBlurEffect } from '../project/scene/blurEffect.mst';
import { getSelection } from '../selection/selection.mst';
import BlurBoxModel, { IBlurBox } from '../project/scene/blurBox.mst';
import { st } from '@castify/studio/fe-common';

// can have perf implications if too high
export const MAX_NUMBER_OF_BLUR_BOXES = 20;

/**
 * State, views, & actions backing the blur tool
 */
const BlurToolModel = types
  .model('BlurToolModel', {
    selectedBox: types.maybe(types.reference(BlurBoxModel)),
  })
  .views((self) => {
    return {
      /**
       * Convenience getter for the effect being edited.
       */
      get targetClip(): IBlurEffect {
        const clip = getSelection(self).selectedClip;
        if (!clip) throw new Error('targetClip accessed when no clip selected');

        /**
         * Safe to make this assertion (hopefully) as the tool can only
         * be opened when blur clips are selected.
         *
         * We should use a type guard that throws here, but importing
         * BlurEffectModel and calling its `is()` member resulted in some webpack
         * issues. Therefore:
         * TODO: find a way to avoid coercion here
         */
        return clip as unknown as IBlurEffect;
      },
      /**
       * are we at the cap already?
       */
      get addBoxButtonEnabled(): boolean {
        return this.targetClip.boxes.length < MAX_NUMBER_OF_BLUR_BOXES;
      },
      /**
       * Convenience getter
       */
      get selectedBoxIntensity(): st.ratio {
        if (self.selectedBox) {
          return self.selectedBox.intensity;
        }
        return 0;
      },
      /**
       * Can we delete any boxes in this clip? Since there's always at least
       * one blur box in a blur clip, the answer is "no" if we only have one
       * box left
       */
      get downToOneBox(): boolean {
        return this.targetClip.boxes.length <= 1;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Selects a blur box
       */
      selectBox(box: IBlurBox): void {
        self.selectedBox = box;
      },
      /**
       * Adds a new blur box and selects it, but only if under the cap
       */
      addAndSelectBlurBox(): void {
        if (!self.addBoxButtonEnabled) return;
        const newBox = self.targetClip.addBlurBox();
        self.selectedBox = newBox;
      },
      /**
       * Change the intensity of the selected blur box
       */
      changeIntensity(value: st.ratio): void {
        if (self.selectedBox) self.selectedBox.setIntensity(value);
      },
      /**
       * Delete the selected box, selecting whatever box is prior to it
       * in the stack, rotating to the top of the stack when we hit the bottom.
       * Disallows deleting the last box.
       */
      deleteSelectedBox(): void {
        if (!self.selectedBox || self.downToOneBox) return;
        const oldSelectedBox = self.selectedBox;
        const oldSelectedBoxIdx = self.targetClip.boxes.indexOf(oldSelectedBox);

        // unselect so we don't accidentally end up with a stale MST ref
        // after destroying the box
        self.selectedBox = undefined;
        self.targetClip.deleteBox(oldSelectedBox);

        // select the box prior to the deleted box, rotating around to top of
        // stack if needed
        const nextBoxIdx =
          oldSelectedBoxIdx > 0
            ? oldSelectedBoxIdx - 1
            : self.targetClip.boxes.length - 1;
        self.selectedBox = self.targetClip.boxes[nextBoxIdx];
      },

      /**
       * Lifecycle hook
       */
      afterAttach(): void {
        // initialize the effect with a blur box if it doesn't already have one
        if (self.targetClip.boxes.length === 0) {
          this.addAndSelectBlurBox();
        }

        // if we already have some boxes, select the last one added
        if (self.targetClip.boxes.length) {
          self.selectedBox =
            self.targetClip.boxes[self.targetClip.boxes.length - 1];
        }
      },
    };
  });
export default BlurToolModel;

export interface IBlurTool extends Instance<typeof BlurToolModel> {}
