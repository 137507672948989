import { css } from '@emotion/react';

export const styles = {
  videoElmsInvisible: css`
    display: none;
  `,
  videoElmsVisible: css`
    display: inherit;
    box-sizing: content-box;
    padding: 10px;
    border: 2px solid grey;
    border-radius: 5px;
    margin: 10px 0 0 10px;
    width: 200px;
    height: 450px;
    position: fixed;
    background-color: white;
    top: 80px;
    left: 0px;
    z-index: 1500;
    display: flex;
    flex-direction: column;

    /* videojs replaces video elm with div */
    /* the classname is set in the jsx */
    div.vjs-player {
      width: 200px;
      height: 112px;

      video {
        width: 200px;
        height: 112px;
      }
    }
  `,
};
