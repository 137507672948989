import { css } from '@emotion/react';

const styles = {
  unlimitedLogo: (height: number, withText: boolean) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    height: ${height}px;
    width: ${withText ? '209px' : '45px'};
  `,
};
export default styles;
