import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { styles } from './FlowDialog.styles';

/**
 * Props for the progress dialog component
 */
interface ProgressDialogProps {
  /**
   * Title of the progress bar
   */
  title: string;
  /**
   *  Should the progress bar be determinate or indeterminate?
   */
  determinate: boolean;
  /**
   * Value to display; should be between 0 and 100
   */
  value?: number;
  /**
   * React children to render inside the dialog; intended to be used
   * to inject extra messages (e.g debug content).
   */
  children?: React.ReactNode;
}

/**
 * Shared component which centralizes markup and styles for the progress dialog
 * displayed by various steps in the ingress flows.
 */
function ProgressDialog({
  title,
  determinate,
  value,
  children,
}: ProgressDialogProps) {
  return (
    <Dialog
      open={true}
      // disable transition to prevent white flashes between modals
      transitionDuration={0}
      css={styles.showTopNav}
    >
      <DialogTitle css={styles.title} data-testid="modal-title">
        {title}
      </DialogTitle>
      <DialogContent css={styles.content}>
        <div>
          <LinearProgress
            variant={determinate ? 'determinate' : 'indeterminate'}
            value={value}
          />
        </div>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ProgressDialog;
