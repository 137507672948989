import { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';

import Player from '@castify/studio/Player';
import { useMst } from '@castify/studio/studio-store';
import styles from './WatchPlayer.styles';
import { useErrorHandler } from 'react-error-boundary';
import { createBrowserLogger } from '@castify/studio/observability/browser';
import type { IBrowserLogger } from '@castify/studio/observability/browser';
import { useMutation } from 'urql';
import {
  UpsertViewerAnalyticsDocument,
  UpsertViewerAnalyticsMutation,
  UpsertViewerAnalyticsMutationVariables,
} from '@castify/studio/database-clients/graphql';
import SeekBar from './players-common/SeekBar/SeekBar';
import LargePlayButton from './players-common/LargePlayButton/LargePlayButton';
import WatchPlayerFooter from './players-common/WatchPlayerFooter/WatchPlayerFooter';
import TitleDisplay from './players-common/TitleDisplay/TitleDisplay';
import Skeleton from '@mui/material/Skeleton';

interface WatchPlayerProps {
  showSkeleton?: boolean;
}

function WatchPlayer({ showSkeleton }: WatchPlayerProps) {
  const logger: IBrowserLogger = createBrowserLogger('ViewCounter');
  const handleError = useErrorHandler();

  const {
    project: { uuid },
    playback: { isPlaying, isFullScreen },
    viewer,
    analytics: { isAnalyticsEnabled },
  } = useMst();
  /**
   * Call to the database to either insert a new entry or update an
   * existing entry. This is used to track the viewer analytics.
   *
   * TODO: Add db testing for this mutation when infrastructure is ready.
   */

  const [{ error }, executeMutation] = useMutation<
    UpsertViewerAnalyticsMutation,
    UpsertViewerAnalyticsMutationVariables
  >(UpsertViewerAnalyticsDocument);

  const videoWrapperRef = useRef(null);
  //Internal state for hover and timeout id
  const [isHovered, setIsHovered] = useState(false);
  const [timeOutId, setTimeOutId] = useState(0);

  //condition used to determine if we show controls
  const isBottomControlsVisible = isHovered || !isPlaying;
  const isOverlayVisible = isHovered || !isPlaying;

  useEffect(() => {
    //on pageload we want to show the controls so we are simulating a hover
    setIsHovered(true);
  }, []);

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  function handleMouseMove() {
    clearTimeout(timeOutId as unknown as NodeJS.Timeout);
    if (isFullScreen) {
      setIsHovered(true);
      const timeOut = setTimeout(() => {
        setIsHovered(false);
      }, 2000) as unknown as number;
      setTimeOutId(timeOut);
    }
  }

  // This is used to determine whether a "new view" event has happened
  // for viewer analytics.
  const [hasPlayed, setHasPlayed] = useState(false);

  // This is used to determine whether or not to initiate a "new view" event
  // for viewer analytics. It is called when the video is played for the first time
  // on a single load only. Reloads will count as an additional view.
  function onPlay() {
    if (!hasPlayed && isAnalyticsEnabled) {
      initiateViewCount();
      setHasPlayed(true);
    }
  }

  /**
   * When a video is played for the first time, we want to record a new view,
   * this makes a call to the database to perform an upsert.
   */
  function initiateViewCount(): void {
    executeMutation({
      projectId: uuid,
      userIdentifier: viewer.userIdentifier,
      displayName: viewer.displayName,
    });
    if (error) {
      logger.error('Error performing viewer analytics upsert');
      handleError(error);
    }
  }

  return (
    <div
      css={[styles.videoWrapper, !isFullScreen && styles.videoWrapperBorder]}
      ref={videoWrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      data-testid="watch-player"
      data-pendo-id="watch-player"
    >
      {showSkeleton ? (
        // Skeleton is huuuge and overflows container on purpose
        <Skeleton variant="rectangular" css={styles.skeletonSquare} />
      ) : (
        <>
          {/* Video Player */}
          <Player />

          {/* Overlay Buttons */}
          <div
            css={[
              styles.controls,
              !isBottomControlsVisible && styles.hideControls,
            ]}
            data-testid="controls"
          >
            <div css={styles.titleDisplay}>
              <TitleDisplay />
            </div>

            <div css={styles.playButtonLarge} onPointerDown={onPlay}>
              {!isPlaying && <LargePlayButton />}
            </div>

            <div css={styles.bottomControls}>
              <SeekBar />
              <WatchPlayerFooter
                videoWrapperRef={videoWrapperRef}
                onPlay={onPlay}
              />
            </div>
          </div>

          {/* Overlay Gradient */}
          {isOverlayVisible && (
            <div css={[styles.overlay, isFullScreen && styles.overlayBorder]} />
          )}
        </>
      )}
    </div>
  );
}

export default observer(WatchPlayer);
