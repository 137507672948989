import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import { getPlayback } from '../playback/playback.mst';
import AudioClipModel from '../project/scene/audioClip.mst';
import BlurEffectModel from '../project/scene/blurEffect.mst';
import ClipModel, { IClip } from '../project/scene/clip.mst';
import { SelectableClip } from '../project/scene/sceneTypes';
import StillSourceModel from '../project/scene/stillSource.mst';
import TextClipModel from '../project/scene/textClip.mst';
import VideoSourceModel from '../project/scene/videoSource.mst';
import ZoomEffectModel from '../project/scene/zoomEffect.mst';
import RootModel from '../root.mst';
import { getToolManager } from '../tools/toolManager.mst';
import { ToolNames } from '../tools/tools.types';

type ISelectVolatileState = {
  selectedClip: SelectableClip | null;
};

/**
 * What clips if any are selected? What "sub-states" of clip selection are
 * active?
 */
const SelectionModel = types
  .model('SelectionModel', {})
  .volatile((): ISelectVolatileState => {
    return {
      selectedClip: null,
    };
  })
  .views((self) => {
    return {
      /**
       * Is the selected clip a video clip?
       */
      get isVideoClipSelected(): boolean {
        return (
          !!self.selectedClip &&
          ClipModel.is(self.selectedClip) &&
          VideoSourceModel.is(self.selectedClip.source)
        );
      },
      /**
       * Is the selected clip a blur effect?
       */
      get isBlurEffectSelected(): boolean {
        return !!self.selectedClip && BlurEffectModel.is(self.selectedClip);
      },
      /**
       * Is the selected clip a zoom effect?
       */
      get isZoomEffectSelected(): boolean {
        return !!self.selectedClip && ZoomEffectModel.is(self.selectedClip);
      },
      /**
       * Is a still clip selected?
       */
      get isStillClipSelected(): boolean {
        return (
          !!self.selectedClip &&
          ClipModel.is(self.selectedClip) &&
          StillSourceModel.is(self.selectedClip.source)
        );
      },
      /**
       * Is a text clip selected?
       */
      get isTextClipSelected(): boolean {
        return !!self.selectedClip && TextClipModel.is(self.selectedClip);
      },
      /**
       * Is detached audio clip selected?
       */
      get isDetachedAudioSelected(): boolean {
        return !!self.selectedClip && AudioClipModel.is(self.selectedClip);
      },
      /**
       * Useful for referring to the main track clip "under" a selected effect,
       * when there is such a thing.
       */
      get selectedClipParentClip(): IClip | undefined {
        if (ClipModel.is(self.selectedClip)) return self.selectedClip;
        if (
          ZoomEffectModel.is(self.selectedClip) ||
          BlurEffectModel.is(self.selectedClip)
        ) {
          return getParentOfType(self.selectedClip, ClipModel);
        }
        return undefined;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Selects a clip, opening tools when appropriate
       */
      selectClip(clip: SelectableClip | null): void {
        // selection change not allowed when playing
        if (getPlayback(self).isPlaying) return;

        // early return when the clip passed in is already selected
        // prevents clicking on clips from closing their tools if tools
        // are already open
        if (clip === self.selectedClip) return;

        // make the selection
        self.selectedClip = clip;

        // Imperatively open tools for some clip types.
        // we do not handle crop and audio here as we do not know just from
        // selecting a video clip whether we can open these tools.
        const tools = getToolManager(self);
        if (!clip) tools.closeTool();
        else if (ClipModel.is(clip) && VideoSourceModel.is(clip.source))
          tools.closeTool();
        // NOTE: PREVENTS BUG UNTIL AUDIO OVERLAY IS IMPLEMENTED
        else if (AudioClipModel.is(clip)) tools.closeTool();
        else if (TextClipModel.is(clip)) tools.openTool(ToolNames.TextTool);
        else if (ClipModel.is(clip) && StillSourceModel.is(clip.source))
          tools.openTool(ToolNames.StillClipTool);
        else if (ZoomEffectModel.is(clip)) tools.openTool(ToolNames.ZoomTool);
        else if (BlurEffectModel.is(clip)) tools.openTool(ToolNames.BlurTool);
      },
      /**
       * Clears selection state
       */
      clearSelection(): void {
        this.selectClip(null);
      },
    };
  });
export default SelectionModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getSelection(self: IAnyStateTreeNode): ISelection {
  const root = getParentOfType(self, RootModel);
  return root.selection;
}
export interface ISelection extends Instance<typeof SelectionModel> {}
