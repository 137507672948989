import { WatchImportProgressSubscription } from '@castify/studio/database-clients/graphql';
import Typography from '@mui/material/Typography';

interface DebugProgressProps {
  videoSourceRow: WatchImportProgressSubscription | undefined;
}

export function DebugProgress({ videoSourceRow }: DebugProgressProps) {
  if (!videoSourceRow) return null;
  if (!videoSourceRow.video_sources_by_pk) return null;

  const {
    generate_playback_completed_count,
    generate_playback_total_count,
    generate_scrub_completed_count,
    generate_scrub_total_count,
    generate_thumbnails_completed_count,
    generate_thumbnails_total_count,
    stream_probe,
  } = videoSourceRow.video_sources_by_pk;

  return (
    <div style={{ marginTop: `2em` }}>
      <Typography>
        Is stream probe complete:{' '}
        {Object.keys(stream_probe).length === 0 ? 'no' : 'yes'}
      </Typography>
      <Typography>
        Playback: {generate_playback_completed_count ?? 0} of{' '}
        {generate_playback_total_count ?? 0} tasks complete
      </Typography>
      <Typography>
        Scrub: {generate_scrub_completed_count ?? 0} of{' '}
        {generate_scrub_total_count ?? 0} tasks complete
      </Typography>
      <Typography>
        Thumbnails: {generate_thumbnails_completed_count ?? 0} of{' '}
        {generate_thumbnails_total_count ?? 0} tasks complete
      </Typography>
    </div>
  );
}
