import { useMst } from '@castify/studio/studio-store';
import { autorun } from 'mobx';
import { useEffect, useRef } from 'react';

type useScaledOverlayOptions = {
  /**
   * useScaledCanvas has two scaling/sizing modes: with and without a
   * css transform.
   *
   * 1. `withTransform` mode sizes the overlay container to the full
   * logical size of the scene, using a css transform to scale it back down
   * to the available room. This works just like the preview canvas.
   *
   * 2. When `withTransform` mode is off, the dimensions are scaled down
   * to the available space prior to being applies to the container.
   *
   * These modes are necessary because some effects (text!) depend on rendering
   * content into a div at the full logical size, while others do not (blur,
   * zoom, crop). As the former mode adds more complexity elsewhere in the
   * application, it is disabled where not needed
   */
  withTransform: boolean;
};

export const useScaledOverlay = ({
  withTransform,
}: useScaledOverlayOptions) => {
  const {
    player: preview,
    project: { scene },
  } = useMst();

  const overlayContainerRef = useRef<HTMLDivElement>(null);

  /**
   * The overlay's size is subscribed to the scene dimensions
   * is the canvas is scaled to fit within the preiew container via css
   * transforms
   *
   * With, height, and scaleFactor are observable and change when window
   * resizes and also when the scene's dimensions chage
   */
  useEffect(() => {
    const disposer = autorun(() => {
      const { width, height } = scene.canvasDimensions;
      const { scaleFactor } = preview;
      if (overlayContainerRef.current) {
        if (withTransform) {
          overlayContainerRef.current.style.width = width + 'px';
          overlayContainerRef.current.style.height = height + 'px';
          overlayContainerRef.current.style.transform = `scale(${scaleFactor})`;
        } else {
          overlayContainerRef.current.style.width =
            Math.ceil(width * scaleFactor) + 'px';
          overlayContainerRef.current.style.height =
            Math.ceil(height * scaleFactor) + 'px';
        }
      }
    });
    return disposer;
  }, [scene, preview, withTransform]);

  return { overlayContainerRef };
};
