import { environment } from '@castify/studio/env/browser';
import { Exchange, subscriptionExchange } from '@urql/core';
import { createClient, Sink } from 'graphql-ws';
import { ExecutionResult } from 'graphql';

/**
 * This exchange is responsible for routing subscriptions through
 * the websocket client we pass in
 */
const createSubscriptionExchange = (
  getJwt: () => Promise<string>,
): Exchange => {
  /**
   * Websocket client for subscriptions. HTTP is used for non-subscription queries,
   * but the connection is upgraded to a websocket for subscriptions. The client
   * handles switching between the two.
   */
  const wsClient = createClient({
    url: environment.websocketHasuraUrl,
    connectionParams: async () => {
      const jwt = await getJwt();
      return {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
    },
  });

  return subscriptionExchange({
    forwardSubscription: (operation) => ({
      subscribe: (sink) => ({
        unsubscribe: wsClient.subscribe(
          operation,
          /**
           * The coercion here is necessary because of an issue in urql 2.0.5
           * A fix is pending and we can likely remove after bumping urql
           * {@link https://github.com/FormidableLabs/urql/issues/1989}
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sink as Sink<ExecutionResult<any, Record<string, any>>>,
        ),
      }),
    }),
  });
};

export default createSubscriptionExchange;
