import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import EmptyPreview from './EmptyPreview/EmptyPreview';
import { IngressFlows } from '@castify/studio/ingress-flows';
import { useOwnedProjectData } from '@castify/studio/components/project-loaders';
import { useState } from 'react';
import SimpleEditDialog from './SimpleEditDialog/SimpleEditDialog';
import { LoadingTreatmentLogger } from '@castify/studio/components/loading';
import ViewCounter from './ViewCounter/ViewCounter';
import AdvancedEditLink from './AdvancedEditLink/AdvancedEditLink';
import Header from '@castify/studio/Header';
import { PreviewShortcuts } from '@castify/studio/keyboard';
import { PreviewPlayer } from '@castify/studio/components/players';
import styles from './PreviewPage.styles';

export function PreviewPage() {
  const {
    project: {
      isLoaded,
      scene: { isTimelineEmpty },
    },
  } = useMst();

  /**
   * State describing whether the simple edit dialog is open
   */
  const [isSimpleEditOpen, setIsSimpleEditOpen] = useState(false);
  const toggleSimpleEdit = () => setIsSimpleEditOpen((curr) => !curr);

  /**
   * Set up project sync
   */
  useOwnedProjectData();

  return (
    <>
      {!isLoaded ? (
        <LoadingTreatmentLogger identifier="PreviewPage" />
      ) : (
        <>
          <SimpleEditDialog
            isSimpleEditOpen={isSimpleEditOpen}
            toggleSimpleEdit={toggleSimpleEdit}
          />
          <IngressFlows />
        </>
      )}

      <div role="main">
        <div className="App" data-testid="editor-app" css={styles.appContainer}>
          <div css={styles.header}>
            <Header showSkeleton={!isLoaded} />
          </div>
          <div role="region" aria-label="Video Preview" css={styles.preview}>
            {/* IIFE here allows early return syntax for readability */}
            {(() => {
              if (!isLoaded)
                return (
                  // just a skeleton
                  <PreviewPlayer
                    toggleSimpleEdit={() => null}
                    showSkeleton={!isLoaded}
                  />
                );
              if (isLoaded && isTimelineEmpty) return <EmptyPreview />;
              if (isSimpleEditOpen) return null;
              return (
                <PreviewPlayer
                  toggleSimpleEdit={toggleSimpleEdit}
                  showSkeleton={false}
                />
              );
            })()}
          </div>
          <div css={styles.footer}>
            {!isSimpleEditOpen && (
              <>
                <ViewCounter showSkeleton={!isLoaded} />
                <AdvancedEditLink showSkeleton={!isLoaded} />
                <PreviewShortcuts />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(PreviewPage);
