import { makeOperation, Operation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';

/**
 * This module is intended for frontend-client.
 * It is meant to encapsulate auth exchange logic.
 */
type AuthState = {
  token: string;
};

/**
 * This creates an Exchange responsible for adding auth token to the urql operation.
 * We have to use an `authExchange` because fetchOptions does not support asynchronously
 * setting header
 *
 * This is an asynchronous exchange, so it must be placed in front of all
 * `fetchExchange`s but after all other synchronous exchanges, like the `cacheExchange`
 */
const createAuthExchange = (getJwt: () => Promise<string>) => {
  /**
   * This exchange is responsible for getting auth token. The `authExchange`
   * is an asynchronous exchange, so it must be placed in front of all
   * `fetchExchange`s but after all other synchronous exchanges, like the `cacheExchange`.
   * {@link https://formidable.com/open-source/urql/docs/advanced/authentication/}
   */
  return authExchange({
    getAuth: async () => {
      return { token: await getJwt() };
    },
    addAuthToOperation,
  });
};

/**
 * Applies the token from the authState to the urql operation. The authState is
 * generated in `getAuth`
 */
const addAuthToOperation = ({
  authState,
  operation,
}: {
  authState: AuthState | null;
  operation: Operation;
}) => {
  if (!authState || !authState.token) {
    return operation;
  }

  /**
   * Because `fetchOptions` can manipulate the header, we have to manually merge
   * in the Authentication field. We currently don't use  `fetchOptions`, but
   * we don't break future uses of `fetchOptions`.
   */
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: `Bearer ${authState.token}`,
      },
    },
  });
};

export default createAuthExchange;
