import { WatchImportProgressSubscription } from '@castify/studio/database-clients/graphql';
import { st } from '@castify/studio/fe-common';

/**
 * How far along is the constrain step?
 */
export const computeConstrainProgress = (
  videoSourceRow: WatchImportProgressSubscription | undefined,
): st.percent => {
  /**
   * Type guards / destructuring
   */
  if (!videoSourceRow) return 0;
  if (!videoSourceRow.video_sources_by_pk) return 0;
  const { constrain_is_necessary, constrain_is_done, constrain_percent } =
    videoSourceRow.video_sources_by_pk;

  /**
   * If no constrain had to take place, return 100%
   */
  if (!constrain_is_necessary) return 100;

  /**
   * If the DB says constrain is finished, return 100% regardless of the
   * progress value
   */
  if (constrain_is_done) return 100;

  /**
   * Return the progress value
   */
  return constrain_percent * 100;
};

/**
 * How far along is the resource generation step?
 * NOTE: this is currently not used in the UI
 */
export const computeResourceGenerationProgress = (
  videoSourceRow: WatchImportProgressSubscription | undefined,
): st.percent => {
  /**
   * Type guards / destructuring
   */
  if (!videoSourceRow) return 0;
  if (!videoSourceRow.video_sources_by_pk) return 0;
  const {
    generate_playback_completed_count,
    generate_playback_total_count,
    generate_scrub_completed_count,
    generate_scrub_total_count,
    generate_thumbnails_completed_count,
    generate_thumbnails_total_count,
  } = videoSourceRow.video_sources_by_pk;

  /**
   * Compute the total number of tasks
   */
  const totalTasks =
    (generate_playback_total_count ?? 0) +
    (generate_scrub_total_count ?? 0) +
    (generate_thumbnails_total_count ?? 0);

  /**
   * How many tasks have already completed?
   */
  const completedTasks =
    (generate_playback_completed_count ?? 0) +
    (generate_scrub_completed_count ?? 0) +
    (generate_thumbnails_completed_count ?? 0);

  /**
   * Figure out percentage
   */
  return (completedTasks / totalTasks) * 100;
};

/**
 * We don't need to wait for all resources to be generated,
 * only for segmentation and waveforms
 */
export const isReadyForPlayback = (
  videoSourceRow: WatchImportProgressSubscription | undefined,
  hasAudio: boolean,
): boolean => {
  if (!videoSourceRow) return false;

  /**
   * Does video segment data array have nonzero length?
   * This is an indication that the asset has segmented, on the assumption
   * that it does indeep have video streams
   */
  const streamSegmentComplete =
    !!videoSourceRow?.video_sources_by_pk?.video_segments.length;

  /**
   * Has the waveform been generated?
   */
  const waveformGenerationComplete =
    videoSourceRow?.video_sources_by_pk?.generate_waveform_is_done;

  if (!streamSegmentComplete) return false;
  if (hasAudio && !waveformGenerationComplete) return false;
  return true;
};

/**
 * Did the ingestion fail permanently?
 */
export const didIngestionFail = (
  videoSourceRow: WatchImportProgressSubscription | undefined,
) => {
  return !!videoSourceRow?.video_sources_by_pk?.ingest_failed;
};
