import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './SmallPlayButton.styles';
import { useMst } from '@castify/studio/studio-store';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/Pause';
import { Fab } from '@mui/material';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

function SmallPlayButton() {
  const {
    playback: { isPlaying, pause, play, playheadAtEnd, playingClip },
  } = useMst();

  const handleClick = () => {
    if (isPlaying) pause();
    else play();
  };

  return (
    <Fab
      css={styles.playButton}
      color="info"
      onPointerDown={handleClick}
      data-pendo-id="play-pause-button"
      data-testid={`${isPlaying ? 'pause' : 'play'}-button`}
      aria-label={`${isPlaying ? 'Pause' : 'Play'}Button`}
    >
      {isPlaying && playingClip && <PauseIcon css={styles.icon} />}
      {!isPlaying && !playheadAtEnd && (
        <PlayArrowRoundedIcon css={styles.icon} />
      )}
      {playheadAtEnd && !isPlaying && <ReplayRoundedIcon css={styles.icon} />}
    </Fab>
  );
}

export default observer(SmallPlayButton);
