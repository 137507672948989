import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  playhead: css`
    width: 2px;
    height: 100%;
    position: absolute;
    background-color: ${colors.TEMP.ZoomPurple};
    pointer-events: none;
    z-index: 2;
  `,
};
export default styles;
