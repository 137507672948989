import { css } from '@emotion/react';

const styles = {
  darkOverlay: css`
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    pointer-events: none;
  `,
  boxContainer: css`
    position: absolute;
  `,
};

export default styles;
