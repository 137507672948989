import { useState } from 'react';
import { IconButton, Skeleton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ButtonTooltip } from '@castify/studio/fe-common';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteProjectConfirmDialog } from '../DeleteProjectConfirmDialog/DeleteProjectConfirmDialog';
import { useMst } from '@castify/studio/studio-store';
interface DeleteProjectButtonProps {
  showSkeleton?: boolean;
}

function DeleteProjectButton({ showSkeleton }: DeleteProjectButtonProps) {
  const {
    ingress: { isUploadInProgress },
  } = useMst();
  const [isOpen, setIsOpen] = useState(false);

  function onClose() {
    setIsOpen(false);
  }
  if (showSkeleton)
    return <Skeleton variant="circular" width={50} height={50} />;

  return (
    <>
      <ButtonTooltip title="Delete Project">
        <IconButton
          size="large"
          data-pendo-id="delete-button"
          onClick={() => setIsOpen(true)}
          aria-label="Delete Button"
          disabled={isUploadInProgress}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </ButtonTooltip>

      <DeleteProjectConfirmDialog isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export default observer(DeleteProjectButton);
