import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Scrubhead.styles';
import { useMst } from '@castify/studio/studio-store';

function Scrubhead() {
  const {
    timeline: { scrubPosition },
  } = useMst();

  // Type guard to narrow scrubTime and also conditionally render
  return typeof scrubPosition === 'number' ? (
    <div
      id="scrubhead"
      css={styles.scrubhead}
      style={{
        // minus one as it is two pixels wide,
        // want center to be directly over current frame
        left: `${scrubPosition - 1}px`,
      }}
    ></div>
  ) : (
    <></>
  );
}

export default observer(Scrubhead);
