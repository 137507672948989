import { st } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const skeletonMargin: st.px = 15;

const styles = {
  timelineContainer: css`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 15px;
    grid-template-areas: 'timeline' 'scrollbar';
  `,
  timelineParent: css`
    overflow: hidden;
    position: relative;
  `,
  timeline: css`
    grid-area: timeline;
    height: 100%;
  `,
  clipsContainer: css`
    position: relative;
  `,
  skeleton: css`
    overflow: hidden;
    margin: 10px ${skeletonMargin}px 10px ${skeletonMargin}px;
    height: 160px;
    width: calc(100vw - ${2 * skeletonMargin}px);
  `,
};
export default styles;
