import { st } from '@castify/studio/fe-common';

// The min space that will make the timeline auto scroll when the user is playing the video
const PLAYHEAD_BUFFER_FROM_EDGE: st.px = 100;

export const calculateScrollbarPositionWhilePlaying = (
  scrollTime: number,
  playbackClock: number,
  visibleStart: number,
  visibleEnd: number,
  zoomFactor: number,
) => {
  const playheadBufferFromEdgeMs: st.ms =
    PLAYHEAD_BUFFER_FROM_EDGE / zoomFactor;

  // Check to see if the playhead appears earlier than the comfortably viewable area
  const minPlayheadPosition = visibleStart + playheadBufferFromEdgeMs;
  if (playbackClock < minPlayheadPosition) {
    // Shift us back into view
    return scrollTime + playbackClock - minPlayheadPosition;
  }

  // Check to see if the playhead appears later than the comfortably viewable area
  const maxPlayheadPosition = visibleEnd - playheadBufferFromEdgeMs;
  if (playbackClock > maxPlayheadPosition) {
    return scrollTime + playbackClock - maxPlayheadPosition;
  }

  return scrollTime;
};
