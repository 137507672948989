import { st } from '@castify/studio/fe-common';
import { Instance, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';

/**
 * Boxes can't be smaller than this % of parent on either
 * axis
 */
const MINIMUM_BOX_SIZE = 0.05;

/**
 * Helper for clamping a value between a minimum and maximum
 */
const clamp = (val: number, min: number, max: number) =>
  Math.min(Math.max(min, val), max);

/**
 * State backing the resizable, movable boxes used in the blur, crop, zoom
 * effects. As all three effets need boxes to be constrained to the bounding box
 * (the user can't drag them off the preview) and also do not enable rotation,
 * so this model is separate from the model backing text (which does not need
 * clamping and needs to enable rotation).
 *
 * The division of labor with the UI code for drags is as follows: this model
 * accepts positions the box and corners should move to, clamping values
 * to stay in bounds; implementation details of drags are left to the UI code.
 */
const BoxModel = types
  .model('BoxModel', {
    /**
     * uuid which is useful when this model is part of an array of models
     */
    uuid: types.optional(types.identifier, nanoid),
    /**
     * X coord of the box's upper left corner expressed in % of container between 0 and 1
     */
    left: types.optional(types.number, 0),
    /**
     * Y coord of the box's upper left corner expressed in % of container between 0 and 1
     */
    top: types.optional(types.number, 0),
    /**
     * width of box expressed in % of container between 0 and 1
     */
    width: types.optional(types.number, 1),
    /**
     * height of box expressed in % of container between 0 and 1
     */
    height: types.optional(types.number, 1),
  })
  .views((self) => {
    return {
      /**
       * How far is the right side of the box from the left bound?
       */
      get right(): st.ratio {
        return self.left + self.width;
      },
      /**
       * How far is the bottom side of the box from the top bound?
       */
      get bottom(): st.ratio {
        return self.top + self.height;
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * Move the box; accepts upper-left corner expressed as % of container.
       * Clamped to bounds of the container
       */
      move(x: st.ratio, y: st.ratio): void {
        self.left = clamp(x, 0, 1 - self.width);
        self.top = clamp(y, 0, 1 - self.height);
      },
      /**
       * Move the top left corner to a point, clamping to container bounds
       * and min box size
       */
      resizeTopLeft(x: st.ratio, y: st.ratio): void {
        const oldRight = self.right;
        const oldBottom = self.bottom;
        self.left = clamp(x, 0, self.right - MINIMUM_BOX_SIZE);
        self.top = clamp(y, 0, self.bottom - MINIMUM_BOX_SIZE);
        self.width = oldRight - self.left;
        self.height = oldBottom - self.top;
      },
      /**
       * Move the top right corner to a point, clamping to container bounds
       * and min box size
       */
      resizeTopRight(x: st.ratio, y: st.ratio): void {
        const oldBottom = self.bottom;
        self.top = clamp(y, 0, self.bottom - MINIMUM_BOX_SIZE);
        self.height = oldBottom - self.top;
        self.width = clamp(x - self.left, MINIMUM_BOX_SIZE, 1 - self.left);
      },
      /**
       * Move the bottom left corner to a point, clamping to container bounds
       * and min box size
       */ resizeBottomLeft(x: st.ratio, y: st.ratio): void {
        const oldRight = self.right;
        self.height = clamp(y - self.top, MINIMUM_BOX_SIZE, 1 - self.top);
        self.left = clamp(x, 0, self.right - MINIMUM_BOX_SIZE);
        self.width = oldRight - self.left;
      },
      /**
       * Move the top right corner to a point, clamping to container bounds
       * and min box size
       */
      resizeBottomRight(x: st.ratio, y: st.ratio): void {
        self.height = clamp(y - self.top, MINIMUM_BOX_SIZE, 1 - self.top);
        self.width = clamp(x - self.left, MINIMUM_BOX_SIZE, 1 - self.left);
      },
    };
  });

export default BoxModel;

export interface IBoxModel extends Instance<typeof BoxModel> {}
