import { css } from '@emotion/react';

const styles = {
  overlayContainer: css`
    position: absolute;
    box-sizing: border-box;
  `,
};

export default styles;
