import { useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import BlurOverlay from './BlurOverlay/BlurOverlay';
import CropOverlay from './CropOverlay/CropOverlay';
import { useScaledOverlay } from './overlayHooks/useScaledOverlay';
import styles from './Overlays.styles';
import TextOverlay from './TextOverlay/TextOverlay';
import TextOverlayContent from './TextOverlay/TextOverlayContent';

/**
 * This component contains all of the overlays which can be displayed
 * on top of the preview when a tool is active
 *
 * It is responsible for:
 * 1. scaling the container into which overlays are rendered
 * 2. conditionally rendering overlays themselves depending on
 *    whether any are active
 *
 * This component's styles presuppose that the parent centers it in
 * the preview pane using flex.
 */
const Overlays = () => {
  const {
    toolManager: {
      textToolIfOpen,
      zoomToolIfOpen,
      blurToolIfOpen,
      cropToolIfOpen,
    },
  } = useMst();

  const { overlayContainerRef: nonTransformedRef } = useScaledOverlay({
    withTransform: false,
  });
  const { overlayContainerRef: transformedRef } = useScaledOverlay({
    withTransform: true,
  });

  return (
    <>
      {/* This will eventually become where we render our text */}
      <div ref={transformedRef} css={styles.overlayContainer}>
        <TextOverlayContent />
      </div>

      <div ref={nonTransformedRef} css={styles.overlayContainer}>
        {!!textToolIfOpen && (
          <TextOverlay overlayContainerRef={nonTransformedRef} />
        )}
        {!!zoomToolIfOpen && <div>zoom tool placeholder</div>}
        {!!blurToolIfOpen && (
          <BlurOverlay overlayContainerRef={nonTransformedRef} />
        )}
        {!!cropToolIfOpen && (
          <CropOverlay overlayContainerRef={nonTransformedRef} />
        )}
      </div>
    </>
  );
};

export default observer(Overlays);
