import { css } from '@emotion/react';

const styles = {
  projectTileContainer: css`
    display: flex;
    flex-direction: column;
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    font-family: Helvetica, Sans-Serif;
    border-radius: 8px;
    min-width: 204px;
    margin: 10px;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4), 0 0 0 3px #7952ff;
      .project-title {
        text-decoration: underline;
      }
    }
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
  projectDetails: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #767676;
    margin-left: 8px;
    margin-right: 8px;
  `,
  title: css`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #2c2c2c;
    text-overflow: ellipsis;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
  `,
  dash: css`
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  `,
  notFoundIcon: css`
    height: 5rem;
    width: 5rem;
  `,
  menu: css`
    position: absolute;
  `,
  thumbnailContainer: (thumbnailUrl: string) => css`
    height: 120px;
    min-width: 204px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #bcc8d3;
    background-image: url(${thumbnailUrl});
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  `,
  bottomRow: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
};
export default styles;
