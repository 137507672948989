import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  container: css`
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
};
export default styles;
