import { observer } from 'mobx-react-lite';
import { ITextClip, useMst } from '@castify/studio/studio-store';
import { useShowTrimHandles } from '../../Clips/useShowTrimHandles';
import TrimHandle, { TrimHandleNames } from '../../Clips/TrimHandle/TrimHandle';
import commonStyles from '../../Clips/commonClip.styles';
import styles from './TextClip.styles';
import { useRef } from 'react';

type TextClipProps = {
  clip: ITextClip;
};

const TextClip = ({ clip }: TextClipProps) => {
  const clipContainerRef = useRef<HTMLDivElement>(null);

  const {
    selection: { selectClip },
    timeline: { initiateSeekFromPointerEvent },
  } = useMst();

  const { setPointerInClip, areHandlesVisible } = useShowTrimHandles(
    clip.isSelected,
  );

  const handleClipSelect = (e: React.PointerEvent) => {
    e.stopPropagation();
    selectClip(clip);
    initiateSeekFromPointerEvent(e);
  };

  return (
    <div
      ref={clipContainerRef}
      onPointerEnter={() => setPointerInClip(true)}
      onPointerLeave={() => setPointerInClip(false)}
      onPointerDown={handleClipSelect}
      data-selectable
      css={styles.container}
      style={{
        left: `${clip.leftOffsetPx}px`,
        width: `${clip.widthPx}px`,
        minWidth: `${clip.widthPx}px`,
        maxWidth: `${clip.widthPx}px`,
      }}
    >
      <div
        css={[
          commonStyles.container,
          styles.containerBox,
          clip.isSelected && commonStyles.selected,
        ]}
      >
        <TrimHandle
          trimHandleName={TrimHandleNames.left}
          isVisible={areHandlesVisible}
          clipOrEffect={clip}
          clipContainerRef={clipContainerRef}
        />
        <div css={styles.textContainer}>
          <p css={styles.text}>Text</p>
        </div>
        <TrimHandle
          trimHandleName={TrimHandleNames.right}
          isVisible={areHandlesVisible}
          clipOrEffect={clip}
          clipContainerRef={clipContainerRef}
        />
      </div>
      <div css={[styles.anchor, clip.isSelected && commonStyles.selected]} />
    </div>
  );
};

export default observer(TextClip);
