import { IBlurEffect, IVideoSource } from '@castify/studio/studio-store';
import { st } from '@castify/studio/fe-common';
import { applyBlur } from './helpers/applyBlur';
import { calculateDestinationPosition } from './helpers/destinationPosition';
import { calculateSourcePosition } from './helpers/sourcePosition';

/**
 * Arguments are documented in paintFrame
 */
type IPaintVideoSource = {
  ctx: CanvasRenderingContext2D;
  canvasElm: HTMLCanvasElement;
  activeClipSource: IVideoSource;
  imageSource: CanvasImageSource;
  isOverrideModeActive: boolean;
  blurCanvas: HTMLCanvasElement;
  blurCtx: CanvasRenderingContext2D;
  blurEffect: IBlurEffect | undefined;
};

/**
 * Paints a video source to the canvas
 */
export const paintVideoSource = ({
  ctx,
  canvasElm,
  activeClipSource,
  imageSource,
  isOverrideModeActive,
  blurCanvas,
  blurCtx,
  blurEffect,
}: IPaintVideoSource) => {
  /**
   * This is a type guard that protects against non-video elements
   * not having the videoWidth and videoHeight attributes. This will need to
   * change if we use non-video elements (images, canvases, etc) as image
   * sources.
   */
  if (!(imageSource instanceof HTMLVideoElement))
    throw new Error('non-video image sources not yet supported');

  /**
   * We apply blur via an intermediate canvas
   */
  if (blurEffect) {
    applyBlur({ blurCanvas, blurCtx, imageSource, blurEffect });
  }

  /**
   * This backdrop is the color for letterboxes/pillarboxes.
   */
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, canvasElm.width, canvasElm.height);

  /**
   * When blur effects are active, our compositor pipeline includes
   * the blur canvas; when it doesn't, we copy directly from the source canvas
   * to save ourselves an extra image copy.
   */
  const blurredImageSource = blurEffect ? blurCanvas : imageSource;

  /**
   * What data will we copy from source?
   */
  const sourcePosition = calculateSourcePosition(
    blurredImageSource,
    activeClipSource,
    isOverrideModeActive,
  );

  /**
   * Where will we put this data
   */
  const destPosition = calculateDestinationPosition(
    activeClipSource,
    canvasElm,
    isOverrideModeActive,
  );

  /**
   * The image blit itself happens here using the 3rd overload of draw image:
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage}
   */
  ctx.drawImage(
    blurredImageSource,
    sourcePosition.left,
    sourcePosition.top,
    sourcePosition.width,
    sourcePosition.height,
    destPosition.left,
    destPosition.top,
    destPosition.width,
    destPosition.height,
  );
};
