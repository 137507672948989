import { getAnonymousUserIdentifier } from '@castify/studio/fe-common';

export type UserInfo = {
  id: string;
  isSignedIn: boolean;
  displayName: string;
  photoURL?: string;
  email?: string;
};

export const userInfoFromGoogleUser = (
  googleUser: gapi.auth2.GoogleUser,
): UserInfo => {
  const profile = googleUser.getBasicProfile();
  if (googleUser.isSignedIn()) {
    return {
      id: profile.getId(),
      isSignedIn: googleUser.isSignedIn(),
      photoURL: profile.getImageUrl(),
      displayName: profile.getName(),
      email: profile.getEmail(),
    };
  } else {
    return {
      // this id will be shared with DataDog RUM user Id, it's also the session user Id
      id: getAnonymousUserIdentifier(),
      isSignedIn: googleUser.isSignedIn(),
      displayName: 'Anonymous Viewer',
    };
  }
};
