import { useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import RotatableBox from '../Boxes/RotatableBox';

type TextOverlayProps = {
  overlayContainerRef: React.RefObject<HTMLDivElement>;
};

/**
 * The overlay for the text tool. Contains the border for
 * rotatable boxes along with all of the interactive elements
 * needed to manipulate a text box.
 */
const TextOverlay = ({ overlayContainerRef }: TextOverlayProps) => {
  const {
    toolManager: { textToolIfOpen },
  } = useMst();

  if (!textToolIfOpen) return null;

  const textBoxes = textToolIfOpen.targetClip.boxes;

  return (
    <>
      {textBoxes.map(({ boundingBox }) => (
        <RotatableBox
          key={boundingBox.uuid}
          borderColor="#ff8282"
          isSelected={true}
          boxModel={boundingBox}
          overlayContainerRef={overlayContainerRef}
        />
      ))}
    </>
  );
};

export default observer(TextOverlay);
