import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import RootModel from '../root.mst';
import AudioToolModel, { IAudioTool } from './audioTool.mst';
import BlurToolModel, { IBlurTool } from './blurTool.mst';
import CropToolModel, { ICropTool } from './cropTool.mst';
import TextToolModel, { ITextTool } from './textTool.mst';
import ZoomToolModel, { IZoomTool } from './zoomTool.mst';
import StillClipToolModel, { IStillClipTool } from './stillClipTool.mst';
import { getPlayback } from '../playback/playback.mst';
import { ToolNames } from './tools.types';

/**
 * State, views, & actions backing right sidebar and tool overlays
 *
 * Children potentially include the tool "controllers": models which correspond
 * to each tool, containing state, views, and actions backing tool lifecycles,
 * overlay, and sidebar.
 *
 * When not being used, tool controllers are torn down from the tree and garbage
 * collected; MST lifecycle methods are expected to be used internal to the
 * tools to manage tool lifecycle.
 */
const ToolManagerModel = types
  .model('ToolManager', {
    /**
     * The model backing the active tool, if any
     */
    activeTool: types.maybeNull(
      types.union(
        CropToolModel,
        ZoomToolModel,
        BlurToolModel,
        TextToolModel,
        AudioToolModel,
        StillClipToolModel,
      ),
    ),
  })
  .views((self) => {
    /**
     * These views are a public API for checking which sidebar if any is active,
     * but also getting a type-safe reference to the model in question.
     *
     * These are type guards-- they help with the fact that TS is not great
     * at inferring narrowed MST unions
     */
    return {
      get isAnyToolOpen(): boolean {
        return !!self.activeTool;
      },
      get textToolIfOpen(): ITextTool | null {
        if (!TextToolModel.is(self.activeTool)) return null;
        return self.activeTool as ITextTool;
      },
      get stillClipToolIfOpen(): IStillClipTool | null {
        if (!StillClipToolModel.is(self.activeTool)) return null;
        return self.activeTool as IStillClipTool;
      },
      get zoomToolIfOpen(): IZoomTool | null {
        if (!ZoomToolModel.is(self.activeTool)) return null;
        return self.activeTool as IZoomTool;
      },
      get blurToolIfOpen(): IBlurTool | null {
        if (!BlurToolModel.is(self.activeTool)) return null;
        return self.activeTool as IBlurTool;
      },
      get cropToolIfOpen(): ICropTool | null {
        if (!CropToolModel.is(self.activeTool)) return null;
        return self.activeTool as ICropTool;
      },
      get audioToolIfOpen(): IAudioTool | null {
        if (!AudioToolModel.is(self.activeTool)) return null;
        return self.activeTool as IAudioTool;
      },
    };
  })
  .views((self) => {
    return {
      /**
       * When the blur and crop tools are open, we see a full res video so the
       * user can crop and blur relative to the source. Override mode
       * causes branching to happen elsewhere in the application; this
       * getter centralizes knowledge of when this special mode is active.
       */
      get isOverrideModeActive(): boolean {
        return !!self.cropToolIfOpen || !!self.blurToolIfOpen;
      },
    };
  })
  .actions((self) => {
    /**
     * These actions are the "public API" of the tool manager.
     */
    return {
      /**
       * Allows opening a tool. Pauses playback.
       */
      openTool(toolName: ToolNames): void {
        getPlayback(self).pause();
        switch (toolName) {
          case ToolNames.CropTool: {
            self.activeTool = CropToolModel.create();
            break;
          }
          case ToolNames.ZoomTool: {
            self.activeTool = ZoomToolModel.create();
            break;
          }
          case ToolNames.BlurTool: {
            self.activeTool = BlurToolModel.create();
            break;
          }
          case ToolNames.TextTool: {
            self.activeTool = TextToolModel.create();
            break;
          }
          case ToolNames.StillClipTool: {
            self.activeTool = StillClipToolModel.create();
            break;
          }
          case ToolNames.AudioTool: {
            self.activeTool = AudioToolModel.create();
            break;
          }
        }
      },
      /**
       * Allows closing the open tool.
       */
      closeTool(): void {
        self.activeTool = null;
      },
    };
  });
export default ToolManagerModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getToolManager(self: IAnyStateTreeNode): IToolManager {
  const root = getParentOfType(self, RootModel);
  return root.toolManager;
}

export interface IToolManager extends Instance<typeof ToolManagerModel> {}
