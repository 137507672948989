import { ProbeData, ProbeDataSchema } from './video-probe-data-schema';

/**
 * Intended to be used as runtime type guard. Can throw an error and should be
 * handled when consumed up the stack
 */
export function verifyProbeOutput(rawProbeOutput: unknown): ProbeData {
  if (typeof rawProbeOutput === 'string') {
    const parsed = JSON.parse(rawProbeOutput);
    ProbeDataSchema.passthrough().parse(parsed);
    return parsed as ProbeData;
  } else {
    ProbeDataSchema.passthrough().parse(rawProbeOutput);
    return rawProbeOutput as ProbeData;
  }
}
