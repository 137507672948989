import { useMst } from '@castify/studio/studio-store';
import { observer } from 'mobx-react-lite';
import styles from './TextOverlayContent.styles';

/**
 * The overlay for the textboxes content. Contains
 * the typography that'll eventually be added to the
 * exported video.
 */
const TextOverlayContent = () => {
  const {
    toolManager: { textToolIfOpen },
  } = useMst();

  if (!textToolIfOpen) return null;

  const textBoxes = textToolIfOpen.targetClip.boxes;

  return (
    <>
      {textBoxes.map(({ boundingBox }) => (
        <div
          css={styles.boxContainer}
          style={{
            top: boundingBox.top * 100 + '%',
            left: boundingBox.left * 100 + '%',
            width: boundingBox.width * 100 + '%',
            height: boundingBox.height * 100 + '%',
            transform: `rotate(${boundingBox.placeholderRotation}deg)`,
            zIndex: +4,
            transformOrigin: 'center',
          }}
        >
          <h2>Hello World</h2>
        </div>
      ))}
    </>
  );
};

export default observer(TextOverlayContent);
