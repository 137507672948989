import { createTheme } from '@mui/material/styles';
import {
  DESKTOP_SIZE,
  LAPTOP_SIZE,
  MOBILE_SIZE,
  TABLET_SIZE,
} from './breakpoints.styles';
import { colors } from './colors.styles';
import { whiteButton } from './variants/whiteButton';

const baseFontSize = 16;
const pxToRem = (px: number) => px / baseFontSize;

// MUI theme implementation
// https://mui.com/customization/theming/
const theme = createTheme({
  // https://mui.com/customization/palette/
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.primaryContrastText,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
      dark: colors.warningDark,
    },
    info: {
      main: colors.infoDark,
    },
    success: {
      main: colors.success,
      contrastText: colors.successTextContrast,
    },
  },
  // https://mui.com/customization/breakpoints/
  breakpoints: {
    values: {
      xs: 0,
      sm: MOBILE_SIZE,
      md: TABLET_SIZE,
      lg: LAPTOP_SIZE,
      xl: DESKTOP_SIZE,
    },
  },
  // custom component styling and default props
  // https://mui.com/customization/theme-components/
  components: {
    MuiButton: {
      variants: [whiteButton],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          height: '48px',
          borderRadius: '8px',
          fontFamily: 'Helvetica',
          fontSize: `${pxToRem(16)}rem`,
          fontWeight: 'bold',
          textTransform: 'none',
        },
        sizeLarge: {
          lineHeight: 'auto',
        },
        sizeMedium: {
          lineHeight: `${pxToRem(24)}rem`,
        },
        sizeSmall: {
          lineHeight: `${pxToRem(22)}rem`,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'bottom',
      },
      styleOverrides: {
        tooltip: {
          fontFamliy: 'Helvetica',
          fontWeight: 'normal',
          fontSize: `${pxToRem(14)}rem`,
          lineHeight: `${pxToRem(14)}rem`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 'bolder',
          fontSize: `${pxToRem(34)}rem`,
          lineHeight: '120%',
        },
        h2: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: `${pxToRem(28)}rem`,
          lineHeight: '116.7%',
        },
        h3: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: `${pxToRem(24)}rem`,
          lineHeight: '123.5%',
        },
        h4: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: `${pxToRem(20)}rem`,
          lineHeight: '133.4%',
        },
        h5: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: `${pxToRem(18)}rem`,
          lineHeight: '160%',
        },
        body1: {
          fontFamily: 'Helvetica',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: `${pxToRem(16)}rem`,
          lineHeight: '150%',
          letterSpacing: '0.15px',
        },
        body2: {
          fontFamily: 'Helvetica',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: `${pxToRem(14)}rem`,
          lineHeight: '143%',
          letterSpacing: '0.15px',
        },
        subtitle1: {
          fontFamily: 'Helvetica',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: `${pxToRem(20)}rem`,
          lineHeight: '175%',
        },
        subtitle2: {
          fontFamily: 'Helvetica',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: `${pxToRem(18)}rem`,
          lineHeight: '157%',
        },
        caption: {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: `${pxToRem(12)}rem`,
          lineHeight: '166%',
          letterSpacing: '0.4px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
          fontWeight: 'normal',
          fontSize: `${pxToRem(12)}rem`,
          lineHeight: `${pxToRem(12)}rem`,
          letterSpacing: '0.15px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
          fontWeight: 'normal',
          fontSize: `${pxToRem(16)}rem`,
          lineHeight: `${pxToRem(24)}rem`,
          letterSpacing: '0.15px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
          fontWeight: 'normal',
          fontSize: `${pxToRem(12)}rem`,
          lineHeight: `${pxToRem(20)}rem`,
          letterSpacing: '0.4px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // PX instead of EM because I think we always want this to be a hard value
          padding: '16px 24px 24px 24px',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        sx: {
          typography: 'h3',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Helvetica',
          fontWeight: 'normal',
          fontSize: `${pxToRem(13)}rem`,
          lineHeight: `${pxToRem(18)}rem`,
          letterSpacing: '0.16px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontFamliy: 'Source Sans Pro',
          fontWeight: 'bold',
          fontSize: `${pxToRem(12)}rem`,
          lineHeight: `${pxToRem(20)}rem`,
          letterSpacing: `0.14px`,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontFamliy: 'Source Sans Pro',
          fontWeight: 'bold',
          fontSize: `${pxToRem(16)}rem`,
          lineHeight: `150%`,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {},
    },
  },
});

export default theme;
