import { useMst } from '@castify/studio/studio-store';
import { autorun } from 'mobx';
import { useCallback, useEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useScaledCanvas = () => {
  const {
    player,
    project: { scene },
  } = useMst();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);

  /**
   * Create a drawing context and store it for use
   */
  useEffect(() => {
    if (!canvasRef.current) return;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ctx = canvasRef.current.getContext('2d')!;
    ctx.imageSmoothingEnabled = false;
    setCtx(ctx);
  }, [canvasRef]);

  /**
   * Subscribe MST to the width and height of the player container whenever
   * it changes
   */
  const scaleCanvasToScene = useCallback(
    (entry: ResizeObserverEntry) => {
      const { width, height } = entry.contentRect;
      player.updatePlayerDimensions({ width, height });
    },
    [player],
  );

  /**
   * On first render, resizes the player to be 0 by 0 pixels (so, invisible).
   * This avoids an issue in which the player momentarily appears with an
   * incorrect size prior to the resize observer firing. This comes at the cost
   * of more re-renders during page mount or just after nav.
   */
  useEffect(() => {
    scaleCanvasToScene({
      contentRect: { width: 0, height: 0 },
    } as ResizeObserverEntry);
  }, [scaleCanvasToScene]);

  /**
   * Scale canvas to scene when player container changes size
   */
  useResizeObserver(canvasContainerRef, scaleCanvasToScene);

  /**
   * The canvas' logical size is subscribed to the scene dimensions
   * is the canvas is scaled to fit within the preiew container via css
   * transforms
   *
   * With, height, and scaleFactor are observable and change when window
   * resizes and also when the scene's dimensions chage
   */
  useEffect(() => {
    const disposer = autorun(() => {
      const { width, height } = scene.canvasDimensions;
      const { scaleFactor } = player;
      if (canvasRef.current) {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        canvasRef.current.style.transform = `scale(${scaleFactor})`;
      }
    });
    return disposer;
  }, [scene, player]);

  return {
    canvasRef,
    canvasContainerRef,
    ctx,
  };
};

export default useScaledCanvas;
