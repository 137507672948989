import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { useMutation } from 'urql';

import {
  DeleteProjectDocument,
  DeleteProjectMutation,
} from '@castify/studio/database-clients/graphql';

import {
  formatHms,
  msToHms,
  calculateDuration,
  formatDate,
} from './formatHelpers';
import styles from './ProjectTile.styles';
import DeleteIcon from '@mui/icons-material/Delete';

// a single project from the getAllProjectsQuery
interface IProjectTileProps {
  project: {
    __typename?: 'projects';
    uuid: string;
    updated_at: string;
    scene: string;
    title?: string | null;
  };
}

export const ProjectTile = (props: IProjectTileProps) => {
  const { project } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const [{ fetching, error }, executeMutation] =
    useMutation<DeleteProjectMutation>(DeleteProjectDocument);

  function handleTileClick() {
    navigate(`/preview/${project.uuid}`);
  }
  function handleMenuClick(event: React.MouseEvent) {
    // prevent this click from propagating to the click handler on the tile
    event.stopPropagation();
    setIsMenuOpen(true);
  }
  function handleMenuClose(event: React.MouseEvent) {
    // prevent this click from propagating to the click handler on the tile
    event.stopPropagation();
    setIsMenuOpen(false);
  }
  function handleDelete(event: React.MouseEvent) {
    // prevent this click from propagating to the click handler on the tile
    event.stopPropagation();
    executeMutation({ projectUuid: project.uuid });
  }

  function openDialog(event: React.MouseEvent) {
    event.stopPropagation();
    setIsMenuOpen(false);
    setIsDialogOpen(true);
  }

  function handleDialogClose(event: React.MouseEvent) {
    event.stopPropagation();
    setIsDialogOpen(false);
  }

  // TODO: We want to move towards some of these fields being materialized views from postgres instead of parsing the scene clientside
  // When that work is done those fields should be directly available from the project query
  // https://github.com/screencastify/castify/issues/2460

  const scene = JSON.parse(project.scene);

  const duration = formatHms(msToHms(calculateDuration(scene)));
  const updatedAt = formatDate(project.updated_at);

  let thumbnailUrl = '';
  if (scene.mainTrack && scene.mainTrack.mainTrackClips.length) {
    thumbnailUrl = `${scene.mainTrack?.mainTrackClips[0].source.baseThumbnailUrl}00000000000.jpg`;
  }

  return (
    <div
      css={styles.projectTileContainer}
      data-testid="project-tile"
      data-pendo-id="open-project-card"
      onClick={handleTileClick}
    >
      <div css={styles.thumbnailContainer(thumbnailUrl)} />
      <Typography noWrap>
        <b css={styles.title} className="project-title">
          {project.title ?? ''}
        </b>
      </Typography>
      <div css={styles.projectDetails}>
        <div css={styles.flexRow}>
          <span>{updatedAt}</span>
          <span css={styles.dash}> - </span>
          <span>{duration}</span>
        </div>
      </div>
      <div css={styles.bottomRow}>
        <IconButton
          onClick={handleMenuClick}
          ref={anchorRef}
          aria-label="ExpandMenuButton"
        >
          <MoreVertIcon />
        </IconButton>
      </div>
      <Menu
        open={isMenuOpen}
        onClose={handleMenuClose}
        css={styles.menu}
        anchorEl={anchorRef.current}
      >
        <MenuItem onClick={openDialog} data-pendo-id="delete-project-button">
          <DeleteRoundedIcon />
          Delete
        </MenuItem>
      </Menu>
      <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="xs">
        <DialogTitle>🗑 Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will remove
            <b css={styles.title} className="project-title">
              {project.title ?? ''}
            </b>
            from your My Videos page. Once you delete this video, it cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-pendo-id="cancel-delete-project-button"
            onClick={handleDialogClose}
          >
            Never mind
          </Button>
          <Button
            data-pendo-id="confirm-delete-project-button"
            onClick={handleDelete}
            variant="contained"
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProjectTile;
