import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../DebugPanel.styles';
import DevVideoList from './DevVideoList';
import FeatureButtons from './FeatureButtons';
import ProjectVideos from './ProjectVideos';

/**
 * A dropdown of things that can be appended to the timeline for debugging purposes
 */
export function DevVideoMenu() {
  const [buttonsExpanded, setButtonsExpanded] = useState(false);
  const { pathname } = useLocation();

  /**
   * Only show when edit or preview are open
   */
  if (!pathname.startsWith('/edit') && !pathname.startsWith('/preview'))
    return null;

  return (
    <div
      role="region"
      aria-label="Debugger"
      onPointerLeave={() => setButtonsExpanded(false)}
    >
      <div css={styles.debuggerButtons}>
        <div
          css={styles.debuggerHover}
          onPointerEnter={() => setButtonsExpanded(true)}
        >
          add ⬇
        </div>
      </div>
      {buttonsExpanded && (
        <div css={styles.addVideoMenuContainer}>
          <DevVideoList />
          <FeatureButtons />
          <ProjectVideos />
        </div>
      )}
    </div>
  );
}

export default observer(DevVideoMenu);
