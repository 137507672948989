import { st } from '@castify/studio/fe-common';
import { MainTrackClip } from '../sceneTypes';
import StillSourceModel from '../stillSource.mst';

/**
 * This file contains helpers for the scene's dimensions- and aspect-ratio
 * related code. These are adapted from the Size class in the old editor:
 * {@link https://github.com/screencastify/castify-edit-broker/blob/4810217f9fb13c0b04d63397d1ae1acecd165e2d/modules/models/src/math/size.ts}
 */

/**
 * Maximum allowed resolution of scene. 1920x1920 is a vertical or horizontal
 * 1080p video.
 */
const MAX_RESOLUTION: st.dims = { width: 1920, height: 1920 };

/**
 * When text clips are present, the aspect ratio is forced to this value
 */
const TEXT_ASPECT_RATIO_DIMS = { width: 16, height: 9 };

/**
 * If there are no clips with dimensions in the scene (e.g. it's all still clips)
 * we fall back to these dimensions
 */
const FALLBACK_DIMENSIONS = { width: 1280, height: 720 };

/**
 * Find the widest and longest values for the width and height
 * of all clips, repsectively, taking into account their dimensions
 * when cropped.
 *
 * Necessary because the canvas needs to be big enough to not destroy
 * information from the source clips, while also small enough that we don't scale
 * source clips up unnecessarily.
 */
export const findMaxClipDims = (clips: Array<MainTrackClip>): st.dims => {
  return clips.reduce(
    (maxDims, clip) => {
      if (StillSourceModel.is(clip.source)) return maxDims;
      else {
        return {
          width: Math.max(maxDims.width, clip.source.croppedDims.width),
          height: Math.max(maxDims.height, clip.source.croppedDims.height),
        };
      }
    },
    { width: 0, height: 0 },
  );
};

/**
 * Given a rectangle and a (hardcoded) aspect ratio, tell us what size container
 * we need matching the aspect ratio in question to totally "contain" or "cover" the
 * source clip.
 *
 * Necessary because when text is present, it is impossible to position it
 * consistently on the scene if the aspect ratio of the scene is allowed to
 * change as clips are added and removed.
 */
export const enforceTextApsectRatio = (rect: st.dims): st.dims => {
  const scale = Math.max(
    rect.width / TEXT_ASPECT_RATIO_DIMS.width,
    rect.height / TEXT_ASPECT_RATIO_DIMS.height,
  );
  return {
    width: TEXT_ASPECT_RATIO_DIMS.width * scale,
    height: TEXT_ASPECT_RATIO_DIMS.height * scale,
  };
};

/**
 * Given a rectangle, scale it down, retaining its aspect ratio so it
 * is just small enough to fit entirely inside a set of bounds.
 *
 * Necessary because we don't want users to be able to use a very
 * high-resolution video which would process very slowly in the renderer.
 *
 * This is likely a second layer of protections against this possibility; the
 * ingress system likely provides duplicate protection when applying a
 * constraining transcode.
 */
export const constrainToMaxResolution = (rect: st.dims): st.dims => {
  // if it already fits, early return, we're done
  if (
    rect.width <= MAX_RESOLUTION.width &&
    rect.height <= MAX_RESOLUTION.height
  ) {
    return rect;
  }

  // figure out the axis which serves as the source of our
  // scaling value and store that value
  const scale = Math.min(
    MAX_RESOLUTION.width / rect.width,
    MAX_RESOLUTION.height / rect.height,
  );

  // do the scaling
  return { width: rect.width * scale, height: rect.height * scale };
};

/**
 * Given a rectangle, if dims are both not disible by 2, round down the axis
 * in question
 *
 * The need for this was not documented in the old editor; it *may* have to do
 * with preventing issues connected to odd dims and how canvas deals with
 * rounding when scaling. Included here for safety.
 */
export const ensureEvenDimensions = (rect: st.dims): st.dims => {
  return {
    width: Math.floor(rect.width / 2) * 2,
    height: Math.floor(rect.height / 2) * 2,
  };
};

/**
 * Given a rectangle, ensure it is nonzero, defaulting to configured
 * values if it has no width or height.
 *
 * Needed to handle cases when all of the clips have no concept of
 * width or height-- as when there are only still clips and text clips
 */
export const ensureNonZeroDimensions = (rect: st.dims) => {
  if (rect.width * rect.height > 0) return rect;
  else return FALLBACK_DIMENSIONS;
};
