import { MutableRefObject, useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';

export enum AllVideoPairNames {
  A = 'A',
  B = 'B',
  Scrub = 'Scrub',
}

/**
 * Return type for the hook; represents videojs instance and its paired
 * <video /> element
 */
export type VideoPair = {
  /**
   * Intended for use in preview effect code without needing
   * to use `.current`
   */
  video: HTMLVideoElement | null;
  /**
   * Intended for use in preview effect code
   */
  player: videojs.Player | null;
  /**
   * This is intended for passing into ref prop in JSX
   */
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  /**
   * Intended to help with debugging
   */
  pairName: AllVideoPairNames;
  /**
   * Helper for awaiting manifest loads
   */
  loadManifest: (url: string) => Promise<void>;
};

/**
 * Videojs is always initialized with a reference to a <video /> element, and
 * to interact with a video we typically need to deal with the video element
 * API in addition to the API of videojs itself. For this reason we initialize
 * them together and return them as a tuple we can interact with elsewhere
 */
export const useVideoPlayer = (pairName: AllVideoPairNames): VideoPair => {
  /**
   * Disabled VJS' default injection of style tags to the head which
   * set player dimensions.
   */
  window.VIDEOJS_NO_DYNAMIC_STYLE = true;
  /**
   * This style of ref initialization returns a `React.MutableRefObject`
   * we can modify under test. If we didn't pass `| null` but initalized
   * with `null`, we'd get back a  `React.RefObject`, which cannot be
   * modified under test.
   */
  const videoRef = useRef<HTMLVideoElement | null>(null);

  /**
   * Store a reference to the videojs player when initialized; should only be
   * set once
   */
  const [player, setPlayer] = useState<videojs.Player | null>(null);
  /**
   * Once the video element exists, initialize videojs player exactly once
   */
  useEffect(() => {
    if (videoRef.current && !player) {
      const videojsPlayer = videojs(
        videoRef.current,
        // options
        {
          // nukes all controls and DOM inside the video elm which
          // would otherwise be added by videojs
          children: [],
          autoplay: false,
          // see https://github.com/videojs/http-streaming
          html5: {
            vhs: {
              // bandwidth: 10000000000,
              limitRenditionByPlayerDimensions: false,
              // useDevicePixelRatio: true,
            },
          },
        },
      );
      setPlayer(videojsPlayer);
    }

    // videoRef.current is necessary, eslint is wrong
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, videoRef.current]);

  /**
   * A helper to transform videojs' event bus API into a promise-based
   * API
   */
  const loadManifest = (url: string): Promise<void> => {
    if (!player) return Promise.reject();
    return new Promise((resolve, reject) => {
      const onError = (err: Error) => {
        player.off('error', onError);
        reject(err);
      };
      player.on('error', onError);
      player.one('loadeddata', () => {
        player.off('error', onError);
        resolve();
      });
      player.src(url);
    });
  };

  return {
    videoRef,
    video: videoRef.current,
    player,
    pairName,
    loadManifest,
  };
};
