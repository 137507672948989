// import { Button } from '@mui/material/Button';
import {
  InsertInvitationDocument,
  InsertInvitationMutation,
  InsertInvitationMutationVariables,
  License_Seat_Roles_Enum,
  License_Seat_Tiers_Enum,
} from '@castify/studio/database-clients/graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import { z } from 'zod';
import { useRoleList, useTierList } from '../../hooks';
import { FormTextInput } from '../FormInputComponents';
import { FormRadioInput } from '../FormInputComponents/FormRadioInput/FormRadioInput';
import {
  filterRolesListBasedOnSeatMax,
  filterTierListBasedOnSeatMax,
} from './AddUserInvitationForm.helpers';
import styles from './AddUserInvitationForm.styles';
import {
  SubmitSnackBar,
  ISubmitSnackBarState,
} from '../SubmitSnackBar/SubmitSnackBar';

// #region Interfaces and Initial Values
const intialValues = {
  email: '',
  role: License_Seat_Roles_Enum.Member,
  tier: License_Seat_Tiers_Enum.Pro,
};

const initialSnackbarState: ISubmitSnackBarState = {
  open: false,
  message: '',
  severity: 'success',
};
interface AddUserInvitationFormProps {
  license_uuid: string;
  max_pro_member_seats: number;
  max_pro_restricted_seats: number;
  max_standard_member_seats: number;
  max_standard_restricted_seats: number;
  // set different initial values or for testing
  defaultValues?: typeof intialValues;
}
// #endregion Interfaces and Initial Values

const schema = z.object({
  email: z.string().email().default(''),
  role: z.string(),
  tier: z.string(),
});

export const AddUserInvitationForm = ({
  license_uuid,
  defaultValues = intialValues,
  ...maxSeats
}: AddUserInvitationFormProps) => {
  const [{ error }, executeMutation] = useMutation<
    InsertInvitationMutation,
    InsertInvitationMutationVariables
  >(InsertInvitationDocument);

  const { roleList } = useRoleList();
  const { tierList } = useTierList();

  const [snackBarState, setToastState] = useState(initialSnackbarState);

  const methods = useForm({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = async (form: typeof defaultValues) => {
    try {
      await executeMutation({
        ...form,
        license_uuid,
        email: form.email.toLowerCase(),
      });
      setToastState({
        message: `${form.email} has been invited to take a seat!`,
        open: true,
        severity: 'success',
      });
      reset();
    } catch (err) {
      setToastState({
        message: `Oh no! Something went wrong trying to invite ${form.email}.`,
        open: true,
        severity: 'error',
      });
      console.error('Error in onSubmit: ', err);
    }
  };

  const onError = async (_message: unknown) => {};
  useEffect(() => {
    return () => {
      setToastState(initialSnackbarState);
    };
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <SubmitSnackBar
        {...snackBarState}
        handleClose={() => setToastState({ ...snackBarState, open: false })}
      ></SubmitSnackBar>
      <Box data-testid="form-container" css={styles.container}>
        <FormProvider {...methods}>
          <FormTextInput
            dataTestId="form-email-input-id"
            name="email"
            label="Add by email"
            cssTextInput={styles.textInput}
          ></FormTextInput>
          <FormRadioInput
            data-testid="form-radio-input-role"
            name="role"
            label="Role"
            options={filterRolesListBasedOnSeatMax(maxSeats, roleList)}
          ></FormRadioInput>
          <FormRadioInput
            data-testid="form-radio-input-tier"
            name="tier"
            label="Tier"
            options={filterTierListBasedOnSeatMax(maxSeats, tierList)}
          ></FormRadioInput>
          <Button data-testid="submit-button-id" type="submit">
            Submit
          </Button>
          <Button data-testid="reset-button-id" onClick={() => reset()}>
            Reset
          </Button>
        </FormProvider>
      </Box>
    </form>
  );
};
