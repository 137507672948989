import { createContext, useContext } from 'react';
import { VideoPair } from '../playerHooks/useVideoPlayer';

export interface IVideoPairContext {
  pairA: VideoPair;
  pairB: VideoPair;
  scrubPair: VideoPair;
}

/**
 * This context provider allows passing the video pairs down to the tree
 */
export const VideoPairContext = createContext<IVideoPairContext | null>(null);

/**
 * Allows consulting the video context in children
 */
export const useVideoPairs = (): IVideoPairContext => {
  const players = useContext(VideoPairContext);
  if (!players)
    throw new Error(
      `${useVideoPairs.name} hook invoked outside a context provider`,
    );
  return players;
};
