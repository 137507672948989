import { observer } from 'mobx-react-lite';
import Player from '@castify/studio/Player';
import Toolbar from '@castify/studio/Toolbar';
import RightSidebar from '@castify/studio/RightSidebar';
import Timeline from '@castify/studio/Timeline';
import { EditShortcuts } from '@castify/studio/keyboard';
import Header from '@castify/studio/Header';
import { IngressFlows } from '@castify/studio/ingress-flows';
import EditFooter from './EditFooter/EditFooter';
import { useOwnedProjectData } from '@castify/studio/components/project-loaders';
import { LoadingTreatmentLogger } from '@castify/studio/components/loading';
import styles from './Edit.styles';
import { useMst } from '@castify/studio/studio-store';

const Edit = () => {
  const {
    project: { isLoaded },
    toolManager: { isAnyToolOpen },
    timeline: { hasText, hasAudio },
  } = useMst();

  /**
   * Set up project sync
   */
  useOwnedProjectData();

  return (
    <div role="main">
      {!isLoaded ? (
        <LoadingTreatmentLogger identifier="Edit" />
      ) : (
        <>
          <EditShortcuts />
          <IngressFlows />
        </>
      )}
      <div
        className="App"
        data-testid="editor-app"
        css={styles.appContainer(hasAudio, hasText, isAnyToolOpen)}
      >
        <div role="region" aria-label="Application Header" css={styles.header}>
          <Header showSkeleton={!isLoaded} />
        </div>
        <div role="region" aria-label="Video Player" css={styles.preview}>
          <Player showSkeleton={!isLoaded} />
        </div>

        {/* NOT CURRENTLY SUPPORTED */}
        <div
          role="region"
          aria-label="Tool Sidebar"
          css={styles.rightSidebar(isAnyToolOpen)}
        >
          {!isLoaded ? <RightSidebar /> : null}
        </div>

        <div role="region" aria-label="Editor Toolbar" css={styles.toolbar}>
          <Toolbar showSkeleton={!isLoaded} />
        </div>
        <div role="region" aria-label="Editor Timeline" css={styles.timeline}>
          <Timeline showSkeleton={!isLoaded} />
        </div>

        <div role="region" aria-label="Editor Footer" css={styles.footer}>
          <EditFooter showSkeleton={!isLoaded} />
        </div>
      </div>
    </div>
  );
};

export default observer(Edit);
