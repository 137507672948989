import React from 'react';
import styles from './DeleteClipButton.styles';
import { Fab, Skeleton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMst } from '@castify/studio/studio-store';
import { ButtonTooltip } from '@castify/studio/fe-common';
import { getShortcutString, shortcutConfigs } from '@castify/studio/keyboard';
import DeleteIcon from '@mui/icons-material/Delete';

interface DeleteButtonProps {
  showSkeleton?: boolean;
}

function DeleteButton({ showSkeleton }: DeleteButtonProps) {
  const {
    toolbar: { isDeleteButtonEnabled, deleteSelected },
  } = useMst();

  if (showSkeleton)
    return <Skeleton variant="rectangular" height={40} width={222} />;

  return (
    <ButtonTooltip
      title={
        isDeleteButtonEnabled
          ? `Delete Selected Clip (${getShortcutString(
              shortcutConfigs.Delete,
            )})`
          : 'Please Select A Clip'
      }
    >
      <Fab
        css={styles.button}
        data-pendo-id="add-media-button"
        disabled={!isDeleteButtonEnabled}
        onClick={deleteSelected}
        variant="extended"
        color="error"
      >
        <DeleteIcon css={styles.icon} />
        <Typography css={styles.font}>
          Delete&nbsp;Selected&nbsp;Clip
        </Typography>
      </Fab>
    </ButtonTooltip>
  );
}

export default observer(DeleteButton);
