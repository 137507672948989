import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

//Initial values in all styles will be mobile first and overridden by media queries
const styles = {
  button: css`
    text-transform: none;
    padding: 20px 10px;
  `,
};
export default styles;
