import { css } from '@emotion/react';

const styles = {
  errorPageContainer: css`
    height: 95vh;
    background-color: #fafafa;
  `,
  logoContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 60px;
    padding-left: 20px;
    padding-top: 20px;
    background-color: white;
  `,
  errorImage: css`
    height: 40%;
    width: 40%;
    min-height: 330px;
  `,
  errorContent: css`
    max-width: 960px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    @media screen and (max-width: 899px) {
      flex-direction: column;
      text-align: center;
    }
  `,
  errorLabel: css`
    font-family: sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 116.7%;
    letter-spacing: -0.01em;
    color: rgba(44, 44, 44, 0.87);
    margin: 0;
    @media screen and (max-width: 800px) {
      font-size: 34px;
      line-height: 120%;
    }
  `,
  errorDescription: css`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 175%;
    letter-spacing: 0.15px;
    color: #4f4f4f;
    @media screen and (max-width: 800px) {
      font-size: 16px;
      line-height: 150%;
    }
  `,
  helperText: css`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #707070;
    @media screen and (max-width: 800px) {
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.4px;
    }
  `,
  footer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid #a6a6a6;
    background-color: #fafafa;
    span,
    a {
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #707070;
      padding-left: 20px;
      padding-right: 20px;
    }
    a {
      margin-left: 20px;
    }
    @media screen and (max-width: 800px) {
      justify-content: space-between;
      a {
        margin-left: 0px;
      }
    }
  `,
  terms: css`
    @media screen and (max-width: 800px) {
      /* margin-right: 30px; */
    }
  `,
};
export default styles;
