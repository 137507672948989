import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton, Skeleton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useMst } from '@castify/studio/studio-store';
import { LeftSideDrawer } from '@castify/studio/LeftSidebar';
import styles from './SidebarButton.styles';

interface SidebarButtonProps {
  showSkeleton?: boolean;
}
function SidebarButtons({ showSkeleton }: SidebarButtonProps) {
  const {
    ingress: { isUploadInProgress },
  } = useMst();

  const [leftMenuOpen, setLeftMenuOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setLeftMenuOpen(open);
    };

  return (
    <div css={styles.buttonContainer}>
      {showSkeleton ? (
        <Skeleton variant="circular" height={40} width={40} />
      ) : (
        <IconButton
          size="large"
          aria-label="open menu drawer"
          data-pendo-id="open-sidebar-button"
          disabled={isUploadInProgress}
          onClick={toggleDrawer(true)}
          onKeyPress={toggleDrawer(true)}
        >
          <MenuIcon fontSize="inherit" />
        </IconButton>
      )}
      <LeftSideDrawer open={leftMenuOpen} onClose={toggleDrawer(false)} />
    </div>
  );
}

export default observer(SidebarButtons);
