import { GetAllProjectsQuery } from '@castify/studio/database-clients/graphql';

import styles from './ProjectBrowser.styles';
import ProjectTile from './ProjectTile';

interface IProjectBrowserProps {
  data: GetAllProjectsQuery | undefined;
}

export const ProjectBrowser = (props: IProjectBrowserProps) => {
  const { data } = props;

  // sort projects in order of most recently updated
  const sortedProjects = data?.projects
    ? data.projects.sort((projectA, projectB) => {
        return (
          Date.parse(projectB.updated_at) - Date.parse(projectA.updated_at)
        );
      })
    : [];
  return (
    <div>
      <div css={styles.projectGrid}>
        {sortedProjects.map((project) => {
          return <ProjectTile project={project} key={project.uuid} />;
        })}
      </div>
      <div css={styles.bottomSection}>
        <span css={styles.legacyLink}>
          Looking for older videos?{' '}
          {/* TODO: Dynamically generate link to different environments */}
          <a href="https://app.screencastify.com/videos">Click here</a>
        </span>
      </div>
    </div>
  );
};

export default ProjectBrowser;
