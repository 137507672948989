import { st } from '@castify/studio/fe-common';
import { TrimmableClip } from '@castify/studio/studio-store';
import { TrimHandleNames } from './TrimHandle';

/**
 * This hook is intended to be used inside the trim handle component.
 *
 * The clip or effect MST model instance passed in is expected to implement
 * an interface that allows trimming. There isn't a good way in MST to
 * document or enforce interface implementation, so we document it here,
 * in the caller.
 *
 * Trims begin with a call to `startTrim` and end with a call to `endTrim` on
 * the pointer down or poitner up events respectively.  Pointer move events
 * are delegated either to `handleTrimFromStart` or `handleTrimFromEnd`.
 */
export const useTrimHandler = (
  // clip being trimmed
  clipOrEffect: TrimmableClip,
  // is this a left or right trim
  trimHandleName: TrimHandleNames,
  // ref to the outermost div of the clip within the timeline
  clipContainerRef: React.RefObject<HTMLDivElement>,
) => {
  return {
    pointerDown: (pointerDownEvent: React.PointerEvent) => {
      if (!clipContainerRef.current) return;
      /**
       * At what pixel measured from the left side of the window is the
       * left edge of the clip/effect's container div?
       */

      const leftOffset: st.px =
        pointerDownEvent.clientX -
        clipContainerRef.current.getBoundingClientRect().left;

      /**
       * At what pixel measured from the left side of the window is the
       * right edge of the clip/effect's container div?
       */
      const rightOffset: st.px =
        clipContainerRef.current.getBoundingClientRect().right -
        pointerDownEvent.clientX;

      const pointerMove = (pointerMoveEvent: PointerEvent) => {
        /**
         * How far are we moving the pointer... right... now.
         */
        const movementX = pointerMoveEvent.movementX;

        /**
         * First we have to pick which handler to delegate movement to depending
         * on whether we're in a right or left trim.
         *
         * The trim handlers were first written to take in "deltas" describing
         * how far the pointer has just moved. We've since learned that using
         * deltas rather than the absolute position of the edge of the clip
         * to drive trimming makes some trim-related math difficult to reason
         * about, and we plan to refactor the code to use absolute positions.
         *
         * Another downside of passing deltas in to the model is that the models
         * cannot tell if the delta should actually translate to a move, as
         * the models do not know the offset of the original pointerdown event.
         * Thus it is possible to drag a clip handle well past a valid position,
         * and then nudge it in the other direction through moving the pointer
         * backwards-- where the correct behavior is to only move the handle
         * back when the mouse has passed the original location of the pointer
         * down event.
         *
         * This, switching away from deltas will be necessary over time both to
         * make the trim logic easier to understand and to help us eliminate
         * bugs that arise from delta use.
         *
         * To allow us to do this gradually, the trim handlers currently
         * take in both a delta and an absolute position; the intention is
         * to eventually remove the delta argument when it is no longer used,
         * following the strangler-fig pattern.
         *
         */
        if (trimHandleName === TrimHandleNames.left) {
          const newLeftSide: st.px = pointerMoveEvent.clientX - leftOffset;
          clipOrEffect.handleTrimFromStart(movementX, newLeftSide);
        } else if (trimHandleName === TrimHandleNames.right) {
          const newRightSide: st.px = pointerMoveEvent.clientX + rightOffset;
          clipOrEffect.handleTrimFromEnd(movementX, newRightSide);
        }
      };

      const pointerUp = () => {
        window.removeEventListener('pointermove', pointerMove);
        window.removeEventListener('pointerup', pointerUp);
        clipOrEffect.stopTrim();
      };

      clipOrEffect.startTrim();
      window.addEventListener('pointermove', pointerMove);
      window.addEventListener('pointerup', pointerUp);
    },
  };
};
