import { useGetAccessToken } from '@castify/studio/auth';
import { useEffect, useRef, useState } from 'react';
import { showDrivePicker } from './showDrivePicker';

/**
 * Hook to show the drive picker
 *
 * This is untested because the GAPI api is bonkers 😬
 */
export function useDrivePicker() {
  const getAccessToken = useGetAccessToken();

  /**
   * Have we received an error
   */
  const [error, setError] = useState<unknown>(null);

  /**
   *  Have we received a file ID
   */
  const [driveFileId, setDriveFileId] = useState<string | null>(null);

  /**
   * Has the picker been closed
   */
  const [pickerClosed, setPickerClosed] = useState(false);

  /**
   * Keep a ref to the drive picker that will allow us to tear it down
   * programatically if needed. Any because the picker is untyped
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const videoPickerRef = useRef<any>(null);

  /**
   * Show the picker when asked to do so. This effect declares a number of
   * dependencies, but `openPicker` is the only actually mutable one
   */
  useEffect(() => {
    /**
     * This shouldn't really be possible since the user has to have signed in
     * to get to this point, but it's better than an assertion.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gapi = (window as any).gapi;
    if (!gapi) {
      setError(new Error('Picker invoked before before GAPI loaded'));
    }

    gapi.load('picker', async () => {
      /**
       * We need an access token to show the picker, and it needs to be a valid
       * one
       */
      const picker = gapi.picker.api;
      let accessToken = '';
      try {
        accessToken = await getAccessToken();
      } catch (error) {
        // TODO: invalidate token
        setError(error);
      }
      if (!accessToken) return;

      /**
       * Show the picker & handle the result of the picking process
       */
      try {
        const driveFileId = await showDrivePicker(
          picker,
          accessToken,
          videoPickerRef,
        );

        if (!driveFileId) {
          setPickerClosed(true);
        } else {
          setDriveFileId(driveFileId);
        }
      } catch (error) {
        /**
         * If the picker errors our and we catch here,
         * need to close the picker programatically in order
         * to show the user the error dialog that will appear underneath
         */
        if (videoPickerRef.current) {
          videoPickerRef.current.dispose();
        }
        setError(error);
      }
    });
  }, [getAccessToken]);

  return { videoPickerRef, error, driveFileId, pickerClosed };
}
