import { StudioParams } from '@castify/studio/fe-common';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchAnyProject } from './useFetchAnyProject';
import { useMst } from '@castify/studio/studio-store';
import { useAutosaveScene } from './useAutosaveScene';

/**
 * This hook encapsulates concerns related to loading project data when
 * the user owns the project.
 *
 * This hook is intended to be used by all of the views which
 * involve editing a project owned by the user
 */
export const useOwnedProjectData = () => {
  const { project } = useMst();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const projectId = useParams<StudioParams>().projectId!;
  const navigate = useNavigate();

  /**
   * On page load, we need tell MST the ID of the project we are using.
   */
  useEffect(() => {
    project.setProjectId(projectId);
  }, [projectId, project]);

  /**
   * This hook fetches the project in subscription mode
   */

  const { data, fetchingError } = useFetchAnyProject({
    projectId,
  });
  /**
   * Redirect to a 404 page if there is no project or if the projectId
   * is an invalid param
   */
  useEffect(() => {
    if (data?.projects_by_pk === null || fetchingError?.graphQLErrors) {
      navigate('/video-not-found', { replace: true });
    }
  }, [data, fetchingError, navigate]);

  /**
   * This hook saves the scene to the DB when changes are made locally
   */
  const { savingError } = useAutosaveScene();
  if (savingError) throw savingError;
};
