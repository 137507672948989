import { st } from '@castify/studio/fe-common';

/**
 * Describes a single thumbnail to the UI
 */
export interface ThumbnailDescriptor {
  /**
   * The URL of the thumbnail image
   */
  url: string;
  /**
   * How far from the left of the thumbnail container the thumbnail
   * container should be rendered
   */
  thumbLeft: st.px;
  /**
   * The start time of the thumbnail. Included to assist with debugging
   * and not intended to be user-facing.
   */
  startTime: st.ms;
  /**
   * Is the thumbnail visible onscreen? Intended to help the UI
   * decide whether or not to actually display a thumbnail image /
   * kick off a request for the image.
   */
  onscreen: boolean;
}

/**
 * Interface for thumbnail data factory
 */
interface IBuildThumbnailDescriptors {
  /**
   * Total number of potential thumbnails to show
   */
  thumbnailContainerWidth: st.px;
  /**
   * Width of individual thumbnail
   */
  thumbnailWidth: st.px;
  /**
   * The timeline's zoom factor; pixels per ms
   */
  zoomFactor: st.ratio;
  /**
   * The trim in of the source
   */
  trimIn: st.ms;
  /**
   * The trim out of the source
   */
  trimOut: st.ms;
  /**
   * The base URL for thumbnails
   */
  baseThumbnailUrl: string;
}

/**
 * Generates data describing thumbnails to render within a clip.
 *
 * Begins with an array describing all possible thumbnails in an un-trimmed
 * "filmstrip" representing an untrimmed clip, then marking some thumbnails
 * as "not rendered" if they are not actually on screen.
 */
export const buildThumbnailDescriptors = ({
  thumbnailContainerWidth,
  thumbnailWidth,
  zoomFactor,
  trimIn,
  trimOut,
  baseThumbnailUrl,
}: IBuildThumbnailDescriptors): Array<ThumbnailDescriptor> => {
  return Array.from({
    length: Math.ceil(thumbnailContainerWidth / thumbnailWidth),
  }).map((_, i) => {
    const thumbLeft: st.px = i * thumbnailWidth;
    const exactStartTime: st.ms = thumbLeft / zoomFactor;
    const startTime: st.ms = Math.floor(exactStartTime / 1000) * 1000;
    return {
      startTime,
      thumbLeft,
      url: `${baseThumbnailUrl}${startTime.toString(10).padStart(10, '0')}.jpg`,
      onscreen: isThumbOnscreen({
        thumbLeft: thumbLeft,
        trimIn,
        trimOut,
        zoomFactor,
        thumbnailWidth,
      }),
    };
  });
};

/**
 * Interface for thumbnail-hiding helper. See IBuildThumbnailDescriptors for
 * documentation
 */
interface IisThumbOnscreen {
  thumbLeft: st.px;
  trimIn: st.ms;
  trimOut: st.ms;
  zoomFactor: st.ratio;
  thumbnailWidth: st.px;
}

/**
 * Tells us if a thumbnail is visible within the container which consists of
 * the trimmed clip. Thumbnails that have been "trimmed out" of the clip are
 * invisible
 */
const isThumbOnscreen = ({
  thumbLeft,
  trimIn,
  trimOut,
  zoomFactor,
  thumbnailWidth,
}: IisThumbOnscreen): boolean => {
  const rangeStart = trimIn * zoomFactor;
  const rangeEnd = trimOut * zoomFactor;
  const thumbRight = thumbLeft + thumbnailWidth;

  return (
    // does thumb start within the visible range?
    (thumbLeft >= rangeStart && thumbLeft < rangeEnd) ||
    // does thumb end within the visible range?
    (thumbRight >= rangeStart && thumbRight < rangeEnd) ||
    // does thumb start before the visible range and end after the visible range?
    (thumbLeft <= rangeStart && thumbRight >= rangeEnd)
  );
};
