/*!
 * Copyright 2022 Screencastify LLC
 */
import { createBrowserLogger } from './browser-logger';
import type { IBrowserLogger } from './browser-logger';
import { useEffect, useState } from 'react';
import ErrorPage from '@castify/studio/components/error-page';

type JSXPropsWithChildren = {
  children: JSX.Element;
};

// export both for unit test
export const logger: IBrowserLogger = createBrowserLogger(
  'GlobalRejectionHandler',
);
export let listener: (e: PromiseRejectionEvent) => void;

/**
 * This functional component catches uncaught promise rejections and renders a fallback UI.
 * This technique is very useful for logging purposes but also allows you a safe fallback any time
 * that a catch method is not used when it should be.
 */
export function OnUnhandledRejection({
  children,
}: JSXPropsWithChildren): JSX.Element {
  const [event, setEvent] = useState<null | PromiseRejectionEvent>(null);

  useEffect(() => {
    listener = (e) => {
      if (e.reason instanceof Error) {
        // TODO: below format can probably be configured in Browser Logger
        // but the idea is if it's an Error object, always log out the message and stack
        logger.error('An unhandled promise rejection is caught:', {
          error: e.reason,
          message: e.reason.message,
          stack: e.reason.stack,
          promise: e.promise,
        });
      } else {
        logger.error('An unhandled promise rejection is caught:', {
          error: e.reason,
          promise: e.promise,
        });
      }
      setEvent(e);
      // prevent default handling
      e.preventDefault();
    };
    /**
     * Assign our own handling method to the global event listener as a side effect,
     * window needs to be explicitly called for jest unit test.
     */
    window.addEventListener('unhandledrejection', listener);

    return () => {
      // clean up event listener before this component unmounts
      window.removeEventListener('unhandledrejection', listener);
    };
  }, []);

  if (event) {
    return <ErrorPage errorType="contactSupport" />;
  } else {
    return children;
  }
}
