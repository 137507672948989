import { st } from '@castify/studio/fe-common';

/**
 * Helper to convert milliseconds to HMS. Millisecond remainder is discarded
 */
export function msToHms(ms: st.ms): st.hms {
  let s = ms / 1000;
  const h = Math.floor(s / 3600);
  s = s % 3600;
  const m = Math.floor(s / 60);
  s = s % 60;
  return { h, m, s };
}

/**
 * Format HMS for display. If H and M and S are not int, discards their
 * fractional component.
 *
 * Returns `mm:ss` if less than an hour; otherwise `hh:mm:ss`. If `h` is
 * only one digit, it does not get padded to 2; by contrast, `m` and `s`
 * are always padded.
 */
export function formatHms(hms: st.hms): string {
  const hours = hms.h ? hms.h.toFixed(0) : '';
  const minutes = hms.m.toFixed(0).padStart(2, '0');
  const seconds = hms.s.toFixed(0).padStart(2, '0');
  const minutesSeconds = `${minutes}m ${seconds}s`;
  return hours ? hours + 'h ' + minutesSeconds : minutesSeconds;
}

// TODO: these types and calculateDuration will go away when this issue is completed
// https://github.com/screencastify/castify/issues/2460
interface IMainTrackClip {
  source: {
    duration?: number;
    trimIn: number;
    trimOut: number;
  };
}
interface IScene {
  mainTrack: {
    mainTrackClips: IMainTrackClip[];
  };
}
export function calculateDuration(scene: IScene) {
  if (!scene.mainTrack) return 0;
  return scene.mainTrack.mainTrackClips.reduce(
    (totalDuration: number, currentClip: IMainTrackClip) => {
      if (currentClip.source.duration)
        return totalDuration + currentClip.source.duration;
      else {
        return (
          totalDuration + currentClip.source.trimOut - currentClip.source.trimIn
        );
      }
    },
    0,
  );
}

export function formatDate(timestamp: string) {
  const date = new Date(timestamp);

  return `${date.toLocaleString('en-us', {
    month: 'short',
  })} ${date.getDate()}, ${date.getFullYear()}`;
}
