import * as Types from '../generated-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetManagedLicensesQueryVariables = Types.Exact<{
  roleType?: Types.Maybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  seat_uuid_clause?: Types.Maybe<Types.Uuid_Comparison_Exp>;
}>;

export type GetManagedLicensesQuery = {
  __typename?: 'query_root';
  licenses: Array<{
    __typename?: 'licenses';
    uuid: string;
    max_pro_member_seats: number;
    max_pro_restricted_seats: number;
    max_standard_member_seats: number;
    max_standard_restricted_seats: number;
    license_invitations: Array<{
      __typename?: 'license_invitations';
      email: string;
      created_at: string;
      role: string;
      seat_uuid?: string | null | undefined;
      uuid: string;
      license_uuid: string;
    }>;
  }>;
};

export type GetManagedLicenseAutoAssignDomainsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetManagedLicenseAutoAssignDomainsQuery = {
  __typename?: 'query_root';
  licenses: Array<{
    __typename?: 'licenses';
    uuid: string;
    auto_assign_domains: Array<{
      __typename?: 'auto_assign_domains';
      domain: string;
      role: Types.License_Seat_Roles_Enum;
      uuid: string;
    }>;
  }>;
};

export const GetManagedLicensesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetManagedLicenses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleType' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seat_uuid_clause' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'uuid_comparison_exp' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'license_invitations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'role' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_in' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'roleType' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'seat_uuid' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'seat_uuid_clause' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seat_uuid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'license_uuid' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'max_pro_member_seats' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'max_pro_restricted_seats' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'max_standard_member_seats' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'max_standard_restricted_seats',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManagedLicensesQuery,
  GetManagedLicensesQueryVariables
>;
export const GetManagedLicenseAutoAssignDomainsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetManagedLicenseAutoAssignDomains' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auto_assign_domains' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deleted_at' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_is_null' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domain' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManagedLicenseAutoAssignDomainsQuery,
  GetManagedLicenseAutoAssignDomainsQueryVariables
>;
