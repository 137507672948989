import { useMst } from '@castify/studio/studio-store';

import { useHotkeys } from 'react-hotkeys-hook';
import { getShortcutString, ShortcutConfig } from './shortcutsConfig';

/**
 * This small helper hook wraps useHotkeys; it exists for two reasons:
 * 1. ensure we always call `preventDefault` so as to block existing browser
 * shortcuts from firing
 * 2. Allow us to pass in a keyboard shortcut config object so calling
 * the hook has a straightforwad syntax
 *
 * The `react-hotkeys-hook` documentation is not easy to find; here is a
 * {@link https://react-hotkeys-hook.vercel.app/docs/api/use-hotkeys}
 */
export const useKeyboardShortcut = (config: ShortcutConfig) => {
  const root = useMst();

  useHotkeys(
    getShortcutString(config),

    /**
     * This callback runs when the key is pressed
     */
    (event: KeyboardEvent) => {
      // here we disable any browser-defined shortcuts
      event.preventDefault();
      config.action(root, event);
    },
    {
      enabled: config.enabled(root),
    },
  );
};
