import {
  IAnyStateTreeNode,
  Instance,
  types,
  getParentOfType,
} from 'mobx-state-tree';
import RootModel from '../root.mst';
import { st } from '@castify/studio/fe-common';
import { getPlayback } from '../playback/playback.mst';
import { getScene } from '../project/scene/scene.mst';
import { IClip } from '../project/scene/clip.mst';
import ZoomModel from './zoom/zoom.mst';
import { calculateScrollbarPositionWhilePlaying } from './helpers/scrollbarHelpers';

/**
 * Helper for clamping a value between a minimum and maximum
 */
const clamp = (val: number, min: number, max: number) =>
  Math.min(Math.max(min, val), max);

/**
 * State, views, & actions backing timeline. Unclear just what here will need
 * to be shared with a future Watch app.
 */
const TimelineModel = types
  .model('TimelineModel', {
    /**
     * Child model which encapsulates zoom-related concerns
     */
    zoom: types.optional(ZoomModel, {}),
    /**
     * Timeline left offset in pixels-- expected to be updated based on a
     * resize handler. Intended to help calculate how much time should be
     * visible to the user on the timeline
     */
    timelineElementLeft: types.optional(types.number, 0),

    /**
     * Timeline width in pixels-- expected to be updated based on a
     * resize handler. Intended to help calculate how much time should be
     * visible to the user on the timeline
     */
    timelineElementWidth: types.optional(types.number, 0),

    /**
     * The position of the scrollbar expressed in MS
     */
    scrollTime: types.optional(types.number, 0),

    /**
     * Added distance during trim operations to ensure that the mouse
     * lines up with what the user is dragging. This is in milliseconds.
     */
    temporaryOffset: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      /**
       * Returns the scrub offset in pixels when scrubbing,
       * undefined when not scrubbing
       */
      get scrubPosition(): st.px | undefined {
        const { scrubTime, isPlaying } = getPlayback(self);
        if (scrubTime === undefined) return undefined;
        if (isPlaying) return undefined;
        else {
          return (scrubTime - self.scrollTime) * self.zoom.zoomFactor;
        }
      },
      /**
       * Returns the playhead offset in pixels
       * Playhead is equal the player head when its playing
       * and will equal to seek time when the player is paused
       */
      get playheadPosition(): st.px {
        const playheadPosition = getPlayback(self).playheadPosition;
        return (playheadPosition - self.scrollTime) * self.zoom.zoomFactor;
      },
      /**
       * The position of the start of the timeline expressed
       * in milliseconds.
       */
      get visibleStart(): st.ms {
        return self.scrollTime + self.temporaryOffset;
      },
      /**
       * The duration in ms of the visible region of the timeline
       */
      get visibleDuration(): st.ms {
        return self.timelineElementWidth / self.zoom.zoomFactor;
      },
      /**
       * The the position of the end of the timeline
       * expressed in milliseconds.
       */
      get visibleEnd(): st.ms {
        return self.scrollTime + this.visibleDuration;
      },
      /**
       * Width of the scrollbar at the bottom of the timeline
       */
      get scrollWidth(): st.px {
        const { totalDuration } = getScene(self).mainTrack;
        if (this.visibleDuration >= totalDuration)
          return self.timelineElementWidth;
        return (
          (this.visibleDuration / totalDuration) * self.timelineElementWidth
        );
      },
      /**
       * Distance from the start for the scrollbar at the bottom of the timeline
       *
       */
      get scrollLeftOffset(): st.px {
        return (
          (self.scrollTime / getScene(self).mainTrack.totalDuration) *
          self.timelineElementWidth
        );
      },

      /**
       * Does the scene have text clips?
       */
      get hasText(): boolean {
        const { textTrack } = getScene(self);
        return !!textTrack.textClips.length;
      },
      /**
       *  Does the scene have detached audio clips?
       */
      get hasAudio(): boolean {
        const { detachedAudioTrack } = getScene(self);
        return !!detachedAudioTrack.audioClips.length;
      },

      /**
       * The clip being swapped if drag/swap is active
       */
      get swappingClip(): IClip | undefined {
        return getScene(self).mainTrack.mainTrackClips.find(
          (clip) => clip.isBeingDragged,
        );
      },
      /**
       * Converts a given pixel value corresponding to a timeline click
       * to a timestamp based on scroll, zoom, offset
       */
      pixelToTime(clientX: st.px): st.ms {
        return (
          self.scrollTime +
          (clientX - self.timelineElementLeft) / self.zoom.zoomFactor
        );
      },
    };
  })
  .actions((self) => {
    return {
      /**
       * A setter for timeline pixel width
       */
      setElementPosition(dims: { left: st.px; width: st.px }): void {
        self.timelineElementLeft = dims.left;
        self.timelineElementWidth = dims.width;
      },
      /**
       * Handler for mouse moving within timeline
       */
      initiateScrubOnPointerMove(e: React.PointerEvent | PointerEvent): void {
        getPlayback(self).setScrubTime(self.pixelToTime(e.clientX));
      },
      /**
       * Handler for mouse leaving timeline
       */
      endScrubOnPointerLeave(): void {
        getPlayback(self).endScrub();
      },
      /**
       * Triggers a seek. Used directly in the timeline backgrund div
       * but may also be used to trigger seeks on other elements due to event
       * propagation challenges (search for references to this action
       * to see why this is the case)
       */
      initiateSeekFromPointerEvent(e: React.PointerEvent | PointerEvent): void {
        // TODO: ongoing playback during seek is temporarily disabled
        if (getPlayback(self).isPlaying) getPlayback(self).pause();
        getPlayback(self).setSeekTime(self.pixelToTime(e.clientX));
      },

      /**
       * Moves the scrollbar
       */
      handleScrollbarMove(scrollbarLeftEdge: st.px): void {
        /**
         * This early return handles the edge case where the user is zoomed
         * all the way out the visible space of the timeline is longer than the
         * actual length of the scene. In this case, the scrollbar should
         * be locked in the zero position.
         */
        if (self.visibleDuration >= getScene(self).mainTrack.totalDuration) {
          self.scrollTime = 0;
          return;
        }

        /**
         * How far is the proposed new left edge of the scrollbar from
         * the left side of the scrollbar container, expressed as a percent?
         */
        const percentFromZero: st.ratio =
          (scrollbarLeftEdge - self.timelineElementLeft) /
          self.timelineElementWidth;

        // convert the space between left edge and container to time
        const timeFromZero =
          percentFromZero * getScene(self).mainTrack.totalDuration;
        this.setScrollbarTime(timeFromZero);
      },

      /**
       * sets the scrollbar position and constrain the value to be between min/max
       */
      setScrollbarTime(position: st.ms) {
        let maximumScrollPosition =
          getScene(self).mainTrack.totalDuration - self.visibleDuration;

        // if visibleDuration is bigger than total duration, then we want
        // to set maximum as 0
        if (maximumScrollPosition < 0) {
          maximumScrollPosition = 0;
        }

        const clampedScroll = clamp(position, 0, maximumScrollPosition);

        self.scrollTime = clampedScroll;
      },

      /**
       * Shifts the scrollbar to contain the playhead if
       * it doesn't already.
       */
      ensurePlayheadInView(): void {
        const scrollTime = calculateScrollbarPositionWhilePlaying(
          self.scrollTime,
          getPlayback(self).playbackClock,
          self.visibleStart,
          self.visibleEnd,
          self.zoom.zoomFactor,
        );

        this.setScrollbarTime(scrollTime);
      },
      /**
       * Changes the distance between the first clip and the start
       * of the timeline so that the user can tell how much they're
       * editing by
       */
      setTemporaryOffset(amount: st.ms) {
        self.temporaryOffset = amount;
      },
    };
  });
export default TimelineModel;

/**
 * A utility function to help navigate to this node from anywhere in the MST.
 */
export function getTimeline(self: IAnyStateTreeNode): ITimeline {
  const root = getParentOfType(self, RootModel);
  return root.timeline;
}

export interface ITimeline extends Instance<typeof TimelineModel> {}
