import { colors } from '@castify/studio/fe-common';
import { css } from '@emotion/react';

const styles = {
  container: css`
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `,
  playbackControls: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  `,
  playclock: css`
    color: white;
  `,
  fullscreenButtonContainer: css`
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
};
export default styles;
