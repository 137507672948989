import { css } from '@emotion/react';
import { colors } from '@castify/studio/fe-common';

const styles = {
  appContainer: css`
    width: 100vw;
    height: 100vh;
    background-color: ${colors.TEMP.PreviewBackground};

    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 70px minmax(0, 1fr) 120px;
    grid-template-areas:
      'header'
      'preview'
      'footer';
    user-select: text;
  `,
  header: css`
    grid-area: header;
  `,
  preview: css`
    grid-area: preview;
    position: relative;
    padding-top: 30px;
    @media only screen and (min-width: 576px) {
      margin-right: 10%;
      margin-left: 10%;
    }
    height: 100%;
  `,
  footer: css`
    grid-area: footer;
    z-index: 2;
  `,
};
export default styles;
